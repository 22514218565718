import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import { cloneDeep, uniq } from 'lodash-es';
import Vue from 'vue';
import { ProductSearchSortTarget, ProductSearchSortOrder, BooleanLike } from '~/enums';

/** State */
export interface State {
  // 上限・下限金額
  priceMin: string | null;
  priceMax: string | null;
  // レビュー評価
  reviewPoint: string | null;
  // 条件
  isFreeShipping: BooleanLike.TRUE | null;
  isNew: BooleanLike.TRUE | null;
  isDiscount: BooleanLike.TRUE | null;
  // 在庫状況
  hasStock: BooleanLike.TRUE | null;
  // 入庫ステータス
  cellarStatus: string | null;
  // タイプ
  color: string | null;
  colors: string[];
  // 産地
  areas: string[];
  // 品種
  varieties: string[];
  // 評価誌
  mediaRatings: string[];
  // ボディ
  tasteMin: string | null;
  tasteMax: string | null;
  // 甘口度合い
  sweetnesses: string[];
  // サイズ
  sizes: string[];
  // ヴィンテージ
  vintage: string | null;
  vintages: string[];
  // ヴィンテージ情報
  vintageInfo: string | null;
  // 格付け
  classes: string[];
  // 生産者・ブランド
  producer: string | null;
  // セット本数
  setSizes: string[];
  // 収納本数
  cellarSizes: string[];
  // プリムールサイズ・本数
  primeurSizes: string[];
  // カテゴリ
  categories: string[];
  // フリーワード検索
  word: string | null;
  // 味わい
  sense: string | null;
  // コード
  code: string | null;
  // 原産地呼称
  aoc: string | null;
  // シーン・キーワード(タグ)
  tags: string[];
  // スタイル
  styles: string[];
  // ソート
  sortTarget: ProductSearchSortTarget | null;
  sortOrder: ProductSearchSortOrder | null;
}

export const initialState: State = {
  priceMin: null,
  priceMax: null,
  reviewPoint: null,
  isFreeShipping: null,
  isNew: null,
  isDiscount: null,
  hasStock: null,
  cellarStatus: null,
  color: null,
  colors: [],
  areas: [],
  varieties: [],
  mediaRatings: [],
  tasteMin: null,
  tasteMax: null,
  sweetnesses: [],
  sizes: [],
  vintage: null,
  vintages: [],
  vintageInfo: null,
  classes: [],
  producer: null,
  setSizes: [],
  cellarSizes: [],
  primeurSizes: [],
  categories: [],
  word: null,
  sense: null,
  code: null,
  aoc: null,
  tags: [],
  styles: [],
  sortTarget: null,
  sortOrder: null,
};

export const state = (): State => cloneDeep(initialState);

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setFieldValue<Field extends keyof State>(state: State, payload: { field: Field; value: State[Field] }): void {
    const nextValue: any = Array.isArray(payload.value) ? uniq(payload.value) : payload.value;

    Vue.typedSet(state, payload.field, nextValue);
  },
  resetFieldValue<Field extends keyof State>(state: State, field: Field): void {
    Vue.typedSet(state, field, initialState[field]);
  },
  resetAll(state: State): void {
    Object.entries(initialState).forEach(([key, value]) => Vue.set(state, key, cloneDeep(value)));
  },
};

/** Actions */
export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;
export const actions = {};

/** Store Module Type */
export type Store = DefineStoreModule<'product/filter', State, typeof getters, typeof mutations, typeof actions>;
