import { FormCheckboxOption, FormRadioOption } from '~/types/ui';

// FIXME:URLでの値が以下のvalueと異なるので要確認（現在はECでの値に合わせている）
/** イベント一覧カテゴリ */
export const EVENT_CATEGORY_OPTIONS: (FormRadioOption & { from?: number; to?: number })[] = [
  {
    text: 'イベント',
    value: 'EVENT',
  },
  {
    text: 'ディナー・ランチ',
    value: 'DINNER_LUNCH',
  },
  {
    text: 'テイスティング',
    value: 'TASTING',
  },
  {
    text: 'セミナー',
    value: 'SEMINAR',
  },
  {
    text: 'その他',
    value: 'OTHER',
  },
];

export const EVENT_CATEGORY_ALL = '全て';

// FIXME:URLでの値が以下のvalueと異なるので要確認（現在はECでの値に合わせている）
/** イベント一覧テイスティングワイン  */
export const EVENT_TASTING_WINE_OPTIONS: FormCheckboxOption[] = [
  {
    text: '赤ワイン',
    value: 'RED',
  },
  {
    text: '白ワイン',
    value: 'WHITE',
  },
  {
    text: 'ロゼワイン',
    value: 'ROSE',
  },
  {
    text: 'スパークリング',
    value: 'SPARKLING',
  },
  {
    text: 'その他',
    value: 'OTHER',
  },
];

/** イベント一覧：1リクエストで取得するイベント数 */
export const EVENTS_LIMIT_PER_PAGE = 30;

/** イベント一覧：検索ボックスの入力上限 */
export const EVENT_SEARCH_WORD_LIMIT = 38;

export const EVENT_RESERVATION_STATUS_CANCELED = 'CANCELED';

export const CANCELLED_EVENT_MESSAGE = '指定されたイベントは、キャンセル済みの為予約できません。';
