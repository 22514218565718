


















































































































































































































import { defineComponent } from '@nuxtjs/composition-api';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import AppImage from '~/components/partials/App/AppImage.vue';
import AppLink from '~/components/partials/App/AppLink.vue';
import { COLOR_MENU, GRAPE_MENU, SCENE_MENU, AREA_MENU, KEYWORD_MENU, OTHER_MENU, OTHER_ITEM_MENU } from '~/constants';
import { url } from '~/utils';
import { BooleanLike } from '~/enums';

export default defineComponent({
  components: {
    AppIcon,
    AppImage,
    AppLink,
  },
  props: {},
  setup(props, { emit }) {
    const handleOutsideClick = () => {
      emit('handleOutsideClick');
    };
    const toggleSubMenu = () => {
      emit('toggleSubMenu');
    };

    return {
      url,
      BooleanLike,
      handleOutsideClick,
      toggleSubMenu,
      COLOR_MENU,
      GRAPE_MENU,
      SCENE_MENU,
      AREA_MENU,
      KEYWORD_MENU,
      OTHER_MENU,
      OTHER_ITEM_MENU,
    };
  },
});
