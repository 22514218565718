import { AREA_ID } from './area';

// 一覧画面の一度に表示する数
export const DISPLAYED_RESULTS = 18;
// おどるワイン一覧画面の一度に表示する数
export const DISPLAYED_ARTICLES_NUM = 12;
// 商品一覧項目の一度に表示する数
export const DISPLAY_ITEMS_NUM = 16;
// お知らせ一覧画面の一度に表示する数
export const DISPLAYED_NEWS_NUM = 20;
// ショップ詳細画面に表示するお知らせ数
export const DISPLAYED_SHOP_NEWS_NUM = 4;

// ショップ一覧に表示するお知らせの上限
export const DISPLAYED_SHOP_LIST_NEWS_NUM = 3;

// タグ検索結果一覧画面の各コンテンツ一度に表示する数
export const TAG_SEARCH_DISPLAY_ITEMS_NUM = 6;

// タグに紐づく特集を一覧画面に一度に表示する数
export const TAG_ARCHIVE_DISPLAYED_RESULTS = 3;

// お知らせ記事年度検索開始日
export const NEWS_SEARCH_START_DATE = '04010000';
// お知らせ記事年度検索終了日
export const NEWS_SEARCH_END_DATE = '03312359';

// ギフトページのパーマリンク
export const ARCHIVE_GIFT = 'GY';

// PDFのベースディレクトリ
export const S3_BASE_FILE_DIR = '/s3/lp/files/';

// 特集のキーワードの最大文字数
export const ARCHIVES_KEYWORD_MAXLENGTH = 38;

// お気に入り一覧画面に一度に表示する数
export const DISPLAYED_FAVORITES = 20;

// 記事カードパーツのdescriptionの最大文字数
export const MAGAZINE_CARD_DESCRIPTION_MAX_COUNT = 60;

// 特集の左カラムの項目
export const ARCHIVES_SIDE_MENU = {
  wine: 'ワインタイプ',
  area: '産地',
};
// 読み物の左カラムの項目
export const ARTICLE_SIDE_MENU = 'カテゴリ';

export const ARCHIVES_WINE_TYPE_MENU = [
  { query: 'red_wine', text: '赤ワイン' },
  { query: 'white_wine', text: '白ワイン' },
  { query: 'sparkling_wine', text: 'スパークリングワイン' },
  { query: 'mix_wine', text: 'ミックス' },
  { query: 'other_wine', text: 'その他ワイン' },
  { query: 'other_than_wine', text: 'ワイン以外' },
];

export const ARCHIVES_LOCALES_MENU = [
  { query: 'BORDEAUX', text: 'ボルドー', countryId: AREA_ID.FRANCE, topParts: 'parts.bordeaux_feature' as const },
  { query: 'BOURGOGNE', text: 'ブルゴーニュ', countryId: AREA_ID.FRANCE, topParts: 'parts.bourgogne_feature' as const },
  {
    query: 'CHAMPAGNE',
    text: 'シャンパーニュ',
    countryId: AREA_ID.FRANCE,
    topParts: 'parts.champagne_feature' as const,
  },
  {
    query: 'FRANCE_AND_OTHER',
    text: 'その他フランス',
    countryId: AREA_ID.FRANCE,
    topParts: 'parts.france_feature' as const,
  },
  { query: 'ITALIA', text: 'イタリア', countryId: AREA_ID.ITALIA, topParts: 'parts.italy_feature' as const },
  {
    query: 'CALIFORNIA',
    text: 'カリフォルニア',
    countryId: AREA_ID.USA,
    topParts: 'parts.california_feature' as const,
  },
  { query: 'JAPAN', text: '日本', countryId: AREA_ID.JAPAN, topParts: 'parts.japan_feature' as const },
  {
    query: 'OTHER_COUNTRIES_REGIONS',
    text: 'その他国・地域',
    countryId: 'others',
    topParts: 'parts.other_countries_feature' as const,
  },
];

// 読みもの一覧取得APIのタイプ
export const FETCH_ARTICLES_TYPE = {
  INITIAL: 'initial',
  NORMAL: 'normal',
};

// 読みもの一覧絞り込みの
export const ARTICLES_STATUS = {
  AUTHOR: 'author',
  CATEGORY: 'category',
};

export const COUNTRIES = {
  FRANCE: 'FRANCE',
  OTHER_FRANCE: 'other france',
  ITALIA: 'ITALIA',
  OTHER_ITALIA: 'Other Italia',
  GERMANY: 'GERMANY',
  SPAIN: 'SPAIN',
  PORTUGAL: 'PORTUGAL',
  USA: 'USA',
  OTHER_USA: 'Other Usa',
  CHILE: 'CHILE',
  NEW_ZEALAND: 'NEW ZEALAND',
  AUSTRALIA: 'AUSTRALIA',
  ARGENTINA: 'ARGENTINA',
  JAPAN: 'JAPAN',
  OTHER_JAPAN: 'Other Japan',
  OTHER: 'OTHER',
};

// FIXME: マスタ移行後id更新&queryいらないかも
export const PRODUCER_AREA_MENU = [
  { query: undefined, text: 'すべての産地' },
  {
    query: COUNTRIES.FRANCE,
    text: 'フランス',
    regions: [
      { query: 'BORDEAUX', text: 'ボルドー', isOtherFlag: false },
      { query: 'BOURGOGNE', text: 'ブルゴーニュ', isOtherFlag: false },
      { query: 'CHAMPAGNE', text: 'シャンパーニュ', isOtherFlag: false },
      { query: 'LOIRE', text: 'ロワール', isOtherFlag: false },
      { query: 'RHONE', text: 'ローヌ' },
      { query: 'LANGUEDOC ROUSSILLON', text: 'ラングドック・ルーション' },
      { query: 'PROVENCE', text: 'プロヴァンス', isOtherFlag: false },
      { query: 'ALSACE', text: 'アルザス', isOtherFlag: false },
      { query: COUNTRIES.OTHER_FRANCE, text: 'フランスその他', isOtherFlag: true },
    ],
  },
  {
    query: COUNTRIES.ITALIA,
    text: 'イタリア',
    regions: [
      { query: 'PIEMONTE', text: 'ピエモンテ', isOtherFlag: false },
      { query: 'LOMBARDIA', text: 'ロンバルディア', isOtherFlag: false },
      { query: 'TOSCANA', text: 'トスカーナ', isOtherFlag: false },
      { query: 'VENETO', text: 'ヴェネト' },
      { query: 'FRIULI VENEZIA GIULIA', text: 'フリウリ・ヴェネツィア・ジュリア' },
      { query: 'UMBRIA', text: 'ウンブリア', isOtherFlag: false },
      { query: 'PUGLIA', text: 'プーリア', isOtherFlag: false },
      { query: 'SICILIA', text: 'シチリア', isOtherFlag: false },
      { query: 'SARDEGNA', text: 'サルデーニャ', isOtherFlag: false },
      { query: COUNTRIES.OTHER_ITALIA, text: 'イタリアその他', isOtherFlag: true },
    ],
  },
  { query: COUNTRIES.GERMANY, text: 'ドイツ' },
  { query: COUNTRIES.SPAIN, text: 'スペイン' },
  { query: COUNTRIES.PORTUGAL, text: 'ポルトガル' },
  {
    query: COUNTRIES.USA,
    text: 'アメリカ',
    regions: [
      { query: 'CALIFORNIA', text: 'カリフォルニア', isOtherFlag: false },
      { query: COUNTRIES.OTHER_USA, text: 'アメリカその他', isOtherFlag: true },
    ],
  },
  { query: COUNTRIES.CHILE, text: 'チリ' },
  { query: COUNTRIES.ARGENTINA, text: 'アルゼンチン' },
  { query: COUNTRIES.NEW_ZEALAND, text: 'ニュージーランド' },
  { query: COUNTRIES.AUSTRALIA, text: 'オーストラリア' },
  {
    query: COUNTRIES.JAPAN,
    text: '日本',
    regions: [
      { query: 'YAMANASHI', text: '山梨', isOtherFlag: false },
      { query: 'NAGANO', text: '長野', isOtherFlag: false },
      { query: 'HOKKAIDO', text: '北海道', isOtherFlag: false },
      { query: COUNTRIES.OTHER_JAPAN, text: '日本その他', isOtherFlag: true },
    ],
  },
  { query: COUNTRIES.OTHER, text: 'その他生産地' },
];

// 生産者のソートの選択肢
export const PRODUCER_SORT_OPTIONS = [
  {
    label: '新着順',
    values: [
      {
        param: 'new_label_flag_i',
        sort: 'desc',
      },
      {
        param: 'sort_new_label_start_d',
        sort: 'desc',
      },
      {
        param: 'producer_name_s',
        sort: 'asc',
      },
    ],
  },
  {
    label: 'アルファベット順',
    values: [
      {
        param: 'producer_name_english_s',
        sort: 'asc',
      },
    ],
  },
  {
    label: 'カタカナ順',
    values: [
      {
        param: 'producer_name_s',
        sort: 'asc',
      },
    ],
  },
];

// 読み物のソートの選択肢
export const ARTICLE_SORT_OPTIONS = [
  { label: '新着順', value: 'desc', param: 'article_publish_start_date_d' },
  { label: '公開の古い順', value: 'asc', param: 'article_publish_start_date_d' },
  { label: '人気順', value: 'desc', param: 'rnk_a1_7d_i' },
];

// 特集のソートの選択肢
export const ARCHIVE_SORT_OPTIONS = [
  { label: '新着順', value: 'desc', param: 'archive_publish_at_d' },
  { label: '公開の古い順', value: 'asc', param: 'archive_publish_at_d' },
  { label: '人気順', value: 'desc', param: 'rnk_a1_7d_i' },
];

// 記事ソートタイプ
export const SORT_TYPE = {
  ARCHIVE: 'archive',
};

// お知らせ記事のカテゴリ
export const NEWS_TYPE = {
  notice: 'お知らせ',
  campaign: 'キャンペーン',
  store_information: '店舗情報',
  other: 'その他',
};

// ワイン紹介のレビューの評価値
export const WINE_INTRODUCTION_REVIEW_THRESHOLD = 3;

// karte用生産者特集カテゴリのコード定義表(外部連携似合わせる)
// https://docs.google.com/spreadsheets/d/1HFTugJMr_RUGJ1Yk7Oh_cNn_Gf9egWC5/edit#gid=1874040251&range=45:76
export const PRODUCER_CATEGORIES = [
  { label: 'フランス', value: 'FRANCE' },
  { label: 'ボルドー', value: 'BORDEAUX' },
  { label: 'ブルゴーニュ', value: 'BOURGOGNEZ' },
  { label: 'シャンパーニュ', value: 'CHAMPAGNE' },
  { label: 'ロワール', value: 'LOIRE' },
  { label: 'ローヌ', value: 'RHONE' },
  { label: 'ラングドック・ルーション', value: 'LANGUEDOC ROUSSILLON' },
  { label: 'プロヴァンス', value: 'PROVENCE' },
  { label: 'アルザス', value: 'ALSACE' },
  { label: 'フランスその他', value: 'other france' },
  { label: 'イタリア', value: 'ITALIA' },
  { label: 'ピエモンテ', value: 'PIEMONTE' },
  { label: 'ロンバルディア', value: 'LOMBARDIA' },
  { label: 'トスカーナ', value: 'TOSCANA' },
  { label: 'ヴェネト', value: 'VENETO' },
  { label: 'フリウリ・ヴェネツィア・ジュリア', value: 'FRIULI VENEZIA GIULIA' },
  { label: 'ウンブリア', value: 'UMBRIA' },
  { label: 'プーリア', value: 'PUGLIA' },
  { label: 'シチリア', value: 'SICILIA' },
  { label: 'サンデーニャ', value: 'SARDEGNA' },
  { label: 'イタリアその他', value: 'Other Italia' },
  { label: 'ドイツ', value: 'GERMANY' },
  { label: 'スペイン', value: 'SPAIN' },
  { label: 'ポルトガル', value: 'PORTUGAL' },
  { label: 'アメリカ', value: 'USA' },
  { label: 'チリ', value: 'CHILE' },
  { label: 'ニュージーワンド', value: 'NEW ZEALAND' },
  { label: 'オーストラリア', value: 'AUSTRALIA' },
  { label: '日本', value: 'JAPAN' },
  { label: 'その他生産地', value: 'OTHER' },
];
