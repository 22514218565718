import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { castArray } from 'lodash-es';
import sanitizeHtml from 'sanitize-html';
import { getOrderedDetails } from '~/composables/tag/useOrderConversion';
import { RootStore } from '~/composables/useStore';
import { url } from '~/utils';

export default defineNuxtPlugin(({ app, ...context }) => {
  app.router?.afterEach(async (to) => {
    const store = context.store as RootStore;
    const { path } = to;

    if (!window._etmc) {
      return;
    }
    try {
      window._etmc.push(['setOrgId', '7220851']);
      if ([url('TOP'), url('MYPAGE'), url('CART')].includes(path)) {
        window._etmc.push(['setUserInfo', { email: store.state.user.info.userBase?.loginId || '' }]);
        window._etmc.push(['trackPageView']);
        return;
      }
      if (path.startsWith('/item/detail')) {
        window._etmc.push(['trackPageView', { item: context.route.params.code }]);
        return;
      }
      if (path === url('ITEM_LIST')) {
        const word = castArray(context.route.query._word)[0];
        if (word) {
          window._etmc.push(['trackPageView', { search: word }]);
          return;
        }
        const queryString = Object.entries(context.route.query)
          .map(([key, value]) => `${key}=${castArray(value)[0]}`)
          .join('&');
        window._etmc.push(['trackPageView', { category: `list?${queryString}` }]);
        return;
      }
      if (path === url('ORDER_COMPLETE')) {
        let totalPrice = 0;
        let orderNumber;

        const subscriptionId = store.state.modules.order.subscriptionId;
        // 頒布会の場合
        if (subscriptionId) {
          let items = [];
          try {
            const res = await store.dispatch('modules/product/fetchProductsVilla', {
              q: `iid:distribution_${store.state.modules.order.userOption.distributionCourseId}`,
            });
            items = res.items;
          } catch {
            // 何もしない
          }
          window._etmc.push(['trackPageView']);
          window._etmc.push([
            'trackConversion',
            {
              cart: items.map((item: any) => ({
                item: item.product_code_s,
                quantity: 1,
                price: item.lowest_price_general_i,
                unique_id: undefined,
              })),
            },
          ]);
          totalPrice =
            store.state.modules.order.distributeSimulationRes?.price_set.item_total_amount_including_tax || 0;
          orderNumber = subscriptionId;
        } else {
          // 通常注文の場合
          const { simulatedData, orderId, cart } = getOrderedDetails(to, store);
          const cartItems = cart?.items || [];
          window._etmc.push(['trackPageView']);
          window._etmc.push([
            'trackConversion',
            {
              cart: cartItems.map((item) => ({
                item: item.product_code,
                quantity: item.quantity,
                price: item.price,
                unique_id: item.prduct_enterprise_system_code,
              })),
            },
          ]);
          totalPrice = simulatedData?.price_set.item_total_amount_including_tax || 0;
          orderNumber = orderId || '';
        }

        // 仕様書からほぼ移植
        // varは置き換えた
        try {
          const convid = '1';
          const displayorder = '1';
          const linkalias = 'Purchase';
          const dataset = `<data amt="${totalPrice}" unit="TotalPrice" accumulate="true" /><data amt="${orderNumber}" unit="OrderNumber" accumulate="false" />`;
          // 使用されていなさそうなためコメントアウト
          // function SetCookie(cookieName, cookieValue, nDays) {
          //   var today = new Date();
          //   var expire = new Date();
          //   if (nDays == null || nDays == 0) nDays = 1;
          //   expire.setTime(today.getTime() + 3600000 * 24 * nDays);
          //   document.cookie = cookieName + "=" + escape(cookieValue) + "; expires=" +
          //   expire.toGMTString() + "; path=/";
          // }
          function getCookie(cookiename: any) {
            // 元は型定義されてない
            if (document.cookie.length > 0) {
              let startC = document.cookie.indexOf(cookiename + '=');
              if (startC !== -1) {
                startC += cookiename.length + 1;
                let endC = document.cookie.indexOf(';', startC);
                if (endC === -1) endC = document.cookie.length;
                return unescape(document.cookie.substring(startC, endC));
              }
            }
            return null;
          }

          const jobid = getCookie('JobID');
          let emailaddr = getCookie('EmailAddr_');
          const subid = getCookie('SubscriberID');
          const listid = getCookie('ListID');
          const batchid = getCookie('BatchID');
          const urlid = getCookie('UrlID');
          const memberid = getCookie('MemberID');
          // 仕様書＆現行はdocument.writeだがcreateElementで書き直し
          const imgElement = document.createElement('img');
          let imgSrc =
            'https://click.exacttarget.com/conversion.aspx?xml=<system><system_name>tracking</system_name><action>conversion</action>';
          imgSrc += '<member_id>' + sanitizeHtml(memberid || '', { allowedTags: [] }) + '</member_id>';
          imgSrc += '<job_id>' + sanitizeHtml(jobid || '', { allowedTags: [] }) + '</job_id>';
          if (subid === undefined) {
            imgSrc += '<sub_id></sub_id>';
          } else {
            imgSrc += '<sub_id>' + sanitizeHtml(subid || '', { allowedTags: [] }) + '</sub_id>';
            // @ts-ignore
            emailaddr = undefined;
          }
          if (emailaddr === undefined) {
            imgSrc += '<email></email>';
          } else {
            imgSrc += '<email>' + sanitizeHtml(emailaddr || '', { allowedTags: [] }) + '</email>';
          }
          imgSrc += '<list>' + sanitizeHtml(listid || '', { allowedTags: [] }) + '</list>';
          imgSrc += '<BatchID>' + sanitizeHtml(batchid || '', { allowedTags: [] }) + '</BatchID>';
          imgSrc += '<original_link_id>' + sanitizeHtml(urlid || '', { allowedTags: [] }) + '</original_link_id>';
          imgSrc += '<conversion_link_id>' + convid + '</conversion_link_id>';
          imgSrc += '<link_alias>' + linkalias + '</link_alias>';
          imgSrc += '<display_order>' + displayorder + '</display_order>';
          imgSrc += '<data_set>' + dataset + '</data_set>';
          imgSrc += '</system>';
          imgElement.src = imgSrc;
          imgElement.width = 1;
          imgElement.height = 1;

          document.body.appendChild(imgElement);
        } catch {
          // 何もしない
        }

        return;
      }
      window._etmc.push(['trackPageView']);
    } catch {
      // 何もしない
    }
  });
});
