import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import {
  PRODUCT_RELATED_ARCHIVES_LIMIT,
  ARTICLE_SORT_OPTIONS,
  ARCHIVE_SORT_OPTIONS,
  PRODUCER_AREA_MENU,
  PRODUCER_SORT_OPTIONS,
  COUNTRIES,
  SORT_TYPE,
} from '~/constants';
import { perseAreasDataToJson } from '~/utils/modules/contents';
import { BaseAxiosAction, ECActionPayload } from '~/types/api';
import type {
  Article,
  ArticleMst,
  Producer,
  ArticleCard,
  ArticleCategory,
  EditorProfile,
  SortedProducer,
  ContentProducer,
  KarteProducer,
  KarteProducerArticle,
  ArticleSpImage,
} from '~/types/modules/contents';
import { ArticleType, ProductSearchSortOrder } from '~/enums';

/** State */
export interface State {}

export const state = (): State => ({});

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {};

/** Actions */
export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;
export const actions = {
  async fetchArticles(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{
      q?: string;
      limit?: number;
      offset?: number;
      sortIndex?: number;
      permalink?: string;
      type?: string;
      isSortingProducerNames?: boolean;
      fl?: Array<string>;
    }>
  ): Promise<Article> {
    const { cancelToken, limit, offset, sortIndex, q, fl } = payload;

    let sort: string | undefined = '';
    if (typeof sortIndex === 'number') {
      const { value, param } =
        payload.type === SORT_TYPE.ARCHIVE ? ARCHIVE_SORT_OPTIONS[sortIndex] : ARTICLE_SORT_OPTIONS[sortIndex];
      sort = `${param} ${value}`;
    } else {
      sort = payload.isSortingProducerNames ? `article_title_s ${ProductSearchSortOrder.ASC}` : undefined;
    }

    const queries = {
      wt: 'json',
      q,
      sort,
      rows: limit,
      start: offset,
      fl,
    };

    const res: ArticleMst = await this.$searchAxios.$get('/select', {
      query: queries,
      cancelToken,
    });

    const { docs, numFound } = res.response;

    const articles = docs.map((item) => ({
      id: item.iid,
      permalink: item.article_permalink_s,
      title: item.article_title_s,
      thumbnailImageSp: item.article_sp_thumbnail_s,
      thumbnailImagePc: item.article_pc_thumbnail_s,
      mainImageSp: item.article_sp_main_image_s,
      mainImagePc: item.article_pc_main_image_s,
      categoryId: item.article_category_s,
      authorId: item.article_magazine_writer_id_i,
      publishedAt: item.article_publish_start_date_d,
    }));

    return {
      articles,
      total: numFound,
    };
  },

  async fetchArticleSpThumbnails(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ ids: string[] }>
  ): Promise<ArticleSpImage[]> {
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:article AND iid:(${payload.ids.map((id) => 'article_' + id).join(' OR ')})`,
        fl: ['iid', 'article_sp_thumbnail_s'],
      },
    });

    return response?.docs.map(
      (item: any): ArticleSpImage => ({
        id: item.iid.replace('article_', ''),
        thumbnailImageSp: item.article_sp_thumbnail_s,
      })
    );
  },

  async fetchArticleCategories(this: Vue, context: Ctx, payload: BaseAxiosAction): Promise<ArticleCategory[]> {
    const { cancelToken } = payload;
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: { '_article_type[eq]': ArticleType.Categories, '_order[DESC]': 'priority' },
    });

    return result.map(
      (item: any): ArticleCategory => ({
        id: item.article_id,
        name: item.contents.category_name,
        permalink: item.permalink,
        description: item.contents.category_description,
      })
    );
  },
  async fetchAuthors(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ authorIds?: Array<number>; permalinks?: Array<string> }>
  ): Promise<EditorProfile[]> {
    const { cancelToken, authorIds, permalinks } = payload;
    const filteredPermalinks = permalinks?.map((permalink: string) => `"${permalink}"`);
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Authors,
        '_article_id[in]': !authorIds ? undefined : `[${authorIds.join(',')}]`,
        '_permalink[in]': !filteredPermalinks ? undefined : `[${filteredPermalinks.join(',')}]`,
      },
    });

    return result.map((item: any): EditorProfile => {
      const { article_id, permalink, contents } = item;
      const { profile_image, description, twitter_url, instagram_url, facebook_url, author_name } = contents;
      return {
        image: profile_image,
        name: author_name,
        profile: description,
        twitter: twitter_url,
        instagram: instagram_url,
        facebook: facebook_url,
        permalink,
        id: article_id,
      };
    });
  },
  async fetchProducers(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ permaLinks?: Array<string>; tagId?: Number; articleIds?: number[] }>
  ): Promise<Producer> {
    const { cancelToken, permaLinks, tagId, articleIds } = payload;
    const { result = [], count } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Producers,
        '_permalink[in]': permaLinks ? `[${permaLinks.join(',')}]` : undefined,
        'tags[array][contains]': tagId ? '[' + tagId + ']' : undefined,
        '_article_id[in]': articleIds?.length ? `[${articleIds.join(',')}]` : undefined,
      },
    });

    const articles = result.map((item: any) => ({
      id: item.article_id,
      permalink: item.permalink,
      name: item.contents.name,
      nameEn: item.contents.name_en,
      title: item.contents.catchcopy,
      categories: item.contents.categories,
      productId: item.contents.product_id,
      mainProducingAreaId: item.contents.main_producing_area_id,
      tagIds: item.contents.tags,
    }));

    return {
      articles,
      total: count,
    };
  },
  async fetchProducersForKarte(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ permaLinks: Array<string> }>
  ): Promise<KarteProducerArticle[]> {
    const { cancelToken, permaLinks } = payload;
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Producers,
        '_permalink[in]': `[${permaLinks.join(',')}]`,
      },
    });

    const articles = result.map(
      (item: any): KarteProducerArticle => ({
        permalink: item.permalink,
        tagIds: item.contents.tags,
        publishAt: item.publish_at,
        expireAt: item.expire_at,
      })
    );

    return articles;
  },
  async fetchProducerForItemDetail(this: Vue, context: Ctx, payload: BaseAxiosAction<{ id: number }>) {
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:producer AND producer_id_i:${payload.id}`,
        fl: [
          'main_image_pc_s',
          'main_image_sp_s',
          'producer_name_s',
          'producer_name_english_s',
          'short_caption_t',
          'short_description_t',
        ],
      },
    });
    return response?.docs[0];
  },

  async fetchArticle(this: Vue, context: Ctx, payload: BaseAxiosAction<{ id: string }>) {
    const { cancelToken, id } = payload;
    const response = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_id[eq]': id,
      },
    });
    return response.result?.length ? response.result[0] : {};
  },
  async fetchProducerDetail(this: Vue, context: Ctx, payload: BaseAxiosAction<{ permalink: string }>): Promise<any> {
    const { cancelToken, permalink } = payload;
    const result = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Producers,
        '_permalink[eq]': permalink,
      },
    });

    return result;
  },
  async fetchArticleDetail(this: Vue, context: Ctx, payload: BaseAxiosAction<{ articleId: number }>): Promise<any> {
    const { cancelToken, articleId } = payload;
    const result = await this.$cmsAxios.$get('/view_articles/published', {
      cancelToken,
      query: { article_id: articleId },
    });

    return result;
  },
  async fetchArchivesDetail(this: Vue, context: Ctx, payload: BaseAxiosAction<{ permalink: string }>): Promise<any> {
    const { cancelToken, permalink } = payload;
    const result = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Archives,
        '_permalink[eq]': permalink,
      },
    });

    return result;
  },
  async fetchMagazineDetail(this: Vue, context: Ctx, payload: BaseAxiosAction<{ permalink: string }>): Promise<any> {
    const { cancelToken, permalink } = payload;
    const result = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Articles,
        '_permalink[eq]': permalink,
      },
    });

    return result;
  },

  async fetchRelatedMagazines(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ articleIdList: number[]; noListDisplayFlag?: boolean }>
  ): Promise<any> {
    const { cancelToken, articleIdList, noListDisplayFlag } = payload;
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Articles,
        '_article_id[in]': `[${articleIdList.join(',')}]`,
        'list_display_flag[bool][eq]': noListDisplayFlag ? undefined : true,
      },
    });
    const articles = result.map((item) => ({
      // お気に入りでは文字列で使用するのでStringに変える
      id: item.article_id.toString(),
      permalink: item.permalink,
      title: item.title,
      thumbnailImageSp: item.contents.thumbnail_image_sp,
      thumbnailImagePc: item.contents.thumbnail_image_pc,
      mainImageSp: item.contents.main_image_sp,
      mainImagePc: item.contents.main_image_pc,
      categoryId: item.contents.category_id || undefined,
      authorId: item.contents.author_id || undefined,
      publishAt: item.publish_at,
      expireAt: item.expire_at,
      tagIds: item.contents.tags || [],
      description: item.contents.meta_description,
    }));

    return articles;
  },

  /** サーチから生産者IDを元に生産者情報を取得 */
  async fetchProducerDetails(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{
      producerIds?: string[];
      rows?: number;
      offset?: number;
    }>
  ): Promise<{
    producers: KarteProducer[];
    numFound: number;
  }> {
    const { producerIds, rows, offset } = payload;

    let q = 'mst:producer';
    if (producerIds?.length) {
      q += ` AND producer_id_i: (${producerIds.join(' OR ')})`;
    }
    // 一覧表示フラグがtrueのもののみ取得
    q += ' AND display_flag_i: 1';
    const { response } = await this.$searchAxios.$get('/select', {
      ...payload,
      query: {
        wt: 'json',
        q,
        fl: [
          'iid',
          'producer_id_i',
          'producer_name_s',
          'page_title_s',
          'producer_name_english_s',
          'short_caption_t',
          'areas_s',
          'product_count_i',
          'new_label_start_d',
          'new_label_end_d',
          'vintage_new_label_start_d',
          'vintage_new_label_end_d',
          'product_image2_t',
          'main_image_pc_s',
          'main_image_sp_s',
          'thumbnail_image_pc_s',
          'thumbnail_image_sp_s',
        ],
        start: offset || 0,
        rows,
      },
    });

    const docs = response?.docs || [];

    const producers = docs.map((item: any): KarteProducer => {
      return {
        id: `${item.producer_id_i}`,
        permalink: item.iid.replace('producer_', ''),
        name: item.producer_name_s,
        nameEn: item.producer_name_english_s,
        title: item.page_title_s,
        pageTitle: item.page_title_s,
        label: item.producer_name_s,
        catchCopy: item.short_caption_t,
        areas: perseAreasDataToJson(item.areas_s),
        productCount: item.product_count_i,
        newLabelStart: item.new_label_start_d,
        newLabelEnd: item.new_label_end_d,
        vintageNewLabelStart: item.vintage_new_label_start_d,
        vintageNewLabelEnd: item.vintage_new_label_end_d,
        productImage2: item.product_image2_t,
        mainPcImage: item.main_image_pc_s,
        mainSpImage: item.main_image_sp_s,
        thumbnailPcImage: item.thumbnail_image_pc_s,
        thumbnailSpImage: item.thumbnail_image_sp_s,
      };
    });

    return {
      producers,
      numFound: response?.numFound,
    };
  },

  async fetchSortedProducers(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{
      sort: number;
      rows: number;
      offset?: number;
      area?: string;
      producerIds?: string[];
      keyword?: string;
    }>
  ): Promise<{
    producers: SortedProducer[];
    numFound: number;
  }> {
    const { sort, offset, rows, area, producerIds, keyword } = payload;

    let q = 'mst:producer';

    const {
      FRANCE,
      OTHER_FRANCE,
      ITALIA,
      OTHER_ITALIA,
      GERMANY,
      SPAIN,
      PORTUGAL,
      USA,
      OTHER_USA,
      CHILE,
      NEW_ZEALAND,
      AUSTRALIA,
      JAPAN,
      OTHER_JAPAN,
      OTHER,
    } = COUNTRIES;

    if (area === FRANCE || area === ITALIA) {
      const regions = PRODUCER_AREA_MENU.find((country) => country.query === area)?.regions;

      q += regions
        ? ` AND producer_producing_area_sm: (${area} OR ${regions?.map((region) => region.query).join(' OR ')})`
        : '';
    } else if (area === OTHER) {
      q += ` AND NOT producer_producing_area_sm: ("${[
        FRANCE,
        ITALIA,
        GERMANY,
        SPAIN,
        PORTUGAL,
        USA,
        CHILE,
        NEW_ZEALAND,
        AUSTRALIA,
        JAPAN,
      ].join('" OR "')}")`;
      // その他のフランス
    } else if (area === OTHER_FRANCE) {
      const regions = PRODUCER_AREA_MENU.find((country) => country.query === FRANCE)?.regions;
      q += ` AND producer_producing_area_sm: "${FRANCE}" AND NOT producer_producing_area_sm: (${regions
        ?.filter(({ isOtherFlag }) => !isOtherFlag)
        .map((region) => `"${region.query}"`)
        .join(' OR ')})`;
      // その他のイタリア
    } else if (area === OTHER_ITALIA) {
      const regions = PRODUCER_AREA_MENU.find((country) => country.query === ITALIA)?.regions;
      q += ` AND producer_producing_area_sm: "${ITALIA}" AND NOT producer_producing_area_sm: (${regions
        ?.filter(({ isOtherFlag }) => !isOtherFlag)
        .map((region) => `"${region.query}"`)
        .join(' OR ')})`;
      // その他のアメリカ
    } else if (area === OTHER_USA) {
      const regions = PRODUCER_AREA_MENU.find((country) => country.query === USA)?.regions;
      q += ` AND producer_producing_area_sm: "${USA}" AND NOT producer_producing_area_sm: (${regions
        ?.filter(({ isOtherFlag }) => !isOtherFlag)
        .map((region) => `"${region.query}"`)
        .join(' OR ')})`;
      // その他の日本
    } else if (area === OTHER_JAPAN) {
      const regions = PRODUCER_AREA_MENU.find((country) => country.query === JAPAN)?.regions;
      q += ` AND producer_producing_area_sm: "${JAPAN}" AND NOT producer_producing_area_sm: (${regions
        ?.filter(({ isOtherFlag }) => !isOtherFlag)
        .map((region) => `"${region.query}"`)
        .join(' OR ')})`;
    } else if (area) {
      q += ` AND producer_producing_area_sm: "${area}"`;
    } else if (keyword) {
      const keywordArr = keyword.split(/\s+/);

      q += ` AND (producer_name_english_hn: (${keywordArr
        .map((title) => `"${title}"`)
        .join(' AND ')}) OR producer_name_hn: (${keywordArr.map((title) => `"${title}"`).join(' AND ')}))`;
    }

    q += ' AND display_flag_i: 1';

    if (producerIds?.length) {
      q += ` AND producer_id_i: (${producerIds.join(' OR ')})`;
    }

    const { response } = await this.$searchAxios.$get('/select', {
      ...payload,
      query: {
        wt: 'json',
        q,
        fl: [
          'iid',
          'producer_id_i',
          'producer_name_s',
          'page_title_s',
          'producer_name_english_s',
          'short_caption_t',
          'areas_s',
          'product_count_i',
          'new_label_start_d',
          'new_label_end_d',
          'vintage_new_label_start_d',
          'vintage_new_label_end_d',
          'product_image2_t',
          'main_image_pc_s',
          'main_image_sp_s',
          'thumbnail_image_pc_s',
          'thumbnail_image_sp_s',
        ],
        sort: `${PRODUCER_SORT_OPTIONS[sort].values.map((value) => `${value.param} ${value.sort}`).join(', ')}`,
        start: offset || 0,
        rows,
      },
    });

    const docs = response?.docs || [];

    const producers = docs.map((item: any) => {
      return {
        id: `${item.producer_id_i}`,
        permalink: item.iid.replace('producer_', ''),
        name: item.producer_name_s,
        nameEn: item.producer_name_english_s,
        title: item.page_title_s,
        label: item.producer_name_s,
        catchCopy: item.short_caption_t,
        areas: perseAreasDataToJson(item.areas_s),
        productCount: item.product_count_i,
        newLabelStart: item.new_label_start_d,
        newLabelEnd: item.new_label_end_d,
        vintageNewLabelStart: item.vintage_new_label_start_d,
        vintageNewLabelEnd: item.vintage_new_label_end_d,
        productImage2: item.product_image2_t,
        mainPcImage: item.main_image_pc_s,
        mainSpImage: item.main_image_sp_s,
        thumbnailPcImage: item.thumbnail_image_pc_s,
        thumbnailSpImage: item.thumbnail_image_sp_s,
      };
    });

    return {
      producers,
      numFound: response?.numFound,
    };
  },

  async fetchProducer(this: Vue, context: Ctx, payload: BaseAxiosAction<{ q: string }>): Promise<ContentProducer[]> {
    const { q } = payload;

    const { response } = await this.$searchAxios.$get('/select', {
      ...payload,
      query: {
        wt: 'json',
        q,
        fl: [
          'iid',
          'producer_id_i',
          'producer_name_s',
          'page_title_s',
          'producer_name_english_s',
          'areas_s',
          'short_caption_t',
          'short_description_t',
          'product_image2_t',
          'main_image_pc_s',
          'main_image_sp_s',
        ],
      },
    });

    const docs = response?.docs;

    return docs.map((item: any) => {
      return {
        id: item.producer_id_i,
        permalink: item.iid.replace('producer_', ''),
        name: item.producer_name_s,
        nameEn: item.producer_name_english_s,
        title: item.page_title_s,
        areas: perseAreasDataToJson(item.areas_s),
        catchCopy: item.short_caption_t,
        description: item.short_description_t,
        productImage2: item.product_image2_t,
        mainPcImage: item.main_image_pc_s,
        mainSpImage: item.main_image_sp_s,
      };
    });
  },
  async fetchRelatedArchives(this: Vue, context: Ctx, payload: BaseAxiosAction<{ q: string }>): Promise<any> {
    const { cancelToken, q } = payload;
    const {
      response: { docs },
    } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:article AND article_type_s:${ArticleType.Archives} AND (${q})`,
        rows: PRODUCT_RELATED_ARCHIVES_LIMIT,
        sort: 'archive_publish_at_d desc',
      },
      cancelToken,
    });

    const permalinks = docs.map((item: any) => `"${item?.article_permalink_s}"`);
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      query: {
        '_article_type[eq]': ArticleType.Archives,
        '_permalink[in]': `[${permalinks}]`,
      },
    });

    const parse = (item: any): ArticleCard => {
      const content = result.find(({ permalink }) => permalink && permalink === item?.article_permalink_s)?.contents;
      return {
        id: item?.article_code_s,
        permalink: item?.article_permalink_s,
        thumbnailImageSp: content?.thumbnail_image_sp || content?.main_image_sp || content?.main_image_pc,
        thumbnailImagePc: content?.thumbnail_image_pc || content?.main_image_pc,
        title: item?.article_title_s,
      };
    };

    return docs.map(parse);
  },
  async fetchShopDetail(this: Vue, context: Ctx, payload: ECActionPayload<'/api/v1/sales_locations/{id}', 'get'>) {
    return await this.$ecAxios.$get('/api/v1/sales_locations/{id}', payload);
  },
  async fetchTags(this: Vue, context: Ctx, payload: BaseAxiosAction<{ tags?: number[]; permalinks?: string[] }>) {
    const { cancelToken, tags, permalinks } = payload;
    return await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Tags,
        '_article_id[in]': tags?.length ? '[' + tags + ']' : undefined,
        '_permalink[in]': permalinks?.length ? '[' + permalinks.map((permalink) => `"${permalink}"`) + ']' : undefined,
        _limit: 200,
      },
    });
  },
  async fetchTag(this: Vue, context: Ctx, payload: BaseAxiosAction<{ permalink: string }>) {
    const { cancelToken, permalink } = payload;
    return await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Tags,
        '_permalink[eq]': permalink,
      },
    });
  },

  async fetchMagazineSlider(this: Vue, context: Ctx, payload: BaseAxiosAction<{}>): Promise<any> {
    const { cancelToken } = payload;
    const result = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.MagazineSlider,
        '_order[ASC]': 'priority',
        _limit: 8,
      },
    });
    return result;
  },
};

/** Store Module Type */
export type Store = DefineStoreModule<'modules/content', State, typeof getters, typeof mutations, typeof actions>;
