import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import { ECActionPayload, BaseAxiosAction, ECSchema } from '~/types/api';
import { ArticleType } from '~/enums';

export type State = {
  appliedLotteries: Array<ECSchema<'response.APIRaffle'>>;
};

export const state = (): State => ({ appliedLotteries: [] });

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setAppliedLotteries(state: State, appliedLotteries: Array<ECSchema<'response.APIRaffle'>>) {
    state.appliedLotteries = appliedLotteries;
  },
};

/** Actions */
export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;
export const actions = {
  async fetchLotteryApplications(
    this: Vue,
    { commit }: Ctx,
    payload: ECActionPayload<'/api/v1/raffle_applications', 'get'>
  ) {
    const res = await this.$ecAxios.$get('/api/v1/raffle_applications', payload);

    commit('setAppliedLotteries', res);
  },
  async fetchLotteryDetail(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/raffles/{id}', 'get'>
  ): Promise<ECSchema<'response.APIRaffleShow'>> {
    return await this.$ecAxios.$get('/api/v1/raffles/{id}', payload);
  },
  async postLotteryApplication(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/raffle_applications', 'post'>
  ) {
    await this.$ecAxios.$post('/api/v1/raffle_applications', payload);
  },
  async fetchLotteryContents(this: Vue, context: Ctx, payload: BaseAxiosAction<{ permalink: string }>): Promise<any[]> {
    const { cancelToken, permalink } = payload;
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Lottery,
        '_permalink[eq]': permalink,
      },
    });
    return result[0].contents.__dynamic_parts;
  },
};
export type Store = DefineStoreModule<'modules/lottery', State, typeof getters, typeof mutations, typeof actions>;
