import { onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';
import { throttle } from 'lodash-es';
import { FOOTER_BOTTOM_POINT, ITEM_SEARCH_TOP_BODY } from '~/constants';
import { useMatchMedia } from './useMatchMedia';

export const useScrollUpdate = () => {
  const isAtBottom = ref(false);
  const { isMobile } = useMatchMedia(); // SSR検証済み

  const updatePosition = throttle(() => {
    const footerSection = document.getElementById(FOOTER_BOTTOM_POINT)?.getBoundingClientRect();
    // 検索トップ画面はフッターがないので、代わりに本体を取得する
    const itemSearchTopSection = document.getElementById(ITEM_SEARCH_TOP_BODY)?.getBoundingClientRect();
    isAtBottom.value = window.innerHeight >= (footerSection?.bottom || itemSearchTopSection?.bottom || 0);
  }, 100);

  onMounted(() => {
    if (isMobile.value) {
      window.addEventListener('scroll', updatePosition);
    }
  });
  onUnmounted(() => {
    if (isMobile.value) {
      window.removeEventListener('scroll', updatePosition);
    }
  });

  return { isAtBottom };
};
