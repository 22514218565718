import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ConsolsActionPayload } from '~/types/api';

export type Ctx = DefineActionContext<never, never, never>;

export const actions = {
  async postPasswordUpdate(
    this: Vue,
    context: Ctx,
    payload: ConsolsActionPayload<'/api/v1/user/password/update', 'post'>
  ) {
    return await this.$consolsAxios.$post('/api/v1/user/password/update', payload);
  },

  async encryptUuid(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/hash', 'post'>) {
    return await this.$consolsAxios.$post('/api/v1/hash', payload);
  },
};

export type Store = DefineStoreModule<'user/user', never, never, never, typeof actions>;
