import { DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';

export type State = {
  isSpFilterModalOpen: boolean;
  isPcFilterModalOpen: boolean;
  hasSearchCancelled: boolean;
  total: number;
  isFetchingOptions: boolean;
  isFetchingProducts: boolean;
  isAfterRedirect: boolean;
  isAfterStateToQuery: boolean;
};

export const state = (): State => ({
  isSpFilterModalOpen: false,
  isPcFilterModalOpen: false,
  hasSearchCancelled: false,
  total: 0,
  isFetchingOptions: false,
  isFetchingProducts: true,
  isAfterRedirect: false,
  isAfterStateToQuery: false,
});

export const mutations = {
  openSpFilterModal(state: State) {
    state.isSpFilterModalOpen = true;
  },
  closeSpFilterModal(state: State) {
    state.isSpFilterModalOpen = false;
  },
  openPcFilterModal(state: State) {
    state.isPcFilterModalOpen = true;
  },
  closePcFilterModal(state: State) {
    state.isPcFilterModalOpen = false;
  },
  toggleHasCancelled(state: State) {
    state.hasSearchCancelled = !state.hasSearchCancelled;
  },
  setTotal(state: State, value: number) {
    state.total = value;
  },
  startFetching(state: State, field: 'isFetchingOptions' | 'isFetchingProducts') {
    Vue.typedSet(state, field, true);
  },
  finishFetching(state: State, field: 'isFetchingOptions' | 'isFetchingProducts') {
    Vue.typedSet(state, field, false);
  },
  setAfterRedirect(state: State) {
    state.isAfterRedirect = true;
  },
  resetAfterRedirect(state: State) {
    state.isAfterRedirect = false;
  },
  setAfterStateToQuery(state: State) {
    state.isAfterStateToQuery = true;
  },
  resetAfterStateToQuery(state: State) {
    state.isAfterStateToQuery = false;
  },
};

export type Store = DefineStoreModule<'product/search', State, never, typeof mutations, never>;
