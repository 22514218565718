import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../src/store/auth.ts'), 'auth.ts')
  resolveStoreModules(require('../src/store/blog/master.ts'), 'blog/master.ts')
  resolveStoreModules(require('../src/store/college/detail.ts'), 'college/detail.ts')
  resolveStoreModules(require('../src/store/college/list.ts'), 'college/list.ts')
  resolveStoreModules(require('../src/store/college/quiz.ts'), 'college/quiz.ts')
  resolveStoreModules(require('../src/store/event/detail.ts'), 'event/detail.ts')
  resolveStoreModules(require('../src/store/event/filter.ts'), 'event/filter.ts')
  resolveStoreModules(require('../src/store/event/list.ts'), 'event/list.ts')
  resolveStoreModules(require('../src/store/event/reserve.ts'), 'event/reserve.ts')
  resolveStoreModules(require('../src/store/internals/alert.ts'), 'internals/alert.ts')
  resolveStoreModules(require('../src/store/internals/header.ts'), 'internals/header.ts')
  resolveStoreModules(require('../src/store/internals/keepScroll.ts'), 'internals/keepScroll.ts')
  resolveStoreModules(require('../src/store/internals/layoutProps.ts'), 'internals/layoutProps.ts')
  resolveStoreModules(require('../src/store/internals/redirect.ts'), 'internals/redirect.ts')
  resolveStoreModules(require('../src/store/modules/amazonPay.ts'), 'modules/amazonPay.ts')
  resolveStoreModules(require('../src/store/modules/cart.ts'), 'modules/cart.ts')
  resolveStoreModules(require('../src/store/modules/cartMessage.ts'), 'modules/cartMessage.ts')
  resolveStoreModules(require('../src/store/modules/content.ts'), 'modules/content.ts')
  resolveStoreModules(require('../src/store/modules/general.ts'), 'modules/general.ts')
  resolveStoreModules(require('../src/store/modules/lottery.ts'), 'modules/lottery.ts')
  resolveStoreModules(require('../src/store/modules/news.ts'), 'modules/news.ts')
  resolveStoreModules(require('../src/store/modules/order.ts'), 'modules/order.ts')
  resolveStoreModules(require('../src/store/modules/product.ts'), 'modules/product.ts')
  resolveStoreModules(require('../src/store/modules/rakuten.ts'), 'modules/rakuten.ts')
  resolveStoreModules(require('../src/store/modules/reviews.ts'), 'modules/reviews.ts')
  resolveStoreModules(require('../src/store/modules/salesLocation.ts'), 'modules/salesLocation.ts')
  resolveStoreModules(require('../src/store/modules/staff.ts'), 'modules/staff.ts')
  resolveStoreModules(require('../src/store/modules/tagList.ts'), 'modules/tagList.ts')
  resolveStoreModules(require('../src/store/modules/veritrans.ts'), 'modules/veritrans.ts')
  resolveStoreModules(require('../src/store/modules/vintageChart.ts'), 'modules/vintageChart.ts')
  resolveStoreModules(require('../src/store/modules/wineArea.ts'), 'modules/wineArea.ts')
  resolveStoreModules(require('../src/store/product/detail.ts'), 'product/detail.ts')
  resolveStoreModules(require('../src/store/product/filter.ts'), 'product/filter.ts')
  resolveStoreModules(require('../src/store/product/foodDiagnosis.ts'), 'product/foodDiagnosis.ts')
  resolveStoreModules(require('../src/store/product/master.ts'), 'product/master.ts')
  resolveStoreModules(require('../src/store/product/option.ts'), 'product/option.ts')
  resolveStoreModules(require('../src/store/product/recommend.ts'), 'product/recommend.ts')
  resolveStoreModules(require('../src/store/product/search.ts'), 'product/search.ts')
  resolveStoreModules(require('../src/store/user/base.ts'), 'user/base.ts')
  resolveStoreModules(require('../src/store/user/favorites.ts'), 'user/favorites.ts')
  resolveStoreModules(require('../src/store/user/info.ts'), 'user/info.ts')
  resolveStoreModules(require('../src/store/user/magazine.ts'), 'user/magazine.ts')
  resolveStoreModules(require('../src/store/user/password.ts'), 'user/password.ts')
  resolveStoreModules(require('../src/store/user/profile.ts'), 'user/profile.ts')
  resolveStoreModules(require('../src/store/user/user.ts'), 'user/user.ts')
  resolveStoreModules(require('../src/store/modules/mypage/cellar.ts'), 'modules/mypage/cellar.ts')
  resolveStoreModules(require('../src/store/modules/mypage/cellarFilter.ts'), 'modules/mypage/cellarFilter.ts')
  resolveStoreModules(require('../src/store/modules/mypage/coupon.ts'), 'modules/mypage/coupon.ts')
  resolveStoreModules(require('../src/store/modules/mypage/creditCard.ts'), 'modules/mypage/creditCard.ts')
  resolveStoreModules(require('../src/store/modules/mypage/distributionCourseSubscription.ts'), 'modules/mypage/distributionCourseSubscription.ts')
  resolveStoreModules(require('../src/store/modules/mypage/event.ts'), 'modules/mypage/event.ts')
  resolveStoreModules(require('../src/store/modules/mypage/history.ts'), 'modules/mypage/history.ts')
  resolveStoreModules(require('../src/store/modules/mypage/myshop.ts'), 'modules/mypage/myshop.ts')
  resolveStoreModules(require('../src/store/modules/mypage/personalMessage.ts'), 'modules/mypage/personalMessage.ts')
  resolveStoreModules(require('../src/store/modules/mypage/point.ts'), 'modules/mypage/point.ts')
  resolveStoreModules(require('../src/store/modules/mypage/primeur.ts'), 'modules/mypage/primeur.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
