import { DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';

type State = {
  food: string;
  wineColor: string;
  priceRange: string;
};

export const state = (): State => ({
  food: '',
  wineColor: '',
  priceRange: '',
});

export const getters = {};

export const mutations = {
  selectFood: (state: State, inputValue: string) => {
    state.food = inputValue;
  },
  selectWineColor: (state: State, inputValue: string) => {
    state.wineColor = inputValue;
  },
  selectPriceRange: (state: State, inputValue: string) => {
    state.priceRange = inputValue;
  },
  resetSelection: (state: State) => {
    state.food = '';
    state.wineColor = '';
    state.priceRange = '';
  },
};

export const actions = {};

export type Store = DefineStoreModule<'product/foodDiagnosis', State, typeof getters, typeof mutations, typeof actions>;
