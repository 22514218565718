import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { formatShopList } from '~/utils/shop';
import { ECActionPayload, ECSchema } from '~/types/api';

/** State */
export type State = {
  // 全ての店舗情報のシンプルな配列
  salesLocationList: Array<ECSchema<'saleslocation.SalesLocation'>>;
  // エリア名で分けたRecord型の店舗情報
  groupedSalesLocationList: Record<string, ECSchema<'saleslocation.SalesLocation'>[]>;
  selectedArea: string;
  hasTasting: boolean;
  hasCellar: boolean;
};

export const state = (): State => ({
  salesLocationList: [],
  groupedSalesLocationList: {},
  selectedArea: 'すべてのエリア',
  hasTasting: false,
  hasCellar: false,
});

/** Getters */
export const getters = {
  getAllAreaShopList: (state: State) => (hasTasting: boolean, hasCellar: boolean) => {
    // 両方falseの場合は全てを取得して返す
    if (!hasTasting && !hasCellar) {
      return formatShopList(state.salesLocationList, false);
    }

    let allAreaShopList: Array<ECSchema<'saleslocation.SalesLocation'>> = [];

    if (hasTasting && hasCellar) {
      allAreaShopList = state.salesLocationList.filter((sl) => {
        return sl.shop_drink_wine && sl.has_rental_cellar;
      });
    } else if (hasTasting) {
      allAreaShopList = state.salesLocationList.filter((sl) => {
        return sl.shop_drink_wine;
      });
    } else {
      allAreaShopList = state.salesLocationList.filter((sl) => {
        return sl.has_rental_cellar;
      });
    }
    return formatShopList(allAreaShopList, false);
  },
  getParticularAreaShopList: (state: State) => (hasTasting: boolean, hasCellar: boolean) => {
    // 選択エリアと一致する店舗で絞る
    const particluarAreaShopList = state.salesLocationList.filter((saleLocation) => {
      return saleLocation.area_name === state.selectedArea;
    });

    // それぞれの条件に合わせて返す
    if (!hasTasting && !hasCellar) {
      return particluarAreaShopList;
    } else if (hasTasting && hasCellar) {
      return particluarAreaShopList.filter((sl) => {
        return sl.shop_drink_wine && sl.has_rental_cellar;
      });
    } else if (hasTasting) {
      return particluarAreaShopList.filter((sl) => {
        return sl.shop_drink_wine;
      });
    } else {
      return particluarAreaShopList.filter((sl) => {
        return sl.has_rental_cellar;
      });
    }
  },
};

/** Mutations */
export const mutations = {
  setSalesLocationList(state: State, salesLocationList: Array<ECSchema<'saleslocation.SalesLocation'>>) {
    state.salesLocationList = salesLocationList;
  },
  setGroupedSalesLocationList(
    state: State,
    groupedSalesLocationList: Record<string, ECSchema<'saleslocation.SalesLocation'>[]>
  ) {
    state.groupedSalesLocationList = groupedSalesLocationList;
  },
  // 選択したエリアを保存
  setSelectedArea(state: State, selectedArea: string) {
    state.selectedArea = selectedArea;
  },
  setHasTasting(state: State) {
    state.hasTasting = !state.hasTasting;
  },
  setHasCellar(state: State) {
    state.hasCellar = !state.hasCellar;
  },
};

export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;

export const actions = {
  async fetchSalesLocationByID(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/sales_locations/{id}', 'get'>
  ) {
    return await this.$ecAxios.$get('/api/v1/sales_locations/{id}', payload);
  },
  async fetchAllSalesLocations(this: Vue, context: Ctx, payload: ECActionPayload<'/api/v1/sales_locations', 'get'>) {
    return await this.$ecAxios.$get('/api/v1/sales_locations', payload);
  },

  // 全てのショップ情報を整形して取得する
  async fetchAllSalesLocationList(
    this: Vue,
    { commit }: Ctx,
    payload: ECActionPayload<'/api/v1/sales_locations', 'get'>
  ) {
    const { data } = await this.$ecAxios.$get('/api/v1/sales_locations', payload);
    if (data) {
      // 以下二つの条件で絞り込む
      // 削除・閉店した店舗ではない
      // 削除された店舗ではない
      const filteredData = data.filter(
        (salesLocation: ECSchema<'saleslocation.SalesLocation'>) =>
          !salesLocation.is_closed && !salesLocation.delete_flag
      );
      // エリア毎にRecord型で整形、保存する
      commit('setGroupedSalesLocationList', formatShopList(filteredData, true));
      // エリア関係なく配列で保存する
      commit('setSalesLocationList', filteredData);
    }
  },
};

export type Store = DefineStoreModule<'modules/salesLocation', State, typeof getters, typeof mutations, typeof actions>;
