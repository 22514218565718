import { FormSelectOption } from '~/types/ui';

export const GENDER_OPTIONS: FormSelectOption[] = [
  { value: 'male', text: '男性' },
  { value: 'female', text: '女性' },
];

export const JA_GENDER_TEXTS: Record<string, string> = {
  male: '男性',
  female: '女性',
};
