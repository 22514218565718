import { max_value, min_value } from 'vee-validate/dist/rules';
import { ValidationRule } from 'vee-validate/dist/types/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const max_price: ValidationRule = {
  params: ['max'],
  message: (field, { max }) => {
    const price = Number.parseInt(max);
    const formatted = Number.isNaN(price) ? price : price.toLocaleString();

    return `価格は${formatted}円以下を指定してください`;
  },
  // @ts-expect-error
  validate(value, { max }) {
    return max_value.validate(value, { max });
  },
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const min_price: ValidationRule = {
  params: ['min'],
  message: (field, { min }) => {
    const price = Number.parseInt(min);
    const formatted = Number.isNaN(price) ? price : price.toLocaleString();

    return `価格は${formatted}円以上を指定してください`;
  },
  // @ts-expect-error
  validate(value, { min }) {
    return min_value.validate(value, { min });
  },
};
