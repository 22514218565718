import { DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import { isEqual } from 'lodash-es';

export type PageData = {
  path: string;
  fullPath: string;
  scrollY: number;
  pagination?: Array<number>;
  tabs?: Array<number>;
  detailsElements?: Array<{
    isOpen?: boolean;
    elementId?: string;
  }>;
  hasStock?: boolean;
  sortIndex?: number;
};

/** State */
export type State = {
  [path: string]: PageData;
};

export const state = (): State => ({});

/** Getter */
export const getters = {
  pagination:
    (state: State) =>
    (path: string, fullPath?: string, index?: number): number => {
      return isEqual(fullPath, state[path]?.fullPath) ? state[path]?.pagination?.[index ?? 0] ?? 1 : 1;
    },
  scrollY:
    (state: State) =>
    (path: string, fullPath?: string): number => {
      return isEqual(fullPath, state[path]?.fullPath) ? state[path]?.scrollY : 0;
    },
  tabs:
    (state: State) =>
    (path: string, fullPath?: string, index?: number): number => {
      return isEqual(fullPath, state[path]?.fullPath) ? state[path]?.tabs?.[index ?? 0] ?? 0 : 0;
    },
  detailsElements:
    (state: State) =>
    (path: string, fullPath?: string): PageData['detailsElements'] | undefined => {
      return isEqual(fullPath, state[path]?.fullPath) ? state[path]?.detailsElements ?? undefined : undefined;
    },
  hasStock:
    (state: State) =>
    (path: string, fullPath?: string): boolean | undefined => {
      return isEqual(fullPath, state[path]?.fullPath) ? state[path]?.hasStock ?? undefined : undefined;
    },
  sortIndex:
    (state: State) =>
    (path: string, fullPath?: string): number => {
      return isEqual(fullPath, state[path]?.fullPath) ? state[path]?.sortIndex ?? 0 : 0;
    },
};

/** Mutation */
export const mutations = {
  setPageData(state: State, pageData: PageData) {
    state[pageData.path] = pageData;
  },
  resetPageData(state: State, path: string) {
    delete state[path];
  },
};

export type Store = DefineStoreModule<'internals/keepScroll', State, typeof getters, typeof mutations, never>;
