




































import { defineComponent } from '@nuxtjs/composition-api';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import AppImage from '~/components/partials/App/AppImage.vue';
import { useFirstPurchasePromotion } from '~/composables/useFirstPurchasePromotion';
import { SESSION_KEY } from '~/constants';

export default defineComponent({
  components: {
    AppIcon,
    AppImage,
  },
  setup(props, { emit }) {
    const { showLogosOnly } = useFirstPurchasePromotion();

    const closePromo = () => {
      sessionStorage.setItem(SESSION_KEY.HAS_CLOSED_PROMO, 'yes');
      emit('close');
    };

    return { closePromo, showLogosOnly };
  },
});
