import { useContext } from '@nuxtjs/composition-api';
import type { PublicRuntimeConfig, PrivateRuntimeConfig } from '~/config/runtimeConfig';

export type RuntimeConfig = PublicRuntimeConfig & PrivateRuntimeConfig;

export const useRuntimeConfig = (): RuntimeConfig => {
  const { $config } = useContext();

  return $config as RuntimeConfig;
};
