import dayjs from 'dayjs';

// notInsideFlag: 開始終了の両方が設定されてない場合、期間中にするかどう用のフラグ
export const isInsidePeriod = (from = '', to = '', notInsideFlag = false) => {
  // 現在日付（表示期間判定用なので、JSTに合わせる）
  const currentDateTime = dayjs().tz('Asia/Tokyo').format();
  // 入力されている日時は東京のTZで設定
  const startAt = dayjs(from).tz('Asia/Tokyo').format();
  const endAt = dayjs(to).tz('Asia/Tokyo').format();

  // 期間が設定されてない場合は期間内と判定
  if (!from && !to) {
    return !notInsideFlag;
  }
  // 両方設定されている場合は、開始日時〜終了日時の間かどうかで判定
  if (from && to) {
    return dayjs(currentDateTime).isBetween(dayjs(startAt), dayjs(endAt), 'minutes', '[]');
  }
  // 開始日時のみが設定されている場合は、開始日時と同じかそれ以後であれば期間内と判定
  if (from) {
    return dayjs(currentDateTime).isAfter(dayjs(startAt)) || dayjs(currentDateTime).isSame(dayjs(startAt), 'minutes');
  }
  // 終了日時のみが設定されている場合は、終了日時と同じかそれ以前であれば期間内と判定
  if (to) {
    return dayjs(currentDateTime).isBefore(dayjs(endAt)) || dayjs(currentDateTime).isSame(dayjs(endAt), 'minutes');
  }

  return false;
};
