import { onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';

export const useComponentLifecycle = () => {
  const isMounted = ref(false);
  const isUnmounted = ref(false);

  onMounted(() => {
    isMounted.value = true;
  });

  onUnmounted(() => {
    isMounted.value = false;
    isUnmounted.value = true;
  });

  return {
    isMounted,
    isUnmounted,
  };
};
