import { WineItem, GiftBoxImage } from '~/types/modules/order';
import { FormSelectOption, FormImageRadioOption } from '~/types/ui';
import { OrderErrorCodes, PaymentSplitNum, ReceiptType } from '~/enums/order';
export const DELIVERY_METHOD_ID = {
  NORMAL: 1,
  COOL: 3,
};

/* お届け時間帯指定なしの時のフロント表示用文言 */
export const DELIVERY_DATE_UNDEFINED = 'お届け日指定なし';

export const WINE_CELLAR_DESCRIPTION_UNEDITED = '詳細情報を入力してください';

/* ワインセラー詳細情報(設置場所)の初期値 */
export const WINE_CELLAR_DESCRIPTION_DEFAULT = `ワインセラー設置場所について\n\n設置階：　階\nエレベーターの有無：有り or 無し\n玄関口までの段数：　段\n※「玄関口までの段数」は玄関口までに階段などがありましたら段数を記載ください。\n\nワインセラーの同時引き取りについて\n\nご希望により、設置と同時にワインセラーの引き取りを承ります。\n引取手数料は有料となります。金額はメーカーによって異なりますため、別途引取手数料をお知らせいたします。\n※ドメティックのワインセラー購入の場合は同社のセラーのみのお引き取りとなります。\n\n既存ワインセラーの引き取り：有り or 無し\n「有り」の場合 メーカー名：\n型番：`;

/* お届け日指定なしの時のフロント表示用文言 */
export const DELIVERY_DATE_UNDEFINED_OPTION = {
  TEXT: '指定なし',
  VALUE: '指定なし',
};

/* お届け時間帯指定なしの時のフロント表示用文言 */
export const DELIVERY_TIME_UNDEFINED = {
  TEXT: '時間帯指定なし',
  VALUE: 0,
};

export const DELIVERY_DATE_DISTRIBUTION = '指定なし（毎月基準日お届け）';

export const DELIVERY_BOX_OPTION = {
  TEXT: '不在時は宅配ボックスへ',
  VALUE: true,
  NOTE: '※クール便は宅配ボックスにはお届けできません。予めご了承ください。',
};

export const RECEIPT_TEXT: Record<ReceiptType, string> = {
  none: '領収書不要',
  web: 'Web領収書を希望',
  same_box: '荷物に同梱を希望',
};

// 注文確認画面で同意のチェックがが必要な生産者
export const LIGER_BELAIR = 'ドメーヌ・デュ・コント・リジェ・ベレール';

export const PAYMENT_METHOD_ID = {
  CREDIT_CARD: 31,
  CASH_ON_DELIVERY: 3,
  ECON_PAY: 8,
  CONVENIENCE_STORE: 16,
  RAKUTEN_PAY: 21,
  AMAZON_PAY: 28,
  BANK_TRANSFER: 2,
  NO_PAYMENT: 90,
  GIFT: 29,
  PAIDY: 32,
  ECON_PAY_BANK_TRANSFER: 9999, // マスタに入っていない値
};

export const PAYMENT_URL_KEY = {
  TOP: 'TOP',
};

export const PAYMENT_METHOD_UNDEFINED = 'お支払い方法を指定してください';

export const PRIMEUR_UNDECIDED = '引渡し時に指定';

export const PAYMENT_SPLIT_NUM: Record<PaymentSplitNum, string> = {
  [PaymentSplitNum.LUMP_SUM]: '一括払い',
  [PaymentSplitNum.TWO_INSTALLMENTS]: '2回払い',
  [PaymentSplitNum.THREE_INSTALLMENTS]: '3回払い',
  [PaymentSplitNum.FIVE_INSTALLMENTS]: '5回払い',
  [PaymentSplitNum.SIX_INSTALLMENTS]: '6回払い',
  [PaymentSplitNum.TEN_INSTALLMENTS]: '10回払い',
  [PaymentSplitNum.TWELVE_INSTALLMENTS]: '12回払い',
  [PaymentSplitNum.FIFTEEN_INSTALLMENTS]: '15回払い',
  [PaymentSplitNum.EIGHTEEN_INSTALLMENTS]: '18回払い',
  [PaymentSplitNum.TWENTY_INSTALLMENTS]: '20回払い',
  [PaymentSplitNum.TWENTY_FOUR_INSTALLMENTS]: '24回払い',
  [PaymentSplitNum.BONUS]: 'ボーナス一括払い',
};

export const SPECIFICATION_TYPE = {
  none: '同封しない',
  enclosing: '同封する',
};

export const PAPER_BAG_OPTIONS: FormSelectOption[] = [
  { value: false, text: 'なし' },
  { value: true, text: 'あり' },
];

export const GIFT_WRAPPING_OPTIONS: FormSelectOption[] = [
  { value: false, text: 'ギフトラッピングを希望しない' },
  { value: true, text: 'ギフトラッピングを希望する' },
];

export const MESSAGE_OPTIONS: FormImageRadioOption[] = [
  { value: true, text: '利用する', image: require('~/assets/images/gift-box/gift-box_item-detail_B-3.png') },
  { value: false, text: '利用しない', image: require('~/assets/images/gift-box/no-img.png') },
];

export const WRAPPING_OPTIONS: FormImageRadioOption[] = [
  { value: 'ribbon', text: 'リボン', image: require('~/assets/images/gift-box/gift-box_item-detail_B-1.jpeg') },
  { value: 'noshi', text: 'のし', image: require('~/assets/images/gift-box/gift-box_item-detail_B-2.png') },
  { value: 'no', text: 'なし', image: require('~/assets/images/gift-box/750ml_2_paper.png') },
];

/** ギフトカード最大文字数 */
export const MAX_GIFT_MESSAGE_WORD_COUNT = 280;

/** ギフトカード最大行数 */
export const MAX_GIFT_MESSAGE_LINE_COUNT = 11;

/** ギフトカード一行最大文字数 */
export const MAX_GIFT_MESSAGE_WORD_COUNT_PER_LINE = 27;

/** のし名入れ最大行数 */
export const MAX_NOSHI_NAME_LINE_COUNT = 5;

/** のし名入れ一行最大文字数 */
export const MAX_NOSHI_NAME_WORD_COUNT_PER_LINE = 15;

/** のし肩書き最大行数 */
export const MAX_NOSHI_POSITION_LINE_COUNT = 2;

/** のし肩書き一行最大文字数 */
export const MAX_NOSHI_POSITION_WORD_COUNT_PER_LINE = 12;

/** requestAnimationFrameでDOM取得のループを回す最長時間(ms) */
export const LOOP_MAX_TIME = 10000;

export const NOSHI_OPTIONS: FormSelectOption[] = [
  { value: '1', text: '寿（結婚お祝い等：結びきり十本）' },
  { value: '2', text: '内祝（結婚お祝いの返礼：結びきり十本）' },
  { value: '3', text: '御礼（お世話になったお礼：花結び）' },
  { value: '4', text: '御祝（お祝い事：花結び）' },
  { value: '5', text: '内祝（出産・入学・進学祝等の返礼：花結び）' },
  { value: '6', text: '御中元（花結び）' },
  { value: '7', text: '御歳暮（花結び）' },
  { value: '8', text: '御年賀（花結び）' },
  { value: '9', text: '御見舞（病気見舞い：結びきり五本）' },
  { value: '10', text: '快気祝（お見舞いの返礼：結びきり五本）' },
  { value: '11', text: '志（仏事返礼：黒白結びきり五本）' },
  { value: '12', text: '暑中御見舞（花結び）～8月中旬' },
  { value: '13', text: '残暑御見舞（花結び）～9月上旬' },
  { value: '14', text: '無地（花結び）' },
];

export const WINE_LIST: Array<WineItem> = [
  {
    id: -1,
    count: 0,
  },
];

// ギフトボックスの画像のデータ
export const GIFT_BOXES: Array<GiftBoxImage> = [
  {
    id: 41,
    name: '1本用フルボトル 木箱',
    imageUrl: require('~/assets/images/gift-box/750ml_1_wood.png'),
  },
  {
    id: 42,
    name: '2本用フルボトル 木箱',
    imageUrl: require('~/assets/images/gift-box/750ml_2_wood.png'),
  },
  {
    id: 37,
    name: '1本用フルボトル 紙箱',
    imageUrl: require('~/assets/images/gift-box/750ml_1_paper.png'),
  },
  {
    id: 38,
    name: '2本用フルボトル 紙箱',
    imageUrl: require('~/assets/images/gift-box/750ml_2_paper.png'),
  },
  {
    id: 39,
    name: '3本用フルボトル 紙箱',
    imageUrl: require('~/assets/images/gift-box/750ml_3_paper.png'),
  },
  {
    id: 40,
    name: '1本用フルボトル(ロング) 紙箱',
    imageUrl: require('~/assets/images/gift-box/750ml_1_paper.png'),
  },
  {
    id: 6,
    name: '1本用ハーフボトル 紙箱',
    imageUrl: require('~/assets/images/gift-box/350ml_1_paper.png'),
  },
  {
    id: 7,
    name: '1本用マグナムボトル 紙箱',
    imageUrl: require('~/assets/images/gift-box/1500ml_1_paper.png'),
  },
];

/** 注文が通せる例外的なエラー */
export const exceptionalValidErrors: string[] = [
  OrderErrorCodes.PROMOTION_EXPIRED,
  OrderErrorCodes.PROMOTION_NOT_FOUND,
];

export const DELIVERY_STATUS = {
  RECIPIENT: 'recipient',
  DELIVERIES: 'deliveries',
};

export const PAIDY_STATUS = {
  AUTHORIZED: 'authorized',
  REJECTED: 'rejected',
};
