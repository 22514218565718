import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ConsolsActionPayload } from '~/types/api';

export type Ctx = DefineActionContext<never, never, never>;

export const actions = {
  async fetchPersonalMessage(
    this: Vue,
    context: Ctx,
    payload: ConsolsActionPayload<'/api/v1/personal/message', 'get'>
  ) {
    return await this.$consolsAxios.$get('/api/v1/personal/message', payload);
  },
};

export type Store = DefineStoreModule<'modules/mypage/personalMessage', never, never, never, typeof actions>;
