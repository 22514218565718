import { PAYMENT_METHOD_ID } from '~/constants';
import { UserOptionType, OrderAddress } from '~/types/modules/order';

export const createSenderOrderReq = (option: UserOptionType): OrderAddress | undefined => {
  const {
    senderAddress,
    shipments,
    isAmazonGuestOrder,
    payment: { paymentMethodId },
  } = option;
  const isAmazonPayOrder = paymentMethodId === PAYMENT_METHOD_ID.AMAZON_PAY;

  /* ギフト注文または自宅以外の住所へ配送する場合は自分の住所を送り主としてリクエスト */
  if (isAmazonPayOrder || shipments.length >= 2 || (!isAmazonGuestOrder && !shipments[0].primaryAddress)) {
    return {
      address1: senderAddress.address,
      address2: senderAddress.building,
      city: senderAddress.city,
      first_name: senderAddress.firstName,
      last_name: senderAddress.lastName,
      phone_number: senderAddress.phoneNumber,
      prefecture_id: senderAddress.prefectureId,
      zipcode: senderAddress.zipCode,
    };
  } else {
    return undefined;
  }
};
