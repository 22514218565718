















import { defineComponent } from '@nuxtjs/composition-api';
import AppLink from '~/components/partials/App/AppLink.vue';
import { sendGaEvent } from '~/utils/ga';

export default defineComponent({
  components: {
    AppLink,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: null,
    },
  },
  setup() {
    return { sendGaEvent };
  },
});
