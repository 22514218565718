





























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import AppAccordion from '~/components/partials/App/AppAccordion.vue';
import AppLink from '~/components/partials/App/AppLink.vue';

export type FooterMenuCardItem = {
  title: string;
  link: string;
  tag?: 'a' | 'NuxtLink';
};

export default defineComponent({
  components: {
    AppAccordion,
    AppLink,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<FooterMenuCardItem[]>,
      required: true,
    },
  },
});
