import { defineNuxtPlugin, onGlobalSetup } from '@nuxtjs/composition-api';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';

export default defineNuxtPlugin(() => {
  onGlobalSetup(() => {
    const { BEACON_ORIGIN, CONSOLS_API_ORIGIN } = useRuntimeConfig();

    (function (w, d, c) {
      w.TLAB = [['Setup', c]];
      const t = d.createElement('script');
      t.type = 'text/javascript';
      t.charset = 'utf-8';
      t.src = (c.RecommendEndpoint || '') + '/js.recommend/tlab_v4.js';
      d.getElementsByTagName('head')[0].appendChild(t);
    })(window, document, {
      BeaconEndpoint: BEACON_ORIGIN,
      RecommendEndpoint: CONSOLS_API_ORIGIN,
      TrackingIdCookieName: 'usertid',
      UserIdCookieName: 'useruid',
      TrackingIdCookiePath: '/',
      UserIdCookiePath: '/',
    });
  });
});
