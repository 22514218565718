export const AREA_ID: Record<string, string> = {
  FRANCE: '100',
  BORDEAUX: '1000',
  BOURGOGNE: '2000',
  CHAMPAGNE: '3001',
  LOIRE: '3100',
  RHONE: '3200',
  LANGUEDOC_ROUSSILLON: '3300',
  PROVENCE: '3400',
  ALSACE: '3500',
  ITALIA: '4000',
  SPAIN: '8000',
  PORTUGAL: '12000',
  GERMANY: '10000',
  NEW_ZEALAND: '9000',
  AUSTRALIA: '11000',
  CHILE: '6000',
  ARGENTINA: '7000',
  USA: '5000',
  SOUTH_AFRICA: '13000',
  JAPAN: '80000',
  AUSTRIA: '80045',
};

// 生産地リスト
export const AREA_LIST = [
  { id: '100', label: 'フランスの全ての産地を見る' },
  { id: '1000', label: 'ボルドー' },
  { id: '2000', label: 'ブルゴーニュ' },
  { id: '3001', label: 'シャンパーニュ' },
  { id: '4000', label: 'イタリアの全ての産地を見る' },
  { id: '4009', label: 'トスカーナ' },
  { id: '4002', label: 'ピエモンテ' },
  { id: '5000', label: 'アメリカの全ての産地を見る' },
  { id: '5001', label: 'カリフォルニア' },
  { id: '6000', label: 'チリ' },
  { id: '8000', label: 'スペイン' },
  { id: '9000', label: 'ニュージーランド' },
  { id: '80000', label: '日本' },
  { id: '7000', label: 'アルゼンチン' },
  { id: '10000', label: 'ドイツ' },
  { id: '11000', label: 'オーストラリア' },
  { id: '12000', label: 'ポルトガル' },
  { id: '13000', label: '南アフリカ' },
  { id: '80029', label: 'ギリシャ' },
  { id: '15000', label: 'ハンガリー' },
  { id: '16000', label: 'イギリス' },
  { id: '17000', label: 'ベルギー' },
  { id: '80043', label: 'スイス' },
  { id: '80203', label: 'スウェーデン' },
  { id: '80045', label: 'オーストリア' },
  { id: '80067', label: 'スロヴァキア' },
  { id: '80066', label: 'ジョージア (グルジア)' },
  { id: '80160', label: 'ルーマニア' },
  { id: '80161', label: 'カナダ' },
  { id: '80064', label: 'イスラエル' },
  { id: '80065', label: 'クロアチア' },
  { id: '80137', label: 'ブルガリア' },
  { id: '80138', label: 'モルドヴァ' },
  { id: '80056', label: '中国' },
  { id: '80204', label: 'ウルグアイ' },
  { id: '80058', label: 'ブラジル' },
  { id: '80205', label: 'スロベニア' },
];
