import dayjs from 'dayjs';

export const formatExpirationDate = (expiry: string): string => {
  if (expiry.length !== 6) return expiry;
  return expiry.substring(0, 4) + '/' + expiry.substring(4);
};

/**
 * 日付が期限切れかどうかをチェック
 * @param expiry YYYY/MM 形式の日付
 * @returns　期限切れたかどうか
 */
export const hasExpired = (expiry: string): boolean => {
  // 所定のフォーマットで解析させる
  return (
    dayjs(expiry, 'YYYY/MM')
      .tz('Asia/Tokyo')
      // 月末に変更する（デフォルトでは 1 日）
      .endOf('month')
      // 今日よりも過去かどうか
      .isBefore(dayjs())
  );
};
