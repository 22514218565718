/**
 * @file 旧システムからのリダイレクト設定
 */

import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { RuntimeConfig } from '~/composables/useRuntimeConfig';
import { url } from '~/utils';

/** .html と index.html を省略する */
export const trimHtmlExtension = (fullPath: string): string | null => {
  if (fullPath.startsWith('/shop')) {
    return null;
  }

  const TRIM_INDEX_PATTERN = /^([^?#]*)\/index\.html(\?|#|$)/;

  if (TRIM_INDEX_PATTERN.test(fullPath)) {
    return fullPath.replace(TRIM_INDEX_PATTERN, '$1/$2') || '/';
  }

  const TRIM_EXTENSION_PATTERN = /^([^?#]*)\.html(\?|#|$)/;

  return TRIM_EXTENSION_PATTERN.test(fullPath) ? fullPath.replace(TRIM_EXTENSION_PATTERN, '$1$2') : null;
};

/** 商品一覧の生産者クエリを生産者詳細にリダイレクトする */
export const redirectProducerFromItemList = ({ pathname, searchParams }: URL): string | null => {
  const producerId = searchParams.get('_producer');
  const isSingular = Array.from(searchParams.keys()).length === 1;

  if (pathname === url('ITEM_LIST') && producerId && isSingular) {
    return url('PRODUCER_DETAIL', { id: producerId });
  }

  return null;
};

/** 商品一覧の特集クエリを特集詳細にリダイレクトする */
export const redirectArchiveFromItemList = ({ pathname, searchParams }: URL): string | null => {
  const permalink = searchParams.get('_label');

  if (pathname === url('ITEM_LIST') && permalink) {
    return url('ARCHIVES_DETAIL', { permalink });
  }

  return null;
};

export default defineNuxtPlugin(({ app, route, redirect, ...context }) => {
  const runtimeConfig = context.$config as RuntimeConfig;
  const { FRONTEND_ORIGIN } = runtimeConfig;
  const getMatchedRedirectDestination = (fullPath: string): string | null => {
    const url = new URL(fullPath, FRONTEND_ORIGIN);

    return trimHtmlExtension(fullPath) || redirectProducerFromItemList(url) || redirectArchiveFromItemList(url);
  };

  // サーバーサイドでは一度のみリダイレクトを実施する
  if (process.server) {
    const destination = getMatchedRedirectDestination(route.fullPath);

    if (destination) {
      redirect(301, destination);
    }

    return;
  }

  if (process.client) {
    // クライアントサイドの場合はルーティングガードを仕込む
    app.router?.beforeEach((to, from, next) => {
      const destination = getMatchedRedirectDestination(to.fullPath);

      if (destination) {
        location.href = destination;

        return;
      }

      next();
    });
  }
});
