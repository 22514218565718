import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import dayjs from 'dayjs';
import Vue from 'vue';
import { BaseAxiosAction } from '~/types/api';
import { CollegeLessonQuiz } from '~/types/common';
import { ArticleType } from '~/enums';

/** State */
type DailyQuiz = {
  date: string;
  contentId: number;
  quizId: number;
};

export type State = {
  dailyQuiz: DailyQuiz;
};

export const state = (): State => ({
  dailyQuiz: {
    date: '2023-01-01T00:00:00+09:00',
    contentId: 0,
    quizId: 0,
  },
});

/** Mutations */
export const mutations = {
  setDailyQuiz(state: State, dailyQuiz: DailyQuiz) {
    state.dailyQuiz = dailyQuiz;
  },
};

/** Actions */
export type Ctx = DefineActionContext<State, never, typeof mutations>;
export const actions = {
  /**
   * 今日のクイズを取得する
   */
  async fetchDailyQuiz(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{}>): Promise<void> {
    const { cancelToken } = payload;

    const result = await this.$eventAxios.$get('/api/v1/wine_college/quiz/daily', { cancelToken });

    commit('setDailyQuiz', {
      date: dayjs().format(),
      contentId: result.contentId,
      quizId: result.quizId,
    });
  },

  /**
   * 講座クイズを取得する
   * @param { number } lessonId - クイズ（記事）ID
   * @return { CollegeLessonQuiz } - クイズ詳細を返す
   */
  async fetchLessonQuiz(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ quizId: number }>
  ): Promise<CollegeLessonQuiz> {
    const { cancelToken, quizId } = payload;
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.CollegeQuiz,
        '_article_id[eq]': quizId,
      },
    });

    return {
      question: result[0].contents.question_text,
      options: [
        result[0].contents.answer_option_1,
        result[0].contents.answer_option_2,
        result[0].contents.answer_option_3,
        result[0].contents.answer_option_4,
      ],
      answer: result[0].contents.correct_option,
      explanation: result[0].contents.explanation_text,
    };
  },
};

/** Store Module Type */
export type Store = DefineStoreModule<'college/quiz', State, never, typeof mutations, typeof actions>;
