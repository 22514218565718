





























import { defineComponent, onMounted, ref, useContext, watch } from '@nuxtjs/composition-api';
import AppLink from '~/components/partials/App/AppLink.vue';
import { useStore } from '~/composables/useStore';
import { PRODUCT_PARAM_QUERIES, RECOMMEND_API_SETTING } from '~/constants';
import { url } from '~/utils';
import { ProductSuggestion } from '~/types/modules/product';

export default defineComponent({
  components: {
    AppLink,
  },
  props: {
    inputValue: {
      type: String,
      default: '',
    },
    isFromSpHeader: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { i18n } = useContext();
    const keywords = ref<ProductSuggestion[]>([]);

    const concatKeywords = (...productSections: ProductSuggestion[]) => {
      return [...productSections].slice(0, RECOMMEND_API_SETTING.LIMIT);
    };

    const fetch = async () => {
      // 入力値が空の時：人気キーワード
      if (!props.inputValue) {
        const data = await store.dispatch('product/recommend/fetchPopularKeyword', {});
        keywords.value = concatKeywords(...data);
        return;
      }

      /**
       * 入力値１文字：前方一致、２文字以上：部分一致
       * [各マスタ、栓、商品、セール情報](閲覧ランキング順)、ヴィンテージ(商品数多い順)　の優先順位
       */
      const [masterSuggestions, stopperSuggestions, itemSuggestions, saleSuggestions, vintageSuggestions] =
        await Promise.all([
          store.dispatch('product/recommend/fetchMasterSuggestions', {
            word: props.inputValue,
            isPrefix: props.inputValue.length === 1,
          }),
          store.dispatch('product/recommend/fetchStopperSuggestions', {
            word: props.inputValue,
            isPrefix: props.inputValue.length === 1,
          }),
          store.dispatch('product/recommend/fetchItemSuggestions', {
            word: props.inputValue,
            isPrefix: props.inputValue.length === 1,
          }),
          store.dispatch('product/recommend/fetchSaleSuggestions', {
            word: props.inputValue,
            isPrefix: props.inputValue.length === 1,
          }),
          store.dispatch('product/recommend/fetchVintageSuggestions', {
            word: props.inputValue,
            isPrefix: true, // TODO: 現状生産年の部分一致がバグってるので、サーチの調査待ち
          }),
        ]);
      keywords.value = concatKeywords(
        ...masterSuggestions,
        ...stopperSuggestions,
        ...itemSuggestions,
        ...saleSuggestions,
        ...vintageSuggestions
      );
    };

    onMounted(async () => {
      await fetch();
    });

    watch(
      () => props.inputValue,
      async () => {
        await fetch();
      }
    );

    const suggestionClicked = (keyword: ProductSuggestion) => {
      // SPヘッダーのアイコンから開く場合、検索結果へ遷移する前に、スクロール禁止を解除する必要がある
      // NOTE: PC商品一覧ページでサジェストクリックするとき、emitでサジェスト閉じるなどの処理が必要（一覧以外の画面はemit発火しないがなぜかリセット出来てる）
      emit('suggestionClicked', props.isFromSpHeader ? { path: url('ITEM_LIST'), query: query(keyword) } : undefined);
    };

    const convertLabel = (item: ProductSuggestion) => {
      // セール情報の場合
      if (item.isSaleInfo) {
        return i18n.t('Search-38.suggest.label.saleInfo').toString();
      }

      // キーワードの場合
      if (!item.id || !item.mst) {
        return;
      }

      // 各マスタの場合
      switch (item.mst) {
        case 'product':
          return i18n.t('Search-38.suggest.label.product').toString();
        case 'category':
          return i18n.t('Search-38.suggest.label.category').toString();
        case 'producing_area':
          return i18n.t('Search-38.suggest.label.producingArea').toString();
        case 'producer':
          return i18n.t('Search-38.sugguest.label.producer').toString();
        case 'brand':
          return i18n.t('Search-38.sugguest.label.grape').toString();
        case 'color':
          return i18n.t('Search-38.suggest.label.color').toString();
        case 'tag2':
          return i18n.t('Search-38.suggest.label.tag').toString();
        case 'vintage':
          return i18n.t('Search-38.suggest.label.vintage').toString();
      }
    };

    const queryParam = (item: ProductSuggestion) => {
      // キーワードとして検索する場合
      if (!item.id || !item.mst || (item.mst && item.mst === 'product')) {
        return PRODUCT_PARAM_QUERIES.WORD;
      }

      // フィールドを指定して検索する場合
      switch (item.mst) {
        case 'category':
          return PRODUCT_PARAM_QUERIES.CATEGORY;
        case 'producing_area':
          return PRODUCT_PARAM_QUERIES.AREA;
        case 'producer':
          return PRODUCT_PARAM_QUERIES.PRODUCER;
        case 'brand':
          return PRODUCT_PARAM_QUERIES.VARIETY;
        case 'color':
          return PRODUCT_PARAM_QUERIES.COLOR;
        case 'tag2':
          return PRODUCT_PARAM_QUERIES.TAG;
        case 'vintage':
          return PRODUCT_PARAM_QUERIES.VINTAGE;
        default:
          return '';
      }
    };

    const query = (item: ProductSuggestion) => {
      const param = queryParam(item);

      switch (param) {
        case PRODUCT_PARAM_QUERIES.WORD:
          return { [param]: item.word };
        case PRODUCT_PARAM_QUERIES.TAG:
          return { [param]: item.word };
        default:
          return { [param]: item.id };
      }
    };

    return {
      keywords,
      url,
      suggestionClicked,
      query,
      convertLabel,
    };
  },
});
