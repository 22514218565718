import { useBreakpoints } from '@vueuse/core';
import { MEDIA_BREAK_POINT } from '~/constants';

export const useMatchMedia = () => {
  const breakpoints = useBreakpoints({
    sm: MEDIA_BREAK_POINT.SM,
    md: MEDIA_BREAK_POINT.MD,
    lg: MEDIA_BREAK_POINT.LG,
    xl: MEDIA_BREAK_POINT.XL,
  });

  const isMobile = breakpoints.smaller('md');
  const isTablet = breakpoints.greater('md');
  const isDesktop = breakpoints.greater('lg');
  const isLargeDesktop = breakpoints.greater('xl');

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
  };
};
