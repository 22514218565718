import { ValidationRule } from 'vee-validate/dist/types/types';
import { definePreset } from '../core/preset';

export const phoneNumberPattern: ValidationRule = {
  message: '{_field_}は半角数字で入力してください',
  validate(value) {
    if (typeof value !== 'string') {
      return false;
    }

    if (!/^[0-9-]+$/.test(value)) {
      return '{_field_}は半角数字で入力してください';
    }

    if (!/^[0-9]{1,5}-[0-9]{1,4}-[0-9]{4}$/.test(value)) {
      return '{_field_}は正しい形式ではありません';
    }

    return true;
  },
};

export const phoneNumberSinglePattern: ValidationRule = {
  message: '{_field_}は半角数字で入力してください',
  validate(value) {
    if (typeof value !== 'string') {
      return false;
    }

    // ハイフンを入れるよう制御しているので、maxではなくこちらで文字数検証
    if (value.replaceAll('-', '').length > 11) {
      return '{_field_}は11文字以内で入力してください　ハイフンは不要です';
    }

    /**
     * 数字以外の文字が入っている場合はエラー
     * ※ハイフンが2文字自動で入るため、ハイフンが3つ以上ある場合も文字エラーとして判定する
     */
    if (!/^[0-9-]+$/.test(value) || (value?.match(/-/g) ?? []).length > 2) {
      return '{_field_}は半角数字で入力してください';
    }

    if (value.replaceAll('-', '').length < 10) {
      return '{_field_}は10文字以上で入力してください';
    }

    return true;
  },
};

export const phoneNumber = definePreset({
  rules: {
    max: 13,
    phoneNumberPattern: true,
  },
});

export const phoneNumberSingle = definePreset({
  rules: {
    phoneNumberSinglePattern: true,
  },
});
