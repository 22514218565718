import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import { filter } from 'lodash-es';
import Vue from 'vue';
import { SEARCH_LIMIT_ROWS } from '~/constants';
import { BaseAxiosAction, EventActionPayload } from '~/types/api';
import { CollegeProducingArea, ProducingAreaLesson, LessonChapter, LessonDetail, BasicLesson } from '~/types/common';
import { BooleanLike, ArticleType } from '~/enums';

export type Ctx = DefineActionContext<never, never, never>;

export const actions = {
  /**
   * CMSから講座一覧を取得する
   * @param { string } articleType - 講座の種類（産地 or 基礎 or 品種）
   * @return { LessonDetail[] } - 講座一覧を返す
   */
  async fetchLessonList(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ articleType: string }>
  ): Promise<LessonDetail[]> {
    const { cancelToken, articleType } = payload;
    const { result } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': articleType,
      },
    });
    return result ?? [];
  },

  /**
   * CMSから基礎講座チャプター一覧を取得する
   * @return { LessonChapter[] } - 目次と講座一覧（講座IDと並び順の配列）を返す
   */
  async fetchBasicLessonChapterList(this: Vue): Promise<LessonChapter[]> {
    const { result } = await this.$cmsAxios.$get('/view_articles', {
      query: {
        '_article_type[eq]': ArticleType.BasicLessonChapter,
      },
    });
    return result ?? [];
  },

  /**
   * サーチのカレッジ産地マスタから産地一覧を取得する
   * @param { string } query - クエリ
   * @return { Array<CollegeProducingArea> } - カレッジの産地一覧を返す
   */
  async fetchCollegeProducingAreas(
    this: Vue,
    context: Ctx,
    payload?: BaseAxiosAction<{ query: string }>
  ): Promise<Array<CollegeProducingArea>> {
    const {
      response: { docs = [] },
    } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:college_producing_area AND is_display_i:${BooleanLike.TRUE}${
          payload?.query ? ` AND ${payload.query}` : ''
        }`,
        fl: [
          'iid',
          'area_id_i',
          'parent_area_id_i',
          'name_ja_s',
          'name_en_s',
          'producing_area_description_s',
          'national_flag_image_s',
          'main_image_pc_s',
          'main_image_sp_s',
          'is_expanded_i',
        ],
        sort: 'display_order_i asc',
        rows: SEARCH_LIMIT_ROWS,
      },
    });

    const areas = docs.map((area: any) => {
      return {
        item_id: area.iid,
        area_id: area.area_id_i,
        parent_area_id: area.parent_area_id_i,
        name_ja: area.name_ja_s,
        name_en: area.name_en_s,
        producing_area_description: area.producing_area_description_s,
        national_flag_image: area.national_flag_image_s,
        main_image_pc: area.main_image_pc_s,
        main_image_sp: area.main_image_sp_s,
        is_expanded: area.is_expanded_i === parseInt(BooleanLike.TRUE),
      };
    });

    return filter(areas, (area) => area.area_id !== area.parent_area_id);
  },

  /**
   * サーチの産地講座マスタから講座一覧を取得する
   * @param { string } query - クエリ
   * @return { Array<ProducingAreaLesson> } - 産地講座一覧を返す
   */
  async fetchProducingAreaLessons(
    this: Vue,
    context: Ctx,
    payload?: BaseAxiosAction<{ query: string }>
  ): Promise<Array<ProducingAreaLesson>> {
    const {
      response: { docs = [] },
    } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:producing_area_lesson${payload?.query ? ` AND ${payload.query}` : ''}`,
        fl: ['iid', 'article_id_i', 'area_id_i'],
        rows: SEARCH_LIMIT_ROWS,
      },
    });

    return docs.map((lesson: any) => {
      return {
        item_id: lesson.iid,
        article_id: lesson.article_id_i,
        area_id: lesson.area_id_i,
      };
    });
  },

  /**
   * サーチのBasic産地マスタから産地一覧を取得する
   * @param { string } query - クエリ
   * @return { Array<BasicLesson> } - 基礎講座一覧を返す
   */
  async fetchBasicLessons(
    this: Vue,
    context: Ctx,
    payload?: BaseAxiosAction<{ query: string }>
  ): Promise<Array<BasicLesson>> {
    const {
      response: { docs = [] },
    } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:basic_lesson ${payload?.query ? ` AND ${payload.query}` : ''}`,
        fl: ['iid', 'article_id_i', 'title_s', 'basic_lesson_description_s', 'main_image_pc_s', 'main_image_sp_s'],
        sort: 'display_order_i asc',
        rows: SEARCH_LIMIT_ROWS,
      },
    });

    return docs.map((doc: any) => {
      return {
        item_id: doc.iid,
        article_id: doc.article_id_i,
        title: doc.title_s,
        description: doc.basic_lesson_description_s,
        main_image_pc: doc.main_image_pc_s,
        main_image_sp: doc.main_image_sp_s,
      };
    });
  },

  /** 受講ステータスを取得 */
  async fetchLessonStatus(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/wine_college/lesson/status', 'get'>
  ) {
    return await this.$eventAxios.$get('/api/v1/wine_college/lesson/status', payload);
  },
};

export type Store = DefineStoreModule<'college/list', never, never, never, typeof actions>;
