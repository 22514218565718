import dayjs from 'dayjs';
import { omitBy, isNil } from 'lodash-es';
import { useEnotecaApp } from '~/composables/useEnotecaApp';
import { useStore } from '~/composables/useStore';
import { karteTrack, formatPoints } from '~/utils';
import { ClubEnotecaType, MemberRank } from '~/enums';

export const useKarteUserTag = () => {
  const store = useStore();
  const { isEnotecaAppWebView } = useEnotecaApp();

  const setKarteUserTag = async (isAuthenticated: boolean, tempCardNo?: string | null) => {
    if (!isAuthenticated) {
      return;
    }

    const { userBase, profile, flag } = store.state.user.info;

    let mailMagazine;
    let balance;
    try {
      const mailMagazineRes = await store.dispatch('user/magazine/fetchMailMagazine', {});
      mailMagazine = mailMagazineRes.mailMagazine;

      if (!tempCardNo) {
        // vf_error回避のため
        const balanceRes = await store.dispatch('modules/mypage/point/getPointBalance', {});
        balance = balanceRes.balance;
      }
    } catch {
      // 何もしない
    }
    const { totalPoint, proximateExpireDateOfPoint } = formatPoints(balance);

    let proximateExpireDateOfCouponName;
    let proximateExpireDateOfCoupon;

    // ユーザ特定クーポンを取得する
    if (!tempCardNo) {
      // vf_error回避のため
      try {
        const { personalPromotions } = await store.dispatch('modules/mypage/coupon/fetchPersonalCoupons', {});
        // 直近の有効期限クーポンの期限とクーポン名を取得
        proximateExpireDateOfCoupon = dayjs(personalPromotions[0].valid_to);
        proximateExpireDateOfCouponName = personalPromotions[0].promotion_name;
      } catch {
        // 何もしない
      }
    }

    let clubEnotecaType = 'なし';
    switch (userBase?.clubenotecaType) {
      case ClubEnotecaType.CLASSIC:
        clubEnotecaType = 'CLASSIC';
        break;
      case ClubEnotecaType.GOLD:
        clubEnotecaType = 'GOLD';
    }

    const createDate = dayjs(userBase?.createdate || null);

    const params = {
      mail_address: userBase?.loginId,
      user_id: tempCardNo || userBase?.cardNo, // アプリ・LINEの仮会員番号がある場合はそれを優先する
      subscription: Boolean(mailMagazine?.isMailSubscription),
      create_date: createDate.isValid() ? createDate.toDate() : undefined,
      first_name: profile?.firstName,
      last_name: profile?.lastName,
      point: totalPoint,
      point_expire_date: totalPoint ? proximateExpireDateOfPoint : undefined,
      coupon: proximateExpireDateOfCouponName,
      coupon_expire_date: proximateExpireDateOfCoupon?.isValid() ? proximateExpireDateOfCoupon.toDate() : undefined,
      rank:
        userBase?.memberRank === MemberRank.THREE_STARS
          ? '★★★'
          : userBase?.memberRank === MemberRank.PREMIUM
          ? 'プレミアムランク'
          : 'レギュラーランク',
      // アプリで見てる場合は常にtrue
      app_inst: isEnotecaAppWebView.value || Boolean(flag?.isAppLink),
      club_enoteca_type: clubEnotecaType,
      club_enoteca_no: userBase?.cardNo,
      line_flg: Boolean(userBase?.lineId),
      shop_ml_flg: Boolean(mailMagazine?.isShopMail),
    };
    karteTrack('identify', omitBy(params, isNil));
  };

  return { setKarteUserTag };
};
