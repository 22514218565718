import { defineNuxtPlugin, onGlobalSetup } from '@nuxtjs/composition-api';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';

export default defineNuxtPlugin(({ app }) => {
  onGlobalSetup(() => {
    const { KARTE_TRACKING_ID } = useRuntimeConfig();

    // Karte Tracker v2 の読み込み
    // Start KARTE Tag
    const $startKarte = document.createElement('script');
    $startKarte.innerHTML = `!function(n){var o=window[n]=function(){var n=[].slice.call(arguments);return o.x?o.x.apply(0,n):o.q.push(n)};o.q=[],o.i=Date.now(),o.allow=function(){o.o="allow"},o.deny=function(){o.o="deny"}}("krt")`;
    document.body.appendChild($startKarte);
    // Start KARTE Compatible Tag
    const $tracker = document.createElement('script');
    $tracker.innerHTML = `!function(t,e,n){var r=this&&this.__spreadArray||function(t,e,n){if(n||2===arguments.length)for(var r,o=0,a=e.length;o<a;o++)!r&&o in e||(r||(r=Array.prototype.slice.call(e,0,o)),r[o]=e[o]);return t.concat(r||Array.prototype.slice.call(e))};n[e]&&(n[e].stop(),console.warn("[krt:compat] detect old tracker and remove it"),delete n[e]);var o=n[e]||(n[e]=[]),a=function(){for(var t=[],e=0;e<arguments.length;e++)t[e]=arguments[e];return n.krt.apply(n,t)};["start","stop","action","event","goal","chat","admin","group","alias","ready","form","click","submit","cmd","emit","on","send","css","js","style","option","get","set","collection"].map((function(t){o[t]=function(){for(var e=[],n=0;n<arguments.length;n++)e[n]=arguments[n];return console.error.apply(console,r(["[krt:compat] not implmeneted",t],e,!1))}})),o.track=function(){for(var t=[],e=0;e<arguments.length;e++)t[e]=arguments[e];if(0!==t.length)return t[1]||(t[1]={}),t[1]._system||(t[1]._system={}),t[1]._system.compatible_tag=!0,a.apply(void 0,r(["send"],t,!1))},o.user=function(){for(var t=[],e=0;e<arguments.length;e++)t[e]=arguments[e];return t[0]||(t[0]={}),t[0]._system||(t[0]._system={}),t[0]._system.compatible_tag=!0,a.apply(void 0,r(["send","identify"],t,!1))},["buy","view","page"].map((function(t){return o[t]=function(){for(var e=[],n=0;n<arguments.length;n++)e[n]=arguments[n];return e[0]||(e[0]={}),e[0]._system||(e[0]._system={}),e[0]._system.compatible_tag=!0,a.apply(void 0,r(["send",t],e,!1))}})),o.link=function(t,e){e||(e={}),e._system||(e._system={}),e._system.compatible_tag=!0;var n=document.querySelector(t);n&&n.addEventListener("click",(function(){return a()("send","link",e)}),!0)},o.api_key="${KARTE_TRACKING_ID}"}(0,"tracker",window)`;
    document.body.appendChild($tracker);
    // End KARTE Compatible Tag
    const $edge = document.createElement('script');
    $edge.src = `https://cdn-edge.karte.io/${KARTE_TRACKING_ID}/edge.js`;
    document.body.appendChild($edge);
    // End KARTE Tag
  });

  app.router?.afterEach(() => {
    if (window.tracker) {
      try {
        window.tracker.view();
      } catch (e) {
        // 何もしない
      }
    }
  });
});
