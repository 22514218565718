import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import { sortBy } from 'lodash-es';
import Vue from 'vue';
import {
  REVIEWS_PER_PAGE,
  STAFF_REVIEW_SEARCH_SORT_OPTIONS,
  MERCHANT_ID,
  PRODUCT_WINE_COLOR_LABELS,
  MAX_STAFF_COUNT,
  STAFFS_PER_PAGE,
} from '~/constants';
import { nonNullable } from '~/utils';
import { BaseAxiosAction } from '~/types/api';
import { StaffReview, StaffReviewDetail, StaffDetail, StaffReviewTag, StaffReviewProduct } from '~/types/modules/staff';

export type Ctx = DefineActionContext<never, never, never>;

const parseColorLabel = (value?: string) => {
  if (!value) return '';

  // 色グループ名が含まれていたらラベルを返す
  const labels = PRODUCT_WINE_COLOR_LABELS.map(({ label }) => label);
  return labels.find((label) => value.includes(label)) || '';
};

const parseProduct = (product: any) => {
  return {
    product_code: product.base_product_code,
    image_url: product.product_image_url,
    name: product.name,
    color: {
      // カテゴリ'1'の時に色IDが返る
      code: product.parent_category === 1 ? product.category.code : '',
      name: parseColorLabel(product.category.name),
    },
    producer: {
      code: product.label.code,
      name: product.label.name,
    },
    area: {
      code: product.gender.code?.split('_')[0] || '',
      name: product.gender.name,
    },
    vintage: {
      code: product.color.code,
      name: product.color.name,
    },
    price: product.selling_price || product.price,
    staff_comment: product.staff_comment || undefined,
  };
};

const parseAttributes = (attributes: any) => {
  const items = attributes.map((attr: any) => {
    return {
      slug: attr.user_attribute_type.slug,
      value: attr.user_attribute_value.display_value,
    };
  });

  // 「好きなワインタイプ」のソート：色、品種、産地1、産地2
  const SORT_FAVORITE_WINE_TYPE = ['profile_types', 'profile_grapes', 'profile_area01', 'profile_area02'];

  const sortedItems = sortBy(items, (item) => SORT_FAVORITE_WINE_TYPE.indexOf(item.slug));

  const qualifications: string[] = [];
  const favorites: string[] = [];
  sortedItems.forEach((attr) => {
    if (attr.slug === 'profile_qualifications') {
      qualifications.push(attr.value);
    } else {
      favorites.push(attr.value);
    }
  });

  return {
    qualifications,
    favorites,
  };
};

export const actions = {
  async fetchStaffReviewList(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{
      productCode?: string;
      staffId?: string;
      count?: number;
      offset?: number;
      sort?: string;
      type?: string;
      area01?: string;
      area02?: string;
      priceFrom?: number;
      priceTo?: number;
      tagId?: number;
    }>
  ): Promise<{ items: StaffReview[]; total: number }> {
    const { productCode, staffId, sort, cancelToken, count, offset, type, area01, area02, priceFrom, priceTo, tagId } =
      payload;

    const targetSortOption = STAFF_REVIEW_SEARCH_SORT_OPTIONS.find((option) => {
      return option.value === sort;
    });

    const {
      data: { item, total },
    } = await this.$staffStartAxios.get('/v2/staff-reviews', {
      query: {
        merchant_id: MERCHANT_ID,
        base_product_code: productCode,
        user_id: staffId,
        sort: targetSortOption?.query || STAFF_REVIEW_SEARCH_SORT_OPTIONS[0].value,
        count: count || REVIEWS_PER_PAGE,
        offset: offset || 0,
        product_category_code: type,
        product_selling_price_from: priceFrom,
        product_selling_price_to: priceTo,
        product_gender: area01 || area02,
        tag_id: tagId,
      },
      cancelToken,
    });

    const parse = (item: any) => {
      try {
        return {
          id: item.id,
          user: item.user,
          shop: item.shop,
          comment: item.comment,
          published_at: item.published_at,
          title: item.title,
          tags: item.tags,
          main_product: parseProduct(item.main_product),
        };
      } catch {
        return null;
      }
    };

    return {
      items: item.map((value: any) => parse(value)).filter(nonNullable),
      total,
    };
  },

  async fetchStaffReviewDetail(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ reviewId: string }>
  ): Promise<StaffReviewDetail> {
    const res = await this.$staffStartAxios.$get('/v2/staff-reviews/detail', {
      query: {
        merchant_id: MERCHANT_ID,
        staff_review_id: payload.reviewId,
      },
      cancelToken: payload.cancelToken,
    });

    const parse = (item: any) => {
      return {
        id: item.id,
        shop: item.shop,
        user: item.user,
        qualifications: item.user_attributes ? parseAttributes(item.user_attributes).qualifications : [],
        favorites: item.user_attributes ? parseAttributes(item.user_attributes).favorites : [],
        published_at: item.published_at,
        title: item.title,
        comment: item.comment,
        tags: item.tags,
        main_product: item.main_product ? parseProduct(item.main_product) : ({} as StaffReviewProduct),
        assessments: item.assessments,
        sub_products: item.sub_products?.map((item: any) => parseProduct(item)),
      };
    };

    return parse(res.item);
  },

  async fetchStaffDetail(this: Vue, context: Ctx, payload: BaseAxiosAction<{ staffId: string }>): Promise<StaffDetail> {
    const { staffId, cancelToken } = payload;
    const { item } = await this.$staffStartAxios.$get('/v1/staff/detail', {
      query: {
        merchant_id: MERCHANT_ID,
        user_id: staffId,
      },
      cancelToken,
    });

    const parse = (item: any): StaffDetail => {
      return {
        user_id: item.user_id,
        shop_name: item.shop_name,
        shop_id: item.shop_id,
        name: item.name,
        profile: item.profile,
        img: item.img,
        qualifications: parseAttributes(item.user_attributes).qualifications,
        favorites: parseAttributes(item.user_attributes).favorites,
        favorite_products: item.favorite_products.map((item: any) => parseProduct(item)),
      };
    };

    return item.map((value: any) => parse(value))[0];
  },

  async fetchAllStaffList(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ offsetIndex: number }>
  ): Promise<{ items: StaffDetail[]; total: number }> {
    const { offsetIndex, cancelToken } = payload;
    const { item, total } = await this.$staffStartAxios.$get('/v2/staff/list', {
      query: {
        merchant_id: MERCHANT_ID,
        count: MAX_STAFF_COUNT, // 現状仕様上max120みたい（200とか指定しても120になっちゃう）
        offset: offsetIndex * MAX_STAFF_COUNT,
      },
      cancelToken,
    });

    const parse = (item: any): StaffDetail => {
      return {
        user_id: item.user_id,
        shop_name: item.shop_name,
        shop_id: item.shop_id,
        name: item.name,
        profile: item.profile,
        img: item.img,
        qualifications: parseAttributes(item.user_attributes).qualifications,
        favorites: parseAttributes(item.user_attributes).favorites,
      };
    };

    return {
      items: item.map((value: any) => parse(value)),
      total,
    };
  },

  async fetchStaffListByShopCode(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ pagination?: number; shop_code?: string | null }>
  ): Promise<{ items: StaffDetail[]; total: number }> {
    const { pagination, cancelToken, shop_code } = payload;
    const { item, total } = await this.$staffStartAxios.$get('/v2/staff/list', {
      query: {
        merchant_id: MERCHANT_ID,
        count: pagination ? STAFFS_PER_PAGE * pagination : undefined,
        shop_code: shop_code || undefined,
      },
      cancelToken,
    });

    const parse = (item: any): StaffDetail => {
      return {
        user_id: item.user_id,
        shop_name: item.shop_name,
        shop_id: item.shop_id,
        name: item.name,
        profile: item.profile,
        img: item.img,
        qualifications: parseAttributes(item.user_attributes).qualifications,
        favorites: parseAttributes(item.user_attributes).favorites,
      };
    };

    return {
      items: item.map((value: any) => parse(value)),
      total,
    };
  },

  async fetchStaffTagRanking(this: Vue): Promise<StaffReviewTag[]> {
    const data = await this.$staffStartAxios.$get('/v2/tags/ranking', {
      query: {
        content_type: 10,
        merchant_id: MERCHANT_ID,
      },
    });

    return data.item;
  },
};

export type Store = DefineStoreModule<'modules/staff', never, never, never, typeof actions>;
