type PageErrorOptions = {
  statusCode?: number;
  message?: string;
};

export class PageError extends Error {
  /** PageError かの判定フラグ */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly __is_PageError = true;

  constructor(public readonly statusCode: number, public readonly options: PageErrorOptions) {
    super(options.message || '不明なエラーが発生しました');
  }

  /**
   * PageError かどうか
   */
  public static isPageError(error: any): error is PageError {
    return error instanceof PageError || (typeof error === 'object' && '__is_PageError' in error);
  }
}
