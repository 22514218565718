export const COOKIE_KEY = {
  // ID token
  ID_TOKEN: 'id_token',
  // リフレッシュ用のJWTトークン
  LOGIN_TOKEN: 'login_token',
  // 外部認証後リダイレクト先
  RETURN_TO: 'return_to',
  // 外部ログインサービスから返ってくるトークン
  AUTH_TOKEN: 'auth_token',
  // 認証エラー
  AUTH_ERROR: 'auth_error',
  // 楽天初回購入フラグ
  IS_RAKUTEN_LOGIN: 'is_rakuten_login',
  // 楽天ログイン失敗時リダイレクトフラグ
  IS_RAKUTEN_SIGNUP: 'is_rakuten_signup',
  // 楽天ログイン遷移元パス
  RAKUTEN_FROM: 'rakuten_from',
  // DECConnect のフローID
  DCON_ID: 'dcon_id',
  // DECConnect の LINE 会員証番号
  DCON_MEMBER_ID: 'dcon_member_id',
  SITE_ID: 'site_id',
  DATE_TIME_ENTERED: 'date_time_entered',
  APP_MEMBER_CODE: 'app_member_code',
  // APIロガーのフィンガープリント(AppLoggerUserService の略)
  API_LOGGER_FINGERPRINT: 'alu_service',
  // マイショップ登録のID
  MYSHOP_ID: 'myshop_id',
  // シルバーエッグ用（ランダムの値を生成してcookieとして設定）
  SILVER_EGG_RANDOM: 'silver_egg_random',
  // 年齢認証用（ランダムの値を生成してcookieとして設定）
  AGE_CHECK_YES: 'age_check_yes',
  AGE_CHECK_NO: 'age_check_no',
  // 購入履歴あり（初回送料無料プロモの表示判定用）
  HAS_PURCHASE_HISTORY: 'has_purchase_history',
  // ソーシャルログインの検証用文字列
  SOCIAL_LOGIN_STATE: 'social_login_state',
  // ハッシュ化後のuuid（LOOPASSとシルバーエッグ用）
  UUID_HASH: 'uuid_hash',
};
