import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import type { RootStore } from '~/composables/useStore';
import { url } from '~/utils';

/**
 * ログイン済みであればマイページへ飛ばす。未ログイン状態でしか見られない画面に設定する
 */
export default defineNuxtMiddleware(({ redirect, ...context }) => {
  const store = context.store as RootStore;
  const { authenticated } = store.state.auth;

  if (!authenticated) {
    return;
  }

  redirect(url('MYPAGE'));
});
