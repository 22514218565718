


















































import { defineComponent, reactive, ref } from '@nuxtjs/composition-api';
import { useResizeObserver } from '@vueuse/core';
import AppIcon from '~/components/partials/App/AppIcon.vue';

export type FontSizeTypes = 'text-lg' | 'text-base';

export default defineComponent({
  components: {
    AppIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    center: {
      type: Boolean,
      default: false,
    },
    titleBold: {
      type: Boolean,
      default: false,
    },
    titleSmall: {
      type: Boolean,
      default: false,
    },
    titleFixed: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    borderAlways: {
      type: Boolean,
      default: false,
    },
    enableContentStyles: {
      type: Boolean,
      default: false,
    },
    smallPaddingY: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isOpen = reactive({ value: props.open });
    const isClosing = ref(false);
    const contentHeight = ref(0);
    const contentWrapperRef = ref<HTMLDivElement>();
    const contentRef = ref<HTMLDivElement>();

    const onTransitionEnd = (): void => {
      isOpen.value = false;
      isClosing.value = false;
    };

    const onSummaryClick = (e: Event): void => {
      e.preventDefault();

      if (!contentWrapperRef.value) {
        return;
      }

      contentWrapperRef.value.removeEventListener('transitionend', onTransitionEnd);

      isClosing.value = false;

      if (!isOpen.value) {
        isOpen.value = true;

        return;
      }

      isClosing.value = true;

      contentWrapperRef.value.addEventListener('transitionend', onTransitionEnd);
    };

    useResizeObserver(contentRef, (entries) => {
      const target = entries[0]?.target;

      if (!(target instanceof HTMLDivElement)) {
        return;
      }

      const rect = target.getBoundingClientRect();

      contentHeight.value = rect.height;
    });

    return {
      isOpen,
      isClosing,
      contentHeight,
      contentWrapperRef,
      contentRef,
      onSummaryClick,
    };
  },
});
