import { PAYMENT_METHOD_ID } from '~/constants';
import { OrderSimulationPayment, OrderPayment, UserOptionType } from '~/types/modules/order';

type CommonType = {
  [K in keyof OrderPayment & keyof OrderSimulationPayment]: OrderPayment[K];
};

const commonReq = ({
  paymentMethodId,
  paymentMemberId,
  option,
}: {
  paymentMethodId: number;
  paymentMemberId?: string;
  option?: UserOptionType;
}): CommonType => ({
  payment_method_id: paymentMethodId,
  /**
   * クレジットカード選択時はeconIdもセットでリクエスト
   * ギフトカード選択時はカード番号をセットする
   */
  payment_member_id:
    paymentMethodId === PAYMENT_METHOD_ID.CREDIT_CARD && paymentMemberId
      ? paymentMemberId
      : paymentMethodId === PAYMENT_METHOD_ID.GIFT
      ? `${option?.payment.cardNumber1}${option?.payment.cardNumber2}${option?.payment.cardNumber3}${option?.payment.cardNumber4}`
      : undefined,
  is_econ_bank_transfer: option?.payment.isEconBankTransfer,
});

export const createPaymentSimulationReq = (option: UserOptionType): OrderSimulationPayment | undefined => {
  const { paymentMethodId, paymentMemberId } = option.payment;
  if (paymentMethodId) {
    return commonReq({ paymentMethodId, paymentMemberId, option });
  }
};

export const createPaymentOrderReq = (option: UserOptionType): OrderPayment => {
  const { paymentMethodId, paymentMemberId } = option.payment;
  return {
    // @ts-expect-error
    // TODO: バリデーション実装後型定義を厳密にする
    ...commonReq({ paymentMethodId, paymentMemberId, option }),
    // @ts-expect-error
    payment_amount: option.payment.paymentAmount,
    payment_split_num: paymentMethodId === PAYMENT_METHOD_ID.CREDIT_CARD ? option.payment.paymentSplitNum : undefined,
    payment_token: option.payment.payment_token,
  };
};
