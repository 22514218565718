import { validate } from 'vee-validate';
import { RuleParamSchema, ValidationRule } from 'vee-validate/dist/types/types';

type DefinePresetOptions<Params extends string> = {
  rules: string | Record<string, any> | ((param: Record<Params, any>) => string | Record<string, any>);
  params?: Array<Params | (RuleParamSchema & { name: Params })>;
};

/**
 * VeeValidateのルールをプリセットとして定義するユーティリティ
 * @param options オプション
 */
export const definePreset = <Params extends string>(options: DefinePresetOptions<Params>): ValidationRule => {
  const { params, rules } = options;

  return {
    params,
    async validate(value, params) {
      if (typeof rules === 'function' && Array.isArray(params)) {
        return false;
      }

      const customRules = typeof rules === 'function' ? rules(params) : rules;

      const { valid, errors } = await validate(value, customRules);
      const [errorMessage = ''] = errors;
      const error = errorMessage.replace(/{field}/g, '{_field_}');

      return valid || error || true;
    },
  };
};
