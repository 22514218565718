






























































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import AppImage from '~/components/partials/App/AppImage.vue';
import AppLink from '~/components/partials/App/AppLink.vue';
import AppRectButton from '~/components/partials/App/AppRectButton.vue';
import { useSocialLogin } from '~/composables/auth/useSocialLogin';
import { useEnotecaApp } from '~/composables/useEnotecaApp';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';
import { useStore } from '~/composables/useStore';
import { PC, SMARTPHONE } from '~/constants';
import { url } from '~/utils';
import { SocialLoginProvider } from '~/enums';

export default defineComponent({
  components: {
    AppIcon,
    AppImage,
    AppLink,
    AppRectButton,
  },
  props: {},
  setup() {
    const { app, redirect } = useContext();
    const { isEnotecaAppWebView } = useEnotecaApp();
    const { getLoginUrl } = useSocialLogin();
    const store = useStore();
    const { LINE_OFFICIAL_ACCOUNT } = useRuntimeConfig();

    const isDesktop = computed<boolean>(() => app.$ua.deviceType() === PC);
    const isSmartPhone = computed<boolean>(() => app.$ua.deviceType() === SMARTPHONE);
    const authenticated = computed<boolean>(() => store.state.auth.authenticated);
    const hasLineId = computed<boolean>(() => {
      const userBaseInfo = store.state.user.info.userBase;
      return !!userBaseInfo && userBaseInfo.lineId !== '';
    });
    /** LINE連携可能かどうか */
    const canLoginWithLine = computed<boolean>(() => {
      // ログイン済み かつ LINE未連携 かつ スマホかタブレット かつ アプリのWebViewでない
      return authenticated.value && !hasLineId.value && !isDesktop.value && !isEnotecaAppWebView.value;
    });

    const clickLineLink = () => {
      if (!canLoginWithLine.value) {
        return;
      }
      const socialLoginUrl = getLoginUrl(SocialLoginProvider.LINE, url('DCON_SOCIAL_MERGE'));
      try {
        redirect(socialLoginUrl);
      } catch {
        // 何もしない
      }
    };

    return {
      isSmartPhone,
      isEnotecaAppWebView,
      url,
      canLoginWithLine,
      AppLink,
      clickLineLink,
      LINE_OFFICIAL_ACCOUNT,
    };
  },
});
