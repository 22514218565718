/**
 * 文字列が長さよりも短い場合、文字列を左側に詰める
 * Examples
 * ('123', 6, '0') -> '000123'
 * ('123', 3, '0') -> '123'
 * @param target - 詰める対象の文字列、数字
 * @param length - 基準の長さ
 * @param padding - 詰める文字、数字
 */
export const stringPadding = (target: number | string, length: number, padding: number | string): string => {
  return String(target).padStart(length, String(padding));
};
