import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { BaseAxiosAction } from '~/types/api';

export type Ctx = DefineActionContext<never, never, never>;

export const actions = {
  async fetchVintageInfo(
    this: Vue,
    ctx: Ctx,
    payload: BaseAxiosAction<{ vintage: string; producingArea: string; colorCode: string | null }>
  ): Promise<string | undefined> {
    const { vintage, producingArea, colorCode, cancelToken } = payload;
    let q = `mst:vintage AND vintage_code_s: ${vintage}`;

    if (colorCode) {
      q += ` AND color_id_i: ${colorCode}`;
    }

    const { response } = await this.$searchAxios.$get('/select', {
      cancelToken,
      query: {
        wt: 'json',
        rows: '1',
        q,
        fl: 'iid,producing_area_sm',
        fq: `producing_area_sm:"${producingArea}"`,
      },
    });

    const iid = response?.docs?.[0]?.iid;
    const matches = /^vintage_(\d+)$/.exec(iid);

    return matches?.[1];
  },
};

export type Store = DefineStoreModule<'modules/vintageChart', never, never, never, typeof actions>;
