export enum SeatsLeft {
  MANY = 'MANY',
  FEW = 'FEW',
  NONE = 'NONE',
  LIMITED = 'LIMITED',
  WAITING_LIST = 'WAITING_LIST',
}

// TODO:urlクエリの内容確認
export enum EventURLQueries {
  DATE = 'event_date',
  AREA = 'event_area',
  SHOP = 'shop',
  AVAILABLE = 'availableOnly',
  WINE_TYPE = 'event_wine-type',
  CATEGORY = 'event_category',
  KEYWORD = 's',
  TAG = 'event_tags',
  EVENT_ID = 'event_id',
}

export enum EventCategoryKeys {
  EVENT = 'EVENT',
  TASTING = 'TASTING',
  DINNER_LUNCH = 'DINNER_LUNCH',
  SEMINAR = 'SEMINAR',
  OTHER = 'OTHER',
}

export enum EventStatus {
  CANCELED = 'CANCELED',
  NORMAL = 'NORMAL',
}

export enum EventAnswerType {
  RADIO = 'RADIO',
  CHECK = 'CHECK',
  TEXT = 'TEXT',
}

export enum EventPaymentType {
  CREDIT = 'CREDIT',
  SHOP = 'SHOP',
}

export enum EventParticipateStatus {
  INTENT = 'INTEND',
  CANCELED = 'CANCELED',
  ATTEND = 'ATTEND',
  ABSENT = 'ABSENT',
}

export enum EventType {
  FutureEvents,
  PastEvents,
}
