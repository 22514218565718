export enum ShippingType {
  CELLAR = 1,
  SHIP = 2,
}

export enum PaymentSplitNum {
  LUMP_SUM = 0,
  TWO_INSTALLMENTS = 2,
  THREE_INSTALLMENTS = 3,
  FIVE_INSTALLMENTS = 5,
  SIX_INSTALLMENTS = 6,
  TEN_INSTALLMENTS = 10,
  TWELVE_INSTALLMENTS = 12,
  FIFTEEN_INSTALLMENTS = 15,
  EIGHTEEN_INSTALLMENTS = 18,
  TWENTY_INSTALLMENTS = 20,
  TWENTY_FOUR_INSTALLMENTS = 24,
  BONUS = 51,
}

export enum ReceiptType {
  NONE = 'none',
  WEB = 'web',
  SAME_BOX = 'same_box',
}

export enum OrderErrorCodes {
  /** クーポンの有効期限切れ */
  PROMOTION_EXPIRED = 'promotion_expired',
  /** 存在しないクーポン */
  PROMOTION_NOT_FOUND = 'promotion_not_found',
  /** 利用回数制限を超えたクーポン */
  PROMOTION_LIMIT_REACHED = 'promotion_limit_reached',
  /** 選択できる配送方法が存在しない */
  SHIPPING_METHOD_NOT_EXISTS = 'shipment_not_exists_selectable_shipping_method_in_area',
  /** 楽天ペイでリダイレクトURLが伴うエラー */
  PAYMENT_FAILURE_WITH_REDIRECT = 'ec_order_payment_failure_with_redirect',
  /** カートに販売期間外の商品が含まれている */
  PRODUCT_VARIANT_IS_NOT_SALE = 'product_variant_is_not_sale',
  /** ポイント全額支払いでクラブエノテカVISAカード以外のカードを選択 */
  ORDER_INVALID_CREDIT_TYPE_PAYMENT_METHOD = 'order_invalid_credit_type_payment_method',
  /* 送り主住所が不正 */
  ORDER_SENDER_ADDRESS_IS_INVALID = 'order_sender_address_is_invalid',
  /* お届け先住所が不正 */
  SHIPMENT_ADDRESS_IS_INVALID = 'shipment_address_is_invalid',
  /* 購入者情報が不正 */
  ORDER_ORDERER_ADDRESS_IS_INVALID = 'order_orderer_address_is_invalid',
  /** 購入者住所が不正 */
  ORDER_ADDRESS_IS_INVALID = 'order_address_is_invalid',
  /** このクーポン適用できない。条件確認してください */
  PROMOTION_INVALID = 'promotion_invalid',
  /** 出荷日固定商品が含まれており、他の商品と一緒に出荷できない */
  SHIPMENT_MULTIPLE_FIXED_SHIPPING_DATE = 'shipment_multiple_fixed_shipping_date',
  /** 冊子を3冊以上選択 */
  OVER_LIMITED_COUNT_OF_LEAFLET = 'leaflet_quantity_is_the_maximum_quantity_you_can_select',
  /** 選択したリーフレットの情報が不正 */
  ORDER_LEAFLET_IS_INVALID = 'leaflet_not_found',
  /** 非表示商品が含まれている */
  ORDER_CART_PRODUCT_NOT_TO_DISPLAY = 'order_cart_product_not_to_display',
  /** 抽選商品が購入期間外 */
  RAFFLE_NOT_PURCHASE_PERIOD = 'raffle_not_purchase_period',
  /** 商品の販売ステータスが「販売可」ではない */
  PRODUCT_VARIANT_IS_NOT_AVAILABLE_SALE = 'product_variant_is_not_available_sale',
}

export enum FreeShippingType {
  TOTAL = 'free_shipping_total',
  EXCEPT_COOL = 'free_shipping_except_cool',
  EXCEPT_COOL_PARTIAL = 'free_shipping_except_cool_partial',
}
