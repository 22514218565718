/**
 * コンポーネントごとにユニークなIDを取得するコンポジション
 */
export const useComponentId = (seed = Math.random()) => {
  const baseUniqId = seed.toString(36).replace('0.', '');

  const getComponentId = (id: string | number | null | undefined) => {
    return id == null ? baseUniqId : `${baseUniqId}__${id}`;
  };

  return {
    baseUniqId,
    getComponentId,
  };
};
