import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import { CancelToken } from 'axios';
import { orderBy, uniqBy } from 'lodash-es';
import Vue from 'vue';
import {
  MEDIA_ORDERS,
  PRODUCT_CELLAR_SIZE_LABELS,
  PRODUCT_PRIMEUR_SIZE_LABELS,
  PRODUCT_REVIEW_POINT_LABELS,
  PRODUCT_SET_SIZE_LABELS,
  PRODUCT_SIZE_LABELS,
  SEARCH_API_FACET_SETTING,
  SWEETNESS_OPTIONS,
} from '~/constants';
import { nonNullable } from '~/utils';
import { BaseAxiosAction } from '~/types/api';
import { AreaOption, TagOption } from '~/types/common';
import { MasterItem } from '~/types/common/master';
import { SearchAxiosInstance } from '~/plugins/api-axios/globals';
import { createPostRequestBody } from '../modules/product';

/** State */
export interface State {
  colors: MasterItem[];
  areas: AreaOption[];
  areaList: MasterItem[];
  primeurAreas: MasterItem[];
  producers: MasterItem[];
  sweetnesses: MasterItem[];
  sizes: MasterItem[];
  setSizes: MasterItem[];
  cellarSizes: MasterItem[];
  primeurSizes: MasterItem[];
  vintages: MasterItem[];
  classes: MasterItem[];
  reviewPoint: MasterItem[];
  categories: MasterItem[];
  varieties: MasterItem[];
  aoc: MasterItem[];
  aocList: MasterItem[];
  styles: { id: string; label: string }[];
  tags: TagOption[];
  mediaRatings: MasterItem[];
  hasFreeShipping: boolean;
  hasNew: boolean;
  hasDiscount: boolean;
}

export const state = (): State => ({
  colors: [],
  areas: [],
  areaList: [],
  primeurAreas: [],
  producers: [],
  sweetnesses: [],
  sizes: [],
  setSizes: [],
  cellarSizes: [],
  primeurSizes: [],
  vintages: [],
  classes: [],
  reviewPoint: [],
  categories: [],
  varieties: [],
  aoc: [],
  aocList: [],
  styles: [],
  tags: [],
  mediaRatings: [],
  hasFreeShipping: false,
  hasNew: false,
  hasDiscount: false,
});

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setFieldValue<Field extends keyof State>(state: State, payload: { field: Field; value: State[Field] }): void {
    Vue.typedSet(state, payload.field, payload.value);
  },
  setStyles(state: State, payload: { styles: MasterItem[] }): void {
    state.styles = payload.styles;
  },
};

// キーワードが含まれているか
const isIncludesKeyword = (query?: string) => {
  if (!query) return false;

  return query.includes('_ht');
};

const fetchData = async (query: any, searchAxios: SearchAxiosInstance, cancelToken?: CancelToken | undefined) => {
  if (isIncludesKeyword(query.q)) {
    return await searchAxios.$post(
      '/select',
      {
        body: createPostRequestBody(query),
        cancelToken,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
  } else {
    // それ以外は[GET]
    return await searchAxios.$get('/select', {
      query,
      cancelToken,
    });
  }
};

/** Actions */
export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;
export const actions = {
  /** 色（タイプ） */
  async fetchColorOptions(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ query?: string; returnValue?: boolean }>
  ) {
    const { cancelToken, query, returnValue } = payload;

    const queries = {
      wt: 'json',
      q: query,
      rows: -1,
      group: 'on',
      'group.field': 'color_id_i',
      fl: 'color_id_i color_group_s',
      sort: 'color_id_i asc',
    };

    const {
      grouped: {
        color_id_i: { groups },
      },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = groups
      .filter(({ groupValue }: any) => groupValue)
      .map(({ doclist }: any) => {
        const data = doclist.docs[0];
        return {
          id: `${data.color_id_i}`,
          label: data.color_group_s,
        };
      })
      .filter(nonNullable);

    return returnValue ? options : commit('setFieldValue', { field: 'colors', value: options });
  },

  /** ヴィンテージ */
  async fetchVintageOptions(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ query?: string; returnValue?: boolean }>
  ) {
    const { cancelToken, query, returnValue } = payload;

    const queries = {
      wt: 'json',
      q: query,
      'facet.field': 'vintage_code_list_sm',
      facet: SEARCH_API_FACET_SETTING.FACET,
      'facet.mincount': SEARCH_API_FACET_SETTING.MINCOUNT,
      rows: SEARCH_API_FACET_SETTING.ROWS,
    };

    const {
      facet_counts: {
        facet_fields: { vintage_code_list_sm },
      },
    } = await fetchData(queries, this.$searchAxios, cancelToken);
    const options = vintage_code_list_sm
      .filter((item: string, index: number) => index % 2 === 0)
      .map((vintage: string) => {
        return { id: vintage, label: `${vintage}年` };
      });

    return returnValue
      ? options
      : commit('setFieldValue', { field: 'vintages', value: orderBy(options, 'id', 'desc') });
  },

  /** 甘口 */
  async fetchSweetnessOptions(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ query?: string; returnValue?: boolean }>
  ) {
    const { cancelToken, query, returnValue } = payload;

    const queries = {
      wt: 'json',
      q: query,
      'facet.field': 'sweetness_s',
      facet: SEARCH_API_FACET_SETTING.FACET,
      'facet.mincount': SEARCH_API_FACET_SETTING.MINCOUNT,
      rows: SEARCH_API_FACET_SETTING.ROWS,
    };

    const {
      facet_counts: {
        facet_fields: { sweetness_s },
      },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const sweetnessId = (label: string) => {
      switch (label) {
        case SWEETNESS_OPTIONS.LITTLE_SWEET.label:
          return SWEETNESS_OPTIONS.LITTLE_SWEET.id;
        case SWEETNESS_OPTIONS.SWEET.label:
          return SWEETNESS_OPTIONS.SWEET.id;
        case SWEETNESS_OPTIONS.VERY_SWEET.label:
          return SWEETNESS_OPTIONS.VERY_SWEET.id;
      }
    };

    const options = sweetness_s
      .filter((item: string, index: number) => index % 2 === 0)
      .map((sweetness: string) => {
        return { id: sweetnessId(sweetness), label: sweetness };
      });

    return returnValue ? options : commit('setFieldValue', { field: 'sweetnesses', value: orderBy(options, 'id') });
  },

  /** 条件（新着、割引、送料無料） */
  async fetchConditionOptions(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ query?: string }>) {
    const { cancelToken, query } = payload;

    const queries = {
      wt: 'json',
      q: query,
      facet: SEARCH_API_FACET_SETTING.FACET,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.query': [
        'new_arrival_flag_i:[1 TO 1]',
        'shipping_fee_discount_flag_i:[1 TO 1]',
        'sale_discount_rate_i:[1 TO *]',
      ],
    };

    const {
      facet_counts: { facet_queries },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    commit('setFieldValue', { field: 'hasNew', value: !!facet_queries['new_arrival_flag_i:[1 TO 1]'] });
    commit('setFieldValue', {
      field: 'hasFreeShipping',
      value: !!facet_queries['shipping_fee_discount_flag_i:[1 TO 1]'],
    });
    commit('setFieldValue', { field: 'hasDiscount', value: !!facet_queries['sale_discount_rate_i:[1 TO *]'] });
  },

  /** クチコミ評価 */
  async fetchReviewOptions(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ query?: string }>) {
    const { cancelToken, query } = payload;

    const queries = {
      wt: 'json',
      q: query,
      facet: SEARCH_API_FACET_SETTING.FACET,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.query': ['sort_r_rating_r:[5 TO 5]', 'sort_r_rating_r:[4 TO 5]', 'sort_r_rating_r:[3 TO 5]'],
    };

    const {
      facet_counts: { facet_queries },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = [
      facet_queries['sort_r_rating_r:[5 TO 5]'] ? PRODUCT_REVIEW_POINT_LABELS.FIVE : null,
      facet_queries['sort_r_rating_r:[4 TO 5]'] ? PRODUCT_REVIEW_POINT_LABELS.FOUR : null,
      facet_queries['sort_r_rating_r:[3 TO 5]'] ? PRODUCT_REVIEW_POINT_LABELS.THREE : null,
    ].filter(nonNullable);

    commit('setFieldValue', { field: 'reviewPoint', value: options });
  },

  /** サイズ */
  async fetchSizeOptions(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ query?: string }>) {
    const { cancelToken, query } = payload;

    const queries = {
      wt: 'json',
      q: query,
      facet: SEARCH_API_FACET_SETTING.FACET,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.query': ['size_amount_i:[1 TO 699]', 'size_amount_i:[700 TO 1499]', 'size_amount_i:[1500 TO *]'],
    };

    const {
      facet_counts: { facet_queries },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = [
      facet_queries['size_amount_i:[1 TO 699]'] ? PRODUCT_SIZE_LABELS.SMALL : null,
      facet_queries['size_amount_i:[700 TO 1499]'] ? PRODUCT_SIZE_LABELS.MEDIUM : null,
      facet_queries['size_amount_i:[1500 TO *]'] ? PRODUCT_SIZE_LABELS.LARGE : null,
    ].filter(nonNullable);

    commit('setFieldValue', { field: 'sizes', value: options });
  },

  /** セラー収納本数 */
  async fetchCellarSizeOptions(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ query?: string }>) {
    const { cancelToken, query } = payload;

    const queries = {
      wt: 'json',
      q: query,
      facet: SEARCH_API_FACET_SETTING.FACET,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.query': [
        'size_amount_i:[17 TO 33]',
        'size_amount_i:[34 TO 54]',
        'size_amount_i:[55 TO 84]',
        'size_amount_i:[85 TO 169]',
      ],
    };

    const {
      facet_counts: { facet_queries },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = [
      facet_queries['size_amount_i:[17 TO 33]'] ? PRODUCT_CELLAR_SIZE_LABELS.SMALL : null,
      facet_queries['size_amount_i:[34 TO 54]'] ? PRODUCT_CELLAR_SIZE_LABELS.MEDIUM : null,
      facet_queries['size_amount_i:[55 TO 84]'] ? PRODUCT_CELLAR_SIZE_LABELS.LARGE : null,
      facet_queries['size_amount_i:[85 TO 169]'] ? PRODUCT_CELLAR_SIZE_LABELS.MAX : null,
    ].filter(nonNullable);

    commit('setFieldValue', { field: 'cellarSizes', value: options });
  },

  /** プリムール本数・サイズ */
  async fetchPrimeurSizeOptions(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ query?: string }>) {
    const { cancelToken, query } = payload;

    const queries = {
      wt: 'json',
      q: query,
      facet: SEARCH_API_FACET_SETTING.FACET,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.query': [
        'size_amount_i:750',
        'size_amount_i:2250',
        'size_amount_i:4500',
        'size_amount_i:9000',
        'size_amount_i:1500',
        'size_amount_i:3000',
      ],
    };

    const {
      facet_counts: { facet_queries },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = [
      facet_queries['size_amount_i:750'] ? PRODUCT_PRIMEUR_SIZE_LABELS.ONE : null,
      facet_queries['size_amount_i:2250'] ? PRODUCT_PRIMEUR_SIZE_LABELS.THREE : null,
      facet_queries['size_amount_i:4500'] ? PRODUCT_PRIMEUR_SIZE_LABELS.SIX : null,
      facet_queries['size_amount_i:9000'] ? PRODUCT_PRIMEUR_SIZE_LABELS.TWELVE : null,
      facet_queries['size_amount_i:1500'] ? PRODUCT_PRIMEUR_SIZE_LABELS.MAGNUM : null,
      facet_queries['size_amount_i:3000'] ? PRODUCT_PRIMEUR_SIZE_LABELS.DOUBLE_MAGNUM : null,
    ].filter(nonNullable);

    commit('setFieldValue', { field: 'primeurSizes', value: options });
  },

  /** セット本数 */
  async fetchSetSizeOptions(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ query?: string }>) {
    const { cancelToken, query } = payload;

    const queries = {
      wt: 'json',
      q: query,
      facet: SEARCH_API_FACET_SETTING.FACET,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.query': [
        'lot_calculation_quantity_i:[* TO 3]',
        'lot_calculation_quantity_i:[4 TO 6]',
        'lot_calculation_quantity_i:[7 TO *]',
      ],
    };

    const {
      facet_counts: { facet_queries },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = [
      facet_queries['lot_calculation_quantity_i:[* TO 3]'] ? PRODUCT_SET_SIZE_LABELS.SMALL : null,
      facet_queries['lot_calculation_quantity_i:[4 TO 6]'] ? PRODUCT_SET_SIZE_LABELS.MEDIUM : null,
      facet_queries['lot_calculation_quantity_i:[7 TO *]'] ? PRODUCT_SET_SIZE_LABELS.LARGE : null,
    ].filter(nonNullable);

    commit('setFieldValue', { field: 'setSizes', value: options });
  },

  /** 格付け */
  async fetchClassOptions(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ query?: string }>) {
    const { cancelToken, query } = payload;

    const queries = {
      wt: 'json',
      q: query,
      rows: -1,
      group: 'on',
      'group.field': 'class_id_i',
      fl: 'class_id_i class_s',
      sort: 'class_id_i asc',
    };

    const {
      grouped: {
        class_id_i: { groups },
      },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = groups
      .filter(({ groupValue }: any) => groupValue)
      .map(({ doclist }: any) => {
        const data = doclist.docs[0];
        return {
          id: `${data.class_id_i}`,
          label: data.class_s,
        };
      })
      .filter(nonNullable);

    commit('setFieldValue', { field: 'classes', value: options });
  },

  /** 生産者 */
  async fetchProducerOptions(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ query?: string }>) {
    const { cancelToken, query } = payload;

    const queries = {
      wt: 'json',
      q: query,
      rows: -1,
      group: 'on',
      'group.field': 'producer_id_i',
      fl: 'producer_id_i producer_s producer_name_english_s',
      sort: 'producer_s asc',
    };

    const {
      grouped: {
        producer_id_i: { groups },
      },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = groups
      .filter(({ groupValue }: any) => groupValue)
      .map(({ doclist }: any) => {
        const data = doclist.docs[0];
        return {
          id: `${data.producer_id_i}`,
          label: data.producer_s,
          label_en: data.producer_name_english_s,
        };
      })
      .filter(nonNullable);

    commit('setFieldValue', { field: 'producers', value: options });
  },

  /** 生産地 */
  async fetchAreaOptions(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ query?: string; returnValue?: boolean }>
  ) {
    const { cancelToken, query, returnValue } = payload;

    const queries = {
      wt: 'json',
      q: query,
      facet: SEARCH_API_FACET_SETTING.FACET,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.pivot': 'area1_id_name_s,area2_id_name_s,area3_id_name_s,area4_id_name_s',
      'facet.sort': 'index',
    };

    const {
      facet_counts: { facet_pivot },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const list: MasterItem[] = [];
    const options = facet_pivot['area1_id_name_s,area2_id_name_s,area3_id_name_s,area4_id_name_s'].map(
      ({ value, pivot }: any) => {
        const area2 = () => {
          if (!pivot) return [];

          return pivot.map(({ value: value2, pivot: pivot2 }: any) => {
            const area2 = value2.split('_');
            const item = { id: area2[1], label: area2[2] };
            list.push(item);

            const area3 = () => {
              if (!pivot2) return [];
              return pivot2.map(({ value: value3, pivot: pivot3 }: any) => {
                const area3 = value3.split('_');
                const item = { id: area3[1], label: area3[2] };
                list.push(item);

                const area4 = () => {
                  if (!pivot3) return [];
                  return pivot3.map(({ value: value3 }: any) => {
                    const area4 = value3.split('_');
                    const item = { id: area4[1], label: area4[2] };
                    list.push(item);

                    return {
                      id: area4[1],
                      label: area4[2],
                    };
                  });
                };

                return {
                  id: area3[1],
                  label: area3[2],
                  area4: area4(),
                };
              });
            };

            return {
              id: area2[1],
              label: area2[2],
              area3: area3(),
            };
          });
        };

        const area1 = value.split('_');
        const item = { id: area1[1], label: area1[2] };
        list.push(item);

        return {
          id: area1[1],
          label: area1[2],
          area2: area2(),
        };
      }
    );
    return returnValue
      ? list
      : // 生産地（3階層）
        (commit('setFieldValue', { field: 'areas', value: options }),
        // 生産地（階層なし）
        commit('setFieldValue', { field: 'areaList', value: list }));
  },

  /** プリムール生産地（生産地3のみ） */
  async fetchPrimeurAreaOptions(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ query?: string; returnValue?: boolean }>
  ) {
    const { cancelToken, query, returnValue } = payload;

    const queries = {
      wt: 'json',
      q: query,
      facet: SEARCH_API_FACET_SETTING.FACET,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.field': 'area3_id_name_s',
      'facet.mincount': SEARCH_API_FACET_SETTING.MINCOUNT,
      'facet.sort': 'index',
    };

    const {
      facet_counts: {
        facet_fields: { area3_id_name_s },
      },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = area3_id_name_s
      .filter((item: string, index: number) => index % 2 === 0)
      .map((variety: string) => {
        const data = variety.split('_');
        return {
          id: data[1],
          label: data[2],
        };
      });

    return returnValue ? options : commit('setFieldValue', { field: 'primeurAreas', value: options });
  },

  /** 品種 */
  async fetchVarietyOptions(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ query?: string; returnValue?: boolean }>
  ) {
    const { cancelToken, query, returnValue } = payload;

    const queries = {
      wt: 'json',
      q: query,
      facet: SEARCH_API_FACET_SETTING.FACET,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.field': 'facet_brand_id_text_sm',
      'facet.mincount': SEARCH_API_FACET_SETTING.MINCOUNT,
      'facet.sort': 'count',
    };

    const {
      facet_counts: {
        facet_fields: { facet_brand_id_text_sm },
      },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = facet_brand_id_text_sm
      .filter((item: string, index: number) => index % 2 === 0)
      .map((variety: string) => {
        const data = variety.split('_');
        return {
          id: data[1],
          label: data[2],
          label_en: data[0],
        };
      });

    return returnValue ? options : commit('setFieldValue', { field: 'varieties', value: options });
  },

  /** シーン・キーワード(タグ) */
  async fetchTagOptions(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ query?: string; returnValue?: boolean }>
  ) {
    const { cancelToken, query, returnValue } = payload;

    const queries = {
      wt: 'json',
      q: query,
      facet: SEARCH_API_FACET_SETTING.FACET,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.field': 'tag2_sm',
      'facet.mincount': SEARCH_API_FACET_SETTING.MINCOUNT,
      'facet.sort': 'index',
    };

    const {
      facet_counts: {
        facet_fields: { tag2_sm },
      },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = tag2_sm
      .filter((item: string, index: number) => index % 2 === 0)
      .map((tag: string) => {
        const data = tag.split('_');
        return {
          id: data[0],
          label: data[1],
          category: data[2],
          displayFlag: data[3],
        };
      });

    return returnValue ? options : commit('setFieldValue', { field: 'tags', value: options });
  },

  /** 原産地呼称 */
  async fetchAocOptions(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ aocId: string | null; returnValue?: boolean }>
  ) {
    const { cancelToken, aocId, returnValue } = payload;

    if (!aocId) return;

    const { id, name_kana } = await this.$ecAxios.$get('/api/v1/native_areas/{id}', {
      path: { id: +aocId },
      cancelToken,
    });

    const options: MasterItem[] = [
      {
        id: id.toString(),
        label: name_kana,
      },
    ];

    return returnValue ? options : commit('setFieldValue', { field: 'aoc', value: options });
  },

  /** 原産地呼称一覧(サーチ経由) */
  async fetchAocSearchOptions(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ query?: string; returnValue?: boolean }>
  ) {
    const { cancelToken, query, returnValue } = payload;

    const queries = {
      wt: 'json',
      q: query,
      'facet.field': 'native_area_id_name_s',
      facet: SEARCH_API_FACET_SETTING.FACET,
      'facet.mincount': SEARCH_API_FACET_SETTING.MINCOUNT,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.sort': 'index',
    };

    const {
      facet_counts: {
        facet_fields: { native_area_id_name_s },
      },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = native_area_id_name_s
      .filter((item: string, index: number) => index % 2 === 0)
      .map((aoc: string) => {
        const data = aoc.split('_');
        return {
          id: data[0],
          label: data[1],
        };
      });

    return returnValue ? options : commit('setFieldValue', { field: 'aocList', value: options });
  },

  /** カテゴリ */
  async fetchCategoryOptions(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ query?: string; returnValue?: boolean }>
  ) {
    const { cancelToken, query, returnValue } = payload;

    const queries = {
      wt: 'json',
      q: query,
      'facet.field': 'category_id_name_s',
      facet: SEARCH_API_FACET_SETTING.FACET,
      'facet.mincount': SEARCH_API_FACET_SETTING.MINCOUNT,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.sort': 'index',
    };

    const {
      facet_counts: {
        facet_fields: { category_id_name_s },
      },
    } = await fetchData(queries, this.$searchAxios, cancelToken);

    const options = category_id_name_s
      .filter((item: string, index: number) => index % 2 === 0)
      .map((category: string) => {
        const data = category.split('_');
        return { id: data[1], label: data[2] };
      });

    return returnValue ? options : commit('setFieldValue', { field: 'categories', value: options });
  },

  /** 評価紙 */
  async fetchMediaOptions(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ query?: string; returnValue?: boolean }>
  ) {
    const { cancelToken, query, returnValue } = payload;
    const queries = {
      wt: 'json',
      q: query,
      'facet.field': 'high_score_vintage_media_sm',
      facet: SEARCH_API_FACET_SETTING.FACET,
      'facet.mincount': SEARCH_API_FACET_SETTING.MINCOUNT,
      rows: SEARCH_API_FACET_SETTING.ROWS,
      'facet.sort': 'index',
    };
    // クエリからvintage_code_list_smを抽出する
    const vintageQuery = query?.split('vintage_code_list_sm:') ?? [];
    const vintageCodeList =
      vintageQuery?.length > 1 ? vintageQuery[1].split(' AND')[0].replaceAll(/\(|\)/g, '').split(' OR ') : [];
    const {
      facet_counts: {
        facet_fields: { high_score_vintage_media_sm },
      },
    } = await fetchData(queries, this.$searchAxios, cancelToken);
    const options: MasterItem[] = high_score_vintage_media_sm
      .filter((item: string, index: number) => index % 2 === 0)
      .reduce((acc: MasterItem[], vintageMedia: string) => {
        const data = vintageMedia.split('_');
        // vintageで絞り込みをかけている場合、同一vintageの評価紙のみをoptionsに加える
        if (!vintageCodeList.length || vintageCodeList.includes(data[0])) {
          acc.push({ id: `${data[1]}`, label: `${data[1]} 90点以上` });
        }
        return acc;
      }, []);
    // ヒットしたvintageの数だけ重複するので、idでuniqByをかける
    const uniqMediaOptions = uniqBy(options, 'id');
    // MEDIA_ORDERSの順にoptionsをソートする
    const sortMediaOptions = uniqMediaOptions.sort((a, b) => {
      const aIndex = MEDIA_ORDERS.indexOf(a.id);
      const bIndex = MEDIA_ORDERS.indexOf(b.id);
      if (aIndex < bIndex) return -1;
      if (aIndex > bIndex) return 1;
      return 0;
    });
    return returnValue ? sortMediaOptions : commit('setFieldValue', { field: 'mediaRatings', value: sortMediaOptions });
  },
};

/** Store Module Type */
export type Store = DefineStoreModule<'product/option', State, typeof getters, typeof mutations, typeof actions>;
