import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { RootStore } from '~/composables/useStore';

export default defineNuxtPlugin(({ ...context }) => {
  const store = context.store as RootStore;

  // TreasureData用関数
  window.getClubEnotecaId = () => {
    return store.state.user.info.userBase?.cardNo || '';
  };
});
