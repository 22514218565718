import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';

export type State = {
  salesLocation: string;
};

export const state = (): State => ({
  salesLocation: '',
});

/** Getters */
export const getters = {};

export const mutations = {
  setSalesLocation(state: State, salesLocation: string) {
    state.salesLocation = salesLocation;
  },
};

/** Actions */
export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;
export const actions = {};

export type Store = DefineStoreModule<'modules/mypage/myshop', State, typeof getters, typeof mutations, typeof actions>;
