export const AUTH_ERROR = {
  DUPLICATED: 'duplicated',
  ERROR: 'error',
};

export const AUTH_ERROR_MESSAGE = {
  DUPLICATED: '既にエノテカオンラインに登録されているアカウントです。',
  ERROR: 'エラーが発生しました。再度お試しください。',
};

export const SOCIAL_PLUS_ERROR_REASON = {
  USER_EXISTED: 'User existed.',
  USER_ASSOCIATED: 'This user has already been associated with this social media.',
  SOCIAL_MEDIA_ASSOCIATED: 'This social media has already been associated with another user.',
};

export const DCON_COOKIE_KEY = {
  ID: 'dcon_id',
  UID: 'dcon_uid',
  BARCODE_ID: 'dcon_barcode_id',
  MEMBER_ID: 'dcon_member_id',
};

export const SOCIAL_PLUS_KEY = {
  ONE_TIME_TOKEN: 'one_time_token',
};

export const DCON_AUTHENTICATED_KEY = 'dcon_authenticated';

export const CAMPAIGN_ARTICLE = 'article';
