import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ConsolsActionPayload } from '~/types/api';

type State = {
  loginId?: string;
};

export const state = (): State => ({});

export const mutations = {
  setLoginId(state: State, loginId: string) {
    state.loginId = loginId;
  },
  resetLoginId(state: State) {
    state.loginId = undefined;
  },
};

export type Ctx = DefineActionContext<State, never, typeof mutations>;

export const actions = {
  async postPasswordReset(
    this: Vue,
    context: Ctx,
    payload: ConsolsActionPayload<'/api/v1/user/password/reset', 'post'>
  ) {
    await this.$consolsAxios.$post('/api/v1/user/password/reset', payload);
  },
  async postPasswordCheck(
    this: Vue,
    context: Ctx,
    payload: ConsolsActionPayload<'/api/v1/user/password/check', 'post'>
  ) {
    await this.$consolsAxios.$post('/api/v1/user/password/check', payload);
  },
};

export type Store = DefineStoreModule<'user/password', State, never, typeof mutations, typeof actions>;
