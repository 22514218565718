













import { defineComponent, PropType } from '@nuxtjs/composition-api';
import AppLink from '~/components/partials/App/AppLink.vue';
import AppOptimizedImage from '~/components/partials/App/AppOptimizedImage.vue';
import { TopBanner } from '~/types/common';

export default defineComponent({
  components: {
    AppLink,
    AppOptimizedImage,
  },
  props: {
    item: {
      type: Object as PropType<TopBanner>,
      required: true,
    },
  },
});
