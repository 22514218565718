import { ValidationRule } from 'vee-validate/dist/types/types';

export const isClubEnotecaNoDifferent: ValidationRule = {
  params: ['oldNumber'],
  message: () => {
    return `現在利用中のクラブエノテカ会員番号と同一です`;
  },
  // @ts-expect-error
  validate(value, { oldNumber }) {
    return value !== oldNumber;
  },
};
