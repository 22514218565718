import { exceptionalValidErrors, PAYMENT_METHOD_ID, WINE_CELLAR_DESCRIPTION_DEFAULT } from '~/constants';
import { OrderRegister, UserOptionType, OrderSimulations } from '~/types/modules/order';
import { CartType } from '~/enums';

export const validateOrder = ({
  option,
  isCellarMember,
  authenticated,
  register,
  simulatedData,
}: {
  option: UserOptionType;
  isCellarMember: boolean;
  authenticated: boolean;
  register: OrderRegister | null;
  simulatedData?: OrderSimulations | null;
}): boolean => {
  const { payment } = option;

  if (!authenticated && !register) {
    return false;
  }

  /* Paymentに関するバリデーション */
  if (!payment.paymentMethodId) {
    /* 支払い方法が選択されていない場合 */
    return false;
  }

  /* 搬送情報に関するバリデーション */
  if (
    option.cartName === CartType.WINE_CELLAR &&
    (!option.shipments[0].wineCellarInfo || option.shipments[0].wineCellarInfo === WINE_CELLAR_DESCRIPTION_DEFAULT)
  ) {
    /* カート種別がワインセラーでセラー搬送情報が入力されていない場合 */
    return false;
  }

  /* プリムール利用規約同意確認 */
  if (option.cartName === CartType.PRIMEUR && !option.primeurAgreement) {
    return false;
  }

  /* セラー会員登録バリデーション */
  if ((option.shipments[0].toRentalCellar || option.cartName === CartType.RENTAL_CELLAR_PACK) && !isCellarMember) {
    return false;
  }

  /* 頒布会利用規約同意確認 */
  if (option.cartName === CartType.DISTRIBUTION && !option.distributionAgreement) {
    return false;
  }

  /* Amazon選択住所が不正 */
  if (payment.paymentMethodId === PAYMENT_METHOD_ID.AMAZON_PAY && !option.shipments[0].address) {
    return false;
  }

  /* ギフトカード紐付けが不正 */
  if (payment.paymentMethodId === PAYMENT_METHOD_ID.GIFT && (!simulatedData || !simulatedData.value_card_no)) {
    return false;
  }

  // シミュレーションエラーコードレスポンス
  if (simulatedData?.error_codes.length) {
    /** 注文が通せる例外的なエラーの条件分岐 */
    if (!(simulatedData.error_codes.length === 1 && exceptionalValidErrors.includes(simulatedData.error_codes[0]))) {
      return false;
    }
  }

  return true;
};
