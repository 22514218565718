import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ConsolsActionPayload } from '~/types/api';

export type Ctx = DefineActionContext<never, never, never>;
export const actions = {
  async postUserBase(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/user', 'post'>) {
    return await this.$consolsAxios.$post('/api/v1/user', payload);
  },
  async putUserBase(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/user', 'put'>) {
    return await this.$consolsAxios.$put('/api/v1/user', payload);
  },
  async getUserInfo(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/user/info', 'get'>) {
    return await this.$consolsAxios.$get('/api/v1/user/info', payload);
  },
  async getUserBase(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/user', 'get'>) {
    const response = await this.$consolsAxios.$get('/api/v1/user', payload);
    return response.userBase;
  },
};

export type Store = DefineStoreModule<'user/base', never, never, never, typeof actions>;
