import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ConsolsActionPayload, ConsolsResponse } from '~/types/api';
import { UserCertification } from '~/types/common/user';

export type State = {
  authenticated: boolean;
  tokenExpired: boolean;
  socialPlusAuthToken: string;
  userCertification: UserCertification;
};

export const state = (): State => ({
  authenticated: false,
  tokenExpired: false,
  socialPlusAuthToken: '',
  userCertification: {
    user: {
      id_token: '',
      login_token: '',
      uuid: '',
      auth_token: '',
    },
  },
});

export const mutations = {
  setAuthenticated(state: State, authenticated: boolean) {
    state.authenticated = authenticated;
  },
  setTokenExpired(state: State, tokenExpired: boolean) {
    state.tokenExpired = tokenExpired;
  },
  setSocialPlusAuthToken(state: State, token: string) {
    state.socialPlusAuthToken = token;
  },
  setUserCertification(state: State, payload: UserCertification) {
    state.userCertification = payload;
  },
};

export type Ctx = DefineActionContext<State, never, typeof mutations>;

export const actions = {
  async getSocialLogin(
    this: Vue,
    context: Ctx,
    payload: ConsolsActionPayload<'/api/v2/user/social/login/{identifier}', 'get'>
  ): Promise<ConsolsResponse<'/api/v2/user/social/login/{identifier}', 'get'>> {
    return await this.$consolsAxios.$get('/api/v2/user/social/login/{identifier}', payload);
  },
  async getSocialMerge(
    this: Vue,
    context: Ctx,
    payload: ConsolsActionPayload<'/api/v2/user/social/merge/{identifier}', 'get'>
  ): Promise<ConsolsResponse<'/api/v2/user/social/merge/{identifier}', 'get'>> {
    return await this.$consolsAxios.$get('/api/v2/user/social/merge/{identifier}', payload);
  },
};

export type Store = DefineStoreModule<'auth', State, never, typeof mutations, typeof actions>;
