import dayjs from 'dayjs';

/** 生年月日から年齢を算出する */
export const getAgeFromBirthday = (birthday: dayjs.Dayjs) => {
  if (!birthday.isValid()) {
    return;
  }

  const today = dayjs();
  return Math.floor(today.diff(birthday, 'month') / 12);
};
