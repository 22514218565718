import url from 'url';
import urlJoin from 'url-join';
import { RawLocation } from 'vue-router';

/** URL情報を文字列化する */
export const stringifyLocation = (location: RawLocation, ecOrigin: string, dropEcOrigin = true): string => {
  let href = '';

  if (typeof location === 'string') {
    href = location;
  }

  if (typeof location === 'object') {
    href = url.format({
      pathname: location.path,
      hash: location.hash,
      // @ts-expect-error
      query: location.query,
    });
  }

  if (href.startsWith('/')) {
    href = urlJoin(ecOrigin, href);
  }

  if (dropEcOrigin && href.startsWith(ecOrigin)) {
    return href.slice(ecOrigin.length);
  }

  return href;
};
