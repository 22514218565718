var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('details',{class:{
    'border-t last:border-b': _vm.border || _vm.borderAlways,
    'md:rounded-middle md:border-l md:border-r': _vm.border,
    'rounded-middle border-l border-r': _vm.borderAlways,
    'flex flex-col-reverse': _vm.reverse,
  },attrs:{"open":_vm.isOpen.value}},[_c('summary',{staticClass:"hover-underline",on:{"click":_vm.onSummaryClick}},[_c('span',{staticClass:"flex w-full cursor-pointer",class:[
        _vm.center ? 'justify-center' : 'justify-between',
        _vm.smallPaddingY ? 'py-3.5' : 'py-5',
        {
          'font-semibold': _vm.titleBold,
          'md:px-5': _vm.border,
          'px-5': _vm.borderAlways,
          'h-12 items-center py-0': _vm.titleFixed,
        } ]},[_c('span',{class:_vm.titleSmall ? 'text-base' : 'text-lg'},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]),_vm._v(" "),_c('AppIcon',{staticClass:"g-accordion-arrow ml-4 h-5 w-5 flex-shrink-0",attrs:{"icon":"arrow-down-black"}})],1)]),_vm._v(" "),_c('div',{ref:"contentWrapperRef",staticClass:"transform-gpu overflow-hidden transition-height",style:({ height: _vm.isClosing ? '0' : (_vm.contentHeight + "px") })},[_c('div',{ref:"contentRef",staticClass:"overflow-hidden",class:{
        'pb-30px text-Black60': _vm.enableContentStyles,
        'md:px-5': _vm.border,
        'px-5': _vm.borderAlways,
      }},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }