import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ECActionPayload } from '~/types/api';
import { CommentFormPayload, ProductDetails, ProductReviews } from '~/types/modules/comment';

/** State */
export interface State {
  productDetails: ProductDetails;
  commentFormInfo: CommentFormPayload;
  shouldUsePreviousFormInfo: boolean;
  userCommentsSortIndex: number;
  myPageCommentsSortIndex: number;
}

export const state = (): State => ({
  productDetails: {
    isWine: false,
    image: '',
    productName: '',
    wineType: '',
    producerName: '',
    producingArea: {
      code_id: '',
      areas: [],
    },
    priceIncludingTax: '',
    productId: 0,
    reviewGroupId: '',
  },
  commentFormInfo: {
    isWine: false,
    nickname: '',
    vintageCode: '',
    title: '',
    comment: '',
    currentRating: 0,
  },
  shouldUsePreviousFormInfo: false,
  userCommentsSortIndex: 0,
  myPageCommentsSortIndex: 0,
});

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setFieldValue<Field extends keyof State>(state: State, payload: { field: Field; value: State[Field] }): void {
    Vue.typedSet(state, payload.field, payload.value);
  },
  setUserCommentsSortIndex(state: State, payload: State['userCommentsSortIndex']): void {
    Vue.typedSet(state, 'userCommentsSortIndex', payload);
  },
  setMyPageCommentsSortIndex(state: State, payload: State['myPageCommentsSortIndex']): void {
    Vue.typedSet(state, 'myPageCommentsSortIndex', payload);
  },
};

export type Ctx = DefineActionContext<never, never, never>;

export const actions = {
  async fetchCommentsByReviewGroupId(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/reviews/{review_group_id}', 'get'>
  ): Promise<ProductReviews> {
    return await this.$ecAxios.$get('/api/v1/reviews/{review_group_id}', payload);
  },
  async fetchCommentsByUserId(this: Vue, context: Ctx, payload: ECActionPayload<'/api/v1/users/reviews', 'get'>) {
    return await this.$ecAxios.$get('/api/v1/users/reviews', payload);
  },
  async fetchCommentsByCommentId(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/pickup_user/reviews/{review_id}', 'get'>
  ) {
    return await this.$ecAxios.$get('/api/v1/pickup_user/reviews/{review_id}', payload);
  },
  async addCommentByProductId(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/reviews/product_variants', 'post'>
  ) {
    return await this.$ecAxios.$post('/api/v1/reviews/product_variants', payload);
  },
  async fetchLikedComments(this: Vue, context: Ctx, payload: ECActionPayload<'/api/v1/user_liked_reviews', 'get'>) {
    return await this.$ecAxios.$get('/api/v1/user_liked_reviews', payload);
  },
  async addLikeToComment(this: Vue, context: Ctx, payload: ECActionPayload<'/api/v1/reviews/{id}/likes', 'put'>) {
    return await this.$ecAxios.$put('/api/v1/reviews/{id}/likes', payload);
  },
  async removeLikeFromComment(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/reviews/{id}/likes', 'delete'>
  ) {
    return await this.$ecAxios.$delete('/api/v1/reviews/{id}/likes', payload);
  },
};

export type Store = DefineStoreModule<'modules/reviews', State, typeof getters, typeof mutations, typeof actions>;
