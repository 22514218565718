import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import { sortBy } from 'lodash-es';
import Vue from 'vue';
import { APIError } from '~/utils/error';
import { BaseAxiosAction, ECActionPayload } from '~/types/api';
import { CartSchema, CartProductVariant, CartSetProductVariant } from '~/types/modules/cart';
import { OrderSimulationRequest, OrderSimulations } from '~/types/modules/order';
import { FreeAreaData } from '~/types/modules/top';
import { ArticleType } from '~/enums';

export type State = {
  carts: Array<CartSchema>;
  guestToken?: string;
  giftCardBalance?: number;
  isFromCartRecommend: boolean;
  hasClosedModal: boolean;
  allowedPaymentMethodIds: number[];
  isFullSizeDisplay: boolean;
  isCartProcessing: boolean;
};

export const state = (): State => ({
  carts: [],
  guestToken: '',
  giftCardBalance: undefined,
  isFromCartRecommend: false,
  hasClosedModal: false, // カート画面のシルバーエッグ枠用
  allowedPaymentMethodIds: [],
  isFullSizeDisplay: true, // カート0件の時のフルサイズでの表示を行うかどうか
  isCartProcessing: false,
});

export const getters = {
  cart: (state: State) => (type: string) => {
    return state.carts.find((cart) => cart.cart_name === type);
  },
  giftCardBalance: (state: State) => {
    return state.giftCardBalance;
  },
};

export const mutations = {
  setCarts(state: State, carts: Array<CartSchema>) {
    state.carts = carts;
  },
  setGuestToken(state: State, token?: string) {
    state.guestToken = token;
  },
  setGiftCardBalance(state: State, balanceTotal?: number) {
    state.giftCardBalance = balanceTotal;
  },
  setIsFromCartRecommend(state: State, flag: boolean) {
    state.isFromCartRecommend = flag;
  },
  setHasClosedModal(state: State, flag: boolean) {
    state.hasClosedModal = flag;
  },
  setFullSizeDisplayFlag(state: State, flag: boolean) {
    state.isFullSizeDisplay = flag;
  },
  setAllowedPaymentMethodIds(state: State, ids: number[]) {
    state.allowedPaymentMethodIds = ids;
  },
  setIsCartProcessing(state: State, flag: boolean) {
    state.isCartProcessing = flag;
  },
};

export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;

export const actions = {
  async postSimulation(
    this: Vue,
    { state, rootState }: Ctx,
    { cartId, cancelToken }: BaseAxiosAction<{ cartId: number }>
  ): Promise<OrderSimulations> {
    const body: OrderSimulationRequest = {
      cart_id: cartId,
      lang_id: 'ja',
    };

    if (rootState.auth.authenticated) {
      return await this.$ecAxios.$post('/api/v1/order_simulations', {
        body,
        cancelToken,
      });
    } else {
      return await this.$ecAxios.$post('/api/v1/guest_order_simulations', {
        // @ts-expect-error
        query: {
          guest_token: state.guestToken,
        },
        body,
        cancelToken,
      });
    }
  },
  /**
   * カートの取得
   */
  async fetchCarts(this: Vue, { commit, rootState, state }: Ctx, { cancelToken }: BaseAxiosAction) {
    if (rootState.auth.authenticated) {
      const res = await this.$ecAxios.$get('/api/v1/carts', {
        cancelToken,
      });
      commit('setCarts', res.carts || []);
    } else {
      try {
        const res = await this.$ecAxios.$get('/api/v1/guest_carts', {
          // @ts-expect-error
          query: {
            guest_token: state.guestToken,
          },
          cancelToken,
        });

        commit('setCarts', res.carts || []);
      } catch (err) {
        if (APIError.isAPIError(err)) {
          if (err.statusCode === 401) {
            commit('setGuestToken', '');
            commit('setCarts', []);
          }
        }
      }
    }
  },
  /**
   * カートの追加・更新
   */
  async updateCart(
    this: Vue,
    { dispatch, rootState, commit, state }: Ctx,
    {
      singleProducts,
      setProducts,
      cancelToken,
    }: BaseAxiosAction<{
      singleProducts?: Array<CartProductVariant>;
      setProducts?: Array<CartSetProductVariant>;
      fromKarte?: boolean;
    }>
  ): Promise<void> {
    const body = {
      single_products: singleProducts,
      set_products: setProducts,
    };
    if (rootState.auth.authenticated) {
      await this.$ecAxios.$put('/api/v1/carts', {
        body,
        cancelToken,
      });
      await dispatch('modules/order/resetUserOption', {}, { root: true });
    } else {
      try {
        const { guest_token } = await this.$ecAxios.$put('/api/v1/guest_carts', {
          // @ts-expect-error
          query: {
            guest_token: state.guestToken,
          },
          body,
          cancelToken,
        });
        if (!state.guestToken) {
          commit('setGuestToken', guest_token);
        }
      } catch (err) {
        if (APIError.isAPIError(err)) {
          if (err.statusCode === 401) {
            commit('setGuestToken', '');
          }
        }
        // throwしないとエラー処理済みと認識されvue側ではそもそもcatchできなくなる
        throw err;
      }
    }
    await dispatch('fetchCarts', {});
  },
  /**
   * カートから商品を削除
   */
  async deleteCartItem(
    this: Vue,
    { rootState, dispatch, state }: Ctx,
    { id, cancelToken }: BaseAxiosAction<{ id: number }>
  ) {
    if (rootState.auth.authenticated) {
      await this.$ecAxios.$delete('/api/v1/cart_items/{id}', {
        path: { id },
        cancelToken,
      });
    } else {
      await this.$ecAxios.$delete('/api/v1/guest_cart_items/{id}', {
        // @ts-expect-error
        query: {
          guest_token: state.guestToken,
        },
        path: { id },
        cancelToken,
      });
    }
    await dispatch('fetchCarts', {});
    await dispatch('modules/order/resetUserOption', {}, { root: true });
  },
  /**
   * カートからセット商品を削除
   */
  async deleteCartSetItem(
    this: Vue,
    { rootState, dispatch, state }: Ctx,
    { id, cancelToken }: BaseAxiosAction<{ id: number }>
  ) {
    if (rootState.auth.authenticated) {
      await this.$ecAxios.$delete('/api/v1/cart_set_items/{id}', {
        path: { id },
        cancelToken,
      });
    } else {
      await this.$ecAxios.$delete('/api/v1/guest_cart_set_items/{id}', {
        // @ts-expect-error
        query: {
          guest_token: state.guestToken,
        },
        path: { id },
        cancelToken,
      });
    }
    await dispatch('fetchCarts', {});
  },
  /**
   * ゲストカートを会員カートに紐付け
   */
  async loginCart(this: Vue, { state }: Ctx, { cancelToken }: BaseAxiosAction<{}>) {
    if (state.guestToken) {
      await this.$ecAxios.$post('/api/v1/carts/login', {
        body: {
          guest_token: state.guestToken,
        },
        cancelToken,
      });
    }
  },
  async fetchFreeAreaData(this: Vue, context: Ctx, payload: BaseAxiosAction): Promise<FreeAreaData[]> {
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      ...payload,
      query: {
        '_article_type[eq]': ArticleType.CartFree,
        '_order[DESC]': 'publish_at',
        _limit: '1',
      },
    });

    return sortBy(result, 'priority').map(
      (item: any): FreeAreaData => ({
        id: item?.id || '',
        title: item?.title || '',
        text: item?.contents.text || '',
        link:
          item.contents.link_text?.text && item.contents.link_text?.url
            ? {
                text: item.contents.link_text.text,
                url: item.contents.link_text.url,
              }
            : undefined,
        target: item?.contents.target || false,
      })
    );
  },
  clearGuestToken(this: Vue, { commit }: Ctx) {
    commit('setGuestToken', '');
  },
  /** ギフトカード紐付け */
  async postGiftCard(this: Vue, { commit }: Ctx, payload: ECActionPayload<'/api/v1/carts/value_card', 'post'>) {
    const res = await this.$ecAxios.$post('/api/v1/carts/value_card', {
      body: {
        card_no: payload.body.card_no,
        cart_id: payload.body.cart_id,
        pin_code: payload.body.pin_code,
      },
    });
    commit('setGiftCardBalance', res.balance_total);
  },
  /** カートイン中かどうかの状態管理 */
  async setIsCartProcessing(this: Vue, { commit }: Ctx, payload: boolean) {
    commit('setIsCartProcessing', payload);
  },
};

export type Store = DefineStoreModule<'modules/cart', State, typeof getters, typeof mutations, typeof actions>;
