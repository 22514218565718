// google トランザクションタグ用
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import dayjs from 'dayjs';
import { RootStore } from '~/composables/useStore';
import { url } from '~/utils';

export default defineNuxtPlugin(({ app, ...context }) => {
  // gtm,ga用情報設定
  app.router?.afterEach((to) => {
    const store = context.store as RootStore;
    const { path } = to;

    // ページの種類(google tag managerを参照)
    let pageType = 'siteview';
    switch (path) {
      case url('TOP'):
        pageType = 'home';
        break;
      case url('CART'):
        pageType = 'cart';
        break;
      case url('SIGN_UP_COMPLETE'):
        pageType = 'register';
        break;
      case url('ORDER_COMPLETE'):
        pageType = 'purchase';
        break;
    }
    if (path.startsWith('/item/detail')) {
      pageType = 'product';
    }
    if (path.startsWith(url('MYPAGE'))) {
      pageType = 'mypage';
    }
    // 端末の種類
    let deviceType = 'd';
    if (context.$device.isMobile) {
      deviceType = 'm';
    }
    if (context.$device.isTablet) {
      deviceType = 't';
    }

    // 購入完了のコンバージョンの値
    let conversionValue = 0;

    if (path === url('ORDER_COMPLETE')) {
      conversionValue = store.state.modules.order.orderConversionValue || 0;
    }

    const createdate = store.state.user.info.userBase?.createdate || '';
    const birthday = store.state.user.info.profile?.birthday || '';
    const age = dayjs(birthday).isValid()
      ? dayjs().tz('Asia/Tokyo').diff(dayjs(birthday).tz('Asia/Tokyo'), 'year')
      : '';

    const createdDate = createdate && dayjs(createdate).tz('Asia/Tokyo');

    window.dataLayer ||= [];
    window.dataLayer.push(
      {
        'gtm-page_type': pageType,
        'gtm-site_type': deviceType,
        'gtm-conversion_value': conversionValue.toFixed(2),
      },
      {
        uid: store.state.user.info.userBase?.uuid || '',
        is_member: store.state.auth.authenticated,
        member_created_year: createdDate ? createdDate.year() : '',
        member_created_month: createdDate ? createdDate.month() + 1 : '',
        member_created_day: createdDate ? createdDate.date() : '',
        member_age: age,
        device_and_age: age ? `${context.$device.isMobile ? 'sp' : 'pc'}_${age}` : '',
      }
    );
  });
});
