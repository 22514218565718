import { ECParamBody } from '~/types/api';
import { FormSelectOption } from '~/types/ui';

type SpecificationType = ECParamBody<'/api/v1/primeur_delivery', 'post'>['specification_type'];

export const PRIMEUR_SPECIFICATION_TYPE_OPTIONS: FormSelectOption<SpecificationType>[] = [
  { value: 'enclosing', text: '同梱する' },
  { value: 'none', text: '同梱しない' },
];

export const PRIMEUR_LOGIN_STATUS = {
  NO_LOGIN: 'noLogin',
  LOGGED_IN: 'loggedIn',
};
