





































import { defineComponent, useContext } from '@nuxtjs/composition-api';
import AppLink from '~/components/partials/App/AppLink.vue';
import { useEnotecaApp } from '~/composables/useEnotecaApp';
import { useWOVNio } from '~/composables/wovn/useWOVNio';
import { nonNullable, url } from '~/utils';

type OtherLink = {
  title: string;
  link?: string;
  telLink?: string;
  text?: string;
  tag?: 'a';
};

export default defineComponent({
  components: {
    AppLink,
  },
  setup() {
    const { i18n } = useContext();
    const { targetDisplayLang, updateCurrentLang } = useWOVNio();
    const { isEnotecaAppWebView } = useEnotecaApp();

    const OTHER_LINKS: OtherLink[] = [
      {
        title: i18n.t('Common.footerMenu.otherLinks.title').toString(),
        link: url('CONTACT_FORM'),
      },
      {
        title: i18n.t('Common.footerMenu.otherLinks.freeDial.text').toString(),
        text: i18n.t('Common.footerMenu.otherLinks.receptionTime').toString(),
        telLink: i18n.t('Common.footerMenu.otherLinks.freeDial.link').toString(),
      },
      {
        title: i18n.t('Common.footerMenu.otherLinks.tokushoho').toString(),
        link: url('TOKUSHOHO'),
      },
      {
        title: i18n.t('Common.footerMenu.otherLinks.privacyPolicy').toString(),
        link: url('PRIVACY_POLICY'),
        tag: 'a' as 'a',
      },
      {
        title: i18n.t('Common.footerMenu.otherLinks.policy').toString(),
        link: url('POLICY'),
      },
      {
        title: i18n.t('Common.footerMenu.otherLinks.companyInfo').toString(),
        link: url('COMPANY_INFO'),
        tag: 'a' as 'a',
      },
      {
        title: i18n.t('Common.footerMenu.otherLinks.recruit').toString(),
        link: url('RECRUIT'),
        tag: 'a' as 'a',
      },
      // OneTrust の Cookie 許諾ポップアップ表示
      !isEnotecaAppWebView.value
        ? { title: 'OneTrust_CMP', text: i18n.t('Common.footerMenu.otherLinks.cookie').toString() }
        : undefined,
      { title: 'language' },
    ].filter(nonNullable);

    return {
      OTHER_LINKS,
      targetDisplayLang,
      updateCurrentLang,
    };
  },
});
