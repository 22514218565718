import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { EventActionPayload } from '~/types/api';
import { EventDetailResponseSchema, EventQuestionnaire, EventSumilationResponseSchema } from '~/types/modules/event';

export type Ctx = DefineActionContext<never, never, never>;

export const actions = {
  async fetchEventDetail(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/events/{eventId}', 'get'>
  ): Promise<EventDetailResponseSchema> {
    const res = await this.$eventAxios.$get('/api/v1/events/{eventId}', payload);
    return res;
  },
  async fetchEventQuestionnaire(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/events/{eventId}/questionnaire', 'get'>
  ): Promise<EventQuestionnaire[]> {
    const res = await this.$eventAxios.$get('/api/v1/events/{eventId}/questionnaire', payload);
    return res.questions || [];
  },
  async fetchEventSimulation(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/events/{eventId}/simulation', 'get'>
  ): Promise<EventSumilationResponseSchema> {
    const res = await this.$eventAxios.$get('/api/v1/events/{eventId}/simulation', payload);

    return res;
  },
  async postEventReservation(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/events/{eventId}/reserve', 'post'>
  ) {
    const res = await this.$eventAxios.post('/api/v1/events/{eventId}/reserve', payload);
    return res.data;
  },
  async postWaitingEventReservation(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/event_reservations_waiting', 'post'>
  ) {
    const res = await this.$eventAxios.post('/api/v1/event_reservations_waiting', payload);
    return res.data;
  },
  // Veritrans決済確定情報取得
  async confirmEventPayment(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/reservations/{reservationId}/payment', 'get'>
  ) {
    const res = await this.$eventAxios.$get('/api/v1/reservations/{reservationId}/payment', payload);
    return res;
  },
};

export type Store = DefineStoreModule<'event/detail', never, never, never, typeof actions>;
