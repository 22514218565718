












import { defineComponent, computed, PropType, useRoute } from '@nuxtjs/composition-api';

export type NoImageType = 'product' | 'cellar' | 'food' | 'goods' | 'avatar' | 'leaflet';

export default defineComponent({
  props: {
    src: {
      type: String,
      default: null,
    },
    srcset: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'img',
    },
    noLazy: {
      type: Boolean,
      default: false,
    },
    noImageType: {
      type: String as PropType<NoImageType>,
      default: null,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const defaultProductImage = require('~/assets/images/common/no-image.png');
    const defaultProductCellarImage = require('~/assets/images/no-image/cellar.png');
    const defaultProductFoodImage = require('~/assets/images/no-image/food.png');
    const defaultProductGoodsImage = require('~/assets/images/no-image/goods.png');
    const defaultAvatarImage = require('~/assets/svg/icon_people.svg');
    const defaultLeafletImage = require('~/assets/images/no-image/leaflet.svg');

    const noImage = computed(() => {
      switch (props.noImageType) {
        case 'product':
          return defaultProductImage;
        case 'cellar':
          return defaultProductCellarImage;
        case 'food':
          return defaultProductFoodImage;
        case 'goods':
          return defaultProductGoodsImage;
        case 'avatar':
          return defaultAvatarImage;
        case 'leaflet':
          return defaultLeafletImage;
        default:
          return props.src;
      }
    });

    const isNoLazy = computed(() => props.tag === 'img' && (props.noLazy || !!route.value.hash));

    const formattedSrc = computed(() => {
      if (!props.src && !props.srcset) {
        return noImage.value;
      }

      return props.src;
    });

    const formattedSrcSet = computed(() => {
      if (!props.srcset && !props.src) {
        return noImage.value;
      }

      return props.srcset;
    });

    const onError = () => {
      emit('error');
    };

    return {
      formattedSrc,
      formattedSrcSet,
      onError,
      isNoLazy,
    };
  },
});
