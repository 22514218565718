




























import { defineComponent } from '@nuxtjs/composition-api';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import { FOOTER_BOTTOM_POINT } from '~/constants';

export default defineComponent({
  components: {
    AppIcon,
  },
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { FOOTER_BOTTOM_POINT };
  },
});
