import { computed, useRoute } from '@nuxtjs/composition-api';
import { RootStore, useStore } from '~/composables/useStore';
import { TRANSITION_SOURCE } from '~/constants';
import { PAGE_URL } from '~/utils';
import type { Route } from 'vue-router';

export const getIsAfter3DSecure = (route: Route) => {
  return route.path === PAGE_URL.ORDER_COMPLETE && route.query.from === TRANSITION_SOURCE.THREE_D_SECURE;
};

export const getOrderedDetails = (route: Route, store: RootStore) => {
  const isAfter3DSecure = getIsAfter3DSecure(route);
  const { simulationRes, orderRes, orderedCartId, veritransOrderedTemporals, veritransOrderInfo } =
    store.state.modules.order;

  const simulatedData = isAfter3DSecure ? veritransOrderedTemporals?.simulationRes : simulationRes;
  const orderId = isAfter3DSecure ? veritransOrderInfo?.order_number : orderRes?.order_number;
  const carts = isAfter3DSecure ? veritransOrderedTemporals?.carts : store.state.modules.cart.carts;
  const cartId = isAfter3DSecure ? veritransOrderedTemporals?.cartId : orderedCartId;
  const cart = carts?.find((cart) => cart.cart_id === cartId);

  return {
    simulatedData,
    orderId,
    carts,
    cartId,
    cart,
  };
};

export const useOrderConversion = () => {
  const store = useStore();
  const route = useRoute();

  const isAfter3DSecure = computed(() => getIsAfter3DSecure(route.value));

  const orderedDetails = computed(() => getOrderedDetails(route.value, store));

  return { isAfter3DSecure, orderedDetails };
};
