import { DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import { cloneDeep, uniq } from 'lodash-es';
import Vue from 'vue';

/** State */
export interface State {
  // ミックスセラーのフリーワード検索
  mixSearchWord: string;
  // 入庫ステータス
  cellarStatus: string | null;
  // タイプ
  color: string | null;
  // 産地
  area: string | null;
  // ヴィンテージ
  vintage: string | null;
  // 生産者
  producer: string | null;
  // フリーワード検索
  word: string | null;
}

export const initialState: State = {
  mixSearchWord: '',
  cellarStatus: null,
  color: null,
  area: null,
  vintage: null,
  producer: null,
  word: null,
};

export const state = (): State => cloneDeep(initialState);

/** Mutations */
export const mutations = {
  setFieldValue<Field extends keyof State>(state: State, payload: { field: Field; value: State[Field] }): void {
    const nextValue: any = Array.isArray(payload.value) ? uniq(payload.value) : payload.value;

    Vue.typedSet(state, payload.field, nextValue);
  },
};

/** Store Module Type */
export type Store = DefineStoreModule<'modules/mypage/cellarFilter', State, never, typeof mutations, never>;
