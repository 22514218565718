/**
 * @file Vuexのルートストアと useStore を定義
 */

import { DefineRootStore } from '@lollipop-onl/vuex-typesafe-helper';
import { useStore as useNuxtStore } from '@nuxtjs/composition-api';

import { Store as AuthStore } from '~/store/auth';
import { Store as BlogMasterStore } from '~/store/blog/master';
import { Store as CollegeDetailStore } from '~/store/college/detail';
import { Store as CollegeListStore } from '~/store/college/list';
import { Store as CollegeQuizStore } from '~/store/college/quiz';
import { Store as EventDetailStore } from '~/store/event/detail';
import { Store as EventFilterStore } from '~/store/event/filter';
import { Store as EventListStore } from '~/store/event/list';
import { Store as EventReserveStore } from '~/store/event/reserve';
import { Store as InternalsAlertPropsStore } from '~/store/internals/alert';
import { Store as InternalsHeader } from '~/store/internals/header';
import { Store as InternalsKeepScrollStore } from '~/store/internals/keepScroll';
import { Store as InternalsLayoutPropsStore } from '~/store/internals/layoutProps';
import { Store as InternalsRedirect } from '~/store/internals/redirect';
import { Store as ModulesAmazonPay } from '~/store/modules/amazonPay';
import { Store as CartStore } from '~/store/modules/cart';
import { Store as CartMessageStore } from '~/store/modules/cartMessage';
import { Store as ModuleContentStore } from '~/store/modules/content';
import { Store as GeneralStore } from '~/store/modules/general';
import { Store as LotteryStore } from '~/store/modules/lottery';
import { Store as MypageCellarStore } from '~/store/modules/mypage/cellar';
import { Store as CellarFilterStore } from '~/store/modules/mypage/cellarFilter';
import { Store as UserUserCouponStore } from '~/store/modules/mypage/coupon';
import { Store as CreditCardStore } from '~/store/modules/mypage/creditCard';
import { Store as DistributionCourseSubscriptionStore } from '~/store/modules/mypage/distributionCourseSubscription';
import { Store as MypageEventStore } from '~/store/modules/mypage/event';
import { Store as MypageHistoryStore } from '~/store/modules/mypage/history';
import { Store as MypageMyshopStore } from '~/store/modules/mypage/myshop';
import { Store as MypagePersonalMessageStore } from '~/store/modules/mypage/personalMessage';
import { Store as MypagePointStore } from '~/store/modules/mypage/point';
import { Store as PrimeurStore } from '~/store/modules/mypage/primeur';
import { Store as ModuleNewsStore } from '~/store/modules/news';
import { Store as ModuleOrderStore } from '~/store/modules/order';
import { Store as ModulesProductStore } from '~/store/modules/product';
import { Store as ModuleRakutenStore } from '~/store/modules/rakuten';
import { Store as ReviewsStore } from '~/store/modules/reviews';
import { Store as SalesLocationStore } from '~/store/modules/salesLocation';
import { Store as ModulesStaffStore } from '~/store/modules/staff';
import { Store as TagListStore } from '~/store/modules/tagList';
import { Store as VeritransStore } from '~/store/modules/veritrans';
import { Store as VintageChartStore } from '~/store/modules/vintageChart';
import { Store as WineAreaStore } from '~/store/modules/wineArea';
import { Store as ProductDetailStore } from '~/store/product/detail';
import { Store as ProductFilterStore } from '~/store/product/filter';
import { Store as FoodDiagnosis } from '~/store/product/foodDiagnosis';
import { Store as ProductMasterStore } from '~/store/product/master';
import { Store as ProductOptionStore } from '~/store/product/option';
import { Store as ProductRecommendStore } from '~/store/product/recommend';
import { Store as ProductSearchStore } from '~/store/product/search';
import { Store as UserBaseStore } from '~/store/user/base';
import { Store as UserFavoritesStore } from '~/store/user/favorites';
import { Store as UserInfoStore } from '~/store/user/info';
import { Store as UserMagazineStore } from '~/store/user/magazine';
import { Store as UserPasswordStore } from '~/store/user/password';
import { Store as UserProfileBase } from '~/store/user/profile';
import { Store as UserUserStore } from '~/store/user/user';

export type RootStore = DefineRootStore<
  AuthStore &
    ModulesAmazonPay &
    CartStore &
    CartMessageStore &
    CollegeDetailStore &
    CollegeListStore &
    CollegeQuizStore &
    InternalsAlertPropsStore &
    InternalsLayoutPropsStore &
    InternalsHeader &
    InternalsKeepScrollStore &
    InternalsRedirect &
    ModulesProductStore &
    ModuleContentStore &
    ModulesStaffStore &
    ModuleOrderStore &
    ModuleNewsStore &
    ModuleRakutenStore &
    SalesLocationStore &
    ProductMasterStore &
    ProductFilterStore &
    ProductOptionStore &
    ProductDetailStore &
    ProductSearchStore &
    ProductRecommendStore &
    MypageHistoryStore &
    MypageMyshopStore &
    MypageCellarStore &
    CellarFilterStore &
    MypagePointStore &
    MypagePersonalMessageStore &
    CreditCardStore &
    DistributionCourseSubscriptionStore &
    PrimeurStore &
    MypageEventStore &
    ReviewsStore &
    GeneralStore &
    VintageChartStore &
    UserBaseStore &
    UserProfileBase &
    UserFavoritesStore &
    UserInfoStore &
    UserPasswordStore &
    UserUserStore &
    UserMagazineStore &
    UserUserCouponStore &
    BlogMasterStore &
    EventDetailStore &
    EventReserveStore &
    EventListStore &
    EventFilterStore &
    VeritransStore &
    WineAreaStore &
    FoodDiagnosis &
    TagListStore &
    LotteryStore
>;

export const useStore = () => useNuxtStore() as RootStore;
