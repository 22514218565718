export enum DECConnectId {
  // Web会員
  EC_USER = '1',
  // 仮会員→本会員登録
  SIGN_UP = '2',
  // マイページ
  MYPAGE = '3',
  // ポイント合算
  POINT_ADDUP = '4',
}
