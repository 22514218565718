





















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    isRed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
