import { ValidationRule } from 'vee-validate/dist/types/types';

/**
 *  0 だと requiredのルールが発生しないので、そのための required ルールを定義した
 * （場合によって、0 をデフォルト値として定義したい）
 */
export const requiredNonZero: ValidationRule = {
  message: '{_field_}を選択してください',
  validate(value) {
    return value > 0;
  },
};
