import { DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import { AlertBase } from '~/types/ui';

export type State = {
  queue: AlertBase[];
};

export const state = (): State => ({
  queue: [],
});

export const mutations = {
  /** キューの最後にアラートを追加する */
  appendAlert(state: State, payload: AlertBase): void {
    state.queue.push(payload);
  },
  /** 現在表示中の次のアラートとして追加する */
  prependAlert(state: State, payload: AlertBase): void {
    state.queue.splice(1, 0, payload);
  },
  /** キューからアラートを削除する */
  removeAlert(state: State, alertId?: string): void {
    state.queue = state.queue.filter((alert) => alert.id !== alertId);
  },
  /** すべてのアラートを削除する */
  clearAlert(state: State): void {
    state.queue = [];
  },
};

export type Store = DefineStoreModule<'internals/alert', State, never, typeof mutations, never>;
