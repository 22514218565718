




import { defineComponent } from '@nuxtjs/composition-api';
import AppLayoutDefault from '~/components/partials/App/AppLayoutDefault.vue';
import { NOT_FOUND_LAYOUT_PROPS, optimizeLayoutProps } from '~/composables/internals/useLayoutPropsProvider';
import { PageError } from '~/utils/error';

export default defineComponent({
  components: {
    AppLayoutDefault,
  },
  setup() {
    const layoutProps = optimizeLayoutProps({
      ...NOT_FOUND_LAYOUT_PROPS,
      fetchState: [
        {
          error: new PageError(404, {}),
          pending: false,
          timestamp: Date.now(),
        },
      ],
    });

    return {
      layoutProps,
    };
  },
});
