import { has, sortBy } from 'lodash-es';
import { ECSchema } from '~/types/api';
import { ShopDistance } from '~/types/modules/shop';

/**
 * @param shopList 店舗情報の配列
 * @param isParentArea 親エリアをkyeとするか
 * @returns 親 もしくは 子エリアID毎のkyeを持つRecord型オブジェクト
 */
export const formatShopList = (
  shopList: ECSchema<'saleslocation.SalesLocation'>[],
  isParentArea: boolean
): Record<string, ECSchema<'saleslocation.SalesLocation'>[]> => {
  // エリアIDをkey、店舗情報を値として持つRecord型のオブジェクトを生成
  const groupedSalesLocations: Record<string, ECSchema<'saleslocation.SalesLocation'>[]> = {};

  // 親エリアkeyとする場合
  if (isParentArea) {
    shopList.forEach((shop: ECSchema<'saleslocation.SalesLocation'>) => {
      if (shop.parent_area_id) {
        const parentAreaId = shop.parent_area_id.toString();
        // エリアIDがkeyとして存在していなければ新しい空配列を追加
        if (!has(groupedSalesLocations, parentAreaId)) {
          groupedSalesLocations[parentAreaId] = [];
        }
        // 存在していれば、同じ親エリアIDの値として店舗情報を追加していく
        groupedSalesLocations[parentAreaId].push(shop);
      }
    });
    return groupedSalesLocations;
    // 子エリアをkeyとする場合
  } else {
    shopList.forEach((shop: ECSchema<'saleslocation.SalesLocation'>) => {
      if (shop.area_id) {
        const areaId = shop.area_id.toString();
        if (!has(groupedSalesLocations, areaId)) {
          groupedSalesLocations[areaId] = [];
        }
        groupedSalesLocations[areaId].push(shop);
      }
    });
    return groupedSalesLocations;
  }
};

/**
 * 2地点の緯度と経度を使って店舗間の距離を取得
 * @param lat1 地点①の緯度
 * @param lng1 地点①の経度
 * @param lat2 地点②の緯度
 * @param lng2 地点②の経度
 * @returns 地点①と②の距離（km）
 *
 *  参考：https://gist.github.com/kawanet/15c5a260ca3b98bd080bb87cdae57230
 */
export const caluculateDistanceBetweenShops = (lat1: number, lng1: number, lat2: number, lng2: number) => {
  const R = Math.PI / 180;
  lat1 *= R;
  lng1 *= R;
  lat2 *= R;
  lng2 *= R;
  return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
};

/**
 *
 * @param shopList 全店舗情報
 * @param shopId 比較元の店舗ID
 * @returns 距離の昇順でソートされたを持った配列
 */
export const getSortedShopDistance = (
  shopList: Array<ECSchema<'saleslocation.SalesLocation'>>,
  shopId: number
): Array<ShopDistance> => {
  const targetShop = shopList.filter((shop) => shop.id === shopId);
  // 比較する店舗の大元の緯度と経度を取得
  const latitude = Number(targetShop[0].google_map_latitude);
  const longitude = Number(targetShop[0].google_map_longitude);

  // 距離を計算した後に、短い距離の昇順でsortする
  return sortBy(
    shopList.map((shop) => ({
      id: shop.id,
      distance: caluculateDistanceBetweenShops(
        latitude,
        longitude,
        Number(shop.google_map_latitude),
        Number(shop.google_map_longitude)
      ),
    })),
    'distance'
  );
};
