import { useContext } from '@nuxtjs/composition-api';
import urlJoin from 'url-join';
import { v4 as uuidv4 } from 'uuid';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';
import { COOKIE_KEY } from '~/constants';
import { SocialLoginProvider } from '~/enums';

type SocialLoginAuthQuery = {
  state: string;
  redirect_uri: string;
  platform?: string;
};

export const useSocialLogin = () => {
  const { app } = useContext();
  const { FRONTEND_ORIGIN, SOCIAL_LOGIN_ORIGIN } = useRuntimeConfig();

  const getLoginUrl = (provider: SocialLoginProvider, callbackPath: string): string => {
    const socialLoginState = uuidv4();
    app.$cookies.set(COOKIE_KEY.SOCIAL_LOGIN_STATE, socialLoginState);

    const query: SocialLoginAuthQuery = {
      state: socialLoginState,
      redirect_uri: urlJoin(FRONTEND_ORIGIN, callbackPath),
    };
    const queryParams = Object.entries(query)
      .map(([key, value]) => {
        return value ? `${key}=${encodeURIComponent(value)}` : '';
      })
      .join('&');

    return urlJoin(SOCIAL_LOGIN_ORIGIN, `/${provider}/auth`, `?${queryParams}`);
  };

  return { getLoginUrl };
};
