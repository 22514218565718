/** @file CMP を提供する OneTrust 関連のユーティリティ */

type OptanonCategory = 'analytics' | 'functional' | 'targeting';

const toCategoryId = (category: OptanonCategory): string => {
  switch (category) {
    case 'analytics':
      return 'C0002';
    case 'functional':
      return 'C0003';
    case 'targeting':
      return 'C0004';
    default:
      return 'C0001';
  }
};

/**
 * CMP のスクリプトブロック用のクラスを取得する
 * @param categories Cookie カテゴリ
 */
export const getOptanonClass = (...categories: OptanonCategory[]) => {
  return `optanon-category-${categories.map(toCategoryId).join('-')}`;
};
