import { cloneDeep, times } from 'lodash-es';
import { ECSchema } from '~/types/api';
import { UserOptionShipment, GiftOption } from '~/types/modules/order';
import { nonNullable } from '../nonNullable';

export const createGiftsReq = (
  shipment: UserOptionShipment,
  giftOptions: GiftOption[],
  type: 'simulation' | 'order',
  itemsIncludedGiftBox?: Array<ECSchema<'order.SimulationItemsIncludedGiftBoxRes'>>
) => {
  if (shipment.toRentalCellar) return undefined;

  // 紙箱
  const paperBags: Array<ECSchema<'order.SimulationGift'>> = [
    {
      options:
        shipment.paperBags && (shipment.hasPaperBags || shipment.giftItems)
          ? Object.entries(shipment.paperBags)
              .map(([key, value]) => {
                return {
                  id: Number(key),
                  quantity: value || 0,
                };
              })
              .filter((item) => item.quantity)
          : undefined,
      items: shipment.giftItems,
    },
  ].filter((obj) => obj.options?.length);

  // ギフト・ラッピング
  const itemsIncludedGiftBoxState = cloneDeep(itemsIncludedGiftBox);

  // シュミレーションAPIのpostの形にparse
  const giftWrappings: Array<ECSchema<'order.SimulationGift'>> =
    shipment.giftWrappings
      ?.map((giftBoxSetting) => {
        const { selectedWines, hasMessage, message, wrappingType, noshi, giftBoxId, hasItem } = giftBoxSetting;
        const options = [];

        if (giftBoxId === null) return null;

        if (hasItem) {
          itemsIncludedGiftBoxState?.forEach((item, index) => {
            if (item.product_variant_id === selectedWines[0].id) {
              itemsIncludedGiftBoxState[index].quantity -= 1;
            }
          });
        }

        options.push({
          id: giftBoxId,
          quantity: 1,
        });

        // リボン
        if (wrappingType === 'ribbon') {
          const ribbon = giftOptions.find((giftOption) => giftOption.group_code === 'ribbon');

          if (!ribbon) return null;

          options.push({
            id: ribbon.id,
            quantity: 1,
          });

          // のし
        } else if (wrappingType === 'noshi') {
          const { id, name, title } = noshi;

          if (!id) return null;

          options.push({
            file_id: noshi.fileData,
            id,
            quantity: 1,
            noshi_name: type === 'order' ? name : undefined,
            noshi_title: type === 'order' ? title : undefined,
          });
        }

        // メッセージカード
        if (hasMessage) {
          const withMessage = giftOptions.find((giftOption) => giftOption.group_code === 'message_card_with_message');
          const withoutMessage = giftOptions.find(
            (giftOption) => giftOption.group_code === 'message_card_without_message'
          );

          const messageId = message.text.length ? withMessage?.id : withoutMessage?.id;

          if (!messageId) return null;

          options.push({
            file_id: message.fileData,
            id: messageId,
            quantity: 1,
            message: type === 'order' ? message.text : undefined,
          });
        }

        const items = selectedWines
          .map((wine) => {
            const { id, count } = wine;

            // 箱に入っていない商品は削除
            if (count === 0) return null;

            return {
              product_variant_id: id,
              quantity: count,
            };
          })
          .filter(nonNullable);

        return {
          items: items.length > 0 ? items : undefined,
          options: options.length > 0 ? options : undefined,
        };
      })
      ?.filter(nonNullable) || [];

  const giftWrappingsWithBox: Array<ECSchema<'order.SimulationGift'>> = [];

  itemsIncludedGiftBoxState?.forEach((item) => {
    const { product_variant_id, gift_box, quantity } = item;

    const result = times(quantity, () => ({
      items: [
        {
          product_variant_id,
          quantity: 1,
        },
      ],
      options: [
        {
          id: gift_box.id,
          quantity: 1,
        },
      ],
    }));

    giftWrappingsWithBox.push(...result);
  });

  return [...paperBags, ...giftWrappings, ...giftWrappingsWithBox];
};
