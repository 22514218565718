export const BLOG_ARTICLES_PER_PAGE = 5;
export const BLOG_TOP_ARTICLES_PER_PAGE = 18;

export const BLOG_DISPLAY_COUNT = 3;

export const BLOG_SEARCH_SORT_OPTIONS = [
  {
    text: '新着順',
    value: '1',
    query: 'publish_start_at_d desc',
  },
  {
    text: '人気順',
    value: '2',
    query: 'rnk_a1_7d_i desc',
  },
];

export const BLOG_SEARCH_ALL_BLOG_OPTION = [
  {
    text: 'すべてのブログ',
    value: true,
  },
];

export const ARTICLE_ID_PREFIX = 'blog_';
