import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { COOKIE_KEY } from '~/constants';
import { url } from '~/utils';

export default defineNuxtMiddleware(({ redirect, route, app }) => {
  const { fullPath } = route;

  // agechkクエリなしの場合はskip
  if (!fullPath.includes('agechk')) {
    return;
  }

  // NO選択済みの場合は、NOのままの年齢認証画面にリダイレクト
  if (app.$cookies.get(COOKIE_KEY.AGE_CHECK_NO)) {
    redirect(url('AGE_CHECK'));
    return;
  }

  // agechk除外のパスを取得
  let returnPath;
  if (fullPath.endsWith('?agechk')) {
    // ?agechkのパターン
    returnPath = fullPath.replace('?agechk', '');
  } else if (fullPath.includes('agechk&')) {
    // ?agechk&otherQuery=sample のパターン
    returnPath = fullPath.replace('agechk&', '');
  } else {
    // ?otherQuery=sample&agechck のパータン
    returnPath = fullPath.replace('&agechk', '');
  }

  // YES選択済みの場合はそのまま現在の画面にアクセス
  if (app.$cookies.get(COOKIE_KEY.AGE_CHECK_YES)) {
    redirect(returnPath);
    return;
  }

  // 選択記録なしの場合は、年齢認証の画面にリダイレクト
  app.$cookies.set(COOKIE_KEY.RETURN_TO, returnPath, { path: '/' });
  redirect(url('AGE_CHECK'));
});
