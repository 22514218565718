import { definePreset } from '../core/preset';

/**
 * 姓のルールプリセット
 */
export const lastName = definePreset({
  rules: {
    shiftjis: true,
    maxBytes: 14,
  },
});

/**
 * 名のルールプリセット
 */
export const firstName = definePreset({
  rules: {
    shiftjis: true,
    maxBytes: 14,
  },
});

/**
 * 姓（フリガナ）のルールプリセット
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const lastName_kana = definePreset({
  rules: {
    max: 10,
    katakana: true,
  },
});

/**
 * 名（フリガナ）のルールプリセット
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const firstName_kana = definePreset({
  rules: {
    max: 10,
    katakana: true,
  },
});
