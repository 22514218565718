/**
 * @file 要素外をクリックした際にbindされた関数を実行するプラグイン
 */
import Vue from 'vue';

Vue.directive('click-outside', {
  bind(el, binding) {
    const handleOutsideClick = (event: Event): void => {
      // HTMLから要素が消えた後の初回のクリック時にイベントリスナーを解除する
      if (!document.body.contains(el)) {
        window.removeEventListener('click', handleOutsideClick);
        window.removeEventListener('touchstart', handleOutsideClick);
      }

      // 要素外にしたくない他の要素
      const otherTarget = () => {
        if (!binding.arg) {
          return null;
        }
        return document.getElementById(binding.arg);
      };

      // 要素外をクリックした場合
      if (!el.contains(event.target as Node) && !otherTarget()?.contains(event.target as Node)) {
        binding.value();
      }
    };

    window.addEventListener('click', handleOutsideClick);
    window.addEventListener('touchstart', handleOutsideClick);
  },
});
