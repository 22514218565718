import { ValidationRule } from 'vee-validate/dist/types/types';
import { ADDRESS_NAME_HOME } from '~/constants/address';
import { definePreset } from '../core/preset';

/**
 * 郵便番号のルールプリセット
 */
export const zipCode: ValidationRule = {
  message: '{_field_}は7桁の半角数字で入力してください',
  validate(value) {
    if (typeof value !== 'string') {
      return false;
    }

    return /^[0-9]{7}$/.test(value);
  },
};

/**
 * 市区町村のルールプリセット
 */
export const city = definePreset({
  rules: {
    max: 12,
    shiftjis: true,
  },
});

/**
 * 丁目・番地・建物名のルールプリセット
 */
export const streetAddress = definePreset({
  rules: {
    min: 2,
    max: 32,
    shiftjis: true,
  },
});

/**
 * お届け先名称のルール
 */
export const addressName = definePreset({
  rules: {
    addressIsNotHome: true,
    max: 100,
  },
});

/**
 * 自宅ではないのルール
 */
export const addressIsNotHome: ValidationRule = {
  message: `「${ADDRESS_NAME_HOME}」はすでに登録されています`,
  validate(value) {
    if (typeof value !== 'string') {
      return false;
    }

    return value !== ADDRESS_NAME_HOME;
  },
};
