import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { BaseAxiosAction, ECActionPayload, ECParamBody, ECSchema } from '~/types/api';

type SpecificationType = ECParamBody<'/api/v1/primeur_delivery', 'post'>['specification_type'];

/** State */
export interface State {
  // 受注ID
  orderId: number | null;
  // レンタルセラー入庫か。選択済みかを判定するためにOptional
  isCellarIn?: boolean;
  // お届け先
  shipAddress?: ECSchema<'primeurdelivery.AddressReq'>;
  // 配送希望日時
  deliveryDate?: string;
  // 配送希望時間帯ID
  deliveryTimeId?: number | null;
  // 配送希望時間帯
  deliveryTime?: string | null;
  // 領収書種別
  specificationType?: SpecificationType;
}

export const state = (): State => ({
  orderId: null,
});

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setOrderId(state: State, orderId: number): void {
    Vue.typedSet(state, 'orderId', orderId);
  },
  setIsCellarIn(state: State, isCellarIn: boolean): void {
    Vue.typedSet(state, 'isCellarIn', isCellarIn);
  },
  setShipAddress(state: State, shipAddress: ECSchema<'primeurdelivery.AddressReq'>): void {
    Vue.typedSet(state, 'shipAddress', shipAddress);
  },
  setDeliveryDate(state: State, deliveryDate: string): void {
    Vue.typedSet(state, 'deliveryDate', deliveryDate);
  },
  setDeliveryTimeId(state: State, deliveryTimeId: number | null): void {
    Vue.typedSet(state, 'deliveryTimeId', deliveryTimeId);
  },
  setDeliveryTime(state: State, deliveryTime: string | null): void {
    Vue.typedSet(state, 'deliveryTime', deliveryTime);
  },
  setSpecificationType(state: State, specificationType: SpecificationType): void {
    Vue.typedSet(state, 'specificationType', specificationType);
  },
  resetDeliveryDate(state: State): void {
    Vue.typedDelete(state, 'deliveryDate');
  },
  resetDeliveryTimeId(state: State): void {
    Vue.typedDelete(state, 'deliveryTimeId');
  },
  resetDeliveryTime(state: State): void {
    Vue.typedDelete(state, 'deliveryTime');
  },
  resetSpecificationType(state: State): void {
    Vue.typedDelete(state, 'specificationType');
  },
  reset(state: State): void {
    Vue.typedSet(state, 'orderId', null);
    Vue.typedDelete(state, 'isCellarIn');
    Vue.typedDelete(state, 'shipAddress');
    Vue.typedDelete(state, 'deliveryDate');
    Vue.typedDelete(state, 'deliveryTimeId');
    Vue.typedDelete(state, 'deliveryTime');
    Vue.typedDelete(state, 'specificationType');
  },
};

/** Actions */
export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;
export const actions = {
  async fetchDeliveryDateTimes(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/primeur_delivery_date_times', 'get'>
  ) {
    return await this.$ecAxios.$get('/api/v1/primeur_delivery_date_times', payload);
  },
  async postPrimeurDelivery(this: Vue, { state }: Ctx, payload: BaseAxiosAction) {
    const { cancelToken } = payload;
    const { orderId, isCellarIn, deliveryDate, deliveryTimeId, shipAddress, specificationType } = state;

    if (!orderId) {
      return;
    }

    await this.$ecAxios.$post('/api/v1/primeur_delivery', {
      cancelToken,
      body: {
        delivery_date: deliveryDate,
        delivery_time_id: deliveryTimeId || undefined,
        is_cellar_in: isCellarIn,
        order_id: orderId,
        ship_address: shipAddress,
        specification_type: specificationType,
      },
    });
  },
};

export type Store = DefineStoreModule<
  'modules/mypage/primeur',
  State,
  typeof getters,
  typeof mutations,
  typeof actions
>;
