export enum AromaType {
  CONTENTS = 'contents',
  OTHER = 'OTHER',
}

// ソートのenum
export enum ContentProductSearchSortTarget {
  PRICE,
  VINTAGE,
  REVIEW,
  MEDIA,
  SIZE,
  ARRIVAL,
}

// 記事の種別
export enum ArticleType {
  Archives = 'special_feature',
  Articles = 'magazine',
  Producers = 'master.producers',
  Tags = 'master.tags',
  Authors = 'master.authors',
  Categories = 'master.magazine_categories',
  Products = 'ec.products',
  Information = 'information',
  PopularKeyword = 'parts.popular_keyword',
  ProductsBottomBanner = 'parts.product_list_bottom_banner',
  TopBanner = 'parts.top_banner',
  HelloBar = 'parts.hello_bar',
  CartFree = 'parts.cart_free',
  Blog = 'blog',
  Lottery = 'ec.raffle',
  MagazineSlider = 'parts.magazine_slider',
  CollegeProducingArea = 'wine_college.producing_area',
  ProducingAreaLesson = 'wine_college.producing_area_lesson',
  ProducingAreaLessonPage = 'wine_college.producing_area_lesson_page',
  BasicLessonChapter = 'wine_college.basic_lesson_chapter',
  BasicLesson = 'wine_college.basic_lesson',
  BasicLessonPage = 'wine_college.basic_lesson_page',
  CollegeQuiz = 'wine_college.quiz',
}
