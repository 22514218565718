import { paths as Paths } from '~/types/api/openapi/consols';

// 存在するメソッドとパスのオブジェクト
type ConsolsMethodPath = {
  [Path in keyof Paths]: {
    [Method in keyof Paths[Path]]: {
      method: Method;
      path: Path;
    };
  }[keyof Paths[Path]];
}[keyof Paths];

// 認証が必要なパスとメソッドの対応
export const consolsAuthPaths: ConsolsMethodPath[] = [
  { method: 'get', path: '/api/v1/user' },
  { method: 'post', path: '/api/v1/user' },
  { method: 'put', path: '/api/v1/user' },
  // @ts-expect-error
  { method: 'delete', path: '/api/v1/user' },
  // @ts-expect-error
  { method: 'get', path: '/api/v1/user/contain-attributes' },
  { method: 'get', path: '/api/v1/profile' },
  { method: 'put', path: '/api/v1/profile' },
  { method: 'post', path: '/api/v1/profile' },
  { method: 'delete', path: '/api/v1/profile' },
  { method: 'post', path: '/api/v1/user/mail/update' },
  { method: 'get', path: '/api/v1/flag' },
  { method: 'put', path: '/api/v1/flag' },
  { method: 'post', path: '/api/v1/flag' },
  { method: 'delete', path: '/api/v1/flag' },
  { method: 'get', path: '/api/v1/mailMagazine' },
  { method: 'put', path: '/api/v1/mailMagazine' },
  { method: 'post', path: '/api/v1/mailMagazine' },
  { method: 'delete', path: '/api/v1/mailMagazine' },
  { method: 'get', path: '/api/v1/cellar' },
  { method: 'put', path: '/api/v1/cellar' },
  { method: 'post', path: '/api/v1/cellar' },
  { method: 'delete', path: '/api/v1/cellar' },
  { method: 'get', path: '/api/v1/other' },
  { method: 'put', path: '/api/v1/other' },
  { method: 'post', path: '/api/v1/other' },
  { method: 'delete', path: '/api/v1/other' },
  { method: 'get', path: '/api/v1/coupons' },
  { method: 'get', path: '/api/v1/coupons/all' },
  { method: 'post', path: '/api/v1/coupons' },
  { method: 'put', path: '/api/v1/coupons' },
  { method: 'delete', path: '/api/v1/coupons' },
  { method: 'get', path: '/api/v1/deliveries' },
  { method: 'get', path: '/api/v1/deliveries/all' },
  { method: 'post', path: '/api/v1/deliveries' },
  { method: 'put', path: '/api/v1/deliveries' },
  { method: 'delete', path: '/api/v1/deliveries' },
  { method: 'get', path: '/api/v1/favorites' },
  { method: 'post', path: '/api/v1/favorites' },
  { method: 'put', path: '/api/v1/favorites' },
  { method: 'get', path: '/api/v1/favorites/all' },
  { method: 'get', path: '/api/v1/multiple-cards' },
  { method: 'get', path: '/api/v1/multiple-cards/all' },
  { method: 'post', path: '/api/v1/multiple-cards' },
  { method: 'put', path: '/api/v1/multiple-cards' },
  { method: 'delete', path: '/api/v1/multiple-cards' },
  { method: 'get', path: '/api/v1/user/info' },
  { method: 'get', path: '/api/v1/point/balance' },
  { method: 'get', path: '/api/v1/point/history' },
  { method: 'post', path: '/api/v1/econ/member/session' },
  { method: 'put', path: '/api/v1/econ/member/session' },
  { method: 'post', path: '/api/v1/econ/member/confirm' },
  { method: 'post', path: '/api/v1/econ/payment/session' },
  { method: 'post', path: '/api/v1/econ/payment/confirm' },
  { method: 'get', path: '/api/v1/point/addup/check' },
  { method: 'get', path: '/api/v1/getuuid' },
  { method: 'post', path: '/api/v1/user/password/update' },
  { method: 'get', path: '/api/v1/user/social/merge' },
  { method: 'get', path: '/api/v1/user/rakuten/merge' },
  { method: 'get', path: '/api/v1/user/amazon/check' },
  { method: 'get', path: '/api/v1/user/amazon/merge' },
  { method: 'get', path: '/api/v1/personal/message' },
  { method: 'get', path: '/api/v2/user/social/merge/{identifier}' },
];
