









































import { defineComponent, useContext } from '@nuxtjs/composition-api';
import { RawLocation } from 'vue-router';
import GlobalFooterMenuCard from '~/components/partials/Global/GlobalFooterMenuCard.vue';
import GlobalFooterMenuOtherLinks from '~/components/partials/Global/GlobalFooterMenuOtherLinks.vue';
import GlobalSNS from '~/components/partials/Global/GlobalSNS.vue';
import { useWOVNio } from '~/composables/wovn/useWOVNio';
import { PRODUCT_CATEGORY_ID, PRODUCT_COLOR_ID, SWEETNESS_OPTIONS } from '~/constants';
import { url } from '~/utils';
import { BooleanLike } from '~/enums';

type MenuLinks = {
  title: string;
  items: {
    title: string;
    link?: RawLocation;
    tag?: string;
  }[];
};

export default defineComponent({
  components: {
    GlobalFooterMenuCard,
    GlobalSNS,
    GlobalFooterMenuOtherLinks,
  },
  setup() {
    const { i18n } = useContext();
    const { targetDisplayLang, updateCurrentLang } = useWOVNio();

    const WINE_TYPE_LINKS: MenuLinks = {
      title: i18n.t('Common.footerMenu.wineLinks.title.type').toString(),
      items: [
        {
          title: i18n.t('Common.footerMenu.wineLinks.wine.red').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _color: PRODUCT_COLOR_ID.RED,
              _qty: BooleanLike.TRUE,
            },
          },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.wine.white').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _color: PRODUCT_COLOR_ID.WHITE,
              _qty: BooleanLike.TRUE,
            },
          },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.wine.sparkling').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _color: [
                PRODUCT_COLOR_ID.SPARKLING_WHITE,
                PRODUCT_COLOR_ID.SPARKLING_ROSE,
                PRODUCT_COLOR_ID.SPARKLING_RED,
              ],
              _qty: BooleanLike.TRUE,
            },
          },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.wine.rose').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _color: PRODUCT_COLOR_ID.ROSE,
              _qty: BooleanLike.TRUE,
            },
          },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.wine.orange').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _color: PRODUCT_COLOR_ID.ORANGE,
              _qty: BooleanLike.TRUE,
            },
          },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.wine.sweet').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _color: [
                PRODUCT_COLOR_ID.RED,
                PRODUCT_COLOR_ID.WHITE,
                PRODUCT_COLOR_ID.ROSE,
                PRODUCT_COLOR_ID.SPARKLING_WHITE,
                PRODUCT_COLOR_ID.SPARKLING_ROSE,
                PRODUCT_COLOR_ID.SPARKLING_RED,
                PRODUCT_COLOR_ID.ORANGE,
              ],
              _sweetness: [
                SWEETNESS_OPTIONS.LITTLE_SWEET.id,
                SWEETNESS_OPTIONS.SWEET.id,
                SWEETNESS_OPTIONS.VERY_SWEET.id,
              ],
              _qty: BooleanLike.TRUE,
            },
          },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.fortifiedWine').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _ctg: PRODUCT_CATEGORY_ID.FORTIFIED_WINE,
              _qty: BooleanLike.TRUE,
            },
          },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.wineSet').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _ctg: PRODUCT_CATEGORY_ID.WINE_SET,
              _qty: BooleanLike.TRUE,
            },
          },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.giftSet').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _ctg: PRODUCT_CATEGORY_ID.GIFT_SET,
              _qty: BooleanLike.TRUE,
            },
          },
        },
      ],
    };

    const BASIC_INFO_LINKS: MenuLinks = {
      title: i18n.t('Common.footerMenu.wineLinks.title.basicInfo').toString(),
      items: [
        {
          title: i18n.t('Common.footerMenu.wineLinks.brand').toString(),
          link: { path: url('WINE_GRAPE') },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.area').toString(),
          link: { path: url('WINE_AREA') },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.producer').toString(),
          link: { path: url('PRODUCER_LIST') },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.vintage').toString(),
          link: { path: url('VINTAGE') },
        },
      ],
    };

    const SIMPLE_SEARCH_LINKS: MenuLinks = {
      title: i18n.t('Common.footerMenu.wineLinks.title.simple').toString(),
      items: [
        {
          title: i18n.t('Common.footerMenu.wineLinks.ranking').toString(),
          link: { path: url('RANKING') },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.food').toString(),
          link: { path: url('FOOD_DIAGNOSIS') },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.scene').toString(),
          link: { path: url('SCENE_LIST') },
        },
        {
          title: i18n.t('Common.footerMenu.wineLinks.keyword').toString(),
          link: { path: url('KEYWORD_LIST') },
        },
        // スタッフレビュー
        {
          title: i18n.t('Common.footerMenu.wineLinks.staffReview').toString(),
          link: { path: url('STAFF_REVIEW_LIST') },
        },
        // 診断
        {
          title: i18n.t('Common.footerMenu.wineLinks.diagnosis').toString(),
          link: { path: url('FEATURE_DIAGNOSIS') },
        },
      ],
    };

    const OTHER_PRODUCTS_LINKS: MenuLinks = {
      title: i18n.t('Common.footerMenu.otherProductsLinks.title').toString(),
      items: [
        {
          title: i18n.t('Common.footerMenu.otherProductsLinks.snack').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _ctg: PRODUCT_CATEGORY_ID.SNACK,
              _qty: BooleanLike.TRUE,
            },
          },
        },
        {
          title: i18n.t('Common.footerMenu.otherProductsLinks.wineGoods').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _ctg: PRODUCT_CATEGORY_ID.WINE_GOODS,
              _qty: BooleanLike.TRUE,
            },
          },
        },
        {
          title: i18n.t('Common.footerMenu.otherProductsLinks.wineCellar').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _ctg: PRODUCT_CATEGORY_ID.WINE_CELLAR,
              _qty: BooleanLike.TRUE,
            },
          },
        },
        {
          title: i18n.t('Common.footerMenu.otherProductsLinks.otherAlcohol').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _ctg: PRODUCT_CATEGORY_ID.ALCOHOL_OTHER,
              _qty: BooleanLike.TRUE,
            },
          },
        },
        {
          title: i18n.t('Common.footerMenu.otherProductsLinks.nonAlcohol').toString(),
          link: {
            path: url('ITEM_LIST'),
            query: {
              _ctg: PRODUCT_CATEGORY_ID.NON_ALCOHOL,
              _qty: BooleanLike.TRUE,
            },
          },
        },
      ],
    };

    const READINGS_LINKS: MenuLinks = {
      title: i18n.t('Common.footerMenu.contents.title').toString(),
      items: [
        {
          title: i18n.t('Common.footerMenu.contents.articles').toString(),
          link: { path: url('ARTICLE_LIST') },
        },
        {
          title: i18n.t('Common.footerMenu.contents.about.odoruwine').toString(),
          link: { path: url('ODORU_WINE') },
        },
        {
          title: i18n.t('Common.footerMenu.contents.college').toString(),
          link: { path: url('COLLEGE_CATEGORY_LIST') },
        },
      ],
    };

    const SERVICE_LINKS: MenuLinks = {
      title: i18n.t('Common.footerMenu.serviceLinks.title').toString(),
      items: [
        {
          title: i18n.t('Common.footerMenu.serviceLinks.club').toString(),
          link: { path: url('CLUB') },
        },
        {
          title: i18n.t('Common.footerMenu.serviceLinks.cellar').toString(),
          link: { path: url('CELLAR') },
        },
        {
          title: i18n.t('Common.footerMenu.serviceLinks.primeur').toString(),
          link: { path: url('PRIMEUR') },
        },
      ],
    };

    const ABOUT_LINKS: MenuLinks = {
      title: i18n.t('Common.footerMenu.aboutLinks.title').toString(),
      items: [
        {
          title: i18n.t('Common.footerMenu.aboutLinks.enoteca').toString(),
          link: { path: url('ABOUT') },
        },
        {
          title: i18n.t('Common.footerMenu.aboutLinks.delivery').toString(),
          link: { path: url('KARTE_GUIDE_ABOUT_SHIPPING') },
          tag: 'a',
        },
        {
          title: i18n.t('Common.footerMenu.aboutLinks.payment').toString(),
          link: { path: url('KARTE_GUIDE_ABOUT_PAYMENT') },
          tag: 'a',
        },
        {
          title: i18n.t('Common.footerMenu.aboutLinks.guide').toString(),
          link: { path: url('KARTE_GUIDE') },
          tag: 'a',
        },
        {
          title: i18n.t('Common.footerMenu.aboutLinks.magazine').toString(),
          link: { path: url('MAGAZINE') },
        },
      ],
    };

    return {
      targetDisplayLang,
      updateCurrentLang,
      SERVICE_LINKS,
      WINE_TYPE_LINKS,
      BASIC_INFO_LINKS,
      SIMPLE_SEARCH_LINKS,
      OTHER_PRODUCTS_LINKS,
      READINGS_LINKS,
      ABOUT_LINKS,
    };
  },
});
