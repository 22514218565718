import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { DISPLAYED_NEWS_NUM, DISPLAYED_SHOP_NEWS_NUM, DISPLAYED_SHOP_LIST_NEWS_NUM } from '~/constants';
import { BaseAxiosAction } from '~/types/api';
import { NewsCard } from '~/types/modules/contents';
import { ArticleType } from '~/enums';

/** State */
export type State = {
  newsList: Array<NewsCard>;
  newsListOfShop: Array<NewsCard>;
  newsListOfAllShop: Array<NewsCard>;
};

export const state = (): State => ({
  newsList: [],
  newsListOfShop: [],
  newsListOfAllShop: [],
});

/** Getters */
export const getters = {
  // お知らせ一覧用のお知らせ
  getLimitedNewsList: (state: State) => (limit: number) => {
    return state.newsList.slice(0, DISPLAYED_NEWS_NUM * limit);
  },
  // 拠点詳細用のお知らせ
  getLimitedShopNewsList: (state: State) => (limit: number) => {
    return state.newsListOfShop.slice(0, DISPLAYED_SHOP_NEWS_NUM * limit);
  },
  // 拠点一覧用のお知らせ
  getLimitedAllShopNewsList: (state: State) => (limit: number) => {
    // 拠点一覧は初期描画時が2件なので、その分を足して取得する
    return state.newsListOfAllShop.slice(2, DISPLAYED_SHOP_LIST_NEWS_NUM * limit + 2);
  },
};

/** Mutations */
export const mutations = {
  setNewsList(state: State, newsList: Array<NewsCard>) {
    state.newsList = state.newsList.concat(newsList);
  },
  setNewsListOfShop(state: State, newsListOfShop: Array<NewsCard>) {
    state.newsListOfShop = state.newsListOfShop.concat(newsListOfShop);
  },
  setNewsListOfAllShop(state: State, newsListOfShop: Array<NewsCard>) {
    state.newsListOfAllShop = state.newsListOfAllShop.concat(newsListOfShop);
  },
  resetNewsList(state: State) {
    state.newsList = [];
  },
  resetShopNewsList(state: State) {
    state.newsListOfShop = [];
  },
  resetAllShopNewsList(state: State) {
    state.newsListOfAllShop = [];
  },
};

/** Actions */
export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;
export const actions = {
  // お知らせ一覧取得
  async fetchNews(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ limit?: number; offset?: number; startDate?: string; endDate?: string }>
  ) {
    const { cancelToken, limit, offset, startDate, endDate } = payload;
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Information,
        '_order[DESC]': 'publish_at',
        'list_display_flag[bool][eq]': true,
        _limit: limit,
        _offset: offset,
        '_publish_at[ge]': startDate,
        '_publish_at[le]': endDate,
      },
    });
    const news = result.map((item: any) => ({
      id: item?.article_id,
      link: item?.permalink,
      category: item?.contents.category,
      title: item?.title,
      publish_at: item?.publish_at,
      display_destination: item?.contents.display_destination,
    }));
    commit('setNewsList', news);
  },

  // お知らせ記事最古公開日取得
  async fetchNewsPublishYear(this: Vue, context: Ctx, payload: BaseAxiosAction<{}>): Promise<string> {
    const { cancelToken } = payload;
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Information,
        '_order[ASC]': 'publish_at',
        _limit: 1,
      },
    });
    return result[0].publish_at;
  },

  // お知らせ記事詳細取得
  async fetchNewsDetail(this: Vue, context: Ctx, payload: BaseAxiosAction<{ permalink: string }>): Promise<any> {
    const { cancelToken, permalink } = payload;
    const result = await this.$cmsAxios.$get('/view_articles/published', {
      cancelToken,
      query: {
        article_type: ArticleType.Information,
        permalink,
        lang_code: 'ja',
      },
    });

    return result;
  },

  // 店舗に紐付くお知らせ一覧取得
  async fetchNewsOfShop(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ limit?: number; offset?: number; shopIds: number[] }>
  ) {
    const { cancelToken, limit, offset, shopIds } = payload;
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': ArticleType.Information,
        '_order[DESC]': 'publish_at',
        'display_destination[array][contains]': '[' + shopIds + ']',
        _limit: limit,
        _offset: offset,
      },
    });
    const news = result.map((item: any) => ({
      id: item?.article_id,
      link: item?.permalink,
      category: item?.contents.category,
      title: item?.title,
      publish_at: item?.publish_at,
      display_destination: item?.contents.display_destination,
    }));
    commit('setNewsListOfShop', news);
  },

  // 店舗情報のお知らせを一覧取得
  async fetchNewsOfAllShop(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ limit?: number; offset?: number }>) {
    const { cancelToken, limit, offset } = payload;
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      // カテゴリが店舗情報のものを取得
      query: {
        '_article_type[eq]': ArticleType.Information,
        '_order[DESC]': 'publish_at',
        'shop_list_display_flag[bool][eq]': true,
        _limit: limit,
        _offset: offset,
      },
    });
    const news = result.map((item: any) => ({
      id: item?.article_id,
      link: item?.permalink,
      category: item?.contents.category,
      title: item?.title,
      publish_at: item?.publish_at,
      display_destination: item?.contents.display_destination,
    }));
    commit('setNewsListOfAllShop', news);
  },
};

/** Store Module Type */
export type Store = DefineStoreModule<'modules/news', State, typeof getters, typeof mutations, typeof actions>;
