

























import { defineComponent, nextTick, PropType, ref, watch } from '@nuxtjs/composition-api';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import AppLink from '~/components/partials/App/AppLink.vue';
import { BreadcrumbsItem } from '~/types/ui';

export default defineComponent({
  components: {
    AppIcon,
    AppLink,
  },
  props: {
    items: {
      type: Array as PropType<BreadcrumbsItem[]>,
      default: null,
    },
  },
  setup(props) {
    const navRef = ref<HTMLElement>();
    const ulRef = ref<HTMLElement>();
    const gradationRef = ref<HTMLElement>();

    watch(
      () => props.items,
      () => {
        // スクロールする必要のときに文字にグラデーションをつける
        nextTick(() => {
          if ((navRef.value?.clientWidth || 0) < (ulRef.value?.scrollWidth || 0)) {
            gradationRef.value?.classList.add('breadcrmbs-background');
            // 最後の要素がグラデーションにより見ずらいのでスクロールスペースをちょっと追加する
            const lastTitleEle = document.getElementById(`title-${props.items.length - 1}`);
            lastTitleEle?.classList.add('pr-16');
          }
        });
      },
      { immediate: true }
    );

    return { navRef, ulRef, gradationRef };
  },
});
