import { MagazineFormOption } from '~/types/modules/magazine';
import { FormSelectOption } from '~/types/ui';
export const MAGAZINE_OPTIONS: FormSelectOption[] = [
  // FIXME: value を実際の値に置き換える
  { value: 'yes', text: 'メールマガジンを購読する' },
  { value: 'no', text: 'メールマガジンを購読しない' },
];

export const NOTICE_OPTIONS: FormSelectOption[] = [
  // FIXME: value を実際の値に置き換える
  { value: 'yes', text: '受信する' },
  { value: 'no', text: '受信しない' },
];

export const DM_OPTIONS: FormSelectOption[] = [
  // FIXME: value を実際の値に置き換える
  { value: 'no', text: '希望しない' },
  { value: 'yes', text: '希望する' },
];

export const MAGAZINE_CONTENTS_OPTIONS: MagazineFormOption[] = [
  {
    value: 'sale',
    title: '新着セール情報（毎日）',
    subtitle: '新商品やキャンペーン、会員様限定セールなど',
  },
  {
    value: 'read',
    title: '読み物 （週1回程度）',
    subtitle: 'ワインのトレンドや料理とのマリアージュなどワインを楽しむコラム',
  },
  {
    value: 'event',
    title: 'イベント情報 （週1回程度）',
    subtitle: '生産者来日情報や試飲イベント',
  },
  {
    value: 'digest',
    title: 'メルマガダイジェスト （週1回程度）',
    subtitle: '1週間のメルマガ情報をまとめてお届け',
  },
];
