export const FOOD_TYPES = [
  {
    ja: '牛肉',
    en: 'beef',
  },
  {
    ja: '豚肉',
    en: 'pork',
  },
  {
    ja: '鶏肉',
    en: 'chicken',
  },
  {
    ja: 'その他肉・内臓系',
    en: 'other_meat',
  },
  {
    ja: '赤身魚',
    en: 'red_fleshed_fish',
  },
  {
    ja: '白身魚',
    en: 'white_fleshed_fish',
  },
  {
    ja: 'エビ・カニ',
    en: 'shrimp_and_crabs',
  },
  {
    ja: '貝類',
    en: 'shellfish',
  },
  {
    ja: 'パスタ・麺・ご飯類',
    en: 'noodles_and_rice',
  },
  {
    ja: '野菜・きのこ',
    en: 'vegetables_and_mushroom',
  },
];

export const WINE_COLORS = [
  {
    text: '赤ワイン',
    value: 'red',
  },
  {
    text: '白ワイン',
    value: 'white',
  },
  {
    text: 'ロゼワイン',
    value: 'rose',
  },
];

export const PRICE_RANGES = [
  {
    text: '2000円台まで',
    value: 'under3000',
  },
  {
    text: '3000円以上',
    value: 'over3000',
  },
];
