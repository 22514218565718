export enum ClubEnotecaType {
  NONE = 'none',
  CLASSIC = 'classic',
  GOLD = 'gold',
}

export enum MemberRank {
  REGULAR = 'RNK00001',
  PREMIUM = 'RNK00002',
  // FIXME:2021年末まで必要、それ以降はプレミアムに振り分けられる
  THREE_STARS = 'RNK00003',
}

export enum FavoriteTypes {
  ITEM = 'item',
  PRODUCER = 'producer',
  ARTICLE = 'article',
}
