import { ValidationRule } from 'vee-validate/dist/types/types';
import FORBIDDEN_CHARACTER from '~/assets/data/characters/FORBIDDEN_CHARACTER.json';
import NEW_LINE_CHARACTERS from '~/assets/data/characters/NEW_LINE_CHARACTERS.json';
import NONSTANDARD_CHARACTER from '~/assets/data/characters/NONSTANDARD_CHARACTER.json';
import OLD_CHARACTER from '~/assets/data/characters/OLD_CHARACTERS.json';
import PERMITTED_CHARACTER from '~/assets/data/characters/PERMITTED_CHARACTER.json';
import PLATFORM_DEPENDENT_CHARACTER from '~/assets/data/characters/PLATFORM_DEPENDENT_CHARACTER.json';
import SPECIFIED_PERMITTED_CHARACTER from '~/assets/data/characters/SPECIFIED_PERMITTED_CHARACTER.json';

const SHIFT_JIS_ENCODING = 'Shift-JIS';
const CONVERTIBLE_CHARACTERS = {
  ...OLD_CHARACTER,
  ...NONSTANDARD_CHARACTER,
  ...PLATFORM_DEPENDENT_CHARACTER,
  ...FORBIDDEN_CHARACTER,
};

// 16進数のUTF-16コードユニットを取得し、適合する禁則文字があるか判定する
const convertibleChar = (char: String) =>
  CONVERTIBLE_CHARACTERS[char.charCodeAt(0).toString(16) as keyof typeof CONVERTIBLE_CHARACTERS];
const permittedChar = (char: String) =>
  PERMITTED_CHARACTER[char.charCodeAt(0).toString(16) as keyof typeof PERMITTED_CHARACTER] !== undefined ||
  NEW_LINE_CHARACTERS.list.includes(char.toString());
// 適合する禁則文字判定(のし・メッセージのルール)
const convertibleCharWithoutSpecifiedPermittedChar = (char: String) =>
  CONVERTIBLE_CHARACTERS[char.charCodeAt(0).toString(16) as keyof typeof CONVERTIBLE_CHARACTERS] !== undefined &&
  SPECIFIED_PERMITTED_CHARACTER[char.charCodeAt(0).toString(16) as keyof typeof SPECIFIED_PERMITTED_CHARACTER] ===
    undefined;
const permittedCharAndSpecifiedPermittedChar = (char: String) =>
  PERMITTED_CHARACTER[char.charCodeAt(0).toString(16) as keyof typeof PERMITTED_CHARACTER] !== undefined ||
  SPECIFIED_PERMITTED_CHARACTER[char.charCodeAt(0).toString(16) as keyof typeof SPECIFIED_PERMITTED_CHARACTER] !==
    undefined ||
  NEW_LINE_CHARACTERS.list.includes(char.toString());

/**
 * Shift_JIS で利用できる文字のみが使用されているかチェックする
 */
export const shiftjis: ValidationRule = {
  message: '使用できない文字列（機種依存文字等）が含まれています',
  async validate(value) {
    if (typeof value !== 'string') {
      return false;
    }

    const chars: Array<String> = Array.from(value);
    // 許可文字に入っていれば通す
    if (chars.every(permittedChar)) {
      return true;
    }

    const {
      encode,
      decode,
      // @ts-expect-error
      defaultCharSingleByte,
    } = await import('iconv-lite');

    return value.split('').every((char) => {
      if (char === defaultCharSingleByte) {
        return true;
      }

      const encoded = encode(char, SHIFT_JIS_ENCODING);
      const decoded = decode(encoded, SHIFT_JIS_ENCODING);

      return decoded === char;
    });
  },
};

/**
 * カタカナのみかをチェックする
 */
export const katakana: ValidationRule = {
  message: '全角カナで入力してください',
  validate: (value) => {
    if (typeof value !== 'string') {
      return false;
    }

    return /^[ァ-ヶー　]*$/.test(value);
  },
};

/**
 * 半角のみかをチェックする
 */
export const hankaku: ValidationRule = {
  message: '半角文字で入力してください',
  validate: (value) => {
    if (typeof value !== 'string') {
      return false;
    }

    return /^[ -~]+$/.test(value);
  },
};

/**
 * 全角のみかをチェックする
 */
export const zenkaku: ValidationRule = {
  message: '全角文字で入力してください',
  validate: (value) => {
    if (typeof value !== 'string') {
      return false;
    }

    // 半角文字が含まれていないかチェック
    return !/[ -~]/.test(value);
  },
};

/**
 * 指定された byte 以内をチェック
 * https://zukucode.com/2017/04/javascript-string-length.html
 */
export const maxBytes: ValidationRule = {
  params: ['max'],
  message: (field, { max }) => {
    return `${field}は全角${max / 2} 文字（半角${max}文字）以内で指定してください`;
  },
  // @ts-expect-error
  validate(value, { max }) {
    let length = 0;
    for (let i = 0; i < value.length; i++) {
      const c = value.charCodeAt(i);
      if ((c >= 0x0 && c < 0x81) || c === 0xf8f0 || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
        length += 1;
      } else {
        length += 2;
      }
    }
    return length <= max;
  },
};

/**
 * 変換可能な禁則文字が使用されているかチェックする
 */
export const convertible: ValidationRule = {
  message: '使用できない文字列（機種依存文字等）が含まれています',
  validate: (value) => {
    const chars: Array<String> = Array.from(value);
    return !chars.some(convertibleChar) && chars.every(permittedChar);
  },
};

/**
 * のし・メッセージのルールで禁則文字が使用されているかチェックする
 */
export const convertibleNoshiMessage: ValidationRule = {
  message: '使用できない文字列（機種依存文字等）が含まれています',
  validate: (value) => {
    const chars: Array<String> = Array.from(value);
    return (
      !chars.some(convertibleCharWithoutSpecifiedPermittedChar) && chars.every(permittedCharAndSpecifiedPermittedChar)
    );
  },
};

/**
 * 値が0でないかチェックする
 */
export const notZero: ValidationRule = {
  message: (field) => `${field}を入力してください`,
  validate: (value) => {
    return !isNaN(value) && value !== 0;
  },
};
