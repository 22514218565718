import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { RuntimeConfig } from '~/composables/useRuntimeConfig';
import { isEnotecaApp } from '~/utils';

export default defineNuxtPlugin(async ({ $axios, app, ...context }) => {
  const runtimeConfig = context.$config as RuntimeConfig;
  const { OPTIMIZED_COOKIE_DOMAIN } = runtimeConfig;

  // ローカル開発環境では動かさない
  if (!OPTIMIZED_COOKIE_DOMAIN) {
    return;
  }
  // アプリの時は動かさない
  if (isEnotecaApp(app.$ua.original())) {
    return;
  }
  try {
    // サブドメインのcookieを設定
    await $axios.$post('/_api/v1/cookie-optimizer/0');
    // デフォルトドメインのcookieを削除
    await $axios.$post('/_api/v1/cookie-optimizer/1');
  } catch {
    // エラーが発生してもなにもしない
  }
});
