import { DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';

export type State = {
  profile: {
    loginId?: string;
    firstName?: string;
    lastName?: string;
    firstNameKana?: string;
    lastNameKana?: string;
    gender?: string;
    birthday?: string;
    phoneNumber?: string;
    zipCode?: string;
    prefecture?: number;
    city?: string;
    streetAddress?: string;
  } | null;
};

export const state = (): State => ({
  profile: null,
});

export const mutations = {
  setProfile(state: State, payload: typeof state.profile) {
    state.profile = payload;
  },
};

export type Store = DefineStoreModule<'modules/rakuten', State, never, typeof mutations, never>;
