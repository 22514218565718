// APIレスポンスからフロント表示への変換
export const CART_NAME: Record<string, string> = {
  general: '一般',
  primeur: 'プリムール',
  wine_cellar: 'ワインセラー',
  rental_cellar_pack: 'レンタルセラーパック',
  presale_beaujolais: '先行販売・ボジョレー',
};

/** レンタルセラーパック以外の商品を一度に購入できる上限個数 */
export const MAX_ITEM_TOTAL = 48;
/** レンタルセラーパックを一度に購入できる上限個数 */
export const MAX_RENTAL_CELLAR_ITEM_TOTAL = 10;

/** お気に入り商品の表示数の上限 */
export const MAX_DISPLAY_FAVORITE_ITEM = 12;

/** 遷移前の画面 */
export const PREV_ROUTE = {
  ITEM_LIST: '/item/list',
  ARCHIVES_DETAIL: '/archives/detail',
  PRODUCER_DETAIL: '/producer/detail',
};

/**
 * 割引項目名によって処理を分岐させる際に使用する固定値
 * HACK: シミュレーションAPIのレスポンスcart_discountsにあるnameでしか判別できない場合に使用しています
 */
export const DISCOUNT_NAME = {
  FIRST_TIME: '初回購入特典：送料無料',
};
