import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { SEARCH_LIMIT_ROWS } from '~/constants';
import { BaseAxiosAction } from '~/types/api';

export type Ctx = DefineActionContext<never, never, never>;

export const actions = {
  async fetchTagList(this: Vue, context: Ctx, payload?: BaseAxiosAction<{ query: string }>) {
    const {
      response: { docs },
    } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:tag2${payload?.query ? ` AND ${payload.query}` : ''}`,
        sort: 'tag2_display_order_i asc',
        rows: SEARCH_LIMIT_ROWS,
      },
    });
    return docs;
  },

  // TOP画面のキーワードアコーディオン用
  async fetchKeywordList(this: Vue, context: Ctx, payload: BaseAxiosAction): Promise<string[]> {
    const { response } = await this.$searchAxios.$get('/select', {
      ...payload,
      query: {
        wt: 'json',
        q: 'mst:tag2',
        fl: ['tag2_name_s'],
        sort: 'tag2_display_order_i asc',
        rows: SEARCH_LIMIT_ROWS,
      },
    });
    return response?.docs.map((item: any) => item.tag2_name_s) || [];
  },
};

export type Store = DefineStoreModule<'modules/tagList', never, never, never, typeof actions>;
