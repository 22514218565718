import { computed, useContext, useRoute, useRouter } from '@nuxtjs/composition-api';
import { cloneDeep, uniq, castArray, range } from 'lodash-es';
import { useStore } from '~/composables/useStore';
import {
  PRODUCT_CATEGORY_ID,
  PRODUCT_COLOR_ID,
  PRODUCT_PARAM_QUERIES,
  SPARKLINGS,
  STYLE_INFO_LIST,
  PRODUCT_SIZE_LABELS,
  PRODUCT_PARENT_CATEGORY_ID,
} from '~/constants';
import { arrayify, nonNullable, PAGE_URL, url } from '~/utils';
import { ProductSearchSortTarget, ProductParentCategory, ProductSearchSortOrder, ProductCategoryId } from '~/enums';
import type { Route } from 'vue-router';

export const useProductParams = () => {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const { redirect } = useContext();

  const pickSingleValue = (key: string, foodDiagnosisParams?: URLSearchParams) => {
    // foodDiagnosisParamsは料理診断のみ使用
    if (foodDiagnosisParams) {
      return foodDiagnosisParams.get(key);
    }

    return arrayify(route.value.query[key])[0] || null;
  };

  const pickMultipleValue = (key: string, foodDiagnosisParams?: URLSearchParams) => {
    // foodDiagnosisParamsは料理診断のみ使用
    if (foodDiagnosisParams) {
      return foodDiagnosisParams.getAll(key);
    }

    let sortedList = arrayify(route.value.query[key])
      .filter((id) => (key === PRODUCT_PARAM_QUERIES.CATEGORY ? id !== PRODUCT_CATEGORY_ID.WINE : id))
      .filter(nonNullable)
      .sort((a: any, b: any) => a - b) as string[];
    if (key !== PRODUCT_PARAM_QUERIES.CATEGORY) return sortedList;

    // 現状①その他アルコール > シードル/甘味果実酒 の場合、②その他飲料 > ミネラルウォーター の場合、カテゴリid親が子より大きいので、親idを先頭にする
    if (sortedList.includes(ProductParentCategory.OTHER) && sortedList[0] !== ProductParentCategory.OTHER) {
      sortedList.unshift(ProductParentCategory.OTHER);
      sortedList = uniq(sortedList);
    }
    if (sortedList.includes(ProductParentCategory.NON_ALCOHOL) && sortedList[0] !== ProductParentCategory.NON_ALCOHOL) {
      sortedList.unshift(ProductParentCategory.NON_ALCOHOL);
      sortedList = uniq(sortedList);
    }
    return sortedList;
  };

  const handleParamformat = (param: string, enableHyphen?: boolean, isWineSize?: boolean) => {
    // ハイフンが入る場合はそのまま返す
    if (enableHyphen && param?.includes('-')) return param;

    // ワインセラー以外のサイズは375・750・1500のみ返す
    if (isWineSize)
      return [PRODUCT_SIZE_LABELS.LARGE.id, PRODUCT_SIZE_LABELS.MEDIUM.id, PRODUCT_SIZE_LABELS.SMALL.id].includes(param)
        ? param
        : undefined;

    return !isNaN(+param) && +param >= 0;
  };

  // 数値型かどうか判定
  const checkTypeParam = (params?: string | string[], enableHyphen?: boolean, isWineSize?: boolean) => {
    // 複数パラメータの場合
    if (Array.isArray(params)) {
      return params.filter((param) => handleParamformat(param, enableHyphen, isWineSize));
    }

    return params && handleParamformat(params, enableHyphen, isWineSize) ? params : undefined;
  };

  // 色id配列に5/6/7が入ってる場合は、それを除外して代わりに99を入れる
  const pickColorValue = (values: string[]) => {
    if (values.length > 0 && values.some((value) => SPARKLINGS.includes(value))) {
      const state = values.filter((v) => !SPARKLINGS.includes(v));
      return [...state, PRODUCT_COLOR_ID.SPARKLING];
    }
    return values;
  };

  const pickSortState = () => {
    const sort = [
      PRODUCT_PARAM_QUERIES.SORT_VINTAGE,
      PRODUCT_PARAM_QUERIES.SORT_SIZE,
      PRODUCT_PARAM_QUERIES.SORT_PRICE,
      PRODUCT_PARAM_QUERIES.SORT_ARRIVAL,
      PRODUCT_PARAM_QUERIES.SORT_REVIEW,
      PRODUCT_PARAM_QUERIES.SORT_MEDIA,
    ]
      .map((key) => [key, route.value.query[key]])
      .find(
        (option): option is string[] =>
          option[1] === ProductSearchSortOrder.ASC || option[1] === ProductSearchSortOrder.DESC
      );

    if (!sort) {
      return {};
    }

    const [key, order] = sort;
    const target =
      key === PRODUCT_PARAM_QUERIES.SORT_VINTAGE
        ? ProductSearchSortTarget.VINTAGE
        : key === PRODUCT_PARAM_QUERIES.SORT_SIZE
        ? ProductSearchSortTarget.SIZE
        : key === PRODUCT_PARAM_QUERIES.SORT_PRICE
        ? ProductSearchSortTarget.PRICE
        : key === PRODUCT_PARAM_QUERIES.SORT_ARRIVAL
        ? ProductSearchSortTarget.ARRIVAL
        : key === PRODUCT_PARAM_QUERIES.SORT_REVIEW
        ? ProductSearchSortTarget.REVIEW
        : key === PRODUCT_PARAM_QUERIES.SORT_MEDIA
        ? ProductSearchSortTarget.MEDIA
        : '';

    return {
      sortTarget: target,
      sortOrder: order,
    };
  };

  /**
   * 発生するケース：
   * ①直リンク、URL直打ち
   * ②watch query発火のケース（商品一覧内部の画面遷移）
   */
  const handleIrregularCases = async (queries: Record<string, any>) => {
    // _ctg=1 が付与されている場合にすべてのワインタイプを付与して _ctg=1 を除去する
    const categories = castArray(queries[PRODUCT_PARAM_QUERIES.CATEGORY]);
    if (categories.includes(PRODUCT_CATEGORY_ID.WINE)) {
      const filteredCategories = categories.filter((ctg) => ctg !== PRODUCT_CATEGORY_ID.WINE);

      if (filteredCategories.length === 0) {
        delete queries[PRODUCT_PARAM_QUERIES.CATEGORY];
      } else {
        queries[PRODUCT_PARAM_QUERIES.CATEGORY] = filteredCategories;
      }
    }

    // ↓スタイル周りのurl直打ちの対応↓
    // NOTE：スパークリングの場合を考慮して、99の変換処理の前に配置する
    const styleIds = castArray(queries[PRODUCT_PARAM_QUERIES.STYLE]).filter(nonNullable);
    // _styleあり_colorなしの処理後は色が必ず存在して、不必要なのにミスマッチ処理に入っちゃうので、初期状態の値を取得して1と2を分ける
    let colorIds1 = castArray(queries[PRODUCT_PARAM_QUERIES.COLOR]).filter(nonNullable);
    const colorIds2 = castArray(queries[PRODUCT_PARAM_QUERIES.COLOR]).filter(nonNullable);
    // _styleあり_colorなしの場合、紐づく_colorを追加
    if (styleIds.length > 0 && colorIds1.length === 0) {
      STYLE_INFO_LIST.forEach((styleInfo) => {
        styleInfo.styles.forEach((style) => {
          if (styleIds.includes(style.style_id.toString())) {
            colorIds1.push(styleInfo.colorId);
          }
        });
      });
      colorIds1 = uniq(colorIds1);
      if (colorIds1.length > 1) {
        redirect(url('ITEM_LIST')); // 色跨ぐ場合は相当意図的なのでデフォルトの一覧に飛ばす
      } else {
        queries[PRODUCT_PARAM_QUERIES.COLOR] = colorIds1;
      }
    }
    // _styleと_colorがミスマッチの場合、_styleを削除
    if (styleIds.length > 0 && colorIds2.length > 0) {
      // _style=5/6/7の場合、99に変更する（定数リストを参照するため）
      const sparklingIds = [
        PRODUCT_COLOR_ID.SPARKLING_WHITE,
        PRODUCT_COLOR_ID.SPARKLING_ROSE,
        PRODUCT_COLOR_ID.SPARKLING_RED,
      ];
      if (colorIds2.some((colorId) => sparklingIds.includes(colorId))) {
        colorIds2.push(PRODUCT_COLOR_ID.SPARKLING);
        colorIds2.filter((colorId) => !sparklingIds.includes(colorId));
      }
      STYLE_INFO_LIST.some((styleInfo) => {
        if (colorIds2.includes(styleInfo.colorId)) {
          if (!styleInfo.styles.some((style) => styleIds.includes(style.style_id.toString()))) {
            delete queries[PRODUCT_PARAM_QUERIES.STYLE];
            return true;
          }
          return false;
        }
        return false;
      });
    }
    // ↑スタイル周りのurl直打ちの対応↑

    // _color=12（甘口） を _sweetness 1〜3 に置き換える
    const colors = castArray(queries[PRODUCT_PARAM_QUERIES.COLOR]);
    if (colors.includes(PRODUCT_COLOR_ID.SWEET)) {
      // _sweetness に 1,2,3 を追加する
      const sweetnesses = castArray(queries[PRODUCT_PARAM_QUERIES.SWEETNESS]).filter(nonNullable);

      queries[PRODUCT_PARAM_QUERIES.SWEETNESS] = sweetnesses.concat(['1', '2', '3']);

      // _color=12 を取り除く
      const filteredColors = colors.filter((color) => color !== PRODUCT_COLOR_ID.SWEET);

      if (filteredColors.length === 0) {
        delete queries[PRODUCT_PARAM_QUERIES.COLOR];
      } else {
        queries[PRODUCT_PARAM_QUERIES.COLOR] = filteredColors;
      }
    }

    // SEOの評価が分散してしまうためc_color=99（ワイン以外） を _color 5〜7 に置き換える
    if (colors.includes(PRODUCT_COLOR_ID.SPARKLING)) {
      queries[PRODUCT_PARAM_QUERIES.COLOR] = [
        PRODUCT_COLOR_ID.SPARKLING_WHITE,
        PRODUCT_COLOR_ID.SPARKLING_ROSE,
        PRODUCT_COLOR_ID.SPARKLING_RED,
      ];
    }

    // _vintage1, _vintage2 を _vintage に置き換える
    const getVintageRange = (): string[] => {
      const vintage1 = Number.parseInt(castArray(queries[PRODUCT_PARAM_QUERIES.VINTAGE1])[0], 10);
      const vintage2 = Number.parseInt(castArray(queries[PRODUCT_PARAM_QUERIES.VINTAGE2])[0], 10);

      // 100 年以上が指定されたら展開しない
      if (!vintage1 || !vintage2 || vintage2 - vintage1 > 100) {
        return [];
      }

      return range(vintage1, vintage2 + 1).map(String);
    };
    const vintages = uniq([...castArray(queries[PRODUCT_PARAM_QUERIES.VINTAGE]), ...getVintageRange()]).filter(
      nonNullable
    );
    delete queries[PRODUCT_PARAM_QUERIES.VINTAGE1];
    delete queries[PRODUCT_PARAM_QUERIES.VINTAGE2];
    if (vintages.length > 0) {
      queries[PRODUCT_PARAM_QUERIES.VINTAGE] = vintages;
    }

    // _brand を _variety に置き換える
    const brand = queries._brand;
    if (brand) {
      queries[PRODUCT_PARAM_QUERIES.VARIETY] = brand;
      delete queries._brand;
    }

    // 子品種idを親品種idに置き換える（直リンク、url直打ちの対応）
    const varieties = castArray(queries[PRODUCT_PARAM_QUERIES.VARIETY]).filter(nonNullable);
    if (varieties.length > 0) {
      const parentVarieties = await store.dispatch('product/master/fetchParentVarieties', {
        ids: varieties,
      });
      queries[PRODUCT_PARAM_QUERIES.VARIETY] = parentVarieties;
    }

    // ↓子_ctg単数の直リンク、url直打ちの対応↓
    const categoriesArr = castArray(queries[PRODUCT_PARAM_QUERIES.CATEGORY]).filter(nonNullable);
    // 先頭_ctgが親じゃない場合、商品カテゴリマスタマスタから親カテゴリidを取得
    if (categoriesArr.length && !Object.values(PRODUCT_PARENT_CATEGORY_ID).includes(categoriesArr[0])) {
      const parentCategoryId = await store.dispatch('product/master/fetchParentCategoryId', {
        childId: categoriesArr[0],
      });
      /**
       * _ctgクエリが不正の場合はそのまま保留（結果0件を表示）
       * 親カテゴリ取得できた場合はクエリ先頭に追加する（酒精強化ワインの親はワインなので除外する）
       */
      if (parentCategoryId) {
        if (!Object.values(PRODUCT_PARENT_CATEGORY_ID).includes(parentCategoryId.toString())) {
          // 本番は孫カテゴリが２件あるため、一番上の親カテゴリを取得するように
          const upperParentCategoryId = await store.dispatch('product/master/fetchParentCategoryId', {
            childId: parentCategoryId,
          });
          if (upperParentCategoryId) {
            categoriesArr.unshift(upperParentCategoryId.toString());
            queries[PRODUCT_PARAM_QUERIES.CATEGORY] = categoriesArr;
          }
        } else if (parentCategoryId.toString() !== PRODUCT_PARENT_CATEGORY_ID.WINE) {
          categoriesArr.unshift(parentCategoryId.toString());
          queries[PRODUCT_PARAM_QUERIES.CATEGORY] = categoriesArr;
        }
      }
    }
    // ↑子_ctg単数の直リンク、url直打ちの対応↑
  };

  // クエリパラメータを正規化して返す
  const normalizeQueries = async (
    originalQueries: Record<string, any>,
    isForRedirect = false
  ): Promise<Route['query']> => {
    const queries = cloneDeep(originalQueries);
    // 除去対象のクエリパラメータ
    const omitTargetKeys = Object.entries(queries).filter(([key, value]) => {
      return (
        // 新ECで使用していないクエリ
        [
          '_restock',
          '_limited',
          '_scene',
          '_point',
          '_reset',
          'itemtype',
          'page',
          '_itemgroup',
          'searchtype',
          'vp1',
          'vp2',
          'x',
          'y',
          '_reset',
          '_url_brand',
          'p_',
        ].includes(key) ||
        // 値が存在しない _ から始まるクエリ
        (key.startsWith('_') && !value)
      );
    });

    omitTargetKeys.forEach(([key]) => {
      delete queries[key];
    });

    // クエリの値を小さい順に並び替える
    Object.entries(queries).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        queries[key] = uniq(value.slice().sort((a: any, b: any) => a - b));
      }
    });

    if (isForRedirect) {
      await handleIrregularCases(queries);
    }

    /**
     * その他アルコール > シードル or 甘味果実酒 の場合
     * クエリが昇順になり検索結果が不整合となるため、その他アルコール(ctg=34)が親カテゴリになるよう先頭に差し込む
     */
    if (
      Array.isArray(queries[PRODUCT_PARAM_QUERIES.CATEGORY]) &&
      queries[PRODUCT_PARAM_QUERIES.CATEGORY].find((id: string) => id === ProductParentCategory.OTHER) &&
      queries[PRODUCT_PARAM_QUERIES.CATEGORY].find(
        (id: string) => id === ProductCategoryId.CIDER.toString() || id === ProductCategoryId.FRUIT_WINE.toString()
      )
    ) {
      queries[PRODUCT_PARAM_QUERIES.CATEGORY].unshift(ProductParentCategory.OTHER);
      queries[PRODUCT_PARAM_QUERIES.CATEGORY] = uniq(queries[PRODUCT_PARAM_QUERIES.CATEGORY]);
    }
    /**
     * その他飲料 > ミネラルウォーター の場合
     * クエリが昇順になり検索結果が不整合となるため、その他飲料(ctg=29)が親カテゴリになるよう先頭に差し込む
     */
    if (
      Array.isArray(queries[PRODUCT_PARAM_QUERIES.CATEGORY]) &&
      queries[PRODUCT_PARAM_QUERIES.CATEGORY].find((id: string) => id === ProductParentCategory.NON_ALCOHOL) &&
      queries[PRODUCT_PARAM_QUERIES.CATEGORY].find((id: string) => id === ProductCategoryId.MINERAL_WATER.toString())
    ) {
      queries[PRODUCT_PARAM_QUERIES.CATEGORY].unshift(ProductParentCategory.NON_ALCOHOL);
      queries[PRODUCT_PARAM_QUERIES.CATEGORY] = uniq(queries[PRODUCT_PARAM_QUERIES.CATEGORY]);
    }

    /* eslint-disable @typescript-eslint/naming-convention */
    const {
      [PRODUCT_PARAM_QUERIES.CATEGORY]: _category,
      [PRODUCT_PARAM_QUERIES.COLOR]: _color,
      [PRODUCT_PARAM_QUERIES.STYLE]: _style,
      [PRODUCT_PARAM_QUERIES.AREA]: _area,
      [PRODUCT_PARAM_QUERIES.SWEETNESS]: _sweetnesses,
      [PRODUCT_PARAM_QUERIES.VARIETY]: _variety,
      [PRODUCT_PARAM_QUERIES.MEDIA]: _media,
      [PRODUCT_PARAM_QUERIES.PRODUCER]: _producer,
      [PRODUCT_PARAM_QUERIES.VINTAGE]: _vintages,
      [PRODUCT_PARAM_QUERIES.VINTAGE_INFO]: _vintageInfo,
      [PRODUCT_PARAM_QUERIES.WORD]: _word,
      [PRODUCT_PARAM_QUERIES.SIZE]: _size,
      [PRODUCT_PARAM_QUERIES.NEW]: _new,
      [PRODUCT_PARAM_QUERIES.REVIEW_POINT]: _reviewPoint,
      [PRODUCT_PARAM_QUERIES.FREE_SHIPPING]: _freeShipping,
      [PRODUCT_PARAM_QUERIES.PRICE_1]: _price1,
      [PRODUCT_PARAM_QUERIES.PRICE_2]: _price2,
      [PRODUCT_PARAM_QUERIES.DISCOUNT]: _discount,
      [PRODUCT_PARAM_QUERIES.TASTE_1]: _taste1,
      [PRODUCT_PARAM_QUERIES.TASTE_2]: _taste2,
      [PRODUCT_PARAM_QUERIES.CLASS]: _class,
      [PRODUCT_PARAM_QUERIES.SENSE]: _sense,
      [PRODUCT_PARAM_QUERIES.CODE]: _code,
      [PRODUCT_PARAM_QUERIES.SET]: _set,
      [PRODUCT_PARAM_QUERIES.SORT_VINTAGE]: _sortVintage,
      [PRODUCT_PARAM_QUERIES.SORT_SIZE]: _sortSize,
      [PRODUCT_PARAM_QUERIES.SORT_PRICE]: _sortPrice,
      [PRODUCT_PARAM_QUERIES.SORT_ARRIVAL]: _sortArrival,
      [PRODUCT_PARAM_QUERIES.SORT_REVIEW]: _sortReview,
      [PRODUCT_PARAM_QUERIES.SORT_MEDIA]: _sortMedia,
      [PRODUCT_PARAM_QUERIES.AOC]: _aoc,
      [PRODUCT_PARAM_QUERIES.TAG]: _tag,
      [PRODUCT_PARAM_QUERIES.QTY]: _qty,
      ...otherQueries
    } = queries;
    /* eslint-enable @typescript-eslint/naming-convention */

    return {
      [PRODUCT_PARAM_QUERIES.CATEGORY]: checkTypeParam(_category),
      [PRODUCT_PARAM_QUERIES.COLOR]: checkTypeParam(_color),
      [PRODUCT_PARAM_QUERIES.STYLE]: _style,
      [PRODUCT_PARAM_QUERIES.AREA]: checkTypeParam(_area),
      [PRODUCT_PARAM_QUERIES.SWEETNESS]: checkTypeParam(_sweetnesses),
      [PRODUCT_PARAM_QUERIES.VARIETY]: checkTypeParam(_variety),
      [PRODUCT_PARAM_QUERIES.MEDIA]: _media,
      [PRODUCT_PARAM_QUERIES.PRODUCER]: checkTypeParam(_producer),
      [PRODUCT_PARAM_QUERIES.VINTAGE]: _vintages,
      [PRODUCT_PARAM_QUERIES.AOC]: checkTypeParam(_aoc),
      [PRODUCT_PARAM_QUERIES.TAG]: _tag,
      [PRODUCT_PARAM_QUERIES.VINTAGE_INFO]: _vintageInfo,
      [PRODUCT_PARAM_QUERIES.WORD]: _word,
      [PRODUCT_PARAM_QUERIES.SIZE]: checkTypeParam(_size, _category?.includes(PRODUCT_CATEGORY_ID.WINE_CELLAR), true),
      [PRODUCT_PARAM_QUERIES.NEW]: checkTypeParam(_new),
      [PRODUCT_PARAM_QUERIES.REVIEW_POINT]: checkTypeParam(_reviewPoint, true),
      [PRODUCT_PARAM_QUERIES.FREE_SHIPPING]: _freeShipping,
      [PRODUCT_PARAM_QUERIES.PRICE_1]: checkTypeParam(_price1),
      [PRODUCT_PARAM_QUERIES.PRICE_2]: checkTypeParam(_price2),
      [PRODUCT_PARAM_QUERIES.DISCOUNT]: _discount,
      [PRODUCT_PARAM_QUERIES.TASTE_1]: checkTypeParam(_taste1),
      [PRODUCT_PARAM_QUERIES.TASTE_2]: checkTypeParam(_taste2),
      [PRODUCT_PARAM_QUERIES.CLASS]: checkTypeParam(_class),
      [PRODUCT_PARAM_QUERIES.SENSE]: _sense,
      [PRODUCT_PARAM_QUERIES.CODE]: _code,
      [PRODUCT_PARAM_QUERIES.SET]: _set,
      [PRODUCT_PARAM_QUERIES.SORT_VINTAGE]: _sortVintage,
      [PRODUCT_PARAM_QUERIES.SORT_SIZE]: _sortSize,
      [PRODUCT_PARAM_QUERIES.SORT_PRICE]: _sortPrice,
      [PRODUCT_PARAM_QUERIES.SORT_ARRIVAL]: _sortArrival,
      [PRODUCT_PARAM_QUERIES.SORT_MEDIA]: _sortMedia,
      [PRODUCT_PARAM_QUERIES.SORT_REVIEW]: _sortReview,
      [PRODUCT_PARAM_QUERIES.QTY]: checkTypeParam(_qty),
      ...otherQueries,
    };
  };

  /** 親カテゴリをProductParentCategory型に変換 */
  const normalizeParentCategory = (categoryId: string | null): ProductParentCategory => {
    switch (categoryId) {
      case ProductParentCategory.WINE_SET:
      case ProductParentCategory.SNACK:
      case ProductParentCategory.WINE_GOODS:
      case ProductParentCategory.WINE_CELLAR:
      case ProductParentCategory.OTHER:
      case ProductParentCategory.NON_ALCOHOL:
      case ProductParentCategory.GIFT_SET:
        return categoryId;
      default:
        return ProductParentCategory.WINE;
    }
  };

  /** 商品親カテゴリ */
  const category = computed((): ProductParentCategory => {
    const { [PRODUCT_PARAM_QUERIES.CATEGORY]: category } = route.value.query;

    // _ctgクエリがない場合はデフォルト：ワイン
    if (!category) {
      return ProductParentCategory.WINE;
    }

    if (typeof category === 'string') {
      return normalizeParentCategory(category);
    }

    return normalizeParentCategory(category[0]);
  });

  /** プリムール一覧かどうか */
  const isPrimeur = computed(() => route.value.path === PAGE_URL.PRIMEUR_LIST);

  const colorValues = computed(() => {
    const { colors } = store.state.product.filter;

    if (colors.includes(PRODUCT_COLOR_ID.SPARKLING)) {
      const state = colors.filter((v) => v !== PRODUCT_COLOR_ID.SPARKLING);
      return [...state, ...SPARKLINGS];
    }
    return colors;
  });

  /** ストアの状態をクエリに反映する */
  const applyStateToQuery = async () => {
    const {
      categories,
      priceMin,
      priceMax,
      reviewPoint,
      isFreeShipping,
      isNew,
      isDiscount,
      hasStock,
      areas,
      varieties,
      mediaRatings,
      tasteMin,
      tasteMax,
      sweetnesses,
      sizes,
      cellarSizes,
      primeurSizes,
      setSizes,
      vintages,
      vintageInfo,
      classes,
      producer,
      word,
      sense,
      code,
      aoc,
      styles,
      tags,
      sortTarget,
      sortOrder,
    } = store.state.product.filter;

    const { SORT_VINTAGE, SORT_SIZE, SORT_PRICE, SORT_ARRIVAL, SORT_REVIEW, SORT_MEDIA } = PRODUCT_PARAM_QUERIES;
    const { VINTAGE, SIZE, PRICE, ARRIVAL, REVIEW, MEDIA } = ProductSearchSortTarget;

    const sortKey =
      sortTarget === VINTAGE
        ? SORT_VINTAGE
        : sortTarget === SIZE
        ? SORT_SIZE
        : sortTarget === PRICE
        ? SORT_PRICE
        : sortTarget === ARRIVAL
        ? SORT_ARRIVAL
        : sortTarget === REVIEW
        ? SORT_REVIEW
        : sortTarget === MEDIA
        ? SORT_MEDIA
        : '';

    // ソートパラメーターを初期化
    const initializedSortParams = {
      ...route.value.query,
      [SORT_VINTAGE]: undefined,
      [SORT_SIZE]: undefined,
      [SORT_PRICE]: undefined,
      [SORT_ARRIVAL]: undefined,
      [SORT_REVIEW]: undefined,
      [SORT_MEDIA]: undefined,
    };

    const queries = await normalizeQueries({
      ...initializedSortParams,
      [PRODUCT_PARAM_QUERIES.CATEGORY]: categories,
      [PRODUCT_PARAM_QUERIES.COLOR]: colorValues.value,
      [PRODUCT_PARAM_QUERIES.AREA]: areas,
      [PRODUCT_PARAM_QUERIES.STYLE]: styles,
      [PRODUCT_PARAM_QUERIES.VARIETY]: varieties,
      [PRODUCT_PARAM_QUERIES.MEDIA]: mediaRatings,
      [PRODUCT_PARAM_QUERIES.PRODUCER]: producer,
      [PRODUCT_PARAM_QUERIES.VINTAGE]: vintages,
      [PRODUCT_PARAM_QUERIES.VINTAGE_INFO]: vintageInfo,
      [PRODUCT_PARAM_QUERIES.PRICE_1]: priceMin || '',
      [PRODUCT_PARAM_QUERIES.PRICE_2]: priceMax || '',
      [PRODUCT_PARAM_QUERIES.SWEETNESS]: sweetnesses,
      [PRODUCT_PARAM_QUERIES.SIZE]: sizes.length > 0 ? sizes : cellarSizes.length > 0 ? cellarSizes : primeurSizes,
      [PRODUCT_PARAM_QUERIES.SET]: setSizes,
      [PRODUCT_PARAM_QUERIES.TASTE_1]: tasteMin,
      [PRODUCT_PARAM_QUERIES.TASTE_2]: tasteMax,
      [PRODUCT_PARAM_QUERIES.DISCOUNT]: isDiscount,
      [PRODUCT_PARAM_QUERIES.NEW]: isNew,
      [PRODUCT_PARAM_QUERIES.REVIEW_POINT]: reviewPoint,
      [PRODUCT_PARAM_QUERIES.CLASS]: classes,
      [PRODUCT_PARAM_QUERIES.WORD]: word,
      [PRODUCT_PARAM_QUERIES.SENSE]: sense,
      [PRODUCT_PARAM_QUERIES.CODE]: code,
      [PRODUCT_PARAM_QUERIES.AOC]: aoc,
      [PRODUCT_PARAM_QUERIES.TAG]: tags,
      [PRODUCT_PARAM_QUERIES.FREE_SHIPPING]: isFreeShipping,
      [PRODUCT_PARAM_QUERIES.QTY]: hasStock,
      [sortKey]: sortOrder,
    });

    store.commit('product/search/setAfterStateToQuery');
    await router.replace({ query: queries }).catch(() => {});
  };

  /** クエリの状態をストアに反映する */
  const applyQueryToState = (foodDiagnosisParams?: URLSearchParams) => {
    let st = null;
    let so = null;
    // 料理診断の場合は対象の親商品コードを取得するだけなのでソートクエリが不要です
    if (!foodDiagnosisParams) {
      const { sortTarget = null, sortOrder = null } = pickSortState();
      st = sortTarget;
      so = sortOrder;
    }
    const sizeField = () => {
      if (category.value === ProductParentCategory.WINE_CELLAR) {
        return 'cellarSizes';
      }
      if (isPrimeur.value) {
        return 'primeurSizes';
      }
      return 'sizes';
    };

    // _sizeパラメータを使う項目を初期化
    store.commit('product/filter/resetFieldValue', 'cellarSizes');
    store.commit('product/filter/resetFieldValue', 'primeurSizes');
    store.commit('product/filter/resetFieldValue', 'sizes');

    store.commit('product/filter/setFieldValue', {
      field: 'categories',
      value: pickMultipleValue(PRODUCT_PARAM_QUERIES.CATEGORY, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'colors',
      value: pickMultipleValue(PRODUCT_PARAM_QUERIES.COLOR, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'areas',
      value: pickMultipleValue(PRODUCT_PARAM_QUERIES.AREA, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'varieties',
      value: pickMultipleValue(PRODUCT_PARAM_QUERIES.VARIETY, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'mediaRatings',
      value: pickMultipleValue(PRODUCT_PARAM_QUERIES.MEDIA, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'tasteMin',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.TASTE_1, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'tasteMax',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.TASTE_2, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'sweetnesses',
      value: pickMultipleValue(PRODUCT_PARAM_QUERIES.SWEETNESS, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: sizeField(),
      value: pickMultipleValue(PRODUCT_PARAM_QUERIES.SIZE, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'setSizes',
      value: pickMultipleValue(PRODUCT_PARAM_QUERIES.SET, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'vintages',
      value: pickMultipleValue(PRODUCT_PARAM_QUERIES.VINTAGE, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'vintageInfo',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.VINTAGE_INFO, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'classes',
      value: pickMultipleValue(PRODUCT_PARAM_QUERIES.CLASS, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'styles',
      value: pickMultipleValue(PRODUCT_PARAM_QUERIES.STYLE),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'producer',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.PRODUCER, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'priceMin',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.PRICE_1, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'priceMax',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.PRICE_2, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'reviewPoint',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.REVIEW_POINT, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'word',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.WORD, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'sense',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.SENSE, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'code',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.CODE, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'aoc',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.AOC, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'tags',
      value: pickMultipleValue(PRODUCT_PARAM_QUERIES.TAG, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'isNew',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.NEW, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'isFreeShipping',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.FREE_SHIPPING, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'isDiscount',
      value: pickSingleValue(PRODUCT_PARAM_QUERIES.DISCOUNT, foodDiagnosisParams),
    });
    store.commit('product/filter/setFieldValue', {
      field: 'hasStock',
      value: Number(pickSingleValue(PRODUCT_PARAM_QUERIES.QTY, foodDiagnosisParams))
        ? pickSingleValue(PRODUCT_PARAM_QUERIES.QTY, foodDiagnosisParams)
        : null,
    });
    store.commit('product/filter/setFieldValue', { field: 'sortTarget', value: st });
    store.commit('product/filter/setFieldValue', { field: 'sortOrder', value: so });
  };

  // パラメータをフォーマット、リダイレクトする
  const redirectUrl = async (isCSR: boolean) => {
    store.commit('product/search/setAfterRedirect');
    const queries = await normalizeQueries(route.value.query, true);
    if (isCSR) {
      await router.replace({ query: queries }).catch(() => {}); // ブラウザバックのときにリダイレクト前のurlを挟まないようにするため
    } else {
      redirect(301, { query: queries });
    }
  };

  return {
    category,
    isPrimeur,
    FALLBACK_CATEGORY: ProductParentCategory.WINE,
    applyStateToQuery,
    applyQueryToState,
    pickColorValue,
    redirectUrl,
  };
};
