import { ValidationRule } from 'vee-validate/dist/types/types';
import { definePreset } from '../core/preset';

/**
 * パスワードの文字列パターン
 */
export const passwordPattern: ValidationRule = {
  message: '{_field_}は同一文字を6文字以上連続して使用できません',
  validate(value) {
    if (typeof value !== 'string') {
      return false;
    }

    // 同じ単語のみの文字列は不正
    return !/^(.)\1+$/.test(value);
  },
};

/**
 * パスワードのルールプリセット
 */
export const password = definePreset({
  rules: {
    min: 6,
    max: 20,
    hankaku: true,
    alpha_num: true,
    passwordPattern: true,
  },
});
