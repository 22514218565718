import { defineNuxtMiddleware } from '@nuxtjs/composition-api';

export default defineNuxtMiddleware(async ({ redirect, ...context }) => {
  const { amazonCheckoutSessionId } = context.query;

  if (!amazonCheckoutSessionId) {
    return;
  }

  // 管理しやすくするため、persisiedStateにamazonCheckoutSessionIdを保存
  const vuexState = context.$cookies.get('checkoutSessionId');
  if (vuexState) {
    vuexState.modules.amazonPay.checkoutSessionId = amazonCheckoutSessionId;
    context.res.setHeader('Set-Cookie', `checkoutSessionId=${JSON.stringify(vuexState).toString()}; Path=/`);
  }

  // amazonCheckoutSessionIdをURLから削除
  const redirectPath = context.route.fullPath.split('&amazonCheckoutSessionId=')[0];
  return redirect(redirectPath);
});
