



















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import type { SizeTypes } from '~/components/partials/App/AppRectButton.vue';
import AppRectButton from '~/components/partials/App/AppRectButton.vue';

export default defineComponent({
  components: {
    AppRectButton,
  },
  props: {
    // AppRectButton とデフォルトが異なるので定義
    size: {
      type: String as PropType<SizeTypes>,
      default: 'h-10',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
