import { paths as Paths } from '~/types/api/openapi/event';

// 存在するメソッドとパスのオブジェクト
type EventMethodPath = {
  [Path in keyof Paths]: {
    [Method in keyof Paths[Path]]: {
      method: Method;
      path: Path;
    };
  }[keyof Paths[Path]];
}[keyof Paths];

// 認証が必要なパスとメソッドの対応
export const eventAuthPaths: EventMethodPath[] = [
  { method: 'get', path: '/api/v1/event_reservations' },
  { method: 'post', path: '/api/v1/events/{eventId}/reserve' },
  { method: 'post', path: '/api/v1/event_reservations_waiting' },
  { method: 'get', path: '/api/v1/event_reservations_waiting' },
  { method: 'get', path: '/api/v1/event_reservations/waiting/{reservationWaitingId}' },
  { method: 'get', path: '/api/v1/events/{eventId}/simulation' },
  { method: 'put', path: '/api/v1/event_reservations/{reservationId}' },
  { method: 'get', path: '/api/v1/event_reservations/{reservationId}' },
  { method: 'post', path: '/api/v1/event_reservations/{reservationId}/cancel' },
  { method: 'get', path: '/api/v1/event_reservations/{reservationId}/receipt' },
  { method: 'get', path: '/api/v1/checks/event/is_card_deletable' },
  { method: 'get', path: '/api/v1/reservations/{reservationId}/payment' },
  { method: 'get', path: '/api/v1/wine_college/lesson/status' },
  { method: 'put', path: '/api/v1/wine_college/lesson/status' },
  { method: 'get', path: '/api/v1/wine_college/article/status' },
  { method: 'post', path: '/api/v1/wine_college/article/status' },
];
