import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import dayjs from 'dayjs';
import Vue from 'vue';
import { nonNullable } from '~/utils';
import { BaseAxiosAction, ECActionPayload } from '~/types/api';
import { ProductInfoForIntroParts, ProductListBanner, ProductListItem, TopItemCard } from '~/types/modules/product';
import { ArticleType } from '~/enums';

type ProductListAPIParam = {
  q: string;
  df?: string;
  pagination?: number;
  sort?: string;
  log?: boolean;
  log_word?: string;
  tracking_id?: string;
  isPost?: boolean;
  limit?: number;
  isFavoriteList?: boolean;
  itemsPerPage?: number;
};

/** State */
export interface State {
  shippingStatus: {
    currentDate: string;
    isShippable: boolean;
  };
}

export const state = (): State => ({
  shippingStatus: {
    currentDate: '',
    isShippable: false,
  },
});

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setIsShippableDate(state: State, payload: { currentDate: string; isShippable: boolean }) {
    state.shippingStatus.currentDate = payload.currentDate;
    state.shippingStatus.isShippable = payload.isShippable;
  },
};

// POST用のbodyを作成
export const createPostRequestBody = (query: Object) => {
  const params = new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    if (value != null) {
      params.append(key, value);
    }
  });

  return params;
};

/** Actions */
export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;
export const actions = {
  async fetchProducts(
    this: Vue,
    { state, commit }: Ctx,
    payload: BaseAxiosAction<ProductListAPIParam>
  ): Promise<{ total: number; items: ProductListItem[] }> {
    const {
      q,
      df,
      sort,
      log,
      log_word,
      tracking_id,
      cancelToken,
      pagination,
      isPost,
      limit,
      isFavoriteList,
      itemsPerPage,
    } = payload;

    const queries = {
      wt: 'json',
      q,
      df,
      sort,
      rows: isFavoriteList
        ? limit
        : pagination
        ? itemsPerPage
          ? itemsPerPage * pagination
          : this.$C.PRODUCT_LIST_PER_PAGE * pagination
        : this.$C.SEARCH_LIMIT_ROWS,
      tlb_log: log,
      'tlb_log.word': log_word,
      'tlb.tracking_id': tracking_id,
    };

    let res;
    // キーワード検索を含む場合は［POST]
    if (log_word || isPost) {
      res = await this.$searchAxios.$post(
        '/select',
        { body: createPostRequestBody(queries), cancelToken },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
    } else {
      // それ以外は[GET]
      res = await this.$searchAxios.$get('/select', {
        query: queries,
        cancelToken,
      });
    }

    const {
      response: { docs, numFound },
    } = res;

    const items = docs.filter(nonNullable).map(
      (item: any): ProductListItem => ({
        iid: item.iid,
        code: item.product_code_s,
        product_variant_code_sm: item.product_variant_code_sm || [],
        product_type: item.product_type_s,
        name: item.product_name_s,
        name_en: item.product_name_english_s,
        category: item.category_s || '',
        category_ids: item.product_category_sm || [],
        image: item.main_image_url_s || '',
        reviews: {
          average: item.sort_r_rating_r || 0,
          count: item.review_count_i || 0,
        },
        producer: item.producer_s || '',
        producer_name_en: item.producer_name_english_s,
        producer_id: item.producer_id_i || '',
        producing_area: {
          code: item.country_s || '',
          area_1: item.area_1_s || '',
          area_2: item.area_2_s || '',
          area_3: item.area_3_s || '',
          area_id_1: item.area_id_1_i || '',
          area_id_2: item.area_id_2_i || '',
          area_id_3: item.area_id_3_i || '',
        },
        description:
          item.product_description_tm && item.product_description_tm.length > 0 ? item.product_description_tm[0] : '',
        display_price: item.lowest_price_general_i || item.lowest_cost_price_i || 0,
        regular_price: item.lowest_cost_price_i || 0,
        discount_rate: item.sale_discount_rate_i,
        children: item.vintage_data_general_sm || [],
        size: item.size_amount_i || 0,
        size_unit: item.size_amount_i ? item.size_unit_s || 'ml' : '',
        quantity_of_set: item.lot_calculation_quantity_i || 0,
        body_point: item.taste_gauge_i || 0,
        color: item.color_group_s || '',
        is_new: item.new_arrival_flag_i,
        is_limited_stock: item.limited_stock_flag_i,
        is_free_shipping: item.shipping_fee_discount_flag_i,
        is_discount: item.sale_discount_rate_i > 0 ? 1 : 0,
        sale_label: item.sale_info_s || '',
        variety: item.brand_sm,
        class: item.class_s,
        class_id: item.class_id_i,
        sweetness: item.sweetness_s || '',
        has_stock: Boolean(item.has_stock_flag_i),
        bio_label: item.bio_settings_s ? item.bio_settings_s.replace('フラグ', '') : '',
        wa_point: item.wa_point_s,
        ws_point: item.ws_point_s,
        js_point: item.js_point_s,
        we_point: item.we_point_s,
        d_point: item.d_point_s,
        v_point: item.v_point_s,
        gr_point: item.gr_point_s,
        latest_produced_year: item.latest_produced_year_i,
        is_visa_happy_wine: !!item.visa_happy_wine_discount_rate_i,
        set_type: item.set_type_s,
        sale_start: item.sales_start_d,
        sale_end: item.sales_end_d,
        bulk_buying_promotion: item.bulk_buying_promotion_s,
        bulk_buying_promotion_valid_from: item.bulk_buying_promotion_valid_from_s,
        bulk_buying_promotion_valid_to: item.bulk_buying_promotion_valid_to_s,
        display_flag: item.display_flag_i,
        product_display_flag: item.product_display_flag_i,
        earliest_shipping_date: item.earliest_shipping_date_s,
        d1_flag: item.d1_flag_i,
        single_display_variant_flag: item.single_display_variant_flag_i,
        style_id: item.style_id_i,
        // 抽選ID_抽選子商品ID_応募開始日_応募終了日_当選発表日_抽選制御開始日_抽選制御終了日_商品購入開始日時_商品購入終了日時_子商品コード
        raffle: item.raffle_sm?.map((raffle: string) => {
          const splittedVariant = raffle.split('_');

          return {
            id: splittedVariant[0],
            raffleItemId: splittedVariant[1],
            applicationStartTime: splittedVariant[2],
            applicationEndTime: splittedVariant[3],
            announcementTime: splittedVariant[4],
            raffleControlStartTime: splittedVariant[5],
            raffleControlEndTime: splittedVariant[6],
            purchaseStartTime: splittedVariant[7],
            purchaseEndTime: splittedVariant[8],
            // 子商品にアンダースコアが入る場合があるので９番目以降を抽出し連結
            productVariantCode: splittedVariant.slice(9).join(''),
          };
        }),
        lead_messages: item.lead_message_tm || [],
        taste_reviews: item.taste_review_tm || [],
        vintage_info_2_list: item.vintage_info_2_tm || [],
      })
    );

    // 今日が出荷可能日かどうか取得する
    const currentDate = dayjs();
    if (!currentDate.isSame(state.shippingStatus.currentDate, 'day')) {
      const { is_shippable_date } = await this.$ecAxios.$get('/api/v1/is_shippable_date', {});
      commit('setIsShippableDate', { currentDate: currentDate.format(), isShippable: is_shippable_date });
    }

    return {
      total: numFound as number,
      items,
    };
  },

  async fetchTopItemCardProducts(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ parentCodes: string[]; variantCodes: string[] }>
  ): Promise<TopItemCard[]> {
    const { parentCodes, variantCodes, cancelToken } = payload;

    const variantQuery = variantCodes
      .map((variantItemCode) => `product_variant_code_sm: ${variantItemCode}`)
      .join(' OR ');
    const parentQuery = parentCodes.map((itemCode) => `product_code_s: ${itemCode}`).join(' OR ');

    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:product AND (${variantQuery} OR ${parentQuery}) NOT display_flag_i:0`,
        fl: [
          'product_code_s',
          'product_variant_code_sm',
          'product_name_s',
          'sort_r_rating_r',
          'lowest_price_general_i',
          'lowest_cost_price_i',
          'sale_discount_rate_i',
          'color_group_s',
          'shipping_fee_discount_flag_i',
          'sale_info_s',
          'wa_point_s',
          'country_s',
          'area_1_s',
          'area_2_s',
          'area_3_s',
          'area_id_1_i',
          'set_type_s',
          'has_stock_flag_i',
          'sales_start_d',
          'sales_end_d',
          'vintage_data_general_sm',
          'raffle_sm',
          'product_image_1_s',
          'product_image_2_s',
        ],
        rows: this.$C.SEARCH_LIMIT_ROWS,
      },
      cancelToken,
    });

    // 各項目の中身はfetchProductsのコピペ
    return response.docs.map((item: any) => ({
      product_variant_code_sm: item.product_variant_code_sm || [],
      image1: item.product_image_1_s || '',
      image2: item.product_image_2_s || '',
      // ↑useTopParts用↑
      // ↓TopItemCard用↓
      code: item.product_code_s,
      name: item.product_name_s,
      review_average: item.sort_r_rating_r || 0,
      producing_area: {
        area_1: item.area_1_s || '',
        area_2: item.area_2_s || '',
        area_3: item.area_3_s || '',
        area_id_1: item.area_id_1_i || '',
      },
      display_price: item.lowest_price_general_i || item.lowest_cost_price_i || 0,
      discount_rate: item.sale_discount_rate_i,
      color: item.color_group_s || '',
      is_free_shipping: item.shipping_fee_discount_flag_i,
      is_discount: item.sale_discount_rate_i > 0 ? 1 : 0,
      sale_label: item.sale_info_s || '',
      // ↑TopItemCard用↑
      // ↓カートボタン用↓
      set_type: item.set_type_s,
      has_stock: Boolean(item.has_stock_flag_i),
      sale_start: item.sales_start_d,
      sale_end: item.sales_end_d,
      children: item.vintage_data_general_sm || [],
      raffle: item.raffle_sm?.map((raffle: string) => {
        const splittedVariant = raffle.split('_');
        return {
          id: splittedVariant[0],
          raffleItemId: splittedVariant[1],
          applicationStartTime: splittedVariant[2],
          applicationEndTime: splittedVariant[3],
          announcementTime: splittedVariant[4],
          raffleControlStartTime: splittedVariant[5],
          raffleControlEndTime: splittedVariant[6],
          purchaseStartTime: splittedVariant[7],
          purchaseEndTime: splittedVariant[8],
          productVariantCode: splittedVariant.slice(9).join(''),
        };
      }),
    }));
  },

  // KARTE用
  async fetchProductsVilla(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<ProductListAPIParam>
  ): Promise<{ total: number; items: any }> {
    const { q } = payload;

    const {
      response: { docs, numFound },
    } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q,
        fl: [
          'product_code_s',
          'product_name_s',
          'lowest_price_general_i',
          'lowest_price_general_exclude_tax_i',
          'product_type_s',
          'main_image_url_s',
          'category_id_i',
          'category_s',
          'category_id_name_s',
          'area_id_1_i',
          'area_1_s',
          'area_id_2_i',
          'area_2_s',
          'area_id_3_i',
          'area_3_s',
          'producer_id_i',
          'producer_s',
          'size_amount_i',
          'parent_color_id_i',
          'parent_color_s',
          'color_id_i',
          'color_group_s',
          'facet_brand_id_text_sm',
          'taste_gauge_i',
          'sense_type_s',
          'taste_finish_s',
          'taste_volume_s',
          'taste_fruity_s',
          'taste_bitter_s',
          'taste_robust_s',
          'taste_sour_s',
          'sort_r_rating_r',
          'shipping_fee_discount_flag_i',
          'display_flag_i',
        ],
        rows: this.$C.SEARCH_LIMIT_ROWS,
      },
    });
    return {
      total: numFound as number,
      items: docs,
    };
  },

  async fetchInfoForIntroductionParts(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ q: string }>
  ): Promise<ProductInfoForIntroParts> {
    const { q } = payload;
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q,
        fl: [
          'new_arrival_flag_i',
          'wa_point_s',
          'ws_point_s',
          'js_point_s',
          'we_point_s',
          'd_point_s',
          'v_point_s',
          'gr_point_s',
        ],
      },
    });
    return {
      is_new: response.docs[0].new_arrival_flag_i,
      wa_point: response.docs[0].wa_point_s,
      ws_point: response.docs[0].ws_point_s,
      js_point: response.docs[0].js_point_s,
      we_point: response.docs[0].we_point_s,
      d_point: response.docs[0].d_point_s,
      v_point: response.docs[0].v_point_s,
      gr_point: response.docs[0].gr_point_s,
    };
  },

  async fetchProductCodes(this: Vue, context: Ctx, payload: BaseAxiosAction<ProductListAPIParam>): Promise<string[]> {
    const { q } = payload;

    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q,
        fl: 'product_code_s',
      },
    });
    return response.docs.map((result: any) => result.product_code_s);
  },

  async fetchProductDetail(this: Vue, context: Ctx, payload: ECActionPayload<'/api/v1/products/{code}', 'get'>) {
    return await this.$ecAxios.$get('/api/v1/products/{code}', payload);
  },

  async fetchMostInStockProduct(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/products_most_in_stock', 'get'>
  ) {
    return await this.$ecAxios.$get('/api/v1/products_most_in_stock', payload);
  },

  async fetchRaffleApplicationList(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/raffle_applications', 'get'>
  ) {
    return await this.$ecAxios.$get('/api/v1/raffle_applications', payload);
  },

  async fetchProductListBanner(this: Vue, context: Ctx, payload: BaseAxiosAction): Promise<ProductListBanner[]> {
    const { cancelToken } = payload;
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      ...payload,
      query: {
        '_article_type[eq]': ArticleType.ProductsBottomBanner,
        '_order[ASC]': 'priority',
        _limit: '10',
      },
      cancelToken,
    });

    return result.map((item: any) => ({
      title: item.title,
      img: item.contents.pc_img,
      url: item.contents.url,
      target: item.contents.target || false,
    }));
  },
  async fetchVisaSalesDate(this: Vue, context: Ctx, payload: ECActionPayload<'/api/v1/visa_sales_date_check', 'get'>) {
    return await this.$ecAxios.$get('/api/v1/visa_sales_date_check', payload);
  },
};

/** Store Module Type */
export type Store = DefineStoreModule<'modules/product', State, typeof getters, typeof mutations, typeof actions>;
