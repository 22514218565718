import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { generateFavoriteId } from '~/utils';
import { BaseAxiosAction } from '~/types/api';
import { Favorite, FavoriteType } from '~/types/common/user';
import { FavoriteTypes } from '~/enums';

type State = {
  favoriteItems: Favorite[];
  favoriteProducers: Favorite[];
  favoriteArticles: Favorite[];
};

export const state = (): State => ({
  favoriteItems: [],
  favoriteProducers: [],
  favoriteArticles: [],
});

export const getters = {};

export const mutations = {
  setFavorites: (state: State, payload: { item: Favorite[]; favoriteType: FavoriteType }) => {
    // お気に入り種別によって更新するストアを選択
    switch (payload.favoriteType) {
      case FavoriteTypes.ITEM: {
        state.favoriteItems = payload.item;
        return;
      }
      case FavoriteTypes.PRODUCER: {
        state.favoriteProducers = payload.item;
        return;
      }
      case FavoriteTypes.ARTICLE: {
        state.favoriteArticles = payload.item;
      }
    }
  },
  addFavorites: (state: State, item: Favorite) => {
    switch (item.favoriteType) {
      case FavoriteTypes.ITEM: {
        if (!state.favoriteItems.find((fav) => fav.favoriteId === item.favoriteId)) {
          state.favoriteItems.unshift(item);
        }
        break;
      }
      case FavoriteTypes.PRODUCER: {
        if (!state.favoriteProducers.find((fav) => fav.favoriteId === item.favoriteId)) {
          state.favoriteProducers.unshift(item);
        }
        break;
      }
      case FavoriteTypes.ARTICLE: {
        if (!state.favoriteArticles.find((fav) => fav.favoriteId === item.favoriteId)) {
          state.favoriteArticles.unshift(item);
        }
        break;
      }
    }
  },
  deleteFavorites: (state: State, payload: { favoriteId: string; favoriteType: string }) => {
    switch (payload.favoriteType) {
      case FavoriteTypes.ITEM: {
        state.favoriteItems = state.favoriteItems.filter((fav) => fav.favoriteId !== payload.favoriteId);
        break;
      }
      case FavoriteTypes.PRODUCER: {
        state.favoriteProducers = state.favoriteProducers.filter((fav) => fav.favoriteId !== payload.favoriteId);
        break;
      }
      case FavoriteTypes.ARTICLE: {
        state.favoriteArticles = state.favoriteArticles.filter((fav) => fav.favoriteId !== payload.favoriteId);
        break;
      }
    }
  },
  // 一括削除
  deleteAllFavorites: (state: State, payload: { favoriteType: string }) => {
    switch (payload.favoriteType) {
      case FavoriteTypes.ITEM: {
        state.favoriteItems = [];
        break;
      }
      case FavoriteTypes.PRODUCER: {
        state.favoriteProducers = [];
        break;
      }
      case FavoriteTypes.ARTICLE: {
        state.favoriteArticles = [];
        break;
      }
    }
  },
};

export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;

export const actions = {
  async fetchAllFavorites(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ favoriteType: FavoriteType }>) {
    const { favorites } = await this.$consolsAxios.$get('/api/v1/favorites/all', {
      ...payload,
      query: { favoriteType: payload.favoriteType, isDeleted: false },
    });
    const favoriteLists = favorites?.map((item) => {
      return {
        favoriteId: item.favoriteId,
        favoriteCode: item.favoriteCode,
        favoriteType: item.favoriteType,
        isDeleted: item.isDeleted,
      };
    });
    commit('setFavorites', { item: favoriteLists || [], favoriteType: payload.favoriteType });
  },

  async addFavorite(
    this: Vue,
    { commit }: Ctx,
    { cancelToken, favoriteType, favoriteCode }: BaseAxiosAction<{ favoriteType: FavoriteType; favoriteCode: string }>
  ) {
    /**
     * favoriteCodeに紐つける親商品コード、生産者id、読み物記事idが互い被る可能性あるので、画面側でfavoriteIdを振る
     * 同一ユーザ内は一意で、ユーザ間で被るのは問題ない
     */
    const favoriteId = generateFavoriteId(favoriteType, favoriteCode);
    const { favorites } = await this.$consolsAxios.$post('/api/v1/favorites', {
      body: { favoriteId, favoriteCode, favoriteType },
      cancelToken,
    });

    if (favorites) commit('addFavorites', favorites);
  },

  /** 単一削除用 */
  async deleteFavorite(
    this: Vue,
    { commit }: Ctx,
    { cancelToken, favoriteType, favoriteCode }: BaseAxiosAction<{ favoriteType: FavoriteType; favoriteCode: string }>
  ) {
    const id = generateFavoriteId(favoriteType, favoriteCode);

    await this.$consolsAxios.$put('/api/v1/favorites', {
      body: {
        favoriteId: id,
        isDeleted: true,
      },
      cancelToken,
    });

    commit('deleteFavorites', { favoriteId: id, favoriteType });
  },

  /** 一括削除用 */
  async deleteAllFavorites(
    this: Vue,
    { commit }: Ctx,
    {
      cancelToken,
      favoriteType,
      favoriteCodes,
    }: BaseAxiosAction<{ favoriteType: FavoriteType; favoriteCodes?: string[] }>
  ) {
    const favoriteIds = favoriteCodes?.map((code) => generateFavoriteId(favoriteType, code));
    if (!favoriteIds) {
      return;
    }
    await Promise.allSettled(
      favoriteIds.map(async (favoriteId) => {
        // NOTE: 外部連携上、論理削除が望ましいので更新APIを使用
        await this.$consolsAxios.$put('/api/v1/favorites', {
          body: {
            favoriteId,
            isDeleted: true,
          },
          cancelToken,
        });
      })
    );
    if (favoriteType) commit('deleteAllFavorites', { favoriteType });
  },
};

export type Store = DefineStoreModule<'user/favorites', State, typeof getters, typeof mutations, typeof actions>;
