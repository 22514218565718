export enum ProductSearchSortTarget {
  PRICE,
  VINTAGE,
  REVIEW,
  MEDIA,
  SIZE,
  ARRIVAL,
}

export enum ProductSearchSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum WineColorText {
  RED = '赤ワイン',
  WHITE = '白ワイン',
  ROSE = 'ロゼワイン',
  RED_SPARKLING = '赤スパークリング',
  WHITE_SPARKLING = '白スパークリング',
  ROSE_SPARKLING = 'ロゼスパークリング',
  SPARKLING_WINE = 'スパークリングワイン',
  SPARKLING = 'スパークリング',
  ORANGE = 'オレンジワイン',
  MIX = 'ミックス',
}

export enum ProductParentCategory {
  WINE_SET = '12',
  SNACK = '11',
  GIFT_SET = '13',
  WINE_GOODS = '2',
  WINE_CELLAR = '37',
  OTHER = '34',
  NON_ALCOHOL = '29',
  WINE = '1',
}

export enum ProductCategoryText {
  WINE = 'ワイン',
  WINE_SET = 'ワインセット',
  GIFT_SET = 'ギフトセット',
  AROMATISED_WINE = 'フレーヴァードワイン',
  FORTIFIED_WINE = '酒精強化ワイン',
  FOOD = '食品',
  DELI = 'デリ',
  FISH_SNACK = '魚系おつまみ',
  MEET_SNACK = '生ハム、サラミ',
  CHEESE = 'チーズ',
  SALT_SPICE_VINEGAR = 'ソルト、スパイス、ビネガー',
  CRACKER_SNACK = 'クラッカー、スナック',
  DRYFRUITS_NUTS = 'ドライフルーツ、ナッツ',
  SWEETS = 'スイーツ',
  OLIVE_PICKLES = 'オリーブ＆ピクルス',
  DIP = 'ディップ、パテ',
  OLIVE_OIL = 'オリーブオイル',
  PASTA_RISOTTO = 'パスタ、リゾット',
  OTHER_DRINK = 'その他飲料(ノンアルコール、ジュースなど)',
  NON_ALCOHOL = 'ノンアルコールワイン、ワインテイスト飲料',
  OTHER_ALCOHOL = 'その他アルコール飲料',
  SPIRITS = '蒸留酒（グラッパなど）',
  BEER = 'ビール',
  PEAR_CIDER = 'ポワレ',
  CIDER = 'シードル',
  FRUIT_WINE = '甘味果実酒',
  WINE_CELLAR = 'ワインセラー',
  WINE_GOODS = 'ワイングッズ',
  WINE_GLASS = 'ワイングラス',
  KNIFE_OPENER = 'ソムリエナイフ、オープナー',
  WINE_COOLER = 'ワインクーラー',
  DECANTER = 'デキャンタ',
  STOPPER = 'ワインセーバー、ストッパー',
  OTHER_GOODS = 'その他ワイングッズ',
  TABLEWARE = '食器',
  BOOK_CD = 'ワイン書籍＆CD',
  OTHER = 'その他',
}

export enum ProductCategoryId {
  WINE = 1,
  WINE_SET = 12,
  GIFT_SET = 13,
  AROMATISED_WINE = 54,
  FORTIFIED_WINE = 43,
  FOOD = 11,
  DELI = 56,
  FISH_SNACK = 46,
  MEET_SNACK = 41,
  CHEESE = 26,
  SALT_SPICE_VINEGAR = 24,
  CRACKER_SNACK = 23,
  DRYFRUITS_NUTS = 21,
  SWEETS = 20,
  OLIVE_PICKLES = 19,
  DIP = 17,
  OLIVE_OIL = 16,
  PASTA_RISOTTO = 15,
  OTHER_DRINK = 29, // その他飲料（検索トップなどの表示はノンアルコール）
  NON_ALCOHOL = 55, // ↑の子；カテゴリ名：ノンアルコールワイン、ワインテイスト飲料；絞り込みなどは更に子の「ノンアルコールワイン」と「ワインテイスト飲料」を使用）
  OTHER_NONALCOHOL = 50, // カテゴリ名：その他ノンアルコール飲料
  MINERAL_WATER = 25,
  OTHER_ALCOHOL = 34,
  SPIRITS = 44,
  BEER = 42,
  PEAR_CIDER = 33,
  CIDER = 32,
  FRUIT_WINE = 31,
  WINE_CELLAR = 37,
  WINE_GOODS = 2,
  WINE_GLASS = 3,
  KNIFE_OPENER = 4,
  WINE_COOLER = 5,
  DECANTER = 6,
  STOPPER = 7,
  OTHER_GOODS = 8,
  TABLEWARE = 39,
  BOOK_CD = 40,
  OTHER = 35,
}

export enum ProductFilterFieldText {
  PRICE = '価格（税込）',
  WINE_GOODS_CATEGORY = 'カテゴリ',
  SNACK_CATEGORY = '食品カテゴリ',
  NON_ALCOHOL_CATEGORY = 'ノンアルコールの種類',
  OTHER_ALCOHOL_CATEGORY = 'そのほかのお酒の種類',
  PRODUCER = '生産者',
  BRAND = 'ブランド',
  COMMENT = 'クチコミ評価',
  AREA = '産地',
  COLOR = 'タイプ',
  STYLE = 'スタイル',
  VARIETY = '品種',
  CONDITION = '条件',
  WINESET_SIZE = '本数',
  CELLAR_SIZE = '収納本数',
  PRIMEUR_CLASS = 'メドック格付け',
  PRIMEUR_SIZE = '本数・サイズ',
}

export enum ProductSetType {
  FIXED_SET = 'fixed_set',
  FIXED_SET_HAS_DETAILS = 'fixed_set_has_details',
  FLEXIBLE_SET = 'flexible_set',
  BULK_PURCHASE_SET = 'bulk_purchase_set',
}

export enum BooleanLike {
  TRUE = '1',
  FALSE = '0',
}

export enum PromotionsName {
  SPECIAL_PRICE = 'special_price',
  EMPLOYEE_DISCOUNT = 'employee_discount',
  VISA_HAPPY_WINE_PRICE = 'visa_happy_wine_price',
}

export enum RecommendApiMethod {
  RANKING = 'ranking',
  SEARCH_WORD = 'searchword',
}

export enum RecommendApiMode {
  PREFIX = 'prefix',
  PARTIAL = 'partial',
}

export enum TailleventType {
  COLLECTION = 'collection',
  SELECTION = 'selection',
}

export enum DisplayLang {
  JA = 'ja',
  EN = 'en',
}

export enum ProductTypeId {
  GENERAL = 1,
  PRIMEUR = 2,
  PRESALE_BEAUJOLAIS = 3,
  WINE_CELLAR = 4,
  RENTAL_CELLAR_PACK = 5,
}

export enum GrapeColor {
  NONE = 'なし',
  BLACK = '黒',
  WHITE = '白',
}
