import { CartType, ProductTypeId } from '~/enums';

/** カート名から productTypeId に変換 */
export const cartNameToProductTypeId = (cartName: string): ProductTypeId | undefined => {
  switch (cartName) {
    case CartType.GENERAL:
      return ProductTypeId.GENERAL;
    case CartType.PRIMEUR:
      return ProductTypeId.PRIMEUR;
    case CartType.WINE_CELLAR:
      return ProductTypeId.WINE_CELLAR;
    case CartType.RENTAL_CELLAR_PACK:
      return ProductTypeId.RENTAL_CELLAR_PACK;
    case CartType.PRESALE_BEAUJOLAIS:
      return ProductTypeId.PRESALE_BEAUJOLAIS;
  }
};
