import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import type { Route } from 'vue-router';

/** Promise な setTimeout */
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default defineNuxtPlugin(({ app }) => {
  /** nuxtRouteLoaded イベントを送信する */
  const pushNuxtRouteLoaded = (to: Route) => {
    /**
     * @nuxtjs/gtm の PageView 相当のデータを送信
     * @see https://github.com/nuxt-community/gtm-module/blob/ae9199e6cab39485bcc85758bdfb36ebace3cdcf/lib/plugin.js#L69-L75
     */
    app.$gtm.push({
      routeName: to.name,
      pageType: 'PageView',
      pageUrl: to.fullPath,
      pageTitle: document.title,
      event: 'nuxtPageLoaded',
    });
  };

  // 画面遷移後、fetchが解決してからイベントを発火する
  app.router?.afterEach(async (to, from) => {
    // 遷移先と遷移元でクエリ・ハッシュを含むパスが同一であればキャンセル
    if (to.fullPath === from.fullPath) {
      return;
    }

    let routeChanged = false;

    // 待機中に画面遷移した場合は、待機をキャンセルしイベントを送信しない
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const unsubscribe = app.router?.beforeEach((_to, _from, next) => {
      routeChanged = true;

      unsubscribe?.();
      next();
    });

    // 100 ms ごとに $fetchState.pending から fetch が完了しているか調べる
    // 一生解決しないことがないように30回（≒30s）までトライする
    for (let i = 0; i < 30; i += 1) {
      if (routeChanged) {
        return;
      }

      await sleep(100);

      // ネストしたページの各ページコンポーネントの fetch() の状況を取得する
      const pending = to.matched.some(({ instances }) =>
        Object.values(instances).some(({ $fetchState }) => $fetchState?.pending || false)
      );

      // fetch がないページは $fetchState が undefined になるのでこの条件で対応できる
      if (!pending) {
        break;
      }
    }

    unsubscribe?.();

    // pending 更新直後だとデータ更新が伝播されていないことがあるので1フレーム待つ
    requestAnimationFrame(() => {
      pushNuxtRouteLoaded(to);
    });
  });
});
