import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { alpha_num, required, between, max, min, numeric, length, confirmed } from 'vee-validate/dist/rules';
import Vue from 'vue';
import * as customRules from './rules';

// プロジェクトで利用可能にするVeeValidateの公式ルール
// バンドルサイズを小さくするために、使用するルールのみを登録する
const officialRules = {
  alpha_num,
  required,
  max,
  min,
  numeric,
  length,
  confirmed,
  between,
};

Object.entries(officialRules).forEach(([ruleName, rule]) => extend(ruleName, rule));
Object.entries(customRules).forEach(([ruleName, rule]) => extend(ruleName, rule));

localize('ja', {
  messages: {
    alpha_num: '半角英数字で入力してください',
    required: '{_field_}を入力してください',
    max: '{length}文字以内で入力してください',
    min: '{length}文字以上で入力してください',
    numeric: '半角数字で入力してください',
    length: '{length}文字で入力してください',
    confirmed: '{_field_}が一致しません',
    between: '{min}〜{max}文字で入力してください',
  },
});

// VeeValidateのコンポーネントを登録
Object.entries({ ValidationProvider, ValidationObserver }).forEach(([componentName, component]) =>
  Vue.component(componentName, component)
);
