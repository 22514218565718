import { url } from '~/utils';

export const MYWINE_MENU_LIST = [
  {
    text: 'マイワイン',
    link: url('MYWINE'),
    icon: 'WineNoteMyWine',
    activeLinks: [url('MYWINE')],
  },
  {
    text: '購入商品',
    link: url('PURCHASE_HISTORY'),
    icon: 'WineNoteItem',
    activeLinks: [url('PURCHASE_HISTORY')],
  },
  {
    text: 'ワインノート',
    link: url('WINENOTE'),
    icon: 'WineNoteNote',
    activeLinks: [url('WINENOTE')],
  },
  {
    text: 'パーソナルレポート',
    link: url('PERSONAL_REPORT'),
    icon: 'WineNoteReport',
    activeLinks: [url('PERSONAL_REPORT')],
  },
  {
    text: 'ワイン診断',
    link: url('WINE_DIAGNOSIS'),
    icon: 'WineNoteTest',
    activeLinks: [url('WINE_DIAGNOSIS')],
  },
  {
    text: 'お気に入り',
    link: url('FAVORITE_LIST'),
    icon: 'mypage-like',
    activeLinks: [url('FAVORITE_LIST')],
  },
];
