import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { EventSchema } from '~/types/api';
import { Schedule, CompanionInfo } from '~/types/modules/event';

export type State = {
  eventId: number | null;
  eventTitle: string;
  schedule: Schedule;
  applicant: EventSchema<'FrontEventDetailsInfoCreate'>;
  accompanyingPerson: CompanionInfo[];
  participantNum: number | null;
  previousPage: string;
  hasCreditCardPaymentMethod: boolean;
};

const getDefaultState = (): State => {
  return {
    eventId: null,
    eventTitle: '',
    schedule: {
      date: '',
      start: '',
    },
    applicant: {
      slotId: 0,
      questionnaire: [],
    },
    accompanyingPerson: [],
    participantNum: null,
    previousPage: '',
    hasCreditCardPaymentMethod: false,
  };
};

export const state = (): State => getDefaultState();

export const mutations = {
  setEventId(state: State, eventId: number) {
    state.eventId = eventId;
  },
  setEventTitle(state: State, eventName: string) {
    state.eventTitle = eventName;
  },
  setSchedule(state: State, payload: Schedule) {
    state.schedule = payload;
  },
  setApplicantSlotId(state: State, slotId: number) {
    state.applicant.slotId = slotId;
  },
  setEcontextId(state: State, id: string) {
    state.applicant.econtextId = id;
  },
  setQuestionnaire(state: State, payload: EventSchema<'AnswerFront'>[]) {
    state.applicant.questionnaire = payload;
  },
  setAccompanyingPerson(state: State, profile: CompanionInfo, index: number) {
    Vue.typedSet(state.accompanyingPerson, index, profile);
  },
  addAccompanyingPerson(state: State) {
    state.accompanyingPerson.push({
      firstName: '',
      lastName: '',
      firstNameKana: '',
      lastNameKana: '',
      age: 0,
      questionnaire: [],
    });
  },
  setAllAccompanyingPeople(state: State, payload: CompanionInfo[]) {
    state.accompanyingPerson = payload;
  },
  setParticipantNum(state: State, participantNum: number) {
    state.participantNum = participantNum;
  },
  setPreviousPage(state: State, url: string) {
    state.previousPage = url;
  },
  setHasCreditCardPaymentMethod(state: State, payload: boolean) {
    state.hasCreditCardPaymentMethod = payload;
  },
  resetState(state: State) {
    Object.assign(state, getDefaultState());
  },
};

export type Ctx = DefineActionContext<State, never, typeof mutations>;

export type Store = DefineStoreModule<'event/reserve', State, never, typeof mutations, never>;
