




import { defineComponent } from '@nuxtjs/composition-api';
import { toPascalCase } from '~/utils';

const components = {
  Enoteca: () => import('~/assets/svg/enoteca.svg?inline'),
  ActionRefresh: () => import('~/assets/svg/icon_action-refresh.svg?inline'),
  ActionRefreshGray: () => import('~/assets/svg/icon_action-refresh-gray.svg?inline'),
  ActionArrowDown: () => import('~/assets/svg/icon_action-arrow-down.svg?inline'),
  ActionArrowNext: () => import('~/assets/svg/icon_action-arrow-next.svg?inline'),
  ActionCart: () => import('~/assets/svg/icon_action-cart.svg?inline'),
  ActionClose: () => import('~/assets/svg/icon_action-close.svg?inline'),
  ActionDeleteFavorite: () => import('~/assets/svg/icon_action-delete-favorite.svg?inline'),
  ActionDownload: () => import('~/assets/svg/icon_action-download.svg?inline'),
  ActionEdit: () => import('~/assets/svg/icon_action-edit.svg?inline'),
  ActionFax: () => import('~/assets/svg/icon_action-fax.svg?inline'),
  ActionGood: () => import('~/assets/svg/icon_action-good.svg?inline'),
  ActionGoodActive: () => import('~/assets/svg/icon_action-good-active.svg?inline'),
  ActionFavorite: () => import('~/assets/svg/icon_action-favorite.svg?inline'),
  ActionJump: () => import('~/assets/svg/icon_action-jump.svg?inline'),
  ActionLike: () => import('~/assets/svg/icon_action-like.svg?inline'),
  ActionLocation: () => import('~/assets/svg/icon_action-location.svg?inline'),
  ActionMail: () => import('~/assets/svg/icon_action-mail.svg?inline'),
  ActionMinus: () => import('~/assets/svg/icon_action-minus.svg?inline'),
  ActionNotlike: () => import('~/assets/svg/icon_action-notlike.svg?inline'),
  ActionPlus: () => import('~/assets/svg/icon_action-plus.svg?inline'),
  ActionStore: () => import('~/assets/svg/icon_action-store.svg?inline'),
  ActionTel: () => import('~/assets/svg/icon_action-tel.svg?inline'),
  ActionWrite: () => import('~/assets/svg/icon_action-write.svg?inline'),
  ActionWriteInactive: () => import('~/assets/svg/icon_action-write-inactive.svg?inline'),
  Active: () => import('~/assets/svg/icon_active.svg?inline'),
  Active2: () => import('~/assets/svg/icon_active2.svg?inline'),
  Airplane: () => import('~/assets/svg/icon_airplane.svg?inline'),
  Amazon: () => import('~/assets/svg/icon_amazon.svg?inline'),
  AnswerCorrect: () => import('~/assets/svg/icon_answer-correct.svg?inline'),
  AnswerWrong: () => import('~/assets/svg/icon_answer-wrong.svg?inline'),
  Applause: () => import('~/assets/svg/icon_applause.svg?inline'),
  Apple: () => import('~/assets/svg/icon_apple.svg?inline'),
  AppMenuNext: () => import('~/assets/svg/icon_app-menu-next.svg?inline'),
  Area: () => import('~/assets/svg/icon_area.svg?inline'),
  ArrowBlack: () => import('~/assets/svg/icon_arrow-black.svg?inline'),
  ArrowDownBlack: () => import('~/assets/svg/icon_arrow-down-black.svg?inline'),
  ArrowDownWinered: () => import('~/assets/svg/icon_arrow-down-winered.svg?inline'),
  ArrowGray: () => import('~/assets/svg/icon_arrow-gray.svg?inline'),
  ArrowLeftGray: () => import('~/assets/svg/icon_arrow-left-gray.svg?inline'),
  ArrowLeftBlack: () => import('~/assets/svg/icon_arrow-left-black.svg?inline'),
  ArrowRightGray: () => import('~/assets/svg/icon_arrow-right-gray.svg?inline'),
  ArrowRightBlack: () => import('~/assets/svg/icon_arrow-right-black.svg?inline'),
  ArrowRightWinered: () => import('~/assets/svg/icon_arrow-right-winered.svg?inline'),
  ArrowUpBlack: () => import('~/assets/svg/icon_arrow-up-black.svg?inline'),
  ArrowUpGray: () => import('~/assets/svg/icon_arrow-up-gray.svg?inline'),
  ArrowWhite: () => import('~/assets/svg/icon_arrow-white.svg?inline'),
  ArrowBack: () => import('~/assets/svg/icon_arrow-back.svg?inline'),
  ArrowForward: () => import('~/assets/svg/icon_arrow-forward.svg?inline'),
  Back: () => import('~/assets/svg/icon_back.svg?inline'),
  Back2: () => import('~/assets/svg/icon_back2.svg?inline'),
  Badge: () => import('~/assets/svg/icon_badge.svg?inline'),
  BarChairTable: () => import('~/assets/svg/icon_bar-chair-table.svg?inline'),
  BarChair: () => import('~/assets/svg/icon_bar-chair.svg?inline'),
  BarNoChair: () => import('~/assets/svg/icon_bar-no-chair.svg?inline'),
  BottleRed: () => import('~/assets/svg/icon_bottle-red.svg?inline'),
  BottleRose: () => import('~/assets/svg/icon_bottle-rose.svg?inline'),
  BottleSparkling: () => import('~/assets/svg/icon_bottle-sparkling.svg?inline'),
  BottleWhite: () => import('~/assets/svg/icon_bottle-white.svg?inline'),
  Calender: () => import('~/assets/svg/icon_calender.svg?inline'),
  CancelForSearch: () => import('~/assets/svg/icon_cancel-for-search.svg?inline'),
  CancelDark: () => import('~/assets/svg/icon_cancel-dark.svg?inline'),
  Cancel: () => import('~/assets/svg/icon_cancel.svg?inline'),
  Car: () => import('~/assets/svg/icon_car.svg?inline'),
  CartWhite: () => import('~/assets/svg/icon_cart-white.svg?inline'),
  Cart: () => import('~/assets/svg/icon_cart.svg?inline'),
  Cellar: () => import('~/assets/svg/icon_cellar.svg?inline'),
  CellarBgRed: () => import('~/assets/svg/icon_cellar-bg-red.svg?inline'),
  CheckOn: () => import('~/assets/svg/icon_check-on.svg?inline'),
  CheckOn2: () => import('~/assets/svg/icon_check-on2.svg?inline'),
  CheckOn3: () => import('~/assets/svg/icon_check-on3.svg?inline'),
  CheersLarge: () => import('~/assets/svg/icon_cheers-large.svg?inline'),
  Cheers: () => import('~/assets/svg/icon_cheers.svg?inline'),
  Clock: () => import('~/assets/svg/icon_clock.svg?inline'),
  Close: () => import('~/assets/svg/icon_close.svg?inline'),
  ContainWine: () => import('~/assets/svg/icon_contain-wine.svg?inline'),
  ContainCellar: () => import('~/assets/svg/icon_contain-cellar.svg?inline'),
  CollegeBook: () => import('~/assets/svg/icon_college-book.svg?inline'),
  CreditCard: () => import('~/assets/svg/icon_credit-card.svg?inline'),
  Delete: () => import('~/assets/svg/icon_delete.svg?inline'),
  Digest: () => import('~/assets/svg/icon_digest.svg?inline'),
  DownloadBlack: () => import('~/assets/svg/icon_download-black.svg?inline'),
  DownloadWhite: () => import('~/assets/svg/icon_download-white.svg?inline'),
  Duty: () => import('~/assets/svg/icon_duty.svg?inline'),
  Edit: () => import('~/assets/svg/icon_edit.svg?inline'),
  EnotecaApp: () => import('~/assets/svg/icon_enoteca-app.svg?inline'),
  Event: () => import('~/assets/svg/icon_event.svg?inline'),
  ExpeditedShipping: () => import('~/assets/svg/icon_expedited-shipping.svg?inline'),
  EyeClose: () => import('~/assets/svg/icon_eye-close.svg?inline'),
  EyeOpen: () => import('~/assets/svg/icon_eye-open.svg?inline'),
  FacebookApp: () => import('~/assets/svg/icon_facebook-app.svg?inline'),
  FavoriteActive: () => import('~/assets/svg/icon_favorite-active.svg?inline'),
  FavoriteInactive: () => import('~/assets/svg/icon_favorite-inactive.svg?inline'),
  Feature: () => import('~/assets/svg/icon_feature.svg?inline'),
  Filtering: () => import('~/assets/svg/icon_filter.svg?inline'),
  FilteringWhite: () => import('~/assets/svg/icon_filter-white.svg?inline'),
  Forward: () => import('~/assets/svg/icon_forward.svg?inline'),
  GiftCard: () => import('~/assets/svg/icon_gift-card.svg?inline'),
  Glass: () => import('~/assets/svg/icon_glass.svg?inline'),
  GrapeBlack: () => import('~/assets/svg/icon_grape-black.svg?inline'),
  GrapeGreen: () => import('~/assets/svg/icon_grape-green.svg?inline'),
  GrapeRed: () => import('~/assets/svg/icon_grape-red.svg?inline'),
  Grape: () => import('~/assets/svg/icon_grape.svg?inline'),
  GroupOne: () => import('~/assets/svg/icon_group-1.svg?inline'),
  GroupTwo: () => import('~/assets/svg/icon_group-2.svg?inline'),
  GroupThree: () => import('~/assets/svg/icon_group-3.svg?inline'),
  GroupFour: () => import('~/assets/svg/icon_group-4.svg?inline'),
  Guide: () => import('~/assets/svg/icon_guide.svg?inline'),
  HomeActive: () => import('~/assets/svg/icon_home-active.svg?inline'),
  HomeInactive: () => import('~/assets/svg/icon_home-inactive.svg?inline'),
  Inactive: () => import('~/assets/svg/icon_inactive.svg?inline'),
  Inactive2: () => import('~/assets/svg/icon_inactive2.svg?inline'),
  Info: () => import('~/assets/svg/icon_info.svg?inline'),
  InfoRed: () => import('~/assets/svg/icon_info-red.svg?inline'),
  InfoGray: () => import('~/assets/svg/icon_info-gray.svg?inline'),
  InstagramApp: () => import('~/assets/svg/icon_instagram-app.svg?inline'),
  LightMeal: () => import('~/assets/svg/icon_light-meal.svg?inline'),
  LineApp: () => import('~/assets/svg/icon_line-app.svg?inline'),
  LineColor: () => import('~/assets/svg/icon_line-color.svg?inline'),
  List: () => import('~/assets/svg/icon_list.svg?inline'),
  Logout: () => import('~/assets/svg/icon_logout.svg?inline'),
  LotteryMenu: () => import('~/assets/svg/icon_lottery-menu.svg?inline'),
  LotteryMenuActive: () => import('~/assets/svg/icon_lottery-menu-active.svg?inline'),
  Lunch: () => import('~/assets/svg/icon_lunch.svg?inline'),
  MedalBronz: () => import('~/assets/svg/icon_medal-bronz.svg?inline'),
  MedalGold: () => import('~/assets/svg/icon_medal-gold.svg?inline'),
  MedalSilver: () => import('~/assets/svg/icon_medal-silver.svg?inline'),
  MeritPoint: () => import('~/assets/svg/icon_merit_4.svg?inline'),
  MeritPresent: () => import('~/assets/svg/icon_merit_5.svg?inline'),
  MeritBirthday: () => import('~/assets/svg/icon_merit_7.svg?inline'),
  MeritSale: () => import('~/assets/svg/icon_merit_8.svg?inline'),
  MeritFavorites: () => import('~/assets/svg/icon_merit_9.svg?inline'),
  Hamburger: () => import('~/assets/svg/icon_hamburger.svg?inline'),
  Move: () => import('~/assets/svg/icon_move.svg?inline'),
  MyWine: () => import('~/assets/svg/icon_my-wine.svg?inline'),
  Mypage: () => import('~/assets/svg/icon_mypage.svg?inline'),
  NoteForcus: () => import('~/assets/svg/icon_note-forcus.svg?inline'),
  Note: () => import('~/assets/svg/icon_note.svg?inline'),
  NoteBlack: () => import('~/assets/svg/icon_note-black.svg?inline'),
  ThreeDots: () => import('~/assets/svg/icon_three-dots.svg?inline'),
  OtherServiceAmazon: () => import('~/assets/svg/icon_other-service-amazon.svg?inline'),
  Others: () => import('~/assets/svg/icon_others.svg?inline'),
  PdfFile: () => import('~/assets/svg/icon_pdf-file.svg?inline'),
  Pen: () => import('~/assets/svg/icon_pen.svg?inline'),
  Pen2: () => import('~/assets/svg/icon_pen2.svg?inline'),
  People: () => import('~/assets/svg/icon_people.svg?inline'),
  Play: () => import('~/assets/svg/icon_play.svg?inline'),
  Plus: () => import('~/assets/svg/icon_plus.svg?inline'),
  Point: () => import('~/assets/svg/icon_point.svg?inline'),
  Point2: () => import('~/assets/svg/icon_point2.svg?inline'),
  Point3: () => import('~/assets/svg/icon_point3.svg?inline'),
  Quote: () => import('~/assets/svg/icon_quote.svg?inline'),
  Quiz: () => import('~/assets/svg/icon_quiz.svg?inline'),
  RadioOn: () => import('~/assets/svg/icon_radio-on.svg?inline'),
  RadioOn2: () => import('~/assets/svg/icon_radio-on2.svg?inline'),
  RadioOn3: () => import('~/assets/svg/icon_radio-on3.svg?inline'),
  Rank1: () => import('~/assets/svg/icon_rank1.svg?inline'),
  Rank2: () => import('~/assets/svg/icon_rank2.svg?inline'),
  Rank3: () => import('~/assets/svg/icon_rank3.svg?inline'),
  Rank4: () => import('~/assets/svg/icon_rank4.svg?inline'),
  Read: () => import('~/assets/svg/icon_read.svg?inline'),
  Reader: () => import('~/assets/svg/icon_reader.svg?inline'),
  Reading: () => import('~/assets/svg/icon_reading.svg?inline'),
  RecycleAlcohol: () => import('~/assets/svg/icon_recycle-alcohol.svg?inline'),
  Sale: () => import('~/assets/svg/icon_sale.svg?inline'),
  SaleTop: () => import('~/assets/svg/icon_sale-top.svg?inline'),
  SearchMenu: () => import('~/assets/svg/icon_menu-search-small.svg?inline'),
  SearchActive: () => import('~/assets/svg/icon_search-active.svg?inline'),
  SearchInactive: () => import('~/assets/svg/icon_search-inactive.svg?inline'),
  SearchSmall: () => import('~/assets/svg/icon_search-small.svg?inline'),
  SeatFewTriangle: () => import('~/assets/svg/icon_seat-few-triangle.svg?inline'),
  SeatFullCross: () => import('~/assets/svg/icon_seat-full-cross.svg?inline'),
  SeatVacantCircle: () => import('~/assets/svg/icon_seat-vacant-circle.svg?inline'),
  Selected: () => import('~/assets/svg/icon_selected.svg?inline'),
  SelectedBlack: () => import('~/assets/svg/icon_selected-black.svg?inline'),
  Set: () => import('~/assets/svg/icon_set.svg?inline'),
  ShoppingGuide: () => import('~/assets/svg/icon_shopping-guide.svg?inline'),
  SnsFacebook: () => import('~/assets/svg/icon_sns-facebook.svg?inline'),
  SnsInstagram: () => import('~/assets/svg/icon_sns-instagram.svg?inline'),
  SnsLine: () => import('~/assets/svg/icon_sns-line.svg?inline'),
  Sort: () => import('~/assets/svg/icon_sort.svg?inline'),
  StarBlank: () => import('~/assets/svg/icon_star-blank.svg?inline'),
  StarMarked: () => import('~/assets/svg/icon_star-marked.svg?inline'),
  StarMarked25: () => import('~/assets/svg/icon_star-marked25.svg?inline'),
  StarMarked50: () => import('~/assets/svg/icon_star-marked50.svg?inline'),
  StarMarked75: () => import('~/assets/svg/icon_star-marked75.svg?inline'),
  StopDrinking: () => import('~/assets/svg/icon_stop-drinking.svg?inline'),
  Store: () => import('~/assets/svg/icon_store.svg?inline'),
  Ticket: () => import('~/assets/svg/icon_ticket.svg?inline'),
  Tile: () => import('~/assets/svg/icon_tile.svg?inline'),
  FreeShipping: () => import('~/assets/svg/icon_free-shipping.svg?inline'),
  NewWine: () => import('~/assets/svg/icon_new-wine.svg?inline'),
  Ranking: () => import('~/assets/svg/icon_ranking.svg?inline'),
  User: () => import('~/assets/svg/icon_user.svg?inline'),
  Visa: () => import('~/assets/svg/icon_visa.svg?inline'),
  Web: () => import('~/assets/svg/icon_web.svg?inline'),
  WineBgRed: () => import('~/assets/svg/icon_wine-bg-red.svg?inline'),
  WineChampagneEmpty: () => import('~/assets/svg/icon_wine-champagne-empty.svg?inline'),
  WineChampagneLarge: () => import('~/assets/svg/icon_wine-champagne-large.svg?inline'),
  WineChampagneNormal: () => import('~/assets/svg/icon_wine-champagne-normal.svg?inline'),
  WineChampagne: () => import('~/assets/svg/icon_wine-champagne.svg?inline'),
  WineCollegeAll: () => import('~/assets/svg/icon_wine-college-all.svg?inline'),
  WineRedEmpty: () => import('~/assets/svg/icon_wine-red-empty.svg?inline'),
  WineRedLargeEmpty: () => import('~/assets/svg/icon_wine-red-large-empty.svg?inline'),
  WineRedLarge: () => import('~/assets/svg/icon_wine-red-large.svg?inline'),
  WineRedNormal: () => import('~/assets/svg/icon_wine-red-normal.svg?inline'),
  WineRed: () => import('~/assets/svg/icon_wine-red.svg?inline'),
  WineRoseLarge: () => import('~/assets/svg/icon_wine-rose-large.svg?inline'),
  WineRoseNormal: () => import('~/assets/svg/icon_wine-rose-normal.svg?inline'),
  WineRose: () => import('~/assets/svg/icon_wine-rose.svg?inline'),
  WineSetFocus: () => import('~/assets/svg/icon_wine-set-focus.svg?inline'),
  WineSet: () => import('~/assets/svg/icon_wine-set.svg?inline'),
  WineWhiteEmpty: () => import('~/assets/svg/icon_wine-white-empty.svg?inline'),
  WineWhiteLarge: () => import('~/assets/svg/icon_wine-white-large.svg?inline'),
  WineWhiteNormal: () => import('~/assets/svg/icon_wine-white-normal.svg?inline'),
  WineWhite: () => import('~/assets/svg/icon_wine-white.svg?inline'),
  Wineevent: () => import('~/assets/svg/icon_wineevent.svg?inline'),
  Winenote: () => import('~/assets/svg/icon_winenote.svg?inline'),
  WinenoteReport: () => import('~/assets/svg/icon_winenote-report.svg?inline'),
  WinenoteTest: () => import('~/assets/svg/icon_winenote-test.svg?inline'),
  World: () => import('~/assets/svg/icon_world.svg?inline'),
  YoungLeaf: () => import('~/assets/svg/icon_young-leaf.svg?inline'),
  MypageCeller: () => import('~/assets/svg/icon_mypage-celler.svg?inline'),
  MypageCellerActive: () => import('~/assets/svg/icon_mypage-celler-active.svg?inline'),
  MypageCoupon: () => import('~/assets/svg/icon_mypage-coupon.svg?inline'),
  MypageCouponActive: () => import('~/assets/svg/icon_mypage-coupon-active.svg?inline'),
  MypageEvent: () => import('~/assets/svg/icon_mypage-event.svg?inline'),
  MypageEventActive: () => import('~/assets/svg/icon_mypage-event-active.svg?inline'),
  MypageMonthly: () => import('~/assets/svg/icon_mypage-monthly.svg?inline'),
  MypageMonthlyActive: () => import('~/assets/svg/icon_mypage-monthly-active.svg?inline'),
  MypageActionMember: () => import('~/assets/svg/icon_mypage-action-member.svg?inline'),
  MypageActionMemberActive: () => import('~/assets/svg/icon_mypage-action-member-active.svg?inline'),
  MypageHistory: () => import('~/assets/svg/icon_mypage-history.svg?inline'),
  MypageHistoryActive: () => import('~/assets/svg/icon_mypage-history-active.svg?inline'),
  MypageLike: () => import('~/assets/svg/icon_mypage-like.svg?inline'),
  MypageLikeActive: () => import('~/assets/svg/icon_mypage-like-active.svg?inline'),
  MypageUser: () => import('~/assets/svg/icon_mypage-user.svg?inline'),
  MypageUserActive: () => import('~/assets/svg/icon_mypage-user-active.svg?inline'),
  MypagePoint: () => import('~/assets/svg/icon_mypage-point.svg?inline'),
  MypagePointActive: () => import('~/assets/svg/icon_mypage-point-active.svg?inline'),
  MypageReview: () => import('~/assets/svg/icon_mypage-review.svg?inline'),
  MypageReviewActive: () => import('~/assets/svg/icon_mypage-review-active.svg?inline'),
  MypageBarcode: () => import('~/assets/svg/icon_mypage_barcode.svg?inline'),
  Setting: () => import('~/assets/svg/icon_setting.svg?inline'),
  Rakuten: () => import('~/assets/svg/icon_rakuten.svg?inline'),
  DummyIcon: () => import('~/assets/svg/icon-other-service-no-image.svg?inline'),
  SearchColorsIcon: () => import('~/assets/svg/icon-search-colors.svg?inline'),
  SearchGrapesIcon: () => import('~/assets/svg/icon-search-grapes.svg?inline'),
  SearchScenesIcon: () => import('~/assets/svg/icon-search-scenes.svg?inline'),
  SearchAreasIcon: () => import('~/assets/svg/icon-search-areas.svg?inline'),
  SearchKeywordsIcon: () => import('~/assets/svg/icon-search-keywords.svg?inline'),
  SearchProducersIcon: () => import('~/assets/svg/icon-search-producers.svg?inline'),
  SearchVintagesIcon: () => import('~/assets/svg/icon-search-vintages.svg?inline'),
  SearchRankingIcon: () => import('~/assets/svg/icon-search-ranking.svg?inline'),
  SearchArchivesIcon: () => import('~/assets/svg/icon-search-archives.svg?inline'),
  SearchFoodDiagnosisIcon: () => import('~/assets/svg/icon-search-diagnosis-food.svg?inline'),
  SearchDiagnosisIcon: () => import('~/assets/svg/icon-search-diagnosis.svg?inline'),
  SearchStaffReviewIcon: () => import('~/assets/svg/icon-search-staff-review.svg?inline'),
  SearchGiftIcon: () => import('~/assets/svg/icon-search-gift.svg?inline'),
  SearchWineSetIcon: () => import('~/assets/svg/icon-search-wineset.svg?inline'),
  SearchFoodsIcon: () => import('~/assets/svg/icon-search-foods.svg?inline'),
  SearchGoodsIcon: () => import('~/assets/svg/icon-search-goods.svg?inline'),
  SearchCellarIcon: () => import('~/assets/svg/icon-search-cellar.svg?inline'),
  SearchNonAlcoholIcon: () => import('~/assets/svg/icon-search-nonalcohol.svg?inline'),
  SearchOtherAlcoholIcon: () => import('~/assets/svg/icon-search-other-alcohol.svg?inline'),
  WineGlassIcon: () => import('~/assets/svg/icon-wineglass-contain-outline.svg?inline'),
  CellarIcon: () => import('~/assets/svg/icon-cellar-contain-outline.svg?inline'),
  WineNoteNote: () => import('~/assets/svg/icon-winenote-note-inactive.svg?inline'),
  WineNoteNoteActive: () => import('~/assets/svg/icon-winenote-note-active.svg?inline'),
  WineNoteReport: () => import('~/assets/svg/icon-winenote-report-inactive.svg?inline'),
  WineNoteReportActive: () => import('~/assets/svg/icon-winenote-report-active.svg?inline'),
  WineNoteTest: () => import('~/assets/svg/icon-winenote-test-inactive.svg?inline'),
  WineNoteTestActive: () => import('~/assets/svg/icon-winenote-test-active.svg?inline'),
  WineNoteItem: () => import('~/assets/svg/icon-winenote-item-inactive.svg?inline'),
  WineNoteItemActive: () => import('~/assets/svg/icon-winenote-item-active.svg?inline'),
  WineNoteMyWine: () => import('~/assets/svg/icon-winenote-mywine-inactive.svg?inline'),
  WineNoteMyWineActive: () => import('~/assets/svg/icon-winenote-mywine-active.svg?inline'),
  WineNoteMenuIcon: () => import('~/assets/svg/icon-winenote-menu-my-wine.svg?inline'),
  AutoLeafletIcon: () => import('~/assets/svg/icon-auto-leaflet.svg?inline'),
  SelectableLeafletIcon: () => import('~/assets/svg/icon-selectable-leaflet.svg?inline'),
  BackActive: () => import('~/assets/svg/icon-back-active.svg?inline'),
  BackInactive: () => import('~/assets/svg/icon-back-inactive.svg?inline'),
  NextActive: () => import('~/assets/svg/icon-next-active.svg?inline'),
  NextInactive: () => import('~/assets/svg/icon-next-inactive.svg?inline'),
  UberEats: () => import('~/assets/svg/icon-ubereats.svg?inline'),
  Wolt: () => import('~/assets/svg/icon-wolt.svg?inline'),
  Lottery: () => import('~/assets/svg/icon-lottery.svg?inline'),
  MailApp: () => import('~/assets/svg/icon_mail-app.svg?inline'),
};

export default defineComponent({
  components,
  props: {
    icon: {
      type: String,
      required: true,
      validator(value: string) {
        const camelizedValue = toPascalCase(value);

        return Object.keys(components).includes(camelizedValue);
      },
    },
  },
});
