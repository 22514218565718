import { paths as Paths } from '~/types/api/openapi/consols';

// 存在するメソッドとパスのオブジェクト
type ConsolsMethodPath = {
  [Path in keyof Paths]: {
    [Method in keyof Paths[Path]]: {
      method: Method;
      path: Path;
    };
  }[keyof Paths[Path]];
}[keyof Paths];

// 認証が必要なパスとメソッドの対応
export const consolsInternalPaths: ConsolsMethodPath[] = [
  { method: 'get', path: '/api/v1/user/social/login' },
  { method: 'get', path: '/api/v1/user/social/merge' },
  { method: 'get', path: '/api/v1/user/amazon/login' },
  { method: 'get', path: '/api/v1/user/rakuten/login' },
  { method: 'get', path: '/api/v1/user/rakuten/merge' },
  { method: 'get', path: '/api/v1/user/callback/rakuten' },
  { method: 'get', path: '/api/v2/user/social/login/{identifier}' },
  { method: 'get', path: '/api/v2/user/social/merge/{identifier}' },
];
