import { DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import { ComputedRef } from '@nuxtjs/composition-api';
import Vue from 'vue';
import { LayoutProps } from '~/composables/internals/useLayoutPropsProvider';

export type State = {
  [uid: string]: ComputedRef<LayoutProps> | undefined;
};

export const state = (): State => ({});

export const mutations = {
  setLayoutProps(state: State, payload: { layoutProps: ComputedRef<LayoutProps>; uid: number }) {
    const { layoutProps, uid } = payload;

    Vue.typedSet(state, `${uid}`, layoutProps);
  },
  clearLayoutProps(state: State, uid: string) {
    Vue.typedDelete(state, `${uid}`);
  },
};

export type Store = DefineStoreModule<'internals/layoutProps', State, never, typeof mutations, never>;
