import { defineNuxtPlugin, onGlobalSetup, useContext } from '@nuxtjs/composition-api';
import Vue from 'vue';
import { useAlert } from '~/composables/useAlert';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';
import { APIError } from '~/utils/error/APIError';

const useGlobalErrorHandler = () => {
  const { $axios, error } = useContext();
  const { DEBUG_MODE } = useRuntimeConfig();
  const { openAlert } = useAlert();

  const handleError = (err: Error) => {
    // eslint-disable-next-line no-console
    console.log(err);

    // Axios のキャンセルイベントの場合は何もしない
    if ($axios.isCancel(err)) {
      return;
    }

    // Error でないケースは throw 時に不正な値を投げたか、外部リソースなので何もしない
    // ※ Error Event ではクロスオリジンなスクリプトからのエラーは内容を受け取れない模様
    if (!(err instanceof Error)) {
      return;
    }

    // FIXME: もっと細かくハンドリングする
    if (process.server) {
      error({ message: err.message });

      return;
    }

    if (APIError.isAPIError(err)) {
      openAlert({
        title: 'エラー',
        message: err.messages,
        debug: [`${err.api}: status ${err.statusCode}`, ...err.errors.map(({ code }) => `${code}`)],
      });

      return;
    }

    if (DEBUG_MODE === 'enabled') {
      openAlert({
        title: 'ランタイムエラー',
        debug: [err.message],
      });
    }
  };

  return { handleError };
};

export default defineNuxtPlugin(() => {
  onGlobalSetup(() => {
    const { handleError } = useGlobalErrorHandler();

    // Vue のランタイムで発生したエラーのハンドラ
    Vue.config.errorHandler = (err) => {
      handleError(err);
    };

    if (!process.client) {
      return;
    }

    // Vue 以外のランタイムで発生したエラーのハンドラ
    window.addEventListener('error', (err) => {
      handleError(err.error);
    });
  });
});
