import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ARRIVAL_TIME_OPTION, SHIPPING_COMPANY_NAME } from '~/constants';
import { RentalCellarActionPayload, BaseAxiosAction, RentalCellarSchema } from '~/types/api';
import {
  CellarDestination,
  CellarDeliveryInfo,
  CellarGift,
  CellarPaperBag,
  SalesLocation,
  CellarOutRequestComplete,
} from '~/types/modules/cellar';

/** State */
export interface State {
  // 出庫フローでの住所
  destination: RentalCellarSchema<'Address'>;
  // 出庫フローでの固有識別コード
  identifyingCodes: number[];
  // 出庫フローでの再入庫フラグ
  includeReturnSlipCode: RentalCellarSchema<'IncludeReturnSlipCode'>;
  // セラー出庫での情報
  CellarOutInfo: RentalCellarSchema<'CellarOutInfoForMyPageCellarOutCreate'>;
  // セラー出庫での拠点情報
  salesLocations: SalesLocation;
  // オンリーセラー詳細画面から出庫されたかどうか
  hasSelectedFromOnlyCellarDetails: boolean;
  // オンリーセラーから出庫されたかどうか
  hasOnlyCellarBeenDelivered: boolean;
  // セラー出庫でのギフト制限情報
  giftRestriction: RentalCellarSchema<'GiftRestrictionResponse'>;
  // セラー出庫でのギフト情報
  giftInfo: CellarGift | null;
  // セラー出庫での紙袋設定
  bagNum: CellarPaperBag | null;
  // セラー一覧でのフィルター時の検索数
  filterTotal: number;
  // 出庫依頼完了
  cellarOutComplete: CellarOutRequestComplete;
  // セラー登録ページでの遷移制限
  isComeFromPolicy: boolean;
  // クレジット画面からの遷移
  isComeFromCredit: boolean;
  // セラー画面から注文に遷移するためのフラグ
  toOrderFromCellar: boolean;
  // 送り主住所
  senderDestination: RentalCellarSchema<'Address'>;
  // 住所を変更したかどうかのフラグ
  isChangedDestination: boolean;
  // お届け先を初期化するか
  isInitDestination: boolean;
  // 送り先の名称
  senderName: string;
}

export const state = (): State => ({
  destination: {
    lastName: '',
    firstName: '',
    zipcode: '',
    prefecture: '',
    city: '',
    address1: '',
    address2: '',
    phoneNumber: '',
  },
  identifyingCodes: [],
  includeReturnSlipCode: 'UN_NECESSARY',
  CellarOutInfo: {
    receivingMethod: 'DELIVER',
    expectedCellarOutDate: '',
    arrivalTime: ARRIVAL_TIME_OPTION.UN_SPECIFIED,
    shippingCompany: SHIPPING_COMPANY_NAME.SAGAWA,
    shippingTypeCode: 'NORMAL',
    cellarOutInsuranceCount: 0,
    giftInfo: {
      giftFlag: false,
    },
  },
  // 拠点
  salesLocations: {
    salesLocationsId: 0,
    salesLocationsName: '',
  },
  // オンリーセラー詳細画面から出庫されたかどうか
  hasSelectedFromOnlyCellarDetails: false,
  // オンリーセラーから出庫されたかどうか
  hasOnlyCellarBeenDelivered: false,
  giftRestriction: {},
  giftInfo: null,
  bagNum: null,
  filterTotal: 0,
  cellarOutComplete: {
    completeFlag: false,
    requestNo: 0,
    isMixCellar: false,
  },
  isComeFromPolicy: false,
  isComeFromCredit: false,
  toOrderFromCellar: false,
  senderDestination: {
    lastName: 'エノテカ',
    firstName: '株式会社',
    zipcode: '',
    prefecture: '',
    city: '',
    address1: '',
    address2: '',
    phoneNumber: '',
  },
  isChangedDestination: false,
  isInitDestination: true,
  senderName: 'エノテカ株式会社',
});

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setFieldValue<Field extends keyof State>(state: State, payload: { field: Field; value: State[Field] }): void {
    Vue.typedSet(state, payload.field, payload.value);
  },
  setDeliveryValue(state: State, deliveryInfo: CellarDeliveryInfo) {
    state.CellarOutInfo.arrivalTime = deliveryInfo.arrivalTime;
    state.CellarOutInfo.shippingCompany = deliveryInfo.shippingCompany;
    state.CellarOutInfo.expectedCellarOutDate = deliveryInfo.expectedCellarOutDate;
    state.CellarOutInfo.expectedArrivalDate = deliveryInfo.expectedArrivalDate;
    state.CellarOutInfo.cellarOutInsuranceCount = deliveryInfo.insuranceCount;
    state.CellarOutInfo.shippingTypeCode = deliveryInfo.shippingTypeCode;
  },
  setReceivingMethod(state: State, receivingMethod: RentalCellarSchema<'ReceivingMethod'>) {
    state.CellarOutInfo.receivingMethod = receivingMethod;
  },
  setGiftFlag(state: State, giftFlag: boolean) {
    state.CellarOutInfo.giftInfo.giftFlag = giftFlag;
  },
  initState(state: State) {
    // identifyingCodesは初期化しない（出庫フローに遷移する際に更新するため）
    state.destination = {
      lastName: '',
      firstName: '',
      zipcode: '',
      prefecture: '',
      city: '',
      address1: '',
      address2: '',
      phoneNumber: '',
    };
    state.includeReturnSlipCode = 'UN_NECESSARY';
    state.CellarOutInfo = {
      receivingMethod: 'DELIVER',
      expectedCellarOutDate: '',
      arrivalTime: ARRIVAL_TIME_OPTION.UN_SPECIFIED,
      shippingCompany: SHIPPING_COMPANY_NAME.SAGAWA,
      shippingTypeCode: 'NORMAL',
      cellarOutInsuranceCount: 0,
      giftInfo: {
        giftFlag: false,
      },
    };
    state.salesLocations = {
      salesLocationsId: 0,
      salesLocationsName: '',
    };
    state.hasSelectedFromOnlyCellarDetails = false;
    state.hasOnlyCellarBeenDelivered = false;
    state.giftRestriction = {};
    state.giftInfo = null;
    state.bagNum = null;
    state.isInitDestination = true;
  },
  initIdentifyingCodes(state: State) {
    state.identifyingCodes = [];
  },
  setTotal(state: State, value: number) {
    state.filterTotal = value;
  },
  initSenderDestination(state: State) {
    state.senderDestination = {
      lastName: 'エノテカ',
      firstName: '株式会社',
      zipcode: '',
      prefecture: '',
      city: '',
      address1: '',
      address2: '',
      phoneNumber: '',
    };
  },
};

/** Actions */
export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;
export const actions = {
  async fetchBillDetail(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/bills/{orderId}', 'get'>
  ) {
    const res = await this.$rentalCellarAxios.$get('/cellar/api/v1/bills/{orderId}', payload);

    return res;
  },

  async fetchBill(this: Vue, context: Ctx, payload: RentalCellarActionPayload<'/cellar/api/v1/bills', 'get'>) {
    const res = await this.$rentalCellarAxios.$get('/cellar/api/v1/bills', payload);

    return res;
  },

  // レンタルセラーのECON IDを取得
  async fetchRentalCellarEconId(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/payment', 'get'>
  ) {
    const response = await this.$rentalCellarAxios.$get('/cellar/api/v1/payment', payload);
    return response.econCduserId;
  },
  // レンタルセラーのECON IDを更新
  async updateRentalCellarEconId(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/payment', 'patch'>
  ) {
    return await this.$rentalCellarAxios.$patch('/cellar/api/v1/payment', payload);
  },
  // レンタルセラーのクレジット登録
  async registerCellarCredit(this: Vue, context: Ctx, payload: BaseAxiosAction<{ econCduserId: string }>) {
    const { econCduserId, cancelToken } = payload;
    return await this.$rentalCellarAxios.$post('/cellar/api/v1/payment', {
      body: {
        econCduserId,
      },
      cancelToken,
    });
  },

  // ギフト制限の取得
  async fetchGiftRestriction(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ identifyingCodeList: number[] }>) {
    const { identifyingCodeList, cancelToken } = payload;
    const giftRestriction = await this.$rentalCellarAxios.$post('/cellar/api/v1/gift_restriction', {
      body: {
        identifyingCodeList,
      },
      cancelToken,
    });
    await commit('setFieldValue', { field: 'giftRestriction', value: giftRestriction });
    return giftRestriction;
  },

  // 出庫依頼一覧の取得
  async fetchCellarOutRequests(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/cellar_out_requests', 'get'>
  ) {
    const res = await this.$rentalCellarAxios.$get('/cellar/api/v1/cellar_out_requests', payload);

    return res;
  },

  // ワインバンクの個数を取得
  async fetchWineCount(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/wine_banks/count', 'get'>
  ) {
    const res = await this.$rentalCellarAxios.$get('/cellar/api/v1/wine_banks/count', payload);
    return res;
  },

  // オンリーセラー詳細の取得
  async fetchOnlyCellarDetail(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/only_cellar_products/{identifyingCode}', 'get'>
  ) {
    const res = await this.$rentalCellarAxios.$get('/cellar/api/v1/only_cellar_products/{identifyingCode}', payload);
    return res;
  },

  // 出庫依頼詳細の取得
  async fetchCellarOutDetail(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/cellar_out_requests/{requestNo}', 'get'>
  ) {
    const res = await this.$rentalCellarAxios.$get('/cellar/api/v1/cellar_out_requests/{requestNo}', payload);

    return res;
  },

  // オンリーセラーの取得
  async fetchOnlyCellarProducts(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/only_cellar_products', 'get'>
  ) {
    const res = await this.$rentalCellarAxios.$get('/cellar/api/v1/only_cellar_products', payload);

    return res;
  },
  // オンリーセラーの件数取得
  async fetchOnlyCellarProductsCount(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/only_cellar_products/count', 'get'>
  ) {
    return await this.$rentalCellarAxios.$get('/cellar/api/v1/only_cellar_products/count', payload);
  },
  // ミックスーセラーの取得
  async fetchMixCellarProducts(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/mix_cellar_products', 'get'>
  ) {
    return await this.$rentalCellarAxios.$get('/cellar/api/v1/mix_cellar_products', payload);
  },
  // ミックスーセラーの件数取得
  async fetchMixCellarProductsCount(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/mix_cellar_products/count', 'get'>
  ) {
    return await this.$rentalCellarAxios.$get('/cellar/api/v1/mix_cellar_products/count', payload);
  },
  // オンリーセラー検索条件の取得
  async fetchOnlyCellarSearchConditions(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/only_cellar_products/search_condition', 'get'>
  ) {
    return await this.$rentalCellarAxios.$get('/cellar/api/v1/only_cellar_products/search_condition', payload);
  },

  // フォームの内容をstoreに保存する
  async setDestination(
    this: Vue,
    { commit }: Ctx,
    { destination }: BaseAxiosAction<{ destination: CellarDestination }>
  ) {
    // 都道府県はnumber型で扱っているためここでstringに変換する
    const tempDestination: RentalCellarSchema<'Address'> = {
      lastName: destination.lastName,
      firstName: destination.firstName,
      zipcode: destination.zipCode,
      prefecture: String(destination.prefecture),
      city: destination.city,
      address1: destination.streetAddress,
      address2: destination.building,
      phoneNumber: destination.phoneNumber,
    };
    commit('setFieldValue', { field: 'destination', value: tempDestination });
  },

  // 送り主の内容をstoreに保存する
  async setSenderDestination(
    this: Vue,
    { commit }: Ctx,
    { destination }: BaseAxiosAction<{ destination: CellarDestination }>
  ) {
    // 都道府県はnumber型で扱っているためここでstringに変換する
    const tempDestination: RentalCellarSchema<'Address'> = {
      lastName: destination.lastName,
      firstName: destination.firstName,
      zipcode: destination.zipCode,
      prefecture: String(destination.prefecture),
      city: destination.city,
      address1: destination.streetAddress,
      address2: destination.building,
      phoneNumber: destination.phoneNumber,
    };
    commit('setFieldValue', { field: 'senderDestination', value: tempDestination });
  },

  // 再入庫のフラグをstoreに保存する
  async setReturnSlipCode(
    this: Vue,
    { commit }: Ctx,
    { slipCode }: BaseAxiosAction<{ slipCode: RentalCellarSchema<'IncludeReturnSlipCode'> }>
  ) {
    commit('setFieldValue', { field: 'includeReturnSlipCode', value: slipCode });
  },

  // ギフトの設定をstoreに保存する
  async setGiftInfo(this: Vue, { commit }: Ctx, { giftInfo }: BaseAxiosAction<{ giftInfo: CellarGift }>) {
    commit('setFieldValue', { field: 'giftInfo', value: giftInfo });
    commit('setGiftFlag', giftInfo.hasGiftWrapping);
  },

  // 紙袋の設定をstoreに保存する
  async setBagNum(this: Vue, { commit }: Ctx, { bagNum }: BaseAxiosAction<{ bagNum: CellarPaperBag }>) {
    commit('setFieldValue', { field: 'bagNum', value: bagNum });
  },

  // 受け取り方法をstoreに保存する
  async setReceivingMethod(
    this: Vue,
    { commit }: Ctx,
    { receivingMethod }: BaseAxiosAction<{ receivingMethod: RentalCellarSchema<'ReceivingMethod'> }>
  ) {
    commit('setReceivingMethod', receivingMethod);
  },

  async setDeliveryInfo(
    this: Vue,
    { commit }: Ctx,
    { deliveryInfo }: BaseAxiosAction<{ deliveryInfo: CellarDeliveryInfo }>
  ) {
    commit('setDeliveryValue', deliveryInfo);
  },

  // 受け取り店舗をstoreに保存する
  async setReceivingSalesLocation(
    this: Vue,
    { commit }: Ctx,
    { salesLocations }: BaseAxiosAction<{ salesLocations: SalesLocation }>
  ) {
    commit('setFieldValue', { field: 'salesLocations', value: salesLocations });
  },

  // ミックスセラー詳細の取得
  async fetchMixCellarDetail(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/mix_cellar_products/{identifyingCode}', 'get'>
  ) {
    const res = await this.$rentalCellarAxios.$get('/cellar/api/v1/mix_cellar_products/{identifyingCode}', payload);
    return res;
  },

  // ミックスセラーの更新
  async updateMixCellar(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/mix_cellar_products/{identifyingCode}', 'patch'>
  ) {
    const res = await this.$rentalCellarAxios.$patch('/cellar/api/v1/mix_cellar_products/{identifyingCode}', payload);
    return res;
  },

  // オンリーセラーの更新
  async updateOnlyCellar(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/only_cellar_products/{identifyingCode}', 'patch'>
  ) {
    const res = await this.$rentalCellarAxios.$patch('/cellar/api/v1/only_cellar_products/{identifyingCode}', payload);
    return res;
  },

  // 出庫依頼シミュレーション
  async fetchCellarOutRequestsSimulation(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/cellar_out_requests/simulation', 'get'>
  ) {
    const res = await this.$rentalCellarAxios.$get('/cellar/api/v1/cellar_out_requests/simulation', payload);
    return res;
  },

  // 配送制限取得
  async fetchShipmentRestriction(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/shipment_restriction', 'get'>
  ) {
    const res = await this.$rentalCellarAxios.$get('/cellar/api/v1/shipment_restriction', payload);
    return res;
  },

  // 支払い状況取得
  async fetchBillsStatus(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/bills/error', 'get'>
  ) {
    const res = await this.$rentalCellarAxios.$get('/cellar/api/v1/bills/error', payload);
    return res;
  },

  // 出庫依頼
  async registerCellarOut(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ cellarOutRequest: RentalCellarSchema<'CellarOutRequestNewFo'> }>
  ) {
    const { cellarOutRequest, cancelToken } = payload;
    return await this.$rentalCellarAxios.$post('/cellar/api/v1/cellar_out_requests', {
      body: {
        cellarType: cellarOutRequest.cellarType,
        destination: cellarOutRequest.destination,
        cellarOutInfo: cellarOutRequest.cellarOutInfo,
        cellarOutSimulation: cellarOutRequest.cellarOutSimulation,
        identifyingCodes: cellarOutRequest.identifyingCodes,
        sender: cellarOutRequest.sender,
      },
      cancelToken,
    });
  },

  // セラーのstateを初期化する
  async initCellarState(this: Vue, { commit }: Ctx) {
    commit('initState');
  },

  // 送り主のstateを初期化する
  async initSenderDestination(this: Vue, { commit }: Ctx) {
    commit('initSenderDestination');
  },

  // セラーのidentifyingCodesを初期化する
  async initCellarIdentifyingCodes(this: Vue, { commit }: Ctx) {
    commit('initIdentifyingCodes');
  },

  // PDFアップロード
  async uploadPdfFile(this: Vue, context: Ctx, payload: BaseAxiosAction<{ file: any }>) {
    const { file, cancelToken } = payload;
    const fileData = await this.$rentalCellarAxios.$post(
      '/cellar/api/v1/gift_files',
      {
        body: file,
        cancelToken,
      },
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
    return fileData.fileId;
  },

  // 次月の請求取得
  async fetchBillNext(this: Vue, context: Ctx, payload: RentalCellarActionPayload<'/cellar/api/v1/bills/next', 'get'>) {
    const res = await this.$rentalCellarAxios.$get('/cellar/api/v1/bills/next', payload);

    return res;
  },

  // セラー商品のCSV取得
  async fetchCellarProductsCsv(
    this: Vue,
    context: Ctx,
    payload: RentalCellarActionPayload<'/cellar/api/v1/cellar_products/csv', 'get'>
  ) {
    return await this.$rentalCellarAxios.$get('/cellar/api/v1/cellar_products/csv', payload, { responseType: 'blob' });
  },

  // フィルターの時の検索数を更新する
  async setFilterTotal(this: Vue, { commit }: Ctx, value: number) {
    commit('setTotal', value);
  },

  // 出庫依頼完了で使う情報をstoreに保存する
  async setCellarOutComplete(
    this: Vue,
    { commit }: Ctx,
    { cellarOutRequestComplete }: BaseAxiosAction<{ cellarOutRequestComplete: CellarOutRequestComplete }>
  ) {
    commit('setFieldValue', { field: 'cellarOutComplete', value: cellarOutRequestComplete });
  },

  // ミックスーセラーの一覧取得
  async fetchMixCellarProductLists(this: Vue, context: Ctx, payload: BaseAxiosAction<{ cellarOutRequestNo: number }>) {
    const { cellarOutRequestNo } = payload;
    return await this.$rentalCellarAxios.$get('/cellar/api/v1/mix_cellar_products', {
      query: {
        cellarOutRequestNo,
      },
    });
  },
  // オンリーセラーの一覧取得
  async fetchOnlyCellarProductLists(this: Vue, context: Ctx, payload: BaseAxiosAction<{ cellarOutRequestNo: number }>) {
    const { cellarOutRequestNo } = payload;
    return await this.$rentalCellarAxios.$get('/cellar/api/v1/only_cellar_products', {
      query: {
        cellarOutRequestNo,
      },
    });
  },
};

export type Store = DefineStoreModule<'modules/mypage/cellar', State, typeof getters, typeof mutations, typeof actions>;
