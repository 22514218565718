import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import dayjs from 'dayjs';
import type { RootStore } from '~/composables/useStore';
import { COOKIE_KEY, PRIMEUR_LOGIN_STATUS } from '~/constants';
import { url } from '~/utils';
import { PageError } from '~/utils/error';

/**
 * プリムール一覧用のVISA会員販売期間のアクセス制御
 */
export default defineNuxtMiddleware(async ({ redirect, route, app, ...context }) => {
  if (!route.path.includes(url('PRIMEUR'))) {
    return;
  }
  try {
    const store = context.store as RootStore;
    const currentDate = dayjs().tz('Asia/Tokyo').format();
    const { is_in_visa_sales_date } = await store.dispatch('modules/product/fetchVisaSalesDate', {
      query: { datetime: currentDate },
    });
    // VISA会員販売期間外(=通常期間)は、未ログインでもアクセスできます
    if (!is_in_visa_sales_date) {
      return;
    } else {
      const { authenticated } = store.state.auth;
      const isVisaMember = store.getters['user/info/isVisaMember'];
      let loginStatus = '';
      // 認証済みの場合
      if (authenticated) {
        // クラブエノテカVISA会員の場合は、アクセスできます
        if (isVisaMember) {
          return;
        }
        // クラブエノテカVISA会員でない場合は、期間外エラー画面にリダイレクト
        loginStatus = PRIMEUR_LOGIN_STATUS.LOGGED_IN;
      } else {
        // 未ログインの場合は、ログインしてくださいエラー画面にリダイレクト
        loginStatus = PRIMEUR_LOGIN_STATUS.NO_LOGIN;
        app.$cookies.set(COOKIE_KEY.RETURN_TO, route.fullPath, { path: '/' });
      }
      redirect(url('PRIMEUR_PROMPT'), { login_status: loginStatus });
    }
  } catch {
    throw new PageError(404, {});
  }
});
