import { sumBy } from 'lodash-es';
import { ValidationRule } from 'vee-validate/dist/types/types';
import {
  MAX_NOSHI_NAME_LINE_COUNT,
  MAX_NOSHI_NAME_WORD_COUNT_PER_LINE,
  MAX_NOSHI_POSITION_LINE_COUNT,
  MAX_NOSHI_POSITION_WORD_COUNT_PER_LINE,
} from '~/constants';
import { definePreset } from '../core/preset';

const createNoshiRule = ({
  maxLineCount,
  maxWordCount,
}: {
  maxLineCount: number;
  maxWordCount: number;
}): ValidationRule => {
  return {
    message: `{_field_}はプレビュー上${maxLineCount}行以内で入力してください`,
    validate(value: any) {
      if (typeof value !== 'string') {
        return false;
      }

      const lines = value.split('\n');

      /* 入力に折り返しがあれば行数を算出 */
      const lineCount = sumBy(lines, (line) => {
        if (line.length > maxWordCount) {
          return Math.ceil(line.length / maxWordCount);
        }
        return 1;
      });

      if (lineCount > maxLineCount) {
        return false;
      }

      return true;
    },
  };
};

/** のし名入れの最大行数 */
export const noshiNameMaxLine = createNoshiRule({
  maxLineCount: MAX_NOSHI_NAME_LINE_COUNT,
  maxWordCount: MAX_NOSHI_NAME_WORD_COUNT_PER_LINE,
});

export const noshiPositionMaxLine = createNoshiRule({
  maxLineCount: MAX_NOSHI_POSITION_LINE_COUNT,
  maxWordCount: MAX_NOSHI_POSITION_WORD_COUNT_PER_LINE,
});

export const noshiName = definePreset({
  rules: {
    noshiNameMaxLine: true,
  },
});

export const noshiPosition = definePreset({
  rules: {
    noshiPositionMaxLine: true,
  },
});
