import { computed, ref, Ref, watch } from '@nuxtjs/composition-api';

type UseElementLayoutRect = Pick<
  DOMRectReadOnly,
  'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
> & {
  offsetWidth: number;
  offsetHeight: number;
};

const defaultState: UseElementLayoutRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  offsetWidth: 0,
  offsetHeight: 0,
};

/**
 * 要素のサイズや位置を追従して返すコンポーザブル
 * @param elementRef サイズや位置を測定したい要素の ref
 * @returns {UseElementLayoutRect} 要素のサイズと位置
 */
export const useElementLayout = <E extends Element>(elementRef: Ref<E | undefined>) => {
  if (process.server) {
    return ref(defaultState);
  }

  const rect = ref<{ -readonly [K in keyof UseElementLayoutRect]: UseElementLayoutRect[K] }>(defaultState);
  const observer = computed(
    () =>
      new window.ResizeObserver(([entry]) => {
        if (entry) {
          const { x, y, width, height, top, left, bottom, right } = entry.contentRect;

          let offsetWidth = 0;
          let offsetHeight = 0;

          if (entry.target instanceof HTMLElement) {
            offsetWidth = entry.target.offsetWidth;
            offsetHeight = entry.target.offsetHeight;
          }

          rect.value = { x, y, width, height, top, left, bottom, right, offsetWidth, offsetHeight };
        }
      })
  );

  watch(
    [elementRef, observer] as const,
    ([element, observer], old, onInvalidate) => {
      if (!element) {
        return;
      }

      observer.observe(element);

      onInvalidate(() => {
        observer.disconnect();
      });
    },
    { immediate: true }
  );

  return rect;
};
