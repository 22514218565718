var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('picture',{class:{
    static: _vm.position === 'static' && _vm.objectFit == null,
    relative:
      _vm.position === 'relative' ||
      (_vm.position === 'static' && _vm.objectFit != null) ||
      (_vm.position == null && _vm.objectFit != null),
    absolute: _vm.position === 'absolute',
    fixed: _vm.position === 'fixed',
    sticky: _vm.position === 'sticky',
  }},[(_vm.srcSpWebp)?_c('source',{attrs:{"srcset":_vm.srcSpWebp,"media":"(max-width:768px)","type":"image/webp"}}):_vm._e(),_vm._v(" "),(_vm.srcSp)?_c('source',{attrs:{"srcset":_vm.srcSp,"media":"(max-width:768px)","type":_vm.spImageType}}):_vm._e(),_vm._v(" "),(_vm.srcWebp)?_c('source',{attrs:{"srcset":_vm.srcWebp,"type":"image/webp"}}):_vm._e(),_vm._v(" "),(!_vm.src && _vm.fallbackImageWebp)?_c('source',{attrs:{"srcset":_vm.fallbackImageWebp,"type":"image/webp"}}):_vm._e(),_vm._v(" "),_c('img',_vm._b({class:{
      // 100%にする方向
      'w-full': _vm.fullSizeDirection === 'width',
      'h-full': _vm.fullSizeDirection === 'height',
      // ベースのスタイル
      'absolute inset-0 h-full w-full': typeof _vm.objectFit === 'string' || (_vm.objectFit.pc && _vm.objectFit.sp),
      'md:absolute md:inset-0 md:h-full md:w-full': typeof _vm.objectFit === 'object' && _vm.objectFit.pc && !_vm.objectFit.sp,
      'absolute inset-0 h-full w-full md:static md:inset-auto md:h-auto md:w-auto':
        typeof _vm.objectFit === 'object' && !_vm.objectFit.pc && _vm.objectFit.sp,
      // object-fit: cover
      'object-cover': _vm.objectFit === 'cover' || (typeof _vm.objectFit === 'object' && _vm.objectFit.sp === 'cover'),
      'md:object-cover': typeof _vm.objectFit === 'object' && _vm.objectFit.pc === 'cover' && _vm.objectFit.sp !== 'cover',
      // object-fit: contain
      'object-contain': _vm.objectFit === 'contain' || (typeof _vm.objectFit === 'object' && _vm.objectFit.sp === 'contain'),
      'md:object-contain': typeof _vm.objectFit === 'object' && _vm.objectFit.pc === 'contain' && _vm.objectFit.sp !== 'contain',
      // object-fit: scale-down
      'object-scale-down':
        _vm.objectFit === 'scale-down' || (typeof _vm.objectFit === 'object' && _vm.objectFit.sp === 'scale-down'),
      'md:object-scale-down':
        typeof _vm.objectFit === 'object' && _vm.objectFit.pc === 'scale-down' && _vm.objectFit.sp !== 'scale-down',
      // fallback
      'md:object-fill': typeof _vm.objectFit === 'object' && _vm.objectFit.pc == null && _vm.objectFit.sp != null,
    },attrs:{"src":_vm.src || _vm.fallbackImage,"loading":_vm.noLazy ? false : 'lazy'}},'img',_vm.$attrs,false))])}
var staticRenderFns = []

export { render, staticRenderFns }