import dayjs from 'dayjs';
import { ValidationRule } from 'vee-validate/dist/types/types';

export const birthday: ValidationRule = {
  validate(value) {
    if (typeof value !== 'string') {
      return false;
    }

    const d = dayjs(value, 'YYYYMMDD', true).tz('Asia/Tokyo');

    // 日付の形式ではない（基本的に存在しない）
    if (!d.isValid()) {
      return '{_field_}は有効な日付ではありません';
    }

    const now = dayjs().tz('Asia/Tokyo');

    if (d.isAfter(now)) {
      return '未来の日付は設定できません';
    }

    const yearDiffs = now.year() - d.year();
    const thisYearBirthday = dayjs(new Date(now.year(), d.month(), d.date())).tz('Asia/Tokyo');
    const age = now.isBefore(thisYearBirthday) ? yearDiffs - 1 : yearDiffs;

    if (age < 20) {
      return '20歳未満のお客様は登録いただけません';
    }

    return true;
  },
};
