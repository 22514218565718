import { required } from 'vee-validate/dist/rules';
import { ValidationRule } from 'vee-validate/dist/types/types';

/**
 * チェックボックスにチェックが入っているか
 */
export const requiredCheckbox: ValidationRule = {
  message: 'チェックをつけてください',
  validate(value) {
    return required.validate(value, { allowFalse: false });
  },
};
