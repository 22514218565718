import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import type { RootStore } from '~/composables/useStore';
import { COOKIE_KEY } from '~/constants';
import { url } from '~/utils';

/**
 * 未認証であればログイン画面に飛ばし、ログイン後にアクセスしようとしたページに戻すミドルウェア
 */
export default defineNuxtMiddleware(({ redirect, route, app, ...context }) => {
  const store = context.store as RootStore;
  const { authenticated } = store.state.auth;

  if (authenticated) {
    return;
  }
  app.$cookies.set(COOKIE_KEY.RETURN_TO, route.fullPath, { path: '/' });

  redirect(url('AUTH_LOGIN'));
});
