import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ConsolsActionPayload } from '~/types/api';

export type Ctx = DefineActionContext<never, never, never>;

export const actions = {
  async fetchMailMagazine(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/mailMagazine', 'get'>) {
    return await this.$consolsAxios.$get('/api/v1/mailMagazine', payload);
  },
  async updateMailMagazine(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/mailMagazine', 'put'>) {
    return await this.$consolsAxios.$put('/api/v1/mailMagazine', payload);
  },
  async updateGuestMailMagazine(
    this: Vue,
    context: Ctx,
    payload: ConsolsActionPayload<'/api/v1/user/mailaddress/register', 'post'>
  ) {
    return await this.$consolsAxios.$post('/api/v1/user/mailaddress/register', payload);
  },
};

export type Store = DefineStoreModule<'user/magazine', never, never, never, typeof actions>;
