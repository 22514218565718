import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ECActionPayload } from '~/types/api';

export type Ctx = DefineActionContext<never, never, never>;

export const actions = {
  // ユーザ特定クーポン取得
  async fetchPersonalCoupons(this: Vue, context: Ctx, payload: ECActionPayload<'/api/v1/user_promotions', 'get'>) {
    const { is_first_purchase, promotions } = await this.$ecAxios.$get('/api/v1/user_promotions', payload);
    // 利用回数制限がない（nullのもの）、利用可能回数と利用回数の差分が 1以上のもの（まだ利用できるもの）のみを取得
    const personalPromotions = promotions.filter((coupon) => {
      if (!coupon.count) {
        return coupon;
      }
      return coupon.count - coupon.used_count > 0;
    });
    return { is_first_purchase, personalPromotions };
  },
};

export type Store = DefineStoreModule<'modules/mypage/coupon', never, never, never, typeof actions>;
