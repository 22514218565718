import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { RootStore } from '~/composables/useStore';

// 同じURLへのリンクをクリックしたときにスクロールを追加する
export default defineNuxtPlugin((context) => {
  const store = context.store as RootStore;

  // ヘッダーの高さを記録させるため $nextTick() 相当待機
  setTimeout(() => {
    // Chrome: SSR直後にヘッダーの高さが考慮されていないアンカー位置へジャンプするので矯正させる
    // Safari: SSR直後にアンカー位置へジャンプしないので手動でジャンプさせる
    try {
      // 初回ランディング時にアンカーリングの場所へジャンプさせる
      const anchorTargetElement = document.querySelector(decodeURIComponent(location.hash));

      if (!anchorTargetElement) return;

      const { headerHeight, headerMenuHeight } = store.state.internals.header;
      const { scrollX, scrollY } = window;
      const { top } = anchorTargetElement.getBoundingClientRect();

      // スクロール位置が上から100px以内（バナーなどが読み込まれてずれた場合を考慮）、
      // もしくは要素の画面内のY位置が2px以内（ボーダーの幅を考慮）のときのみジャンプ
      if (scrollY < 100 || Math.abs(top) < 2) {
        window.scrollTo(scrollX, top + scrollY - (headerHeight + headerMenuHeight));
      }
    } catch {
      // エラーになってもどうしようもないので無視する
    }
  }, 10);

  document.addEventListener(
    'click',
    (e) => {
      const { target: targetElement } = e;

      if (!(targetElement instanceof Element)) {
        return;
      }

      const anchorElement = targetElement instanceof HTMLAnchorElement ? targetElement : targetElement.closest('a');

      if (!anchorElement) {
        return;
      }

      const { href, target } = anchorElement;
      const url = new URL(href, window.location.origin);

      if (target === '_blank' || url.origin !== location.origin || url.pathname !== location.pathname) {
        return;
      }

      if (!url.hash) {
        window.scrollTo(0, 0);

        return;
      }

      const $targetElement = document.querySelector(decodeURIComponent(url.hash));

      if (!$targetElement) {
        return;
      }

      const { top } = $targetElement.getBoundingClientRect();
      const { headerHeight, headerMenuHeight } = store.state.internals.header;

      window.scrollTo(window.scrollX, top + window.scrollY - (headerHeight + headerMenuHeight));

      // 上記のコードでアンカー位置までジャンプしているので、既存のアンカーリンクの動作をキャンセルする
      e.preventDefault();
    },
    true
  );
});
