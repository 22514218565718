/**
 * ページに関係なく使用されるデータを保持する
 */
import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { BaseAxiosAction } from '~/types/api';
import { HelloBar, TopBanner } from '~/types/common';
import { ArticleType } from '~/enums';

export type State = {
  helloBar?: HelloBar;
  topBanner?: TopBanner;
  firstPurchasePromoClosed: boolean;
  isBasicInfoTab: boolean; // SP検索トップ用（デフォルト：基本情報タブ）
};

export const state = (): State => ({ firstPurchasePromoClosed: false, isBasicInfoTab: true });

export const mutations = {
  setHelloBar(state: State, payload: HelloBar) {
    Vue.typedSet(state, 'helloBar', payload);
  },
  setTopBanner(state: State, payload: TopBanner) {
    Vue.typedSet(state, 'topBanner', payload);
  },
  setFirstPurchasePromoClosed(state: State, value: boolean) {
    state.firstPurchasePromoClosed = value;
  },
  setIsBasicInfoTab(state: State, value: boolean) {
    state.isBasicInfoTab = value;
  },
};

export type Ctx = DefineActionContext<State, never, typeof mutations>;

export const actions = {
  async fetchHelloBar(this: Vue, { commit }: Ctx, payload: BaseAxiosAction): Promise<void> {
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      ...payload,
      query: {
        '_article_type[eq]': ArticleType.HelloBar,
        '_order[DESC]': 'publish_at',
        _limit: '1',
      },
    });

    const [{ contents }] = result;
    const { text, url } = contents;

    commit('setHelloBar', { text, url });
  },
  async fetchTopBanner(this: Vue, { commit }: Ctx, payload: BaseAxiosAction): Promise<void> {
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      ...payload,
      query: {
        '_article_type[eq]': ArticleType.TopBanner,
        '_order[DESC]': 'publish_at',
        _limit: '1',
      },
    });

    const [{ title = '', contents }] = result;
    const { pc_img, sp_img, target = false, url: link } = contents;

    commit('setTopBanner', {
      title,
      pc_img,
      sp_img,
      target,
      link,
    });
  },
};

export type Store = DefineStoreModule<'modules/general', State, never, typeof mutations, typeof actions>;
