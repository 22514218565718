type KarteEventType =
  | '_view_blog'
  | '_view_item'
  | '_view_category'
  | 'buy'
  | 'favorite'
  | 'favorite_content'
  | 'cart'
  | 'identify'
  | 'login'
  | 'signup'
  | 'wine_college_produciton_area'
  | 'wine_college_basic';

/**
 * KARTE：タグの設置
 * @param eventName タグのイベント名
 * @param params タグ発火時に送信するパラメータ
 */
export const karteTrack = async (eventName: KarteEventType, params?: any) => {
  if (!window.tracker) {
    return;
  }
  try {
    window.tracker.track(eventName, params);
  } catch (e) {
    try {
      window.tracker.track('_error');
    } catch (e) {
      // 何もしない
    }
  }
};
