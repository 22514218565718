import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import dayjs from 'dayjs';
import Vue from 'vue';
import { MERCHANT_ID } from '~/constants';
import { BaseAxiosAction } from '~/types/api';
import { ArticleType, ProductParentCategory } from '~/enums';

const checkUnread = (readAt?: string, updatedAt?: string): boolean => {
  const lastUpdatedAt = dayjs(updatedAt);
  const lastReadAt = dayjs(readAt);

  // 最終更新日が存在しない or 不正な日付であれば常に false
  if (!updatedAt || !lastUpdatedAt.isValid()) {
    return false;
  }

  // 最終閲覧日が存在しない or 不正な日付であれば常に true
  if (!readAt || !lastReadAt.isValid()) {
    return true;
  }

  return lastUpdatedAt.isAfter(lastReadAt);
};

export type State = {
  headerHeight: number;
  headerMenuHeight: number;
  headerOffsetTop: number;
  // 更新バッジ表示用
  // 更新日時
  archivesUpdatedAt?: string;
  articlesUpdatedAt?: string;
  staffReviewsUpdatedAt?: string;
  wineSetUpdatedAt?: string;
  // 閲覧日時
  archivesReadAt?: string;
  articlesReadAt?: string;
  staffReviewsReadAt?: string;
  wineSetReadAt?: string;
  // SPヘッダーの表示フラグ
  isHeaderVisible?: boolean;
};

export const state = (): State => ({
  headerHeight: 0,
  headerMenuHeight: 0,
  headerOffsetTop: 0,
});

export const getters = {
  isUnreadArchives(state: State) {
    return checkUnread(state.archivesReadAt, state.archivesUpdatedAt);
  },
  isUnreadArticles(state: State) {
    return checkUnread(state.articlesReadAt, state.articlesUpdatedAt);
  },
  isUnreadStaffReviews(state: State) {
    return checkUnread(state.staffReviewsReadAt, state.staffReviewsUpdatedAt);
  },
  isUnreadWineSet(state: State) {
    return checkUnread(state.wineSetReadAt, state.wineSetUpdatedAt);
  },
};

export const mutations = {
  setHeaderHeight(state: State, value: number) {
    state.headerHeight = value;
  },
  setHeaderMenuHeight(state: State, value: number) {
    state.headerMenuHeight = value;
  },
  setHeaderOffsetTop(state: State, value: number) {
    state.headerOffsetTop = value;
  },
  resetUpdatedAt(state: State) {
    Vue.typedDelete(state, 'archivesUpdatedAt');
    Vue.typedDelete(state, 'articlesUpdatedAt');
    Vue.typedDelete(state, 'staffReviewsUpdatedAt');
    Vue.typedDelete(state, 'wineSetUpdatedAt');
  },
  setArchivesUpdatedAt(state: State, value: string) {
    Vue.typedSet(state, 'archivesUpdatedAt', value);
  },
  setArticlesUpdatedAt(state: State, value: string) {
    Vue.typedSet(state, 'articlesUpdatedAt', value);
  },
  setStaffReviewsUpdatedAt(state: State, value: string) {
    Vue.typedSet(state, 'staffReviewsUpdatedAt', value);
  },
  setWineSetUpdatedAt(state: State, value: string) {
    Vue.typedSet(state, 'wineSetUpdatedAt', value);
  },
  setArchivesReadAt(state: State) {
    Vue.typedSet(state, 'archivesReadAt', dayjs().toISOString());
  },
  setArticlesReadAt(state: State) {
    Vue.typedSet(state, 'articlesReadAt', dayjs().toISOString());
  },
  setStaffReviewsReadAt(state: State) {
    Vue.typedSet(state, 'staffReviewsReadAt', dayjs().toISOString());
  },
  setWineSetReadAt(state: State) {
    Vue.typedSet(state, 'wineSetReadAt', dayjs().toISOString());
  },
  setIsHeaderVisible(state: State, value: boolean) {
    Vue.typedSet(state, 'isHeaderVisible', value);
  },
};

export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;

export const actions = {
  async fetchHeaderMenuBadges(this: Vue, { commit }: Ctx, { cancelToken }: BaseAxiosAction): Promise<void> {
    const timestamp = dayjs().toISOString();
    const [archiveResult, articleResult, staffReviewsResult, wineSetResult] = await Promise.allSettled([
      this.$cmsAxios.$get('/view_articles', {
        cancelToken,
        query: {
          '_article_type[eq]': ArticleType.Archives,
          'is_display_archive[bool][eq]': true,
          _limit: 1,
          '_order[DESC]': 'publish_at',
        },
      }),
      this.$cmsAxios.$get('/view_articles', {
        cancelToken,
        query: {
          '_article_type[eq]': ArticleType.Articles,
          'list_display_flag[bool][eq]': true,
          _limit: 1,
          '_order[DESC]': 'publish_at',
        },
      }),
      this.$staffStartAxios.$get('/v2/staff-reviews', {
        query: {
          merchant_id: MERCHANT_ID,
          count: 1,
          sort: 'time',
        },
      }),
      this.$searchAxios.$get('/select', {
        query: {
          wt: 'json',
          q: `mst:product AND product_category_sm: ${ProductParentCategory.WINE_SET} AND has_stock_flag_i: 1 NOT product_type_s: プリムール NOT sales_start_d: [${timestamp} TO *] NOT sales_end_d: [* TO ${timestamp}]`,
          sort: 'sort_newer_d desc',
          rows: 1,
        },
      }),
    ]);

    commit('resetUpdatedAt');

    if (archiveResult.status === 'fulfilled') {
      const publishedAt = archiveResult.value.result?.[0]?.publish_at;

      if (publishedAt) {
        commit('setArchivesUpdatedAt', publishedAt);
      }
    }

    if (articleResult.status === 'fulfilled') {
      const publishedAt = articleResult.value.result?.[0]?.publish_at;

      if (publishedAt) {
        commit('setArticlesUpdatedAt', publishedAt);
      }
    }

    if (staffReviewsResult.status === 'fulfilled') {
      const publishedAt = staffReviewsResult.value.item?.[0]?.published_at;

      if (publishedAt) {
        commit('setStaffReviewsUpdatedAt', publishedAt);
      }
    }

    if (wineSetResult.status === 'fulfilled') {
      const publishedAt = wineSetResult.value.response.docs[0]?.sort_newer_d;

      if (publishedAt) {
        commit('setWineSetUpdatedAt', publishedAt);
      }
    }
  },
};

export type Store = DefineStoreModule<'internals/header', State, typeof getters, typeof mutations, typeof actions>;
