export const CREDIT_CARD_BRAND: Record<string, string> = {
  '1': 'visa',
  '2': 'mastercard',
  '3': 'jcb',
  '4': 'american-express',
  '5': 'diners-club',
  '6': 'others',
};

export const TRANSITION_SOURCE: Record<string, string> = {
  CELLAR: 'cellar',
  PRIMEUR_CELLAR: 'primeurCellar',
  ORDER: 'order',
  ORDER_PAYMENT: 'orderPayment',
  SUBSCRIPTION: 'subscription',
  EVENT_PAYMENT: 'eventPayment',
  MYPAGE_EVENT_PAYMENT: 'mypageEventPayment',
  THREE_D_SECURE: '3DSecure',
  ORDER_COMPLETE: 'order3DSecure',
  EVENT_COMPLETE: 'event3DSecure',
};

// お支払い方法がクレジットカードの場合の表記
export const CARD_METHOD_NAME: string[] = [
  '登録済みクレジットカード',
  'クレジットカード',
  'クレジットカード(ベリトランス)', // TODO:検証用のためリリース後は外す
];
