import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { merge } from 'lodash-es';
import qs from 'qs';
import urlJoin from 'url-join';
import { RuntimeConfig } from '~/composables/useRuntimeConfig';
import { RootStore } from '~/composables/useStore';
import { COOKIE_KEY } from '~/constants';
import { url } from '~/utils';
import { APIError, APIName } from '~/utils/error/APIError';
import { consolsAuthPaths } from './auth/consols';
import { ecAuthPaths } from './auth/ec';
import { eventAuthPaths } from './auth/event';
import { rentalCellarAuthPaths } from './auth/rental-cellar';
import { consolsInternalPaths } from './internal/consols';
import { CustomAxiosRequestConfig, Methods, RequestPayload } from './types';

/** サポートしているメソッド */
const AVAILABLE_METHODS: Methods[] = ['get', 'post', 'put', 'delete', 'patch'];

/** 1フレーム待機する */
const sleepFrame = () => new Promise((resolve) => requestAnimationFrame(resolve));

export default defineNuxtPlugin(({ $axios, $cookies, $config, route, ...context }, inject) => {
  const { CONSOLS_API_ORIGIN, STAFF_START_API_ORIGIN } = $config as RuntimeConfig;
  const store = context.store as RootStore;

  // リクエスト中の Promise をプールするMap
  // NOTE: サーバーサイドでの実行時にメモリリークの懸念がある
  const requestPromises = new Map<string, Promise<AxiosResponse<any>>>();
  let authenticator: null | Promise<any> = null;

  const tokenRefresh = async (): Promise<void> => {
    if (!authenticator) {
      authenticator = $axios.$post('/_api/v1/refresh-token');
    }

    try {
      await authenticator;
    } finally {
      authenticator = null;
    }
  };

  const defineAxiosInstance = (
    apiName: APIName,
    rewritePath: (method: Methods, actualPath: string, payload: RequestPayload, originalPath: string) => string,
    customAxiosConfig: AxiosRequestConfig = {}
  ): [string, any] => {
    const requests = Object.fromEntries(
      AVAILABLE_METHODS.flatMap((method) => {
        return [method, `$${method}` as const].map((methodName) => {
          return [
            methodName,
            async (path: string, payload: RequestPayload, config: CustomAxiosRequestConfig = {}) => {
              try {
                const actualPath = path.replace(/{([^}]+)}/g, (matched, $1) => payload.path?.[$1] as any);
                const rewrotePath = rewritePath(method, actualPath, payload, path);
                const axiosConfig: AxiosRequestConfig = merge(
                  config,
                  { params: payload.query, cancelToken: payload.cancelToken },
                  customAxiosConfig
                );
                // axios の実行引数
                const axiosArgs = ['get', 'delete'].includes(method)
                  ? [rewrotePath, axiosConfig]
                  : [rewrotePath, payload.body, axiosConfig];

                const requestAxios = async (): Promise<any> => {
                  // Get メソッドの場合は同じリクエストを呼ばないよう Promise をキャッシュする
                  if (method === 'get') {
                    // Axios のキャンセルを待機するために 1フレーム 実行を待機する
                    if (process.client) {
                      await sleepFrame();
                    }

                    const cacheKey = `${rewrotePath}?${qs.stringify(payload.query || {})}`;
                    const hasCache = requestPromises.has(cacheKey);

                    try {
                      if (!hasCache) {
                        // @ts-expect-error
                        requestPromises.set(cacheKey, $axios[method](...axiosArgs));
                      }

                      const promise = requestPromises.get(cacheKey);

                      const response = await promise;

                      return methodName === '$get' ? response?.data : response;
                    } catch (error) {
                      // サーバーサイドの場合はリクエストがエラーだった場合のみキャッシュを削除（リトライのため）
                      if (process.server) {
                        requestPromises.delete(cacheKey);
                      }

                      throw APIError.getAPIError(apiName, error);
                    } finally {
                      if (process.client) {
                        requestPromises.delete(cacheKey);
                      }
                    }
                  }

                  try {
                    // Patch リクエストを Put リクエストに置き換える（ Akamai 対応 )
                    // FIXME: API側で完全に Patch が排斥されたら削除する
                    const optimizedMethodName =
                      methodName === 'patch' ? 'put' : methodName === '$patch' ? '$put' : methodName;

                    // @ts-expect-error
                    return await $axios[optimizedMethodName](...axiosArgs);
                  } catch (error) {
                    throw APIError.getAPIError(apiName, error);
                  }
                };

                try {
                  return await requestAxios();
                } catch (error) {
                  if (!APIError.isAPIError(error)) {
                    throw error;
                  }

                  // 退会済みユーザーの場合はトップへリダイレクトさせる（nuxtServerInitでログアウト処理も実施される）
                  if (
                    error.hasCode('vf_error_status_200_return_code_20') ||
                    error.hasCode('vf_error_status_200_return_code_21')
                  ) {
                    if (process.server) {
                      throw error;
                    }

                    location.href = url('TOP');

                    return;
                  }

                  // FIXME: 正しいエラーコードか確認する
                  const isTokenExpired = error.hasCode('401');

                  if (!isTokenExpired) {
                    throw error;
                  }

                  try {
                    await tokenRefresh();
                  } catch {
                    throw error;
                  }

                  return await requestAxios();
                }
              } catch (error) {
                if (!APIError.isAPIError(error)) {
                  throw error;
                }

                // FIXME: 正しいエラーコードか確認する
                const isTokenExpired = error.hasCode('401');

                if (!isTokenExpired) {
                  throw error;
                }

                store.commit('auth/setTokenExpired', true);

                // 状態の更新を伝播させる
                if (process.client) {
                  await sleepFrame();
                }

                $cookies.set(COOKIE_KEY.RETURN_TO, route.fullPath, { path: '/' });

                if (process.server) {
                  throw error;
                }

                location.href = url('AUTH_LOGIN');
              }
            },
          ];
        });
      })
    );

    return [`${apiName}Axios`, requests];
  };

  inject(
    ...defineAxiosInstance('ec', (method, path, payload, originalPath) => {
      const isAuthPath = ecAuthPaths.some((rule) => rule.method === method && rule.path === originalPath);

      return isAuthPath ? urlJoin('/_api/v1/auth', path) : urlJoin(CONSOLS_API_ORIGIN, path);
    })
  );

  inject(
    ...defineAxiosInstance('consols', (method, path, payload, originalPath) => {
      const isAuthPath = consolsAuthPaths.some((rule) => rule.method === method && rule.path === originalPath);
      const isInternalPath = consolsInternalPaths.some((rule) => rule.method === method && rule.path === originalPath);

      // body を含むリクエストか内部向けのパスであれば Proxy
      if (payload.body || isInternalPath) {
        return isAuthPath ? urlJoin('/_api/v1/internal-api-auth', path) : urlJoin('/_api/v1/internal-api', path);
      }

      return isAuthPath ? urlJoin('/_api/v1/auth', path) : urlJoin(CONSOLS_API_ORIGIN, path);
    })
  );

  inject(
    ...defineAxiosInstance('cms', (method, path) => {
      return urlJoin(CONSOLS_API_ORIGIN, path);
    })
  );

  inject(
    ...defineAxiosInstance('postal', (method, path) => {
      return urlJoin('/_proxy/postal/api/v1', path);
    })
  );

  inject(
    ...defineAxiosInstance(
      'search',
      (method, path) => {
        return urlJoin(CONSOLS_API_ORIGIN, path);
      },
      { paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
    )
  );

  inject(
    ...defineAxiosInstance(
      'recommend',
      (method, path) => {
        return urlJoin(CONSOLS_API_ORIGIN, path);
      },
      { paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
    )
  );

  inject(
    ...defineAxiosInstance('rentalCellar', (method, path, payload, originalPath) => {
      const isAuthPath = rentalCellarAuthPaths.some((rule) => rule.method === method && rule.path === originalPath);

      return isAuthPath ? urlJoin('/_api/v1/auth', path) : urlJoin('/_proxy', path);
    })
  );

  inject(
    ...defineAxiosInstance(
      'event',
      (method, path, payload, originalPath) => {
        const isAuthPath = eventAuthPaths.some((rule) => rule.method === method && rule.path === originalPath);

        return isAuthPath ? urlJoin('/_api/v1/auth', path) : urlJoin(CONSOLS_API_ORIGIN, path);
      },
      { paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' }) }
    )
  );

  inject(
    ...defineAxiosInstance('staffStart', (method, path) =>
      urlJoin(process.server ? STAFF_START_API_ORIGIN : '/_proxy/staff-start', path)
    )
  );
});
