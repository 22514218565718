import { onMounted, ref, watch } from '@nuxtjs/composition-api';
import { useStore } from '~/composables/useStore';
import { SESSION_KEY } from '~/constants';
import { useRandomValueCookie } from './useRandomValueCookie';

export const useFirstPurchasePromotion = () => {
  const store = useStore();
  const { hasPurchaseHistoryCookie } = useRandomValueCookie();

  const hasPurchaseHistory = ref(true);
  const hasClosedPromo = ref(false);
  const showLogosOnly = ref(false); // 一覧系画面用

  onMounted(async () => {
    if (hasPurchaseHistoryCookie.value && !sessionStorage.getItem(SESSION_KEY.HAS_CLOSED_PROMO)) {
      showLogosOnly.value = true;
    }

    if (sessionStorage.getItem(SESSION_KEY.HAS_CLOSED_PROMO)) {
      hasClosedPromo.value = true;
    }

    if (hasPurchaseHistoryCookie.value) {
      return;
    }

    hasPurchaseHistory.value = false;
  });

  // 一覧系画面で✕ボタンのクリックを検知したら非表示にする
  watch(
    () => store.state.modules.general.firstPurchasePromoClosed,
    () => {
      if (store.state.modules.general.firstPurchasePromoClosed) {
        hasClosedPromo.value = true;
      }
    }
  );

  // 一覧系画面の✕ボタンの動作
  const closePromotion = () => {
    hasClosedPromo.value = true;
    store.commit('modules/general/setFirstPurchasePromoClosed', true);
  };

  return {
    hasPurchaseHistory,
    hasClosedPromo,
    showLogosOnly,
    closePromotion,
  };
};
