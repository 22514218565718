import { ValidationRule } from 'vee-validate/dist/types/types';
import { definePreset } from '../core/preset';

/**
 * 会員基盤のメールアドレスバリデーションパターン
 * @see https://github.com/go-playground/validator/blob/e40bece34258ded65ca46f4e66272d73d0c8a9f3/regexes.go#L18
 * @example
 * Goの正規表現からJSの正規表現に直す（\x{1234} -> \u1234）
 * ```js
 * 'Go_RegExp_HERE'.replace(/\\x{([^}]+)}/g, `\\u$1`);
 * ```
 */
const EMAIL_PATTERN =
  // eslint-disable-next-line no-control-regex, no-useless-escape, unicorn/escape-case
  /^(?:(?:(?:(?:[a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(?:\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|(?:(?:\x22)(?:(?:(?:(?:\x20|\x09)*(?:\x0d\x0a))?(?:\x20|\x09)+)?(?:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:(?:[\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(?:(?:(?:\x20|\x09)*(?:\x0d\x0a))?(\x20|\x09)+)?(?:\x22))))@(?:(?:(?:[a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:(?:[a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])(?:[a-zA-Z]|\d|-|\.|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*(?:[a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(?:(?:[a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:(?:[a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])(?:[a-zA-Z]|\d|-|\.|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*(?:[a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;

/**
 * メールアドレスの文字列パターン
 */
export const emailPattern: ValidationRule = {
  message: '{_field_}は有効ではない入力パターンです',
  validate(value) {
    if (typeof value !== 'string') {
      return false;
    }

    return EMAIL_PATTERN.test(value);
  },
};

/**
 * メールアドレスのルールプリセット
 */
export const email = definePreset({
  rules: {
    max: 256,
    hankaku: true,
    emailPattern: true,
  },
});
