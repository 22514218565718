import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import { CancelToken } from 'axios';
import dayjs from 'dayjs';
import { orderBy } from 'lodash-es';
import Vue from 'vue';
import { BLOG_ARTICLES_PER_PAGE, BLOG_SEARCH_SORT_OPTIONS } from '~/constants';
import { nonNullable } from '~/utils';
import { BaseAxiosAction } from '~/types/api';
import { BlogArticle } from '~/types/modules/blog';
import { SearchAxiosInstance } from '~/plugins/api-axios/globals';

const fetchData = async (query: any, searchAxios: SearchAxiosInstance, cancelToken?: CancelToken | undefined) => {
  return await searchAxios.$get('/select', {
    query,
    cancelToken,
  });
};

/** Actions */
export type Ctx = DefineActionContext<{}, {}, {}>;
export const actions = {
  async fetchBlogArticles(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{
      shopId?: string;
      count?: number;
      sort?: string;
      year?: string;
      month?: string;
      listDisplayFlag?: number;
      detailDisplayFlag?: number;
      isCurrentlyPublished?: boolean;
      cancelToken?: CancelToken;
    }>
  ): Promise<{ items: BlogArticle[]; total: number }> {
    const { cancelToken, shopId, count, sort, year, month, listDisplayFlag, detailDisplayFlag, isCurrentlyPublished } =
      payload;

    const findSortOption = BLOG_SEARCH_SORT_OPTIONS.find((option) => {
      return option.value === sort;
    });

    const targetYearOption = !year ? '' : ` AND publish_year_s:${year}`;
    const targetYearMonthOption = !(year && month) ? '' : ` AND publish_year_month_s:${year}_${month}`;
    let targetSortOption = !findSortOption ? BLOG_SEARCH_SORT_OPTIONS[0].query : findSortOption.query;
    targetSortOption += ',shop_id_s asc';
    let q = 'mst:blog';
    // ショップIDが指定された場合
    if (shopId) {
      q += ` AND shop_id_s:${shopId}${targetYearOption}${targetYearMonthOption}`;
    }
    // ブログ表示フラグがある場合、クエリにフラグ設定を追加
    if (listDisplayFlag !== undefined && detailDisplayFlag !== undefined) {
      q += ` AND detail_display_flag_i:${detailDisplayFlag} AND list_display_flag_i:${listDisplayFlag}`;
    }
    // 公開中の記事だけを取得したい場合、クエリに期間設定を追加
    if (isCurrentlyPublished) {
      // API側は utc タイムゾーンを使っている
      const currentDate = dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
      q += ` AND publish_start_at_d:[2000-01-01T00:00:00.000Z TO ${currentDate}Z] AND publish_end_at_d:[${currentDate}Z TO *]`;
    }
    const queries = {
      wt: 'json',
      rows: count || BLOG_ARTICLES_PER_PAGE,
      q,
      sort: `${targetSortOption}`,
    };

    const { response } = await fetchData(queries, this.$searchAxios, cancelToken);
    const parse = (item: any) => {
      try {
        return {
          id: item.iid,
          title: item.title_s,
          authorName: item.author_name_s,
          description: item.description_t,
          permalink: item.permalink_s,
          publishedAt: item.publish_start_at_d,
          publishDate: item.publish_year_month_s,
          publishYear: item.publish_year_s,
          shopId: item.shop_id_s,
          shopPermalink: item.shop_permalink_s,
          mainImage: item.thumbnail_s,
          detailDisplayFlag: item.detail_display_flag_i,
          listDisplayFlag: item.list_display_flag_i,
        };
      } catch {
        return null;
      }
    };

    return {
      items: response.docs.map((value: any) => parse(value)).filter(nonNullable),
      total: response.numFound,
    };
  },

  /** 過去のブログオプション一覧取得 */
  async fetchBlogPublishOptions(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{
      shopId?: string;
      listDisplayFlag?: number;
      detailDisplayFlag?: number;
      isCurrentlyPublished?: boolean;
    }>
  ) {
    const { shopId, listDisplayFlag, detailDisplayFlag, isCurrentlyPublished } = payload;

    let q = `mst:blog AND shop_id_s:${shopId}`;
    // ブログ表示フラグがある場合、クエリにフラグ設定を追加
    if (listDisplayFlag !== undefined && detailDisplayFlag !== undefined) {
      q += ` AND detail_display_flag_i:${detailDisplayFlag} AND list_display_flag_i:${listDisplayFlag}`;
    }
    // 公開中の記事だけを取得したい場合、クエリに期間設定を追加
    if (isCurrentlyPublished) {
      // API側は utc タイムゾーンを使っている
      const currentDate = dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
      q += ` AND publish_start_at_d:[2000-01-01T00:00:00.000Z TO ${currentDate}Z] AND publish_end_at_d:[${currentDate}Z TO *]`;
    }

    const queries = {
      wt: 'json',
      q,
      facet: 'on',
      rows: 0,
      'facet.pivot': 'publish_year_month_s',
      'facet.sort': 'index',
    };

    const {
      facet_counts: {
        facet_pivot: { publish_year_month_s },
      },
    } = await fetchData(queries, this.$searchAxios);

    const options = publish_year_month_s.map((el: any) => {
      return { count: el.count, year: el.value.split('_')[0], month: parseInt(el.value.split('_')[1]) };
    });

    const sortedOptions = orderBy(options, 'month', 'desc');

    return sortedOptions.filter(nonNullable);
  },
};

/** Store Module Type */
export type Store = DefineStoreModule<'blog/master', {}, {}, {}, typeof actions>;
