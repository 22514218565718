import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import { map, find, findIndex, sortBy } from 'lodash-es';
import Vue from 'vue';
import { nonNullable } from '~/utils';
import { BaseAxiosAction, EventSchema } from '~/types/api';
import { ArticleType, CollegeLessonType, CollegeLessonStatus } from '~/enums';

/** State */
type LessonInfo = {
  id: number;
  title: string;
  articles: Array<{
    id: number;
    title: string;
  }>;
  quizIds: Array<string>;
};

export type State = {
  lessonInfo: LessonInfo;
  readArticleIds: Array<number>;
};

export const state = (): State => ({
  lessonInfo: {
    id: 0,
    title: '',
    articles: [],
    quizIds: [],
  },
  readArticleIds: [],
});

/** Mutations */
export const mutations = {
  setLessonInfo(state: State, lessonInfo: LessonInfo) {
    if (state.lessonInfo.id !== lessonInfo.id) {
      state.lessonInfo = lessonInfo;
    } else {
      if (lessonInfo.title) {
        state.lessonInfo.title = lessonInfo.title;
      }
      if (lessonInfo.articles.length > 0) {
        state.lessonInfo.articles = lessonInfo.articles;
      }
      if (lessonInfo.quizIds.length > 0) {
        state.lessonInfo.quizIds = lessonInfo.quizIds;
      }
    }
  },
  setReadArticleIds(state: State, readArticleIds: Array<number>) {
    state.readArticleIds = readArticleIds;
  },
  pushReadArticleId(state: State, readArticleId: number) {
    state.readArticleIds.push(readArticleId);
  },
};

/** Actions */
export type Ctx = DefineActionContext<State, never, typeof mutations>;
export const actions = {
  /**
   * 講座詳細を取得する
   * @param { number } lessonId - 講座ID
   * @return { CollegeProducingAreaLessonDetail | CollegeBasicLessonDetail } - 講座詳細を返す
   */
  async fetchLessonDetail(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ lessonId: number; articleType?: string }>
  ): Promise<any> {
    const { cancelToken, lessonId, articleType } = payload;
    const lessonDetail: any = { id: lessonId };

    const { result: lessonInfo = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_id[eq]': lessonId,
        '_article_type[eq]': articleType,
      },
    });
    lessonDetail.article_ids = map(sortBy(lessonInfo[0].contents.lesson_pages, 'order_number'), 'id').filter(
      nonNullable
    );
    lessonDetail.quiz_ids = map(sortBy(lessonInfo[0].contents.lesson_quizzes, 'order_number'), 'id').filter(
      nonNullable
    );

    switch (lessonInfo[0].article_type) {
      case ArticleType.ProducingAreaLesson: {
        // 産地マスタを叩く
        const { result: producingArea = [] } = await this.$cmsAxios.$get('/view_articles', {
          cancelToken,
          query: {
            '_article_type[eq]': ArticleType.CollegeProducingArea,
            '_article_id[eq]': lessonInfo[0].contents.producing_area,
          },
        });
        lessonDetail.lesson_type = CollegeLessonType.AREA;
        lessonDetail.area_id = producingArea[0].contents.producing_area_id || lessonDetail[0].contents.producing_area;
        lessonDetail.parent_area_id = producingArea[0].contents.parent_producing_area_id;
        lessonDetail.area_name_en = producingArea[0].contents.area_name_en;
        lessonDetail.area_name_ja = producingArea[0].contents.area_name_ja;
        lessonDetail.description = producingArea[0].contents.description;
        lessonDetail.main_image_pc = producingArea[0].contents.main_image_pc;
        lessonDetail.main_image_sp = producingArea[0].contents.main_image_sp;
        lessonDetail.national_flag_image = producingArea[0].contents.national_flag_image;
        break;
      }
      case ArticleType.BasicLesson: {
        // 講座チャプターを叩く
        const { result: chapters = [] } = await this.$cmsAxios.$get('/view_articles', {
          cancelToken,
          query: {
            '_article_type[eq]': ArticleType.BasicLessonChapter,
          },
        });
        // チャプターを表示順昇順に並べ替える
        chapters.sort((a, b) => Number(a.contents.display_order) - Number(b.contents.display_order));
        const chapterIndex = findIndex(chapters, (chapter) => {
          return map(chapter.contents.basic_lessons, 'id').includes(lessonId.toString());
        });
        /**
         * チャプターから講座情報を取り出し、次の講座IDを取得する
         * ・チャプター内の講座の場合　　　　　：次の講座（next_lesson_id）を表示
         * ・チャプター内最後の講座の場合　　　：次のチャプターの1番目を表示
         * ・最後のチャプターの最後の講座の場合：一番頭のチャプターの1番目の講座を表示
         */
        let sortedLessonAcrossChapter: any = [];
        chapters.forEach((chapter) => {
          sortedLessonAcrossChapter.push(map(chapter.contents.basic_lessons, 'id'));
        });
        sortedLessonAcrossChapter = sortedLessonAcrossChapter.flat().filter(nonNullable);
        const lessonIndex = findIndex(sortedLessonAcrossChapter, (lesson) => Number(lesson) === lessonId);
        const nextLessonIndex = lessonIndex < sortedLessonAcrossChapter.length - 1 ? lessonIndex + 1 : 0;

        lessonDetail.lesson_type = CollegeLessonType.BASIC;
        lessonDetail.chapter = chapterIndex + 1;
        lessonDetail.lesson_name = lessonInfo[0].contents.lesson_name;
        lessonDetail.description = lessonInfo[0].contents.description;
        lessonDetail.main_image_pc = lessonInfo[0].contents.main_image_pc;
        lessonDetail.main_image_sp = lessonInfo[0].contents.main_image_sp;
        lessonDetail.next_lesson_id =
          lessonInfo[0].contents.next_lesson_id || sortedLessonAcrossChapter[nextLessonIndex];
        break;
      }
    }

    return lessonDetail;
  },

  /**
   * CMSから目次タイトルを取得し、ストアにセットする
   * @param { string } lessonType - 講座の種類（産地 or 基礎 or 品種）
   * @param { number } lessonId - 講座ID
   * @param { Array<string> } lessonPageIds - 講座の中の読み物のIDたち
   */
  async fetchLessonArticleTitles(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ articleType: string; lessonId: number; articleIds: Array<string> }>
  ): Promise<void> {
    const { cancelToken, articleType, lessonId, articleIds } = payload;
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': articleType,
        '_article_id[in]': `[${articleIds.join(',')}]`,
      },
    });

    // NOTE: _article_id[in]は配列の順番関係なくarticle_idの小さい順から取得されるので、ページ順に並び替える
    const sortedResult: Array<{ id: number; title: string }> = [];
    articleIds.forEach((articleId) => {
      sortedResult.push({
        id: Number(articleId),
        title: find(result, (item) => item.article_id === Number(articleId)).contents.lesson_page_title,
      });
    });

    commit('setLessonInfo', {
      id: lessonId,
      title: '',
      articles: sortedResult,
      quizIds: [],
    });
  },

  /**
   * 講座の読み物の内容を取得する
   * @param { string } lessonType - 講座の種類（産地 or 基礎 or 品種）
   * @param { number } lessonId - 講座ID
   * @return { Array<any> } - 読み物のダイナミックパーツを返す
   */
  async fetchLessonArticle(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ articleType: string; articleId: number }>
  ): Promise<Array<any>> {
    const { cancelToken, articleType, articleId } = payload;
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      cancelToken,
      query: {
        '_article_type[eq]': articleType,
        '_article_id[eq]': articleId,
      },
    });
    return result[0].contents.__dynamic_parts;
  },

  /**
   * 既読の読み物一覧を取得する
   * @param { string } lessonType - 講座の種類（"BASIC", "BRAND", "PRODUCING_AREA"）
   * @param { number } lessonId - 講座ID
   * @return { Array<EventSchema<'ArticleSummary'>> } - 読み物IDと講座種別の配列を返す
   */
  async fetchReadLessonArticles(
    this: Vue,
    { commit }: Ctx,
    payload: BaseAxiosAction<{ lessonType: CollegeLessonType; lessonId: number }>
  ): Promise<void> {
    const { cancelToken, lessonType, lessonId } = payload;
    const result: Array<EventSchema<'ArticleSummary'>> = await this.$eventAxios.$get(
      '/api/v1/wine_college/article/status',
      {
        cancelToken,
        query: {
          lessonType,
          lessonId,
        },
      }
    );
    commit('setReadArticleIds', map(result, 'articleId'));
  },

  /**
   * 読み物の既読ステータスを登録する
   * @param { string } lessonType - 講座の種類（"BASIC", "BRAND", "PRODUCING_AREA"）
   * @param { number } lessonId - 講座ID
   */
  async postReadLessonArticle(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ lessonType: CollegeLessonType; lessonId: number; articleId: number }>
  ): Promise<void> {
    const { cancelToken, lessonType, lessonId, articleId } = payload;
    await this.$eventAxios.$post('/api/v1/wine_college/article/status', {
      cancelToken,
      query: {},
      body: {
        lessonType,
        lessonId,
        articleId,
      },
    });
  },

  /**
   * 受講済みの受講ステータスを登録する
   * @param { CollegeLessonType } lessonType - 講座種別
   * @param { number } lessonId - 講座ID
   */
  async putCompletedLessonStatus(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ lessonType: CollegeLessonType; lessonId: number }>
  ): Promise<void> {
    const { cancelToken, lessonType, lessonId } = payload;
    await this.$eventAxios.$put('/api/v1/wine_college/lesson/status', {
      cancelToken,
      query: {},
      body: {
        lessonType,
        lessonId,
        lessonStatus: CollegeLessonStatus.COMPLETED,
      },
    });
  },
};

/** Store Module Type */
export type Store = DefineStoreModule<'college/detail', State, never, typeof mutations, typeof actions>;
