import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import { cloneDeep, uniq } from 'lodash-es';
import Vue from 'vue';
import { ShopInfo } from '~/types/modules/event';

/** State */
export interface State {
  keyword: string | undefined;
  yearMonth: string[];
  prefectureId: number[];
  shop: string[];
  shopData: ShopInfo[];
  availableOnly: boolean | undefined;
  wineType: string[];
  category: string[];
}

export const initialState: State = {
  keyword: undefined,
  yearMonth: [],
  prefectureId: [],
  shop: [],
  shopData: [],
  availableOnly: undefined,
  wineType: [],
  category: [],
};

export const state = (): State => cloneDeep(initialState);

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setFieldValue<Field extends keyof State>(state: State, payload: { field: Field; value: State[Field] }): void {
    const nextValue: any = Array.isArray(payload.value) ? uniq(payload.value) : payload.value;

    Vue.typedSet(state, payload.field, nextValue);
  },
  resetFieldValue<Field extends keyof State>(state: State, field: Field): void {
    Vue.typedSet(state, field, initialState[field]);
  },
  resetAll(state: State): void {
    Object.entries(initialState).forEach(([key, value]) => Vue.set(state, key, cloneDeep(value)));
  },
};

/** Actions */
export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;
export const actions = {};

/** Store Module Type */
export type Store = DefineStoreModule<'event/filter', State, typeof getters, typeof mutations, typeof actions>;
