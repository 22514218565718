import { onBeforeMount, ref, useContext } from '@nuxtjs/composition-api';
import crypto from 'crypto';
import dayjs from 'dayjs';
import { COOKIE_KEY } from '~/constants';

export const useRandomValueCookie = () => {
  const { app } = useContext();
  const silverEggCookie = ref('');
  const hasPurchaseHistoryCookie = ref('');

  onBeforeMount(() => {
    silverEggCookie.value = getCookieValue(COOKIE_KEY.SILVER_EGG_RANDOM);
    hasPurchaseHistoryCookie.value = getCookie(COOKIE_KEY.HAS_PURCHASE_HISTORY);
  });

  const getCookieValue = (key: string) => {
    const cookie = getCookie(key);
    if (cookie) return cookie;

    setCookie(key);
    return getCookie(key);
  };

  const getCookie = (key: string) => {
    return app.$cookies.get(key);
  };

  // ランダムの値を生成してcookieとして設定する
  const setCookie = (key: string) => {
    if (getCookie(key)) return;

    const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // 全アルファベットと数字
    const arr32 = Array.from(crypto.randomFillSync(new Uint8Array(32)));
    const randomValue = arr32.map((n: number) => S[n % S.length]).join('');

    let expireAt;
    switch (key) {
      case COOKIE_KEY.SILVER_EGG_RANDOM:
      case COOKIE_KEY.HAS_PURCHASE_HISTORY:
        expireAt = dayjs().add(10, 'year').endOf('year').toDate();
        break;
      case COOKIE_KEY.AGE_CHECK_YES:
      case COOKIE_KEY.AGE_CHECK_NO:
        expireAt = dayjs().add(1, 'day').toDate();
        break;
    }

    app.$cookies.set(key, randomValue, {
      expires: expireAt,
      secure: true,
      sameSite: 'none',
    });
  };

  return {
    setCookie,
    silverEggCookie,
    hasPurchaseHistoryCookie,
  };
};
