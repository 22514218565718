import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ECActionPayload } from '~/types/api';
import { UserDistributionCourseSubscriptionIndex as Subscription } from '~/types/modules/mypage';

/** State */
export interface State {
  subscriptions: Subscription[];
}

export const state = (): State => ({
  subscriptions: [],
});

/** Getters */
export const getters = {
  subscription:
    (state: State) =>
    (id: number): Subscription | undefined => {
      return state.subscriptions.find((subscription) => subscription.id === id);
    },
};

/** Mutations */
export const mutations = {
  setSubscriptions(state: State, payload: Subscription[]) {
    state.subscriptions = payload;
  },
  setFieldValue<Field extends keyof State>(state: State, payload: { field: Field; value: State[Field] }): void {
    Vue.typedSet(state, payload.field, payload.value);
  },
};

/** Actions */
export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;
export const actions = {
  // 頒布会申し込み中一覧（全件）
  async fetchSubscriptions(this: Vue, { commit }: Ctx) {
    // 一応リクエスト数の上限を設定しておく
    const MAX_REQUEST_COUNT = 5;
    let subscriptions: Subscription[] = [];
    let page = 1;

    while (page <= MAX_REQUEST_COUNT) {
      const result = await this.$ecAxios.$get('/api/v1/distribution_course_subscriptions', {
        // 一度に取得できる上限は40
        query: { limit: 40, page },
      });

      if (!result) {
        commit('setSubscriptions', []);
        return;
      }
      // @ts-ignore
      subscriptions = subscriptions.concat(result.data);

      if (subscriptions.length >= Number(result.total)) {
        commit('setSubscriptions', subscriptions);
        return;
      }
      page++;
    }
  },
  // 頒布会申し込み中一覧
  async getDistributionCourseSubscriptions(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/distribution_course_subscriptions', 'get'>
  ) {
    return await this.$ecAxios.$get('/api/v1/distribution_course_subscriptions', payload);
  },
  // 頒布会受注日時変更選択肢一覧
  async getDistributionCourseDeliveryDates(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/distribution_course_subscription_orders/{id}/delivery_dates', 'get'>
  ) {
    return await this.$ecAxios.$get('/api/v1/distribution_course_subscription_orders/{id}/delivery_dates', payload);
  },
  // 頒布会受注変更
  async updateDistributionCourseOrders(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/distribution_course_orders/{id}', 'put'>
  ) {
    return await this.$ecAxios.$put('/api/v1/distribution_course_orders/{id}', payload);
  },
  // 頒布会申し込み更新
  async updateDistributionCourseSubscriptions(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/distribution_course_subscriptions/{id}', 'put'>
  ) {
    return await this.$ecAxios.$put('/api/v1/distribution_course_subscriptions/{id}', payload);
  },
  async fetchMostRecentOrder(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/distribution_course_subscriptions/recent_orders', 'get'>
  ) {
    return await this.$ecAxios.$get('/api/v1/distribution_course_subscriptions/recent_orders', payload);
  },
};

export type Store = DefineStoreModule<
  'modules/mypage/distributionCourseSubscription',
  State,
  typeof getters,
  typeof mutations,
  typeof actions
>;
