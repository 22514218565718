/**
 * キーワードカテゴリ
 * 2023/6/8時点での定義：https://docs.google.com/spreadsheets/d/18gHpSY5hmdbbpKJ95O6LznBW5qkkQK21z9yF6elcD4k/edit#gid=0
 * ※EC側で管理しているマスタと同じ内容、並び順にしておくこと
 */
export const TAG_CATEGORY = [
  { name: 'スタイル' },
  { name: '形状・サイズ' },
  { name: '高評価・注目' },
  { name: 'ペアリング' },
  { name: 'ラベルデザイン' },
  { name: 'その他' },
];
