import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ConsolsActionPayload, EventActionPayload } from '~/types/api';

/** State */
export interface State {
  hasAddedCreditCard: Boolean;
}

export const state = (): State => ({
  hasAddedCreditCard: false,
});

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setFieldValue<Field extends keyof State>(state: State, payload: { field: Field; value: State[Field] }): void {
    Vue.typedSet(state, payload.field, payload.value);
  },
  setHasAddedCreditCard(state: State, payload: State['hasAddedCreditCard']): void {
    Vue.typedSet(state, 'hasAddedCreditCard', payload);
  },
};

export type Ctx = DefineActionContext<never, never, never>;

export const actions = {
  // クレジットカード情報取得
  async getCreditCard(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/multiple-cards', 'get'>) {
    const response = await this.$consolsAxios.$get('/api/v1/multiple-cards', payload);
    return response.multipleCards;
  },
  // クレジットカード一覧取得
  async getAllCreditCards(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/multiple-cards/all', 'get'>) {
    const response = await this.$consolsAxios.$get('/api/v1/multiple-cards/all', payload);
    return response.multipleCards;
  },
  // クレジットカード情報の更新
  async updateCreditCard(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/multiple-cards', 'post'>) {
    return await this.$consolsAxios.$post('/api/v1/multiple-cards', payload);
  },
  async deleteCreditCard(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/multiple-cards', 'delete'>) {
    const response = await this.$consolsAxios.$delete('/api/v1/multiple-cards', payload);
    return response.multipleCards;
  },
  // イベント予約の存在有無をチェック
  async fetchEventCardStatus(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/checks/event/is_card_deletable', 'get'>
  ) {
    return await this.$eventAxios.$get('/api/v1/checks/event/is_card_deletable', payload);
  },
};

export type Store = DefineStoreModule<
  'modules/mypage/creditCard',
  State,
  typeof getters,
  typeof mutations,
  typeof actions
>;
