import { paths as Paths } from '~/types/api/openapi/rental-cellar';

// 存在するメソッドとパスのオブジェクト
type RentalCellarMethodPath = {
  [Path in keyof Paths]: {
    [Method in keyof Paths[Path]]: {
      method: Method;
      path: Path;
    };
  }[keyof Paths[Path]];
}[keyof Paths];

// 認証が必要なパスとメソッドの対応
export const rentalCellarAuthPaths: RentalCellarMethodPath[] = [
  { method: 'get', path: '/cellar/api/v1/wine_banks/count' },
  { method: 'get', path: '/cellar/api/v1/bills' },
  { method: 'post', path: '/cellar/api/v1/payment' },
  { method: 'get', path: '/cellar/api/v1/bills/{orderId}' },
  { method: 'post', path: '/cellar/api/v1/bills/{orderId}/receipt' },
  { method: 'get', path: '/cellar/api/v1/cellar_out_requests' },
  { method: 'get', path: '/cellar/api/v1/only_cellar_products' },
  { method: 'get', path: '/cellar/api/v1/only_cellar_products/count' },
  { method: 'get', path: '/cellar/api/v1/only_cellar_products/{identifyingCode}' },
  { method: 'get', path: '/cellar/api/v1/cellar_out_requests/{requestNo}' },
  { method: 'get', path: '/cellar/api/v1/payment' },
  { method: 'patch', path: '/cellar/api/v1/payment' },
  { method: 'get', path: '/cellar/api/v1/only_cellar_products/search_condition' },
  { method: 'get', path: '/cellar/api/v1/mix_cellar_products' },
  { method: 'get', path: '/cellar/api/v1/mix_cellar_products/count' },
  { method: 'get', path: '/cellar/api/v1/mix_cellar_products/{identifyingCode}' },
  { method: 'patch', path: '/cellar/api/v1/mix_cellar_products/{identifyingCode}' },
  { method: 'patch', path: '/cellar/api/v1/only_cellar_products/{identifyingCode}' },
  { method: 'get', path: '/cellar/api/v1/cellar_out_requests/simulation' },
  { method: 'get', path: '/cellar/api/v1/shipment_restriction' },
  { method: 'get', path: '/cellar/api/v1/bills/error' },
  { method: 'post', path: '/cellar/api/v1/gift_restriction' },
  { method: 'post', path: '/cellar/api/v1/cellar_out_requests' },
  { method: 'post', path: '/cellar/api/v1/gift_files' },
  { method: 'get', path: '/cellar/api/v1/bills/next' },
  { method: 'get', path: '/cellar/api/v1/cellar_products/csv' },
];
