import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { EventActionPayload, EventParamBody } from '~/types/api';
import { Schedule, EventQuestionnaireAnswers, EventMyPageCompanionInfoUpdate } from '~/types/modules/event';
import { EventPaymentType } from '~/enums';

export type State = {
  reservationInfo: {
    reservationId: number | null;
    eventId: number | null;
    requestBody: EventParamBody<'/api/v1/event_reservations/{reservationId}', 'put'>;
    schedule: Schedule;
    participantNum: number | null;
    paymentType: string | null;
  };
  // reservationInfoをlocalStorageに保存するため、これだけ別で管理
  econtextId?: string;
  // 予約済みの情報
  reservedSchedule: Schedule;
  reservedParticipantNum: number;
};

const DEFAULT_RESERVATION_INFO = {
  reservationId: null,
  eventId: null,
  requestBody: {
    applicant: {
      slotId: 0,
      econtextId: '',
    },
  },
  schedule: {
    date: '',
    start: '',
  },
  participantNum: null,
  paymentType: null,
};

export const state = (): State => ({
  reservationInfo: DEFAULT_RESERVATION_INFO,
  reservedSchedule: { date: '', start: '' },
  reservedParticipantNum: 0,
});

export const getters = {
  /** 店頭支払いで設定されているか */
  isShopPayment: (state: State) => {
    return state.reservationInfo.paymentType === EventPaymentType.SHOP;
  },
};

export const mutations = {
  setReservationId(state: State, payload: number) {
    state.reservationInfo.reservationId = payload;
  },
  setEventId(state: State, payload: number) {
    state.reservationInfo.eventId = payload;
  },
  setSlotId(state: State, payload: number) {
    state.reservationInfo.requestBody.applicant.slotId = payload;
  },
  setQuestionnaireAnswer(state: State, payload: EventQuestionnaireAnswers) {
    state.reservationInfo.requestBody.applicant.questionnaire = payload;
  },
  setCompanions(state: State, payload: EventMyPageCompanionInfoUpdate[]) {
    state.reservationInfo.requestBody.accompanyingPerson = payload;
  },
  setSchedule(state: State, payload: Schedule) {
    state.reservationInfo.schedule = payload;
  },
  setParticipantNum(state: State, payload: number) {
    state.reservationInfo.participantNum = payload;
  },
  setPaymentType(state: State, payload: string) {
    state.reservationInfo.paymentType = payload;
  },
  setEcontextId(state: State, payload: string) {
    state.econtextId = payload;
  },
  setReservedSchedule(state: State, payload: Schedule) {
    state.reservedSchedule = payload;
  },
  setReservedParticipantNum(state: State, payload: number) {
    state.reservedParticipantNum = payload;
  },
  clearReservationInfo(state: State) {
    state.reservationInfo = DEFAULT_RESERVATION_INFO;
    state.econtextId = undefined;
  },
};

type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;

export const actions = {
  async fetchEventReservations(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/event_reservations', 'get'>
  ) {
    return this.$eventAxios.$get('/api/v1/event_reservations', payload);
  },
  async fetchEventReservationsWaiting(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/event_reservations_waiting', 'get'>
  ) {
    return this.$eventAxios.$get('/api/v1/event_reservations_waiting', payload);
  },
  async fetchEventReservationDetail(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/event_reservations/{reservationId}', 'get'>
  ) {
    return this.$eventAxios.$get('/api/v1/event_reservations/{reservationId}', payload);
  },
  async fetchWaitingEventReservationDetail(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/event_reservations/waiting/{reservationWaitingId}', 'get'>
  ) {
    return this.$eventAxios.$get('/api/v1/event_reservations/waiting/{reservationWaitingId}', payload);
  },
  async cancelReservedEvent(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/event_reservations/{reservationId}/cancel', 'post'>
  ) {
    return this.$eventAxios.$post('/api/v1/event_reservations/{reservationId}/cancel', payload);
  },
  async updateReservedEvent(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/event_reservations/{reservationId}', 'put'>
  ) {
    await this.$eventAxios.$put('/api/v1/event_reservations/{reservationId}', payload);
  },
};

export type Store = DefineStoreModule<'modules/mypage/event', State, typeof getters, typeof mutations, typeof actions>;
