/*
 * string から '-' を外す
 */
export const removeHyphens = (input: string) => {
  return input.replace(/-/g, '');
};

/*
 * 電話番号11桁形式 / 12桁形式からハイフンありの形式に変更
 */
export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return phoneNumber;
  if (phoneNumber.length === 10) {
    return phoneNumber.replace(/(\d{4})(\d{2})(\d{4})/, '$1-$2-$3');
  } else if (phoneNumber.length === 11) {
    return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (phoneNumber.length === 12) {
    return phoneNumber.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
  }
  return phoneNumber;
};

/*
 * 郵便番号7桁形式(0000000)からハイフンありの形式(000-0000)に変更
 */
export const formatZipCode = (zipCode: string | undefined) => {
  if (!zipCode || zipCode.length !== 7) return zipCode;
  return zipCode.replace(/(\d{3})(\d{4})/, '$1-$2');
};
