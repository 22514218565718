import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { RootStore } from '~/composables/useStore';
import { LOCAL_STORAGE_KEY } from '~/constants';

const LOCAL_STORAGE_VALUE = 'Y';

const waitFrame = () => new Promise((resolve) => requestAnimationFrame(resolve));

const waitVisibilityVisible = () =>
  new Promise<void>((resolve) => {
    // visibilitychange イベントに対応していなければ即時解決
    if (typeof document.onvisibilitychange === 'undefined' || document.visibilityState === 'visible') {
      return resolve();
    }

    document.addEventListener('visibilitychange', function handler() {
      if (document.visibilityState === 'hidden') {
        return;
      }

      document.removeEventListener('visibilitychange', handler);

      resolve();
    });
  });

export default defineNuxtPlugin(async (context) => {
  const store = context.store as RootStore;

  if (!store.state.auth.authenticated) {
    localStorage.removeItem(LOCAL_STORAGE_KEY.IS_AUTHENTICATED);
  } else {
    localStorage.setItem(LOCAL_STORAGE_KEY.IS_AUTHENTICATED, LOCAL_STORAGE_VALUE);
  }

  window.addEventListener('storage', async (ev) => {
    if (ev.key !== LOCAL_STORAGE_KEY.IS_AUTHENTICATED) {
      return;
    }

    const isAuthenticated = ev.newValue === LOCAL_STORAGE_VALUE;

    if (isAuthenticated === store.state.auth.authenticated) {
      return;
    }

    await waitVisibilityVisible();

    store.commit('auth/setTokenExpired', true);

    await waitFrame();

    location.reload();
  });
});
