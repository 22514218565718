import { DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import { RedirectSetting } from '~/types/modules/redirect';

export type State = {
  redirectConfig: RedirectSetting[];
};

export const state = (): State => ({
  redirectConfig: [],
});

export const mutations = {
  setRedirectConfig(state: State, redirectConfig: RedirectSetting[]) {
    state.redirectConfig = redirectConfig;
  },
};

export type Store = DefineStoreModule<'internals/redirect', State, never, typeof mutations, never>;
