import { computed } from '@nuxtjs/composition-api';
import { uniqueId } from 'lodash-es';
import { AlertBase } from '~/types/ui';
import { useStore } from './useStore';

export const useAlert = () => {
  const store = useStore();
  const alertQueue = computed(() => store.state.internals.alert.queue);
  const activeAlert = computed(() => alertQueue.value[0]);

  const openAlert = (payload: Omit<AlertBase, 'id'> & { prepend?: boolean }): { id: string } => {
    const { prepend = false, ...alertPayload } = payload;
    const id = uniqueId();

    if (prepend) {
      store.commit('internals/alert/prependAlert', { id, ...alertPayload });
    } else {
      store.commit('internals/alert/appendAlert', { id, ...alertPayload });
    }

    return { id };
  };

  const closeAlert = (alertId?: string): void => {
    store.commit('internals/alert/removeAlert', alertId);
  };

  return {
    activeAlert,
    alertQueue,
    openAlert,
    closeAlert,
  };
};
