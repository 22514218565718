import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ConsolsActionPayload, ConsolsResponse } from '~/types/api';
import { ClubEnotecaType } from '~/enums';

type UserInfo = ConsolsResponse<'/api/v1/user/info', 'get'>;

export type State = UserInfo;

export const store = (): State => ({});

export const getters = {
  /** Club Enoteca Visa会員かどうかのフラグ */
  isVisaMember: (state: State) => {
    if (!state.userBase?.clubenotecaType) {
      return false;
    }

    return [ClubEnotecaType.CLASSIC, ClubEnotecaType.GOLD].includes(state.userBase.clubenotecaType as ClubEnotecaType);
  },
};

export const mutations = {
  setUserBase(state: State, payload: UserInfo['userBase']): void {
    Vue.typedSet(state, 'userBase', payload);
  },
  setProfile(state: State, payload: UserInfo['profile']): void {
    Vue.typedSet(state, 'profile', payload);
  },
  setOther(state: State, payload: UserInfo['other']): void {
    Vue.typedSet(state, 'other', payload);
  },
  setDeliveries(state: State, payload: UserInfo['deliveries']): void {
    Vue.typedSet(state, 'deliveries', payload);
  },
  setFlag(state: State, payload: UserInfo['flag']): void {
    Vue.typedSet(state, 'flag', payload);
  },
  setCellar(state: State, payload: UserInfo['cellar']): void {
    Vue.typedSet(state, 'cellar', payload);
  },
  cleanUp(state: State): void {
    Vue.typedDelete(state, 'userBase');
    Vue.typedDelete(state, 'profile');
    Vue.typedDelete(state, 'other');
    Vue.typedDelete(state, 'deliveries');
    Vue.typedDelete(state, 'flag');
    Vue.typedDelete(state, 'cellar');
  },
};

export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;

export const actions = {
  /**
   * userBase, profile, other, delvieries, flag, cellar を一括で取得する
   */
  async fetchUserInfo(
    this: Vue,
    { commit }: Ctx,
    payload: ConsolsActionPayload<'/api/v1/user/info', 'get'>
  ): Promise<void> {
    const { userBase, profile, other, deliveries, flag, cellar } = await this.$consolsAxios.$get(
      '/api/v1/user/info',
      payload
    );

    commit('setUserBase', userBase);
    commit('setProfile', profile);
    commit('setOther', other);
    commit('setDeliveries', deliveries);
    commit('setFlag', flag);
    commit('setCellar', cellar);
  },
  // -- UserBase --
  /**
   * userBase を単体で取得する
   */
  async fetchUserBase(this: Vue, { commit }: Ctx, payload: ConsolsActionPayload<'/api/v1/user', 'get'>): Promise<void> {
    const { userBase } = await this.$consolsAxios.$get('/api/v1/user', payload);

    commit('setUserBase', userBase);
  },
  /**
   * userBase を更新する
   */
  async updateUserBase(
    this: Vue,
    { commit }: Ctx,
    payload: ConsolsActionPayload<'/api/v1/user', 'put'>
  ): Promise<void> {
    const { userBase } = await this.$consolsAxios.$put('/api/v1/user', payload);

    commit('setUserBase', userBase);
  },
  // -- Profile --
  /**
   * profile を単体で取得する
   */
  async fetchProfile(
    this: Vue,
    { commit }: Ctx,
    payload: ConsolsActionPayload<'/api/v1/profile', 'get'>
  ): Promise<void> {
    const { profile } = await this.$consolsAxios.$get('/api/v1/profile', payload);

    commit('setProfile', profile);
  },
  /**
   * profile を更新する
   */
  async updateProfile(
    this: Vue,
    { commit }: Ctx,
    payload: ConsolsActionPayload<'/api/v1/profile', 'put'>
  ): Promise<void> {
    const { profile } = await this.$consolsAxios.$put('/api/v1/profile', payload);

    commit('setProfile', profile);
  },
  /**
   * ユーザー情報が更新される時のメール送信
   */
  async sendNotificationEmail(
    this: Vue,
    context: Ctx,
    payload: ConsolsActionPayload<'/api/v1/user/mail/update', 'post'>
  ): Promise<void> {
    await this.$consolsAxios.$post('/api/v1/user/mail/update', payload);
  },
  // -- Other --
  /**
   * other を単体で取得する
   */
  async fetchOther(this: Vue, { commit }: Ctx, payload: ConsolsActionPayload<'/api/v1/other', 'get'>): Promise<void> {
    const { other } = await this.$consolsAxios.$get('/api/v1/other', payload);

    commit('setOther', other);
  },
  /**
   * other を更新する
   */
  async updateOther(this: Vue, { commit }: Ctx, payload: ConsolsActionPayload<'/api/v1/other', 'put'>): Promise<void> {
    const { other } = await this.$consolsAxios.$put('/api/v1/other', payload);

    commit('setOther', other);
  },
  // -- Deliveries --
  /**
   * deliveries をすべて取得する
   */
  async fetchDeliveriesAll(
    this: Vue,
    { commit }: Ctx,
    payload: ConsolsActionPayload<'/api/v1/deliveries/all', 'get'>
  ): Promise<void> {
    const { deliveries } = await this.$consolsAxios.$get('/api/v1/deliveries/all', payload);

    commit('setDeliveries', deliveries);
  },
  /**
   * deliveries を登録する
   */
  async registerDeliveries(
    this: Vue,
    { commit }: Ctx,
    payload: ConsolsActionPayload<'/api/v1/deliveries', 'post'>
  ): Promise<void> {
    await this.$consolsAxios.$post('/api/v1/deliveries', payload);

    // プライマリフラグの変更や並びの変更に対応するため、全件再取得
    const { cancelToken } = payload;
    const { deliveries } = await this.$consolsAxios.$get('/api/v1/deliveries/all', { cancelToken });

    commit('setDeliveries', deliveries);
  },
  /**
   * deliveries を更新する
   */
  async updateDeliveries(
    this: Vue,
    { commit }: Ctx,
    payload: ConsolsActionPayload<'/api/v1/deliveries', 'put'>
  ): Promise<void> {
    await this.$consolsAxios.$put('/api/v1/deliveries', payload);

    // プライマリフラグの変更や並びの変更に対応するため、全件再取得
    const { cancelToken } = payload;
    const { deliveries } = await this.$consolsAxios.$get('/api/v1/deliveries/all', { cancelToken });

    commit('setDeliveries', deliveries);
  },
  /**
   * 指定した住所を削除してdeliveriesを更新する
   */
  async deleteDeliveries(
    this: Vue,
    { commit }: Ctx,
    payload: ConsolsActionPayload<'/api/v1/deliveries', 'delete'>
  ): Promise<void> {
    await this.$consolsAxios.$delete('/api/v1/deliveries', payload);

    // プライマリフラグの変更や並びの変更に対応するため、全件再取得
    const { cancelToken } = payload;
    const { deliveries } = await this.$consolsAxios.$get('/api/v1/deliveries/all', { cancelToken });

    commit('setDeliveries', deliveries);
  },
  // -- Other --
  /**
   * flag を単体で取得する
   */
  async fetchFlag(this: Vue, { commit }: Ctx, payload: ConsolsActionPayload<'/api/v1/flag', 'get'>): Promise<void> {
    const { flag } = await this.$consolsAxios.$get('/api/v1/flag', payload);

    commit('setFlag', flag);
  },
  /**
   * flag を更新する
   */
  async updateFlag(this: Vue, { commit }: Ctx, payload: ConsolsActionPayload<'/api/v1/flag', 'put'>): Promise<void> {
    const { flag } = await this.$consolsAxios.$put('/api/v1/flag', payload);

    commit('setFlag', flag);
  },
  // -- Cellar --
  /**
   * cellar を単体で取得する
   */
  async fetchCellar(this: Vue, { commit }: Ctx, payload: ConsolsActionPayload<'/api/v1/cellar', 'get'>): Promise<void> {
    const { cellar } = await this.$consolsAxios.$get('/api/v1/cellar', payload);

    commit('setCellar', cellar);
  },
  /**
   * cellar を更新する
   */
  async updateCellar(
    this: Vue,
    { commit }: Ctx,
    payload: ConsolsActionPayload<'/api/v1/cellar', 'put'>
  ): Promise<void> {
    const { cellar } = await this.$consolsAxios.$put('/api/v1/cellar', payload);

    commit('setCellar', cellar);
  },
};

export type Store = DefineStoreModule<'user/info', State, typeof getters, typeof mutations, typeof actions>;
