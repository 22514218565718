import { AreaOption } from '~/types/common';
import { FormCheckboxOption, FormRadioOption, FormSelectOption } from '~/types/ui';

export const REVIEWS_PER_PAGE = 20;

export const STAFFS_PER_PAGE = 20;
export const MAX_STAFF_COUNT = 120;

export const TOP_REVIEW_COUNT = 4;

export const SAME_PRODUCT_OTHER_REVIEW_COUNT = 2;

export const MERCHANT_ID = '0aedac0847fbf9d60ff40ea7ed1605a0';

// スタッフレビューＰＶ計測タグ（コンテンツ種類）
export const CONTENT_TYPE = '10';

/** スタッフレビュー検索のURLクエリパラメータ */
export const STAFF_REVIEW_SEARCH_QUERY_PARAMETERS = {
  SORT: '_s',
  TYPE: '_type',
  AREA_1: '_area1',
  AREA_2: '_area2',
  PRICE: '_price',
  TAG: '_tag',
};

/** スタッフ一覧検索のURLクエリパラメータ */
export const STAFF_LIST_SEARCH_QUERY_PARAMETER = {
  SHOP: '_shop',
};

export const STAFF_LIST_DEFAULT_SEARCH_PARAMETER = {
  id: null,
  label: '全ての店舗',
};

/** スタッフレビュー検索価格条件 */
export const STAFF_REVIEW_SEARCH_PRICE_OPTIONS: (FormRadioOption & { from?: number; to?: number })[] = [
  {
    text: 'すべて',
    value: '',
  },
  {
    text: '～￥2,999',
    value: '1',
    to: 2999,
  },
  {
    text: '￥3,000～￥9,999',
    value: '2',
    from: 3000,
    to: 9999,
  },
  {
    text: '￥10,000～',
    value: '3',
    from: 10000,
  },
];

/** スタッフレビュー検索ワインタイプ条件 */
export const STAFF_REVIEW_SEARCH_TYPE_OPTIONS: FormCheckboxOption[] = [
  {
    text: '赤ワイン',
    value: '1',
  },
  {
    text: '白ワイン',
    value: '2',
  },
  {
    text: 'ロゼワイン',
    value: '3',
  },
  {
    text: 'スパークリングワイン',
    value: '5',
  },
];

export const STAFF_REVIEW_SEARCH_AREA_OPTIONS_UNSELECT: FormRadioOption[] = [
  {
    text: 'すべて',
    value: 'unselect',
  },
];

/** スタッフレビュー検索産地カテゴリー1条件 */
export const STAFF_REVIEW_SEARCH_AREA_OPTIONS_1: AreaOption[] = [
  {
    id: '100',
    label: 'フランス',
    area2: [
      {
        id: '100_1000,100_1000_1001,100_1000_1002,100_1000_1003,100_1000_1004,100_1000_1006,100_1000_1007,100_1000_1008,100_1000_1009,100_1000_1012,100_1000_1060,100_1000_1500,100_1000_80010,100_1000_80090',
        label: 'ボルドー',
        area3: [],
      },
      {
        id: '100_2000,100_2000_2001,100_2000_2039_2004,100_2000_2039_2005,100_2000_2039_2006,100_2000_2039_2007,100_2000_2039_2008,100_2000_2039_2009,100_2000_2039_2002,100_2000_2039_2003,100_2000_2042_2010,100_2000_2042_2011,100_2000_2042_2012,100_2000_2042_2013,100_2000_2042_2014,100_2000_2042_2015,100_2000_2042_2016,100_2000_2042_2017,100_2000_2042_2022,100_2000_2042_2023,100_2000_2042_2018,100_2000_2042_2019,100_2000_2042_2020,100_2000_2042_2021,100_2000_2042_2024,100_2000_2042_2025,100_2000_80146_2026,100_2000_80146_2027,100_2000_80146_2028,100_2000_80146_2029,100_2000_80146_2030,100_2000_80212_2031,100_2000_80212_2032,100_2000_80212_2033,100_2000_2034,100_2000_2039_2035,100_2000_2042_2036,100_2000_2037,100_2000_2038,100_2000_2039,100_2000_2040,100_2000_2041,100_2000_2042,100_2000_2042_2043,100_2000_2045,100_2000_2042_80036,100_2000_80146,100_2000_80212',
        label: 'ブルゴーニュ',
        area3: [],
      },
      { id: '100_3001', label: 'シャンパーニュ', area3: [] },
      { id: '100_3100,100_3100_80012,100_3100_80013,100_3100_80014,100_3100_80015', label: 'ロワール', area3: [] },
      { id: '100_3200,100_3200_80192,100_3200_80193', label: 'ローヌ', area3: [] },
      { id: '100_3300', label: 'ラングドック・ルーション', area3: [] },
      { id: '100_3400', label: 'プロヴァンス', area3: [] },
      { id: '100_3500', label: 'アルザス', area3: [] },
      {
        id: '100,100_3900,100_3900_80190,100_3900_80190_80186,100_3900_80190_80060,100_3900_80190_80199,100_3900_80190_80200,100_3900_80190_80201,100_3900_80190_80202,100_3900_80035,100_3900_2044,100_3900_3600,100_3900_3700,100_3900_3800,100_3900_3901',
        label: 'その他フランス',
        area3: [],
      },
    ],
  },
  {
    id: '4000',
    label: 'イタリア',
    area2: [
      { id: '4000_4002', label: 'ピエモンテ', area3: [] },
      { id: '4000_4003', label: 'ロンバルディア', area3: [] },
      { id: '4000_4009,4000_4009_80074,4000_4009_80102,4000_4009_80103', label: 'トスカーナ', area3: [] },
      { id: '4000_4005', label: 'ヴェネト', area3: [] },
      { id: '4000_4006', label: 'フリウリ・ヴェネツィア・ジュリア', area3: [] },
      { id: '4000_4010', label: 'ウンブリア', area3: [] },
      { id: '4000_4018', label: 'プーリア', area3: [] },
      { id: '4000_4019', label: 'シチリア', area3: [] },
      { id: '4000_4021', label: 'サルデーニャ', area3: [] },
      {
        id: '4000,4000_80209_4012,4000_80209_4015,4000_80209_4018,4000_80209_4019,4000_80209_80102,4000_80209_80103,4000_80209_80209,4000_80209_5000,4000_80209_5001,4000_4016,4000_80209_80018,4000_80209',
        label: 'その他イタリア',
        area3: [],
      },
    ],
  },
];

/** スタッフレビュー検索産地カテゴリー2条件 */
export const STAFF_REVIEW_SEARCH_AREA_OPTIONS_2: FormRadioOption[] = [
  {
    text: 'ドイツ',
    value:
      '10000,10000_80037,10000_80149,10000_80150,10000_80151,10000_80152,10000_80153,10000_80154,10000_80158,10000_80159',
  },
  {
    text: 'スペイン',
    value:
      '8000,8000_8001,8000_8001_8008,8000_8001_80022,8000_8001_80024,8000_80162,8000_80162_8003,8000_80162_8004,8000_80163,8000_80163_8005,8000_8009,8000_8010,8000_8010_8002,8000_8010_80038,8000_8011,8000_8011_80023,8000_80164,8000_80164_8012,8000_8013,8000_8013_8006,8000_8013_8007,8000_8013_80021,8000_8013_80198,8000_80145,8000_80165,8000_80221',
  },
  {
    text: 'ポルトガル',
    value: '12000,12000_80166,12000_80167,12000_80168,12000_80169,12000_80170,12000_80171,12000_80172,12000_80191',
  },
  {
    text: 'アメリカ',
    value:
      '5000,5000_5001,5000_5001_80018_80017,5000_5001_80018_80018,5000_5001_80018,5000_5001_80018_80072,5000_5001_80072,5000_5001_80018_80017_80104,5000_5001_80018_80017_80105,5000_5001_80018_80017_80106,5000_5001_80018_80017_80107,5000_5001_80018_80017_80108,5000_5001_80018_80017_80109,5000_5001_80018_80017_80110,5000_5001_80018_80017_80111,5000_5001_80018_80017_80112,5000_5001_80018_80017_80113,5000_5001_80018_80017_80114,5000_5001_80018_80017_80115,5000_5001_80018_80017_80116,5000_5001_80018_80017_80117,5000_5001_80018_80018_80039,5000_5001_80018_80018_80118,5000_5001_80018_80018_80119,5000_5001_80018_80018_80120,5000_5001_80018_80018_80121,5000_5001_80018_80018_80122,5000_5001_80018_80018_80123,5000_5001_80018_80018_80124,5000_5001_80018_80018_80125,5000_5001_80018_80018_80126,5000_5001_80018_80072_80127,5000_5001_80072_80129,5000_5001_80072_80130,5000_5001_80072_80131,5000_5002,5000_5002_80128,5000_80053,5000_80053_80132,5000_80053_80133,5000_80053_80136,5000_80069,5000_80069_80134,5000_80069_80135',
  },
  {
    text: 'チリ',
    value:
      '6000,6000_6010_6001,6000_6010_6002,6000_6006_6003,6000_6006_6004,6000_6010_6005,6000_6006,6000_6010_6001_6007,6000_6010_6005_6008,6000_6010_6001_6007_6009,6000_6010,6000_6010_6001_6007_6011,6000_6010_80025,6000_80148_80026,6000_6010_6005_80068,6000_6006_80061,6000_80148,6000_80092',
  },
  {
    text: 'ニュージーランド',
    value: '9000,9000_80044,9000_80050,9000_80070,9000_80071,9000_80071_80099,9000_80196,9000_80197',
  },
  {
    text: 'オーストラリア',
    value:
      '11000,11000_80077,11000_80077_80083,11000_80077_80215,11000_80078,11000_80078_80084,11000_80078_80085,11000_80078_80086,11000_80078_80087,11000_80078_80188,11000_80078_80213,11000_80082,11000_80079,11000_80079_80189,11000_80080,11000_80080_80088,11000_80080_80089,11000_80080_80214,11000_80080_80220,11000_80081',
  },
  {
    text: '日本',
    value:
      '80000,80000_80001,80000_80002,80000_80003,80000_80004,80000_80005,80000_80006,80000_80007,80000_80008,80000_80009,80000_80034,80000_80047,80000_80020,80000_80054,80000_80057,80000_80076,80000_80100,80000_80101,80000_80139,80000_80144,80000_80147,80000_80187,80000_80195',
  },
];

export const STAFF_REVIEW_SEARCH_SORT_OPTIONS: (FormSelectOption & { query: string })[] = [
  {
    text: '新着順',
    value: '1',
    query: 'time',
  },
  {
    text: '人気順',
    value: '2',
    query: 'pv',
  },
];
