import { ref, onMounted, computed, useContext } from '@nuxtjs/composition-api';
import { noop } from 'lodash-es';
import { DisplayLang } from '~/enums';

type WOVNLang = {
  code: DisplayLang;
  name: string;
  en: string;
};

// シングルトンにするため、 useWOVNio外で定義する
// WOVNの初期化中は翻訳がかからないので、日本語とする
const currentLang = ref<WOVNLang>({
  code: DisplayLang.JA,
  name: '日本語',
  en: 'Japanese',
});

export const useWOVNio = () => {
  const { i18n } = useContext();

  const targetDisplayLang = computed(() => {
    return currentLang.value.code === DisplayLang.EN
      ? {
          code: DisplayLang.JA,
          name: i18n.t('Common.language.name.ja').toString(),
          selectionLabel: i18n.t('Common.language.selection.ja').toString(),
        }
      : {
          code: DisplayLang.EN,
          name: i18n.t('Common.language.name.en').toString(),
          selectionLabel: i18n.t('Common.language.selection.en').toString(),
        };
  });

  /** WOVN Widget API の初期化を待機する */
  const wovnApiReady = () =>
    new Promise<void>((resolve) => {
      if (!window.WOVN) {
        throw new Error('WOVN API is not loaded.');
      }

      if (window.WOVN.io.isApiReady()) {
        resolve();

        return;
      }

      window.addEventListener('wovnApiReady', () => {
        resolve();
      });
    });

  const updateCurrentLang = (newLang: string) => {
    if (!window.WOVN) {
      return;
    }
    window.WOVN.io.changeLang(newLang);
    currentLang.value = window.WOVN.io.getCurrentLang();
  };

  onMounted(() => {
    wovnApiReady()
      .then(() => {
        currentLang.value = window.WOVN.io.getCurrentLang();
      })
      .catch(noop);
  });

  return {
    currentLang,
    targetDisplayLang,
    updateCurrentLang,
  };
};
