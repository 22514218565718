import { AREA_ID } from '~/constants/area';
import { ECSchema } from '~/types/api';
import { ProductLabelColor, CommonProducingArea, ProducingAreas, AreaOption, Area2, Area3 } from '~/types/common';
import {
  ProductVariantPromotionShow,
  ProductVariantShow,
  ProductPriceSettings,
  ProductPrice,
  ProductMostInStockPriceSettings,
} from '~/types/modules/product';
import { PromotionsName, ProductCategoryId, WineColorText } from '~/enums';

export const getProductColorFromType = (color: string): ProductLabelColor => {
  switch (color) {
    case WineColorText.WHITE:
      return 'WineWhite';
    case WineColorText.ROSE:
    case WineColorText.ROSE_SPARKLING:
      return 'WineRose';
    case WineColorText.WHITE_SPARKLING:
    case WineColorText.SPARKLING_WINE:
    case WineColorText.SPARKLING:
      return 'WineSparkling';
    case WineColorText.ORANGE:
      return 'WineOrange';
    case WineColorText.MIX:
      return 'WineMix';
    default:
      return 'WineRed';
  }
};

/** 各種スパークリングの表記を「スパークリング」に変更、他はそのまま */
export const convertColorText = (color: string) => {
  switch (color) {
    case WineColorText.RED_SPARKLING:
    case WineColorText.WHITE_SPARKLING:
    case WineColorText.ROSE_SPARKLING:
    case WineColorText.SPARKLING_WINE:
      return WineColorText.SPARKLING;
    default:
      return color;
  }
};

/**
 * { key: string; value: string }の配列をオブジェクトに変換する
 * @param items { key: string; value: string }の配列
 * @returns 変換後のオブジェクト
 */
export const formatKeyValueArray = (items?: { key?: string; value?: string }[]) => {
  if (!items) return;
  return Object.fromEntries(items.map((el) => [el?.key, el?.value]));
};

/**
 * 指定した子商品オブジェクトを取得
 * @param productVariants 子商品の配列
 * @param productVariantId 取得したい子商品ID
 * @returns 指定した子商品オブジェクト
 */
export const getProductVariant = (
  productVariantId: number,
  productVariants?: ProductVariantShow[]
): ProductVariantShow | undefined => {
  return productVariants?.find((el) => el.id && el.id === productVariantId);
};

/**
 * 特価の価格情報を取得
 * @param priceSettings APIレスポンスの価格部分
 * @returns 特価の価格情報
 */
export const getSpecialPricePromotion = (
  priceSettings?: ProductPriceSettings | ProductMostInStockPriceSettings
): ProductVariantPromotionShow | undefined => {
  return priceSettings?.promotions?.find((el) => el?.name === PromotionsName.SPECIAL_PRICE);
};

/**
 * 生産地のレスポンスをコンポーネント用に整形する
 * @param producingArea 生産地
 * @returns 整形された生産地
 */
export const formatProducingArea = (
  producingArea?: ECSchema<'enotecaproduct.ProducingAreaShow'>[]
): CommonProducingArea | undefined => {
  if (!producingArea || producingArea.length === 0) return;
  return {
    code_id: producingArea[0]?.id?.toString() || '',
    areas: producingArea.map((area) => ({
      name: area?.name,
      id: area?.id?.toString(),
    })),
  };
};

/**
 * 英語の国名をコンポーネント用に整形する
 * @param countryName 英語の国名
 * @returns 整形された生産地
 */
export const formatProducingAreaFromCountryNameEn = (countryName: string | undefined) => {
  let codeId = '';
  if (countryName) {
    const capitalizedCountryName = countryName.toUpperCase();
    if (Object.prototype.hasOwnProperty.call(AREA_ID, capitalizedCountryName)) codeId = AREA_ID[capitalizedCountryName];
  }
  return {
    code_id: codeId,
    areas: [
      {
        name: '',
      },
    ],
  };
};

/**
 * 生産地のレスポンスをコンポーネント用に整形する
 * @param producingAreas 生産地
 * @returns 整形された生産地
 */
export const formatProducingAreasToAreaOption = (producingAreas: ProducingAreas[]): AreaOption[] => {
  return producingAreas.map((producingArea) => {
    return {
      id: producingArea.id?.toString(),
      label: producingArea.name,
      area2: formatChildrenToArea2(producingArea.children),
    } as AreaOption;
  });
};

const formatChildrenToArea2 = (children: ProducingAreas[] | undefined): Area2[] => {
  if (!children) return [];
  return children.map((producingArea) => {
    return {
      id: producingArea.id?.toString(),
      label: producingArea.name,
      area3: formatChildrenToArea3(producingArea.children),
    } as Area2;
  });
};

const formatChildrenToArea3 = (children: ProducingAreas[] | undefined): Area3[] => {
  if (!children) return [];
  return children.map((producingArea) => {
    return {
      id: producingArea.id?.toString(),
      label: producingArea.name,
      area4: producingArea.children?.map((area) => {
        return { id: area.id?.toString(), label: area.name };
      }),
    } as Area3;
  });
};

/**
 * APIレスポンスから整形された価格情報を取得
 * @param priceSettings APIレスポンスの価格部分
 * @returns 整形された価格情報
 */
export const getProductPrice = (
  priceSettings: ProductPriceSettings | ProductMostInStockPriceSettings
): ProductPrice => {
  const specialPricePromotion = getSpecialPricePromotion(priceSettings);
  const hasSpecialPrice = specialPricePromotion?.price_including_tax !== undefined;
  return {
    isRed: hasSpecialPrice,
    lineThroughPrice: hasSpecialPrice ? priceSettings.price_including_tax : undefined,
    price: hasSpecialPrice ? specialPricePromotion?.price_including_tax : priceSettings.price_including_tax,
    excludingTaxPrice: hasSpecialPrice ? specialPricePromotion?.price_excluding_tax : priceSettings.price_excluding_tax,
  };
};

/**
 * サイズ情報をパース
 * @param size_amount サイズ数値
 * @param size_unit サイズ単位
 * @returns パースされたサイズ情報
 */
export const parseSizeText = (sizeAmount?: number, sizeUnit?: string) => {
  if (!sizeAmount) {
    return;
  }
  const size = `${sizeAmount}${sizeUnit || 'ml'}`;
  switch (size) {
    case '2250ml':
      return '750ml x 3本';
    case '4500ml':
      return '750ml x 6本';
    case '9000ml':
      return '750ml x 12本';
    case '1500ml':
      return 'マグナムボトル';
    case '3000ml':
      return 'ダブルマグナムボトル';
    default:
      return size;
  }
};

/**
 * ヴィンテージ評価を配列にパースする
 * @param info ヴィンテージ評価オブジェクト
 * @returns 「評価誌+ポイント」のリスト
 */
export const parseReviewMediumInfo = (info?: ECSchema<'enotecaproduct.ReviewMediumInfo'>) => {
  if (!info) {
    return [];
  }
  const labels = [];
  if (info.d_point) {
    labels.push(`D ${info.d_point}`);
  }
  if (info.gr_point) {
    switch (info.gr_point) {
      case '1':
        labels.push(`GR トレ・ビッキエリ`);
        break;
      case '2':
        labels.push(`GR ドゥエ・ビッキエリ(*)`);
        break;
      case '3':
        labels.push(`GR ドゥエ・ビッキエリ`);
    }
  }
  if (info.js_point) {
    labels.push(`JS ${info.js_point}`);
  }
  if (info.v_point) {
    labels.push(`V ${info.v_point}`);
  }
  if (info.wa_point) {
    labels.push(`WA ${info.wa_point}`);
  }
  if (info.we_point) {
    labels.push(`WE ${info.we_point}`);
  }
  if (info.ws_point) {
    labels.push(`WS ${info.ws_point}`);
  }
  return labels;
};

/**
 * カテゴリに対応した単位の表記を返す
 * @param categories 商品カテゴリ配列
 * @returns 単位(本、個など)
 */
export const getProductUnit = (categories: { id: number; name?: string }[]) => {
  const parentCategoryId = categories[0]?.id;
  const categoryId = categories[1]?.id || categories[0]?.id;
  switch (categoryId) {
    case ProductCategoryId.WINE_SET:
    case ProductCategoryId.GIFT_SET:
      return 'セット';
    case ProductCategoryId.WINE_CELLAR:
      return '台';
    case ProductCategoryId.OLIVE_OIL:
      return '本';
  }

  if (parentCategoryId && [ProductCategoryId.FOOD, ProductCategoryId.WINE_GOODS].includes(parentCategoryId)) {
    return '個';
  }
  return '本';
};

/**
 * 商品種別の日本語名を取得
 * @param typeId 商品種別ID
 */
export const getProductType = (typeId: number) => {
  switch (typeId) {
    case 2:
      return 'プリムール';
    case 3:
      return '先行・ボジョレー';
    case 4:
      return 'ワインセラー';
    case 5:
      return 'レンタルセラーパック';
    default:
      return '一般';
  }
};

/**
 * 評価を四捨五入する
 * @param rating 評価の平均
 * @returns 小数点第一位までの評価の平均
 */
export const filterReviewRating = (rating?: number) => {
  return rating ? rating.toFixed(1) : 0;
};
