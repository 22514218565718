import axios, { AxiosError } from 'axios';
import { castArray } from 'lodash-es';
import { nonNullable } from '~/utils/nonNullable';
import { APIErrorItem } from '~/types/common';
import CONSOLS_ERROR_MESSAGES from '~/locales/api-errors/consols.json';
import EC_ERROR_MESSAGES from '~/locales/api-errors/ec.json';
import RENTAL_CELLAR_ERROR_MESSAGES from '~/locales/api-errors/rental-cellar.json';

export type APIName =
  | 'ec'
  | 'consols'
  | 'cms'
  | 'postal'
  | 'search'
  | 'recommend'
  | 'rentalCellar'
  | 'event'
  | 'staffStart';

const ERROR_MESSAGES: { [K in APIName]?: Record<string, string> } = {
  ec: EC_ERROR_MESSAGES,
  rentalCellar: RENTAL_CELLAR_ERROR_MESSAGES,
  consols: CONSOLS_ERROR_MESSAGES,
  event: EC_ERROR_MESSAGES,
};

const getErrorMessage = (api: APIName, error?: AxiosError) => {
  if (!error) {
    return `[${api}] エラーが指定されませんでした`;
  }

  if (axios.isCancel(error)) {
    return `リクエストがキャンセルされました（理由：${error.message}）`;
  }

  const {
    config: { url, method },
    response,
  } = error;

  if (!response) {
    return `[${api}] ${method}:${url} へのリクエストで不明なエラーが発生しました`;
  }

  const { status, data } = response;

  if (typeof data !== 'object' || 'error' in data) {
    return `[${api}] ${method}:${url} へのリクエストでステータスコード ${status} の不明なエラーが発生しました`;
  }

  return `[${api}] ${method}:${url} へのリクエストでステータスコード ${status} のエラーが発生しました`;
};

const getErrorMessages = (api: APIName, errors: APIErrorItem[]): string[] => {
  const messages = ERROR_MESSAGES[api];

  if (!messages || errors.length === 0) {
    return errors.map(({ message }) => message).filter(nonNullable);
  }

  return errors.map(
    (error) => messages[error.code] || 'エラーが発生しました。しばらくしてからもう一度お試しください。'
  );
};

export class APIError extends Error {
  /** APIError かの判定フラグ */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly __is_APIError = true;

  /** エラークラスの表示名 */
  public readonly name = 'APIError';

  constructor(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public readonly api: APIName,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _error?: AxiosError,
    /** ステータスコード。タイムアウトのときに -1 が返される */
    public readonly statusCode: number = _error?.response?.status || -1,
    // TODO: APIにデータ形式を正しくしてもらう
    public readonly errors: APIErrorItem[] = castArray(_error?.response?.data.error || _error?.response?.data).filter(
      nonNullable
    ),
    public readonly originalMessages: string[] = errors.map(({ message }) => message).filter(nonNullable),
    public readonly messages: string[] = getErrorMessages(api, errors),
    public readonly hasCode = (...codes: string[]) => errors.some(({ code }) => codes.includes(code)),
    public readonly findByCode = (code: string) => errors.find((error) => error.code === code)
  ) {
    super(getErrorMessage(api, _error));
  }

  /**
   * Axios エラーであれば APIError にして返す
   */
  public static getAPIError(api: APIName, error: Error): APIError | Error {
    if (axios.isAxiosError(error)) {
      return new APIError(api, error);
    }

    return error;
  }

  /**
   * APIError かどうか
   */
  public static isAPIError(error: any): error is APIError {
    return error instanceof APIError || (typeof error === 'object' && '__is_APIError' in error);
  }
}
