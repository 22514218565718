import { ConvertedRecommendItem, RecommendItem } from '~/types/common/silverEgg';

// シルバーエッグから取得した商品を、ECと同じ型に変換する
export const convertRecommendItems = (silverEggItems: Array<RecommendItem>): Array<ConvertedRecommendItem> =>
  silverEggItems.map((item: RecommendItem) => {
    return {
      code: item._id,
      catchCopy: '', // 現状表示不要
      image: item.img_url,
      detail_url: item.ct_url,
      product: {
        name: item.title,
        color: item.label || '',
        producing_area: {
          area_1: item.country_name_eventdate,
          area_id_1: item.country_id,
          area_2: item.area_shop_name !== item.country_name_eventdate ? item.area_shop_name : '', // area_shop_nameは最も子の生産地名
          area_3: undefined,
        },
        review_average: Number(item.review_point),
        display_price: item.special_price_string || item.price_string,
        special_price: item.special_price_string,
        is_free_shipping: undefined,
        is_discount: undefined,
        discount_rate: undefined,
        sale_label: item.sale_promotion_info,
      },
      variantCode: undefined,
    };
  });
