import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { getTrackingId } from '~/composables/product/useSearchAPIParam';
import { RECOMMEND_API_SETTING, SEARCH_API_FACET_SETTING } from '~/constants';
import { BaseAxiosAction } from '~/types/api';
import { ProductSuggestion } from '~/types/modules/product';
import { ArticleType, RecommendApiMethod, RecommendApiMode } from '~/enums';

const parseSuggestion = (item: any, isSaleInfo = false) => ({
  id: item?.field[0].iid.split(`${item?.field[0].mst}_`)[1],
  mst: item?.field[0].mst,
  word: item?.value,
  isSaleInfo,
});

/** Actions */
export type Ctx = DefineActionContext<never, never, never>;
export const actions = {
  /** 人気キーワード */
  async fetchPopularKeyword(this: Vue, context: Ctx, payload: BaseAxiosAction): Promise<ProductSuggestion[]> {
    const { result = [] } = await this.$cmsAxios.$get('/view_articles', {
      ...payload,
      query: {
        '_article_type[eq]': ArticleType.PopularKeyword,
        '_order[DESC]': 'article_created_at',
      },
    });

    return result.map((item: any) => ({
      word: item.contents.text,
      url: item.contents.url,
    }));
  },

  /**
   * 下記クエリ①〜④
   * @see: https://docs.google.com/spreadsheets/d/1ZqZxa2L7lKa4bIMurximi6goBOQIxRsl6YSKOBXFPFc/edit#gid=1332252736
   */

  /** クエリ①-１：キーワード（各マスタ） */
  async fetchMasterSuggestions(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ word: string; isPrefix: boolean }>
  ): Promise<ProductSuggestion[]> {
    const trackingId = await getTrackingId();

    const {
      result: { words },
    } = await this.$recommendAxios.$get('/suggest.json', {
      ...payload,
      query: {
        tracking_id: trackingId,
        method: RecommendApiMethod.RANKING,
        rf: RECOMMEND_API_SETTING.RF,
        mode: payload.isPrefix ? RecommendApiMode.PREFIX : RecommendApiMode.PARTIAL,
        limit: RECOMMEND_API_SETTING.LIMIT,
        lf: RECOMMEND_API_SETTING.LF,
        sf: [
          'producing_area_name_search',
          'producing_area_name_en',
          'producer_name_search',
          'producer_name_english',
          'brand_name_search',
          'brand_name_en',
          'color_name_search',
          'category_name_search',
          'tag2_name',
        ],
        q: 'mst:(producer OR producing_area OR brand OR color OR category OR tag2)',
        of: ['mst', 'iid'],
        sort: 'suggest_sort_rnk_i desc',
        approx: 'true',
        fq: ['producing_area_name_search_s:(-その他)', 'display_flag_i:(-0)'],
        word: payload.word,
      },
    });
    return words.map((item: any) => parseSuggestion(item));
  },

  /** クエリ①-２：キーワード（栓） */
  async fetchStopperSuggestions(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ word: string; isPrefix: boolean }>
  ): Promise<ProductSuggestion[]> {
    const trackingId = await getTrackingId();

    const {
      result: { words },
    } = await this.$recommendAxios.$get('/suggest.json', {
      ...payload,
      query: {
        tracking_id: trackingId,
        method: RecommendApiMethod.RANKING,
        rf: RECOMMEND_API_SETTING.RF,
        mode: payload.isPrefix ? RecommendApiMode.PREFIX : RecommendApiMode.PARTIAL,
        limit: RECOMMEND_API_SETTING.LIMIT,
        lf: RECOMMEND_API_SETTING.LF,
        sf: ['stopper'],
        q: 'mst:product',
        of: ['mst', 'iid'],
        sort: 'suggest_sort_rnk_i desc',
        approx: 'true',
        fq: ['display_flag_i:(-0)'],
        word: payload.word,
      },
    });
    return words.map((item: any) => parseSuggestion(item));
  },

  /** クエリ②：キーワード（商品名part） */
  async fetchItemSuggestions(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ word: string; isPrefix: boolean }>
  ): Promise<ProductSuggestion[]> {
    const trackingId = await getTrackingId();

    const {
      result: { words },
    } = await this.$recommendAxios.$get('/suggest.json', {
      ...payload,
      query: {
        tracking_id: trackingId,
        method: RecommendApiMethod.RANKING,
        rf: RECOMMEND_API_SETTING.RF,
        mode: payload.isPrefix ? RecommendApiMode.PREFIX : RecommendApiMode.PARTIAL,
        limit: RECOMMEND_API_SETTING.LIMIT,
        lf: RECOMMEND_API_SETTING.LF,
        sf: ['product_name_search', 'product_name_english'],
        q: 'mst:product',
        of: ['mst', 'iid'],
        fq: 'display_flag_i:(-0)',
        word: payload.word,
      },
    });
    return words.map((item: any) => parseSuggestion(item));
  },

  /** クエリ③：キーワード（セール情報part） */
  async fetchSaleSuggestions(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ word: string; isPrefix: boolean }>
  ): Promise<ProductSuggestion[]> {
    const trackingId = await getTrackingId();

    const {
      result: { words },
    } = await this.$recommendAxios.$get('/suggest.json', {
      ...payload,
      query: {
        tracking_id: trackingId,
        method: RecommendApiMethod.RANKING,
        rf: RECOMMEND_API_SETTING.RF,
        mode: payload.isPrefix ? RecommendApiMode.PREFIX : RecommendApiMode.PARTIAL,
        limit: RECOMMEND_API_SETTING.LIMIT,
        lf: RECOMMEND_API_SETTING.LF_SALE,
        sf: ['sale_info'],
        q: 'mst:product',
        of: ['mst', 'iid'],
        fq: 'display_flag_i:(-0)',
        word: payload.word,
      },
    });
    return words.map((item: any) => parseSuggestion(item, true));
  },

  /** クエリ④：生産年 */
  async fetchVintageSuggestions(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{ word: string; isPrefix: boolean }>
  ): Promise<ProductSuggestion[]> {
    const trackingId = await getTrackingId();

    const {
      facet_counts: {
        facet_fields: { vintage_code_list_sm },
      },
    } = await this.$searchAxios.$get('/select', {
      ...payload,
      query: {
        tracking_id: trackingId,
        method: RecommendApiMethod.RANKING,
        q: '*:*',
        [payload.isPrefix ? 'facet.prefix' : 'facet.partial']: payload.word,
        'facet.field': 'vintage_code_list_sm',
        'facet.limit': RECOMMEND_API_SETTING.LIMIT,
        facet: SEARCH_API_FACET_SETTING.FACET,
        fq: ['producing_area_name_search_s:(-その他)', 'display_flag_i:(-0)'],
        fl: 'iid',
        wt: 'json',
      },
    });
    return vintage_code_list_sm
      .filter((item: string, index: number) => index % 2 === 0)
      .map((vintage: string) => {
        return { id: vintage, mst: 'vintage', word: `${vintage}年` };
      });
  },
};

/** Store Module Type */
export type Store = DefineStoreModule<'product/recommend', never, never, never, typeof actions>;
