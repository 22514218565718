import { isNaN } from 'lodash-es';

/**
 * 数字の値段を「,」付きのフォーマットされた string に変更
 * Examples
 * 123 -> '123'
 * 12345 -> '12,345'
 * 123456789 -> '123,456,789'
 */
const priceFormatter = (price: number | string): string => {
  if (isNaN(price)) return price.toString();
  const formatter = Intl.NumberFormat('ja-JP');
  return formatter.format(+price);
};

export default priceFormatter;
