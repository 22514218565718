// @ts-check
import { url } from '~/utils';

/**
 * Nuxt App でアンカージャンプが発生したときのハンドラ
 *
 * 参考にしたサイトなど
 * @see https://github.com/nuxt/nuxt.js/blob/00aeff62e700cabf65d5c67828bd419c015558e6/packages/vue-app/template/router.scrollBehavior.js
 * @see https://blog.csdn.net/ximenxiafeng/article/details/113277820
 */

/**
 * @type {(scrollRestoration: ScrollRestoration) => void}
 */
const setScrollRestoration = (scrollRestoration) => {
  try {
    window.history.scrollRestoration = scrollRestoration;
  } catch {
    // なにもしない
  }
};

if (process.client) {
  if ('scrollRestoration' in window.history) {
    setScrollRestoration('manual');

    window.addEventListener('beforeunload', () => {
      setScrollRestoration('auto');
    });

    window.addEventListener('load', () => {
      setScrollRestoration('manual');
    });
  }
}

const noRestorationPaths = [
  url('ITEM_LIST'),
  '/item/detail',
  url('ARTICLE_LIST'),
  url('ARCHIVES_LIST'),
  '/archives/detail/',
  url('PRODUCER_LIST'),
  '/producer/detail/',
  url('RECOMMEND'),
  url('RANKING'),
  url('ORDER'),
];

/** @type {import('vue-router').RouterOptions['scrollBehavior']} */
const scrollBehavior = (to, from, savedPosition) => {
  return new Promise((resolve) => {
    /** @type {import('@nuxt/types/app').NuxtApp} */
    const nuxt = window.$nuxt;
    /** @type {import('~/composables/useStore').RootStore} */
    const store = nuxt.$store;

    if ((to.path === from.path && to.hash !== from.hash) || to === from) {
      nuxt.$nextTick(() => nuxt.$emit('triggerScroll'));
    }

    nuxt.$once('triggerScroll', () => {
      const shouldScroll = !noRestorationPaths.some((path) => {
        if (to.path === url('TOP')) {
          return true;
        }
        return to.path.includes(path);
      });

      if (savedPosition && shouldScroll) {
        resolve(savedPosition);

        return;
      }

      if (!to.hash) {
        resolve({ x: 0, y: 0 });

        return;
      }

      // 0ms 待機して対象要素の登場を待つ
      // （Vue.$nextTick だとダメだった）
      setTimeout(async () => {
        try {
          /**
           * @param element {HTMLImageElement}
           * @returns {Promise<void>}
           */
          const waitForLoaded = (element) =>
            new Promise((resolve) => {
              if (element.loading === 'lazy' || element.complete) {
                resolve();

                return;
              }

              element.addEventListener('load', () => {
                resolve();
              });
            });

          const $img = document.querySelectorAll('img');

          await Promise.all(Array.from($img).map((elm) => waitForLoaded(elm)));

          if (document.querySelector(to.hash)) {
            resolve({ selector: to.hash, offset: { x: 0, y: store.state.internals.header.headerHeight } });

            return;
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn(err);
        }

        resolve({ x: 0, y: 0 });
      }, 0);
    });
  });
};

export default scrollBehavior;
