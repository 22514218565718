import { sumBy } from 'lodash-es';
import { ValidationRule } from 'vee-validate/dist/types/types';
import {
  MAX_GIFT_MESSAGE_LINE_COUNT,
  MAX_GIFT_MESSAGE_WORD_COUNT,
  MAX_GIFT_MESSAGE_WORD_COUNT_PER_LINE,
} from '~/constants';
import { definePreset } from '../core/preset';

/** メッセージカードの最大行数 */
export const messageMaxLines: ValidationRule = {
  message: `{_field_}はプレビュー上${MAX_GIFT_MESSAGE_LINE_COUNT}行以内で入力してください`,
  validate(value) {
    if (typeof value !== 'string') {
      return false;
    }

    const lines = value.split('\n');

    /* 入力に折り返しがあれば行数を算出 */
    const lineCount = sumBy(lines, (line) => {
      if (line.length > MAX_GIFT_MESSAGE_WORD_COUNT_PER_LINE) {
        return Math.ceil(line.length / MAX_GIFT_MESSAGE_WORD_COUNT_PER_LINE);
      }
      return 1;
    });

    if (lineCount > MAX_GIFT_MESSAGE_LINE_COUNT) {
      return false;
    }

    return true;
  },
};

export const messageCard = definePreset({
  rules: {
    max: MAX_GIFT_MESSAGE_WORD_COUNT,
    messageMaxLines: true,
  },
});
