import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { formatPoints } from '~/utils';
import { ConsolsActionPayload, ECActionPayload } from '~/types/api';
import { PointFormInfo } from '~/types/common/point';

/** State */
export interface State {
  pointFormInfo: PointFormInfo;
  shouldUsePreviousFormInfo: boolean;
  pointBalance: number;
}

export const state = (): State => ({
  pointFormInfo: {
    clubEnotecaNo: '',
    birthday: '',
  },
  shouldUsePreviousFormInfo: false,
  pointBalance: 0,
});

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setFieldValue<Field extends keyof State>(state: State, payload: { field: Field; value: State[Field] }): void {
    Vue.typedSet(state, payload.field, payload.value);
  },
};

export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;

export const actions = {
  async getPointBalance(this: Vue, { commit }: Ctx, payload: ConsolsActionPayload<'/api/v1/point/balance', 'get'>) {
    const res = await this.$consolsAxios.$get('/api/v1/point/balance', payload);
    const pointBalance = formatPoints(res.balance).totalPoint;
    commit('setFieldValue', { field: 'pointBalance', value: pointBalance });
    return res;
  },
  async getPointHistory(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/point/history', 'get'>) {
    return await this.$consolsAxios.$get('/api/v1/point/history', payload);
  },
  async pointAddup(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/point/addup', 'post'>) {
    return await this.$consolsAxios.$post('/api/v1/point/addup', payload);
  },
  async birthdayCheck(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/point/addup/check', 'get'>) {
    return await this.$consolsAxios.$get('/api/v1/point/addup/check', payload);
  },
  async updateClubMember(this: Vue, context: Ctx, payload: ECActionPayload<'/member/v1/users/merge', 'post'>) {
    return await this.$ecAxios.$post('/member/v1/users/merge', payload);
  },
};

export type Store = DefineStoreModule<'modules/mypage/point', State, typeof getters, typeof mutations, typeof actions>;
