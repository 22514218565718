export enum LoginStatus {
  SUCCEED = '成功',
  TRIED = '試行',
}

export enum LoginCategory {
  NORMAL = '通常',
  AMAZON = 'amazon',
  RAKUTEN = '楽天',
  APPLE = 'apple',
}
