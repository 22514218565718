export const LOCAL_STORAGE_KEY = {
  /* 未ログイン時ゲストカート用トークン */
  GUEST_TOKEN: 'guestToken',
  /* 注文シミュレーションのオプション */
  USER_OPTION: 'userOption',
  /* 注文の会員登録情報 */
  ORDER_USER_REGISTER: 'orderUserRegister',
  /* 注文の会員登録情報を使用するカートId */
  ORDER_USER_REGISTER_CART_ID: 'orderUserRegisterCartId',
  /** 注文時のカートタイプ */
  ORDERING_CART_NAME: 'orderingCartName',
  /* ヘッダーメニューの更新(新着)バッジ用 特集の最終閲覧日時 */
  ARCHIVES_READ_AT: 'header-archives_read_at',
  /* ヘッダーメニューの更新(新着)バッジ用 読み物の最終閲覧日時 */
  ARTICLES_READ_AT: 'header-articles_read_at',
  /* ヘッダーメニューの更新(新着)バッジ用 特集の最終閲覧日時 */
  STAFFREVIEWS_READ_AT: 'header-staffReviews_read_at',
  /* ヘッダーメニューの更新(新着)バッジ用 特集の最終閲覧日時 */
  WINESET_READ_AT: 'header-wineSet_read_at',
  /* セラー規約 */
  CELLAR_POLICY: 'cellarPolicy',
  /* セラークレジット */
  CELLAR_CREDIT: 'cellarCredit',
  /** セラーから注文に遷移 */
  CELLAR_ORDER: 'cellarOrder',
  /** 認証状態か */
  IS_AUTHENTICATED: 'is-authenticated',
  /** イベント予約入力情報 */
  EVENT_RESERVE: 'eventReserve',
  /** 予約イベント変更入力情報 */
  EVENT_RESERVATION_UPDATE_BODY: 'eventReservationUpdateBody',
  /** Veritrans 注文のシミュレーション・カート一時データ */
  ORDER_VERITRANS_TEMPORARY: 'orderVeritransTemporary',
  /** コンバージョンの値 */
  ORDER_CONVERSION_VALUE: 'orderConversionValue',
  /** スクロール位置 */
  KEEP_SCROLL: 'keepScroll',
  /** クーポンの選択状態 */
  IS_USED_COUPON: 'isUsedCoupon',
  /** 配送方法の注記 */
  SHIPPING_METHOD_NOTES: 'shippingMethodNotes',
  /** AmazonPayのCheckoutsessionId */
  AMAZON_PAY_CHECKOUT_SESSION_ID: 'checkoutSessionId',
};
