import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';

export type State = {
  freeShippingText: string;
  shipmentFreeAmount: string;
  totalAmount: string; // コマ区切りのフォーマットに変換されるため文字列にする
};

export const state = (): State => ({
  freeShippingText: '',
  shipmentFreeAmount: '',
  totalAmount: '',
});

export const mutations = {
  setShipmentFreeMessage(state: State, payload: typeof state) {
    state.freeShippingText = payload.freeShippingText;
    state.shipmentFreeAmount = payload.shipmentFreeAmount;
    state.totalAmount = payload.totalAmount;
  },
};

export type Ctx = DefineActionContext<State, never, typeof mutations>;

export const actions = {
  setShipmentFreeMessage(this: Vue, { commit }: Ctx, payload: State) {
    commit('setShipmentFreeMessage', payload);
  },
};

export type Store = DefineStoreModule<'modules/cartMessage', State, never, typeof mutations, typeof actions>;
