




























































import { computed, defineComponent, ref, useContext, onUnmounted, watch } from '@nuxtjs/composition-api';
import { RawLocation } from 'vue-router';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import AppLink from '~/components/partials/App/AppLink.vue';
import GlobalHeaderSubmenu from '~/components/partials/Global/GlobalHeaderSubmenu.vue';
import { useElementLayout } from '~/composables/useElementLayout';
import { useStore } from '~/composables/useStore';
import { PRODUCT_CATEGORY_ID, ARCHIVE_GIFT, SMARTPHONE } from '~/constants';
import { url } from '~/utils';
import { sendNavigationEvent, gaEventType } from '~/utils/ga';
import { BooleanLike } from '~/enums';

type HeaderMainMenu = {
  desktopTitle: string;
  mobileTitle?: string;
  isUnread?: boolean;
  link: RawLocation;
  mobileLink?: RawLocation;
  gaEventKey: keyof typeof gaEventType;
};

export default defineComponent({
  components: {
    AppLink,
    AppIcon,
    GlobalHeaderSubmenu,
  },
  setup() {
    const { i18n, app } = useContext();
    const store = useStore();
    const isSubMenuOpen = ref(false);
    const toggleSubMenu = () => {
      isSubMenuOpen.value = !isSubMenuOpen.value;

      if (isSubMenuOpen.value) {
        sendNavigationEvent('searchItem', false);
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    };

    const handleOutsideClick = () => {
      isSubMenuOpen.value = false;
      document.body.classList.remove('overflow-hidden');
    };

    // ヘッダーメニューの高さを取得する
    const headerMenuRef = ref<HTMLDivElement>();
    const headerMenuRect = useElementLayout(headerMenuRef);
    const headerMenuHeight = computed(() => headerMenuRect.value.offsetHeight);
    // ヘッダーメニューの高さをストアに格納する
    watch(
      headerMenuHeight,
      (headerMenuHeight) => {
        store.commit('internals/header/setHeaderMenuHeight', headerMenuHeight);
      },
      { immediate: true }
    );

    onUnmounted(() => {
      document.body.classList.remove('overflow-hidden');
    });

    const isSmartPhone = computed(() => {
      return app.$ua.deviceType() === SMARTPHONE;
    });

    const headerMainMenu = computed((): (HeaderMainMenu | undefined)[] => [
      {
        desktopTitle: i18n.t('Common.globalHeaderMenu.mainMenu.desktopTitle.ranking').toString(),
        link: url('RANKING'),
        gaEventKey: 'ranking',
      },
      {
        desktopTitle: i18n.t('Common.globalHeaderMenu.mainMenu.desktopTitle.wineSet').toString(),
        mobileTitle: i18n.t('Common.globalHeaderMenu.mainMenu.mobileTitle.wineSet').toString(),
        isUnread: store.getters['internals/header/isUnreadWineSet'],
        link: {
          path: url('ITEM_LIST'),
          query: {
            _ctg: PRODUCT_CATEGORY_ID.WINE_SET,
            _qty: BooleanLike.TRUE,
          },
        },
        gaEventKey: 'wineSet',
      },
      {
        desktopTitle: i18n.t('Common.globalHeaderMenu.mainMenu.desktopTitle.giftSet').toString(),
        isUnread: false,
        mobileTitle: i18n.t('Common.globalHeaderMenu.mainMenu.mobileTitle.giftSet').toString(),
        link: {
          path: url('ARCHIVES_DETAIL', { permalink: ARCHIVE_GIFT }),
        },
        gaEventKey: 'wineGift',
      },
      {
        desktopTitle: i18n.t('Common.globalHeaderMenu.mainMenu.desktopTitle.archives').toString(),
        isUnread: store.getters['internals/header/isUnreadArchives'],
        link: url('ARCHIVES_LIST'),
        gaEventKey: 'specialFeature',
      },
      {
        desktopTitle: i18n.t('Common.globalHeaderMenu.mainMenu.desktopTitle.staffReview').toString(),
        isUnread: store.getters['internals/header/isUnreadStaffReviews'],
        link: url('STAFF_REVIEW_LIST'),
        gaEventKey: 'staffReview',
      },
      {
        desktopTitle: i18n.t('Common.globalHeaderMenu.mainMenu.desktopTitle.articles').toString(),
        mobileTitle: i18n.t('Common.globalHeaderMenu.mainMenu.mobileTitle.articles').toString(),
        isUnread: store.getters['internals/header/isUnreadArticles'],
        link: url('ARTICLE_LIST'),
        gaEventKey: 'magazine',
      },
      {
        desktopTitle: i18n.t('Common.globalHeaderMenu.mainMenu.desktopTitle.shop').toString(),
        isUnread: false,
        link: url('SHOP_LIST'),
        gaEventKey: 'shop',
      },
      {
        desktopTitle: i18n.t('Common.globalHeaderMenu.mainMenu.desktopTitle.event').toString(),
        mobileTitle: i18n.t('Common.globalHeaderMenu.mainMenu.mobileTitle.event').toString(),
        isUnread: false,
        link: {
          path: url('EVENT_LIST'),
          query: {
            availableOnly: 'true',
          },
        },
        gaEventKey: 'event',
      },
    ]);

    return {
      url,
      BooleanLike,
      headerMainMenu,
      PRODUCT_CATEGORY_ID,
      isSubMenuOpen,
      toggleSubMenu,
      handleOutsideClick,
      sendNavigationEvent,
      isSmartPhone,
      headerMenuRef,
    };
  },
});
