import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import dayjs from 'dayjs';
import { union } from 'lodash-es';
import Vue from 'vue';
import { EventActionPayload } from '~/types/api';
import { EventSchema } from '~/types/api/event-utils';
import { EventListResponseSchema } from '~/types/modules/event';

/** State */
export type State = {
  shopEventList: Array<EventSchema<'FrontEventSummary'>>;
  totalEventCounts: number;
  datesOfEvents: Array<string>;
};

export const state = (): State => ({
  shopEventList: [],
  totalEventCounts: 0,
  datesOfEvents: [],
});

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setShopNewsList(state: State, eventList: Array<EventSchema<'FrontEventSummary'>>) {
    state.shopEventList = state.shopEventList.concat(eventList);
  },
  setTotalEventCounts(state: State, total: number) {
    state.totalEventCounts = total;
  },
  setDatesOfEvents(state: State, dates: Array<string>) {
    state.datesOfEvents = dates;
  },
  resetShopEventList(state: State) {
    state.shopEventList = [];
  },
  resetDatesOfEvents(state: State) {
    state.datesOfEvents = [];
  },
  resetTotalEventCounts(state: State) {
    state.totalEventCounts = 0;
  },
};

export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;

export const actions = {
  // イベント一覧画面用に保持
  async fetchEvents(
    this: Vue,
    context: Ctx,
    payload: EventActionPayload<'/api/v1/events', 'get'>
  ): Promise<EventListResponseSchema> {
    const res = await this.$eventAxios.$get('/api/v1/events', payload);
    return res;
  },
  /** イベント一覧を取得 */
  async fetchEventList(this: Vue, { commit }: Ctx, payload: EventActionPayload<'/api/v1/events', 'get'>) {
    const { items, totalItems } = await this.$eventAxios.$get('/api/v1/events', payload);
    if (items && totalItems) {
      commit('setShopNewsList', items);
      commit('setTotalEventCounts', totalItems);
    }
  },

  /** イベント開催日を取得 */
  async fetchDatesOfEvent(this: Vue, { commit }: Ctx, payload: EventActionPayload<'/api/v1/events', 'get'>) {
    const { items } = await this.$eventAxios.$get('/api/v1/events', payload);
    if (items) {
      const dates = items
        .map((item) => {
          return item.datesOfEvent;
        })
        .flatMap((event) => event)
        .map((time) => dayjs(time?.start).format('YYYY-MM-DD'));
      commit('setDatesOfEvents', union(dates));
    }
  },
};

export type Store = DefineStoreModule<'event/list', State, typeof getters, typeof mutations, typeof actions>;
