import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import urlJoin from 'url-join';
import { RuntimeConfig } from '~/composables/useRuntimeConfig';
import { RootStore } from '~/composables/useStore';
import { RedirectSetting } from '~/types/modules/redirect';
import { RedirectComparisonType } from '~/enums';

export default defineNuxtPlugin(async ({ $axios, app, route, redirect, ...context }) => {
  const { FRONTEND_ORIGIN, S3_ASSETS_ORIGIN } = context.$config as RuntimeConfig;
  const store = context.store as RootStore;

  const optimizeDestination = (destination: string): string => {
    try {
      const url = new URL(destination, FRONTEND_ORIGIN);

      // 本番環境のドメインが指定されている場合はドメインを除去する
      if (url.host === 'www.enoteca.co.jp') {
        return destination.replace(/^https?:\/\/[^/]+/, '');
      }

      return destination;
    } catch {
      return destination;
    }
  };

  /** マッチしたリダイレクト設定を取得する */
  const getMatchedRedirectSetting = (fullPath: string) =>
    store.state.internals.redirect.redirectConfig.find(({ comparison_type: comparisonType, condition }) => {
      const { origin } = new URL(condition, FRONTEND_ORIGIN);

      // オリジンが異なる場合は常に false を返す
      if (origin !== FRONTEND_ORIGIN) {
        return false;
      }

      const conditionFullPath = condition.replace(origin, '');

      switch (comparisonType) {
        case RedirectComparisonType.EXACT_MATCH:
          return fullPath === conditionFullPath;
        case RedirectComparisonType.PREFIX_MATCH:
          return fullPath.startsWith(conditionFullPath);
        case RedirectComparisonType.BACKWARD_MATCH:
          return fullPath.endsWith(conditionFullPath);
        case RedirectComparisonType.PARTIAL_MATCH:
          return fullPath.includes(conditionFullPath);
        default:
          return false;
      }
    });

  if (process.server) {
    try {
      const redirectConfig = await $axios.$get<RedirectSetting[]>(urlJoin(S3_ASSETS_ORIGIN, '/redirect_config.json'));

      store.commit('internals/redirect/setRedirectConfig', redirectConfig);

      // ランディングしたページがリダイレクト対象の場合はリダイレクト
      const matchedRedirectSetting = getMatchedRedirectSetting(route.fullPath);

      if (matchedRedirectSetting) {
        redirect(301, optimizeDestination(matchedRedirectSetting.destination));
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      return;
    }
  }

  if (process.client) {
    // クライアントサイドの場合はルーティングガードを仕込む
    app.router?.beforeEach((to, from, next) => {
      const matchedRedirectSetting = getMatchedRedirectSetting(to.fullPath);

      if (matchedRedirectSetting) {
        location.href = optimizeDestination(matchedRedirectSetting.destination);

        return;
      }

      next();
    });
  }
});
