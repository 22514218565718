import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';

/** State */
export interface State {
  currentlyOpenedCountryCode: number;
}

export const state = (): State => ({
  currentlyOpenedCountryCode: -1,
});

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setCurrentlyOpenedCountryCode(state: State, payload: State['currentlyOpenedCountryCode']): void {
    Vue.typedSet(state, 'currentlyOpenedCountryCode', payload);
  },
};

export type Ctx = DefineActionContext<never, never, never>;

export type Store = DefineStoreModule<'modules/wineArea', State, typeof getters, typeof mutations, never>;
