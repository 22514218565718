import urlJoin from 'url-join';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';

export const useDefaultImage = () => {
  const { FRONTEND_ORIGIN } = useRuntimeConfig();
  const ogpImagePath = urlJoin(FRONTEND_ORIGIN, '/ogp_enoteca_logo.jpg');
  const mainImagePath = (imagePath: string) => urlJoin(FRONTEND_ORIGIN, imagePath);

  return { ogpImagePath, mainImagePath };
};
