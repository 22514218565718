/** 拠点マスタの表示順 */
export const SALES_LOCATION_DISPLAY_ORDER = 'sales_location_display_order';
/** 親エリアの表示順 */
export const PARENT_AREA_DISPLAY_ORDER = 'parent_area_display_order';
/** 子エリアの表示順 */
export const AREA_DISPLAY_ORDER = 'area_display_order';

/** PDFメニューファイル名 */
export const BAR_MENU_PDF = '_bar.pdf';

export const BAR_SPECIAL_MENU_PDF = '_barsp.pdf';

export const SHOP_ANCHOR = {
  BASIC_INFO: 'basic-info',
  EVENT_CALENDAR: 'event-calendar',
  BAR: 'bar',
  BLOG: 'blog',
  SHOP_NEWS: 'shop-news',
  NEARBY_SHOPS: 'nearby-shops',
};
