import { url } from '~/utils';

/** 申し込み中の頒布会1ページネーションごとの表示数 */
export const DISTRIBUTION_LIST_PER_PAGE = 10;
export const ENOTECA_ONLINE = 'エノテカ・オンライン';

/** パーソナルメッセージ優先度 */
export const PERSONAL_MESSAGE_PRIORITIES = {
  PRIMEUR: 0, // プリームルへ遷移
  RANK_VIP: 4, // VIPランクとは？へ遷移
  RANK_UP: 5, // VIPランクとは？へ遷移
};

/** エノテカポイントカードのコード */

export const POINTO_CARD_CODE = '290';

/** マイページサイドメニューリスト */
export const MYPAGE_MENU_LIST = [
  {
    text: 'マイページ',
    link: url('MYPAGE'),
    icon: 'mypage-user',
    activeLinks: [url('MYPAGE')],
  },
  {
    text: '注文履歴',
    link: url('MYPAGE_HISTORY_LIST'),
    icon: 'mypage-history',
    activeLinks: [url('MYPAGE_HISTORY_LIST')],
  },
  {
    text: '頒布会申し込み履歴',
    link: url('MYPAGE_SUBSCRIPTION_LIST'),
    icon: 'mypage-monthly',
    activeLinks: [url('MYPAGE_SUBSCRIPTION_LIST')],
  },
  {
    text: '会員情報',
    link: url('MYPAGE_PROFILE'),
    icon: 'mypage-action-member',
    activeLinks: [
      url('MYPAGE_PROFILE'),
      url('MYPAGE_ACCOUNT'),
      url('MYPAGE_PASSWORD'),
      url('MYPAGE_CREDIT'),
      url('MYPAGE_DELIVERY_LIST'),
      url('MYPAGE_DELIVERY_EDIT', { deliveryName: '' }),
      url('MYPAGE_DELIVERY_NEW'),
      url('MYPAGE_MYSHOP'),
      url('MYPAGE_MAGAZINE'),
    ],
  },
  {
    text: 'ポイント履歴',
    link: url('MYPAGE_POINT_LIST'),
    icon: 'mypage-point',
    activeLinks: [url('MYPAGE_POINT_LIST')],
  },
  {
    text: '特典一覧',
    link: url('MYPAGE_COUPON_LIST'),
    icon: 'mypage-coupon',
    activeLinks: [url('MYPAGE_COUPON_LIST')],
  },
  {
    text: 'レンタルセラー管理',
    link: url('MYPAGE_CELLAR'),
    icon: 'mypage-celler',
    activeLinks: [url('MYPAGE_CELLAR')],
  },
  {
    text: 'イベント予約管理',
    link: url('MYPAGE_EVENT_LIST'),
    icon: 'mypage-event',
    activeLinks: [url('MYPAGE_EVENT_LIST')],
  },
  {
    text: '抽選応募履歴',
    link: url('MYPAGE_LOTTERY_HISTORY'),
    icon: 'lottery-menu',
    activeLinks: [url('MYPAGE_LOTTERY_HISTORY')],
  },
  {
    text: '投稿したクチコミ',
    link: url('MYPAGE_COMMENT_LIST'),
    icon: 'mypage-review',
    activeLinks: [url('MYPAGE_COMMENT_LIST')],
  },
  {
    text: 'ログアウト',
    link: url('AUTH_LOGOUT'),
    icon: 'logout',
    activeLinks: [url('AUTH_LOGOUT')],
  },
];

export const errorCode1 = 'vf_error_status_200_return_code_1';
export const errorCode15 = 'vf_error_status_200_return_code_15';
