import AxiosLogger from '@lollipop-onl/axios-logger';
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { castArray } from 'lodash-es';
import { RuntimeConfig } from '~/composables/useRuntimeConfig';
import { nonNullable } from '~/utils';

export default defineNuxtPlugin(({ $axios, $config, app }) => {
  const { API_TRACKING_LOGGER, DEBUG_MODE, CONSOLS_API_ORIGIN, CONSOLS_INTERNAL_API_ORIGIN, STAFF_START_API_ORIGIN } =
    $config as RuntimeConfig;

  // Axios Logger を登録する
  if (DEBUG_MODE === 'enabled') {
    const baseURL: string[] = [CONSOLS_API_ORIGIN, CONSOLS_INTERNAL_API_ORIGIN, STAFF_START_API_ORIGIN];

    if (process.client) {
      baseURL.push(window.location.origin);
    }

    const logger = new AxiosLogger({
      isServer: process.server,
      baseURL,
    });

    // @ts-expect-error
    $axios.onResponse(logger.log);

    $axios.onResponseError((err) => {
      logger.log(err);

      return Promise.reject(err);
    });
  }

  // API トラッキングロガーを登録する
  if (API_TRACKING_LOGGER === 'enabled') {
    const getErrorCode = (data: any | undefined): string | undefined => {
      try {
        const errors = castArray(data?.error || data).filter(nonNullable);

        return errors.map(({ code }) => code).join(', ');
      } catch {
        // 何も返さない
      }
    };

    // サーバにログを残すロガーを仕込む
    $axios.onResponse((response) => {
      const {
        config: { method = 'unknown', url = '???' },
        status,
      } = response;

      app.$logger.send({
        status: status.toString(),
        url,
        method,
      });
    });

    $axios.onResponseError((err) => {
      try {
        const {
          config: { method = 'unknown', url = '?' },
          response,
        } = err;

        app.$logger.send({
          status: response?.status.toString() || 'unknown',
          url,
          method,
          errorCode: getErrorCode(response?.data),
        });
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        return Promise.reject(err);
      }
    });
  }
});
