import dayjs from 'dayjs';
import { sumBy } from 'lodash-es';
import { PointBalance } from '~/types/modules/mypage';

/** /api/v1/point/balanceのレスポンス整形関数 */
export const formatPoints = (balance?: PointBalance) => {
  const balanceList =
    balance?.balanceList?.filter(({ expireDate }) => {
      return (
        dayjs().tz('Asia/Tokyo').isBefore(expireDate) ||
        dayjs().tz('Asia/Tokyo').isSame(dayjs(expireDate).tz('Asia/Tokyo'), 'day')
      );
    }) || [];

  const totalPoint = sumBy(balanceList, (balance) => Number(balance.balancePoint));

  const proximateExpireDateOfPoint = dayjs(
    balanceList.sort((a, b) => (dayjs(a.expireDate).isBefore(dayjs(b.expireDate)) ? 1 : -1))[0]?.expireDate
  )
    .tz('Asia/Tokyo')
    .toDate();

  return {
    balanceList,
    totalPoint,
    proximateExpireDateOfPoint,
  };
};
