import { CellarBoxImage } from '~/types/modules/cellar';
import { FormSelectOption } from '~/types/ui';

/** 出庫依頼1ページネーションごとの表示数 */
export const REQUESTS_LIST_PER_PAGE = 20;

export const INSURANCE_OPTIONS: FormSelectOption[] = [
  {
    value: 0,
    text: '任意保険なし',
  },
].concat(
  Array(47)
    .fill(null)
    .map((val, i) => {
      return {
        value: i + 4,
        text: `${i + 4}口 ${i + 4}00円(${i + 4}0万円まで保障)`,
      };
    })
);

export const ARRIVAL_TIME_OPTIONS: FormSelectOption[] = [
  {
    value: 'UN_SPECIFIED',
    text: '時間指定なし',
  },
  {
    value: 'AM',
    text: '午前中',
  },
  {
    value: 'PM_2',
    text: '14時〜16時',
  },
  {
    value: 'PM_3',
    text: '16時〜18時',
  },
  {
    value: 'PM_4',
    text: '18時〜20時',
  },
  {
    value: 'PM_6',
    text: '19時〜21時',
  },
];

export const CELLAR_PLAN_TYPE: FormSelectOption[] = [
  {
    value: 'ONLY',
    text: 'オンリーセラー',
  },
  {
    value: 'MIX_6',
    text: 'ミックスセラー6',
  },
  {
    value: 'MIX_12',
    text: 'ミックスセラー12',
  },
  {
    value: 'MIX_M',
    text: 'ミックスセラーマグナム',
  },
];

export const CELLAR_STATUS: FormSelectOption[] = [
  {
    value: 'ORDER_CONFIRMING',
    text: '確認中',
  },
  {
    value: 'CELLAR_OUT_AVAILABLE',
    text: '出庫可',
  },
  {
    value: 'CELLAR_OUT_WAITING',
    text: '出庫待ち',
  },
  {
    value: 'CELLAR_OUT_COMPLETED',
    text: '出庫済み',
  },
  {
    value: 'CELLAR_OUT_CANCELLED',
    text: '出庫キャンセル',
  },
  {
    value: 'CELLAR_OUT_INPROGRESS',
    text: '出庫中',
  },
];

export const SHIPPING_COMPANY: FormSelectOption[] = [
  {
    value: 'YAMATO',
    text: 'ヤマト運輸',
  },
  {
    value: 'SAGAWA',
    text: '佐川急便',
  },
];

export const YAMATO_SHIPPING_COMPANY: FormSelectOption[] = [
  {
    value: 'YAMATO',
    text: 'ヤマト運輸',
  },
];

export const SAGAWA_SHIPPING_COMPANY: FormSelectOption[] = [
  {
    value: 'SAGAWA',
    text: '佐川急便',
  },
];

export const SHIPPING_TYPE: FormSelectOption[] = [
  {
    value: 'NORMAL',
    text: '普通便',
  },
  {
    value: 'COOL',
    text: 'クール便',
  },
];

export const RETURN_SLIP_CODE: FormSelectOption[] = [
  {
    value: 'UN_NECESSARY',
    text: '同梱不要',
  },
  {
    value: 'YAMATO',
    text: '要同梱（ヤマト伝票）',
  },
  {
    value: 'SAGAWA',
    text: '要同梱（佐川伝票',
  },
];

export const RECEIVING_METHOD: FormSelectOption[] = [
  {
    value: 'SHOP',
    text: '来店',
  },
  {
    value: 'DELIVER',
    text: '配送',
  },
];

// ミックスセラーのギフトボックスの画像
export const CELLAR_BOX_IMAGES: Array<CellarBoxImage> = [
  {
    value: 'MIX_6',
    imageUrl: require('~/assets/images/gift-box/mix_cellar_6.png'),
  },
  {
    value: 'MIX_12',
    imageUrl: require('~/assets/images/gift-box/mix_cellar_12.png'),
  },
  {
    value: 'MIX_M',
    imageUrl: require('~/assets/images/gift-box/mix_cellar_m.png'),
  },
];

export const CELLAR_FILTER_TYPES = {
  COLOR: 'color',
  AREA: 'area',
  VINTAGE: 'vintage',
  PRODUCER: 'producer',
};

export const CONSTANTS_TEXT_TYPE = {
  CELLAR_TYPE: 'cellarType',
  CELLAR_STATUS: 'cellarStatus',
  METHOD: 'method',
  TIME: 'time',
  SHIPPING_COMPANY: 'company',
  SHIPPING_TYPE: 'shippingType',
  RETURN_SLIP_CODE: 'returnSlipCode',
};

export const O_CELLAR_STATUSES = {
  ORDER_CONFIRMING: 'O_ORDER_CONFIRMING',
  ORDER_COMPLETED: 'O_ORDER_COMPLETED',
  IN_OPERATING: 'O_CELLAR_IN_OPERATING',
  IN_CONTINUE: 'O_CELLAR_IN_CONTINUE',
  OUT_CONFIRMING: 'O_CELLAR_OUT_CONFIRMING',
  OUT_OPERATING: 'O_CELLAR_OUT_OPERATING',
  OUT_COMPLETED: 'O_CELLAR_OUT_COMPLETED',
};

export const M_CELLAR_STATUSES = {
  ORDER_CONFIRMING: 'M_PACK_ORDER_CONFIRMING',
  SHIPPING_OPERATING: 'M_PACK_ORDER_SHIPPING_OPERATING',
  SHIPPING_COMPLETED: 'M_PACK_ORDER_SHIPPING_COMPLETED',
  IN_CONTINUE: 'M_CELLAR_IN_CONTINUE',
  REQUEST_CONFIRMING: 'M_CELLAR_OUT_REQUEST_CONFIRMING',
  OUT_OPERATING: 'M_CELLAR_OUT_OPERATING',
  OUT_COMPLETED: 'M_CELLAR_OUT_COMPLETED',
  RESTOCK: 'M_CELLAR_OUT_COMPLETED_AND_WAITING_FOR_RESTOCK',
};

export const NOSHI_LIST: FormSelectOption[] = [
  { text: '寿（結婚お祝い等：結びきり十本）', value: 'DMYG000007' },
  { text: '内祝（結婚お祝いの返礼：結びきり十本）', value: 'DMYG000008' },
  { text: '御礼（お世話になったお礼：花結び）', value: 'DMYG000009' },
  { text: '御祝（お祝い事：花結び）', value: 'DMYG000010' },
  { text: '内祝（出産・入学・進学祝等の返礼：花結び）', value: 'DMYG000011' },
  { text: '御中元（花結び）', value: 'DMYG000012' },
  { text: '御歳暮（花結び）', value: 'DMYG000013' },
  { text: '御年賀（花結び）', value: 'DMYG000016' },
  { text: '御見舞（病気見舞い：結びきり五本）', value: 'DMYG000017' },
  { text: '快気祝（お見舞いの返礼：結びきり五本）', value: 'DMYG000018' },
  { text: '志（仏事返礼：黒白結びきり五本）', value: 'DMYG000019' },
  { text: '暑中御見舞（花結び）～8月中旬', value: 'DMYG000014' },
  { text: '残暑御見舞（花結び）～9月上旬', value: 'DMYG000015' },
  { text: '無地（花結び）', value: 'DMYG000023' },
];

export const POLICY_DOWNLOAD_LINK =
  'https://assets.store.enoteca.co.jp/files/71a16c875b7b47ef63554b65084673c2b8d23cf7039da9767b4677895668914a';

export const BILL_ERROR = 'ERROR';
export const CELLAR_LIST_DOWNLOAD_PATH = '/_api/v1/auth/cellar/api/v1/cellar_products/csv';
export const CELLAR_LIST_DOWNLOAD_NAME = 'cellar_item.csv';

export const YAMATO_PRICE_LIMIT = 300000;

export const ERROR_CODE_YAMATO_400 = 'ce_400_cellar_out_product_price_exceeds_yamato_limit';
export const ERROR_CODE_402 = 'ce_402_payment_error';

export const ARRIVAL_TIME_OPTION = {
  UN_SPECIFIED: 'UN_SPECIFIED',
} as const;

export const SHIPPING_COMPANY_NAME = {
  YAMATO: 'YAMATO',
  SAGAWA: 'SAGAWA',
} as const;

export const SHIPPING_COMPANY_SETTING = {
  YAMATO_SETTINGS: 'yamatoSettings',
  SAGAWA_SETTINGS: 'sagawaSettings',
} as const;

export const PAYMENT_STATUS = {
  REFUND: 'REFUND',
};
