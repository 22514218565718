
















































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { extname } from 'path';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';

/** fit の値 */
export type ObjectFit = 'cover' | 'contain' | 'scale-down';

/** position の値 */
export type PositionValue = 'relative' | 'absolute' | 'fixed' | 'sticky' | 'static';

/** フォールバック画像の種類 */
export type FallbackType = 'product' | 'cellar' | 'food' | 'goods' | 'avatar' | 'tag';

/** 100%にする方向 */
export type FullSizeDirection = 'width' | 'height';

/** ファイルパスから画像タイプを取得する */
const getImageType = (src: string | null | undefined, base?: string): string | undefined => {
  if (!src || !base) return;

  const { pathname } = new URL(src, base);
  const ext = extname(pathname);

  switch (ext) {
    case '.png':
      return 'image/png';
    case '.webp':
      return 'image/webp';
    case '.jpg':
    case '.jpeg':
      return 'image/jpeg';
  }
};

export default defineComponent({
  inheritAttrs: false,
  props: {
    src: {
      type: String as PropType<string | null | undefined>,
      default: undefined,
    },
    srcWebp: {
      type: String as PropType<string | null | undefined>,
      default: undefined,
    },
    srcSp: {
      type: String as PropType<string | null | undefined>,
      default: undefined,
    },
    srcSpWebp: {
      type: String as PropType<string | null | undefined>,
      default: undefined,
    },
    position: {
      type: String as PropType<PositionValue | null | undefined>,
      default: undefined,
    },
    objectFit: {
      type: [String, Object] as PropType<ObjectFit | { [Media in 'sp' | 'pc']?: ObjectFit }>,
      default: () => ({}),
    },
    fallback: {
      type: String as PropType<FallbackType>,
      default: 'product',
    },
    noLazy: {
      type: Boolean,
      default: false,
    },
    fullSizeDirection: {
      type: String as PropType<FullSizeDirection>,
      default: 'width',
    },
  },
  setup(props) {
    const { FRONTEND_ORIGIN } = useRuntimeConfig();
    const mainImageType = computed(() => getImageType(props.src, FRONTEND_ORIGIN));
    const spImageType = computed(() => getImageType(props.srcSp, FRONTEND_ORIGIN));
    const fallbackImage = computed((): string => {
      switch (props.fallback) {
        case 'cellar':
          return require('~/assets/images/no-image/cellar.png');
        case 'food':
          return require('~/assets/images/no-image/food.png');
        case 'goods':
          return require('~/assets/images/no-image/goods.png');
        case 'avatar':
          return require('~/assets/svg/icon_people.svg');
        case 'tag':
          return require('~/assets/images/content-ogp-fallback.png');
        default:
          return require('~/assets/images/common/no-image.png');
      }
    });
    const fallbackImageWebp = computed((): string | undefined => {
      switch (props.fallback) {
        case 'cellar':
          return require('~/assets/images/no-image/cellar.png?webp');
        case 'food':
          return require('~/assets/images/no-image/food.png?webp');
        case 'goods':
          return require('~/assets/images/no-image/goods.png?webp');
        case 'avatar':
          return;
        case 'tag':
          return require('~/assets/images/content-ogp-fallback.png?webp');
        default:
          return require('~/assets/images/common/no-image.png?webp');
      }
    });

    return {
      mainImageType,
      spImageType,
      fallbackImage,
      fallbackImageWebp,
    };
  },
});
