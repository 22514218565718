import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { RootStore } from '~/composables/useStore';
import { COOKIE_KEY } from '~/constants';
import { isEnotecaApp } from '~/utils';
import { PublicRuntimeConfig } from '~/config/runtimeConfig';
import type { LoggerLog } from '~/serverMiddleware/api/v1/handlers/logger';

type Logger = {
  send(log: Omit<LoggerLog, 'platform' | 'fingerprint' | 'uuid'>): void;
};

export const sendTrackingLog = (log: Omit<LoggerLog, 'fingerprint'>): void => {
  const params = new URLSearchParams();

  params.append('m', log.method);
  params.append('u', log.url);
  params.append('s', log.status);

  if (log.uuid) {
    params.append('uu', log.uuid);
  }

  if (log.errorCode) {
    params.append('e', log.errorCode);
  }

  if (log.platform) {
    params.append('p', log.platform);
  }

  if (typeof navigator.sendBeacon === 'function') {
    navigator.sendBeacon('/_api/v1/logger', params);
  }
};

export default defineNuxtPlugin(({ $cookies, app, ...context }, inject) => {
  const store = context.store as RootStore;
  const config = context.$config as PublicRuntimeConfig;

  const logger: Logger = {
    send(log) {
      if (config.ENABLED_API_LOGGER !== 'enabled') return;

      const uuid = store.state.user.info.userBase?.uuid;

      if (process.server) {
        const fingerprint =
          $cookies.get(COOKIE_KEY.API_LOGGER_FINGERPRINT) ||
          $cookies.get(COOKIE_KEY.API_LOGGER_FINGERPRINT, { fromRes: true });
        const result: LoggerLog = {
          ...log,
          platform: 'server',
          uuid,
          fingerprint,
        };

        // eslint-disable-next-line no-console
        console.log(JSON.stringify(result));
      } else {
        sendTrackingLog({
          ...log,
          uuid,
          platform: isEnotecaApp(app.$ua.original()) ? 'app' : 'client',
        });
      }
    },
  };

  inject('logger', logger);
});

declare module 'vue/types/vue' {
  interface Vue {
    $logger: Logger;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $logger: Logger;
  }
}
