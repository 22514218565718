import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { APIError } from '~/utils/error/APIError';
import { ConsolsActionPayload, ConsolsResponse } from '~/types/api';

export type Ctx = DefineActionContext<never, never, never>;

export const actions = {
  async getUserProfile(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/profile', 'get'>) {
    return await this.$consolsAxios.$get('/api/v1/profile', payload);
  },
  async postUserProfile(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/profile', 'post'>) {
    return await this.$consolsAxios.$post('/api/v1/profile', payload);
  },
  async postRegister(
    this: Vue,
    context: Ctx,
    { body, cancelToken }: ConsolsActionPayload<'/api/v1/user/register', 'post'>
  ): Promise<ConsolsResponse<'/api/v1/user/register', 'post'>> {
    try {
      return await this.$axios.$post('/_api/v1/signup', body, { cancelToken });
    } catch (err) {
      throw new APIError('consols', err);
    }
  },
};

export type Store = DefineStoreModule<'user/profile', never, never, never, typeof actions>;
