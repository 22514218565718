/**
 * 入力値 fooBar or foo-bar をパスカルケース FooBar に変換する
 * @param value 入力値
 */
export const toPascalCase = (value: string): string => {
  return value
    .split('-')
    .map((chunk) => [chunk[0].toUpperCase(), chunk.slice(1)].join(''))
    .join('');
};
