import { render, staticRenderFns } from "./GlobalHelloBar.vue?vue&type=template&id=992a6d18&scoped=true&"
import script from "./GlobalHelloBar.vue?vue&type=script&lang=ts&"
export * from "./GlobalHelloBar.vue?vue&type=script&lang=ts&"
import style0 from "./GlobalHelloBar.vue?vue&type=style&index=0&id=992a6d18&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "992a6d18",
  null
  
)

export default component.exports