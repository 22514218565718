import { defineNuxtPlugin, onGlobalSetup } from '@nuxtjs/composition-api';
import { useRandomValueCookie } from '~/composables/useRandomValueCookie';
import { COOKIE_KEY } from '~/constants';

export default defineNuxtPlugin(() => {
  onGlobalSetup(() => {
    const { setCookie } = useRandomValueCookie();
    setCookie(COOKIE_KEY.SILVER_EGG_RANDOM);
  });
});
