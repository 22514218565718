import { computed, defineNuxtPlugin, onGlobalSetup } from '@nuxtjs/composition-api';
import { useRandomValueCookie } from '~/composables/useRandomValueCookie';
import { useStore } from '~/composables/useStore';
import { COOKIE_KEY } from '~/constants';

export default defineNuxtPlugin(() => {
  onGlobalSetup(async () => {
    const store = useStore();
    const { setCookie, hasPurchaseHistoryCookie } = useRandomValueCookie();
    if (hasPurchaseHistoryCookie.value) return;

    const authenticated = computed(() => store.state.auth.authenticated);
    if (!authenticated.value) return;

    // ログイン済みユーザーが購入履歴ありの場合はcookieに保存
    try {
      const { is_first_purchase } = await store.dispatch('modules/mypage/coupon/fetchPersonalCoupons', {});
      if (!is_first_purchase) setCookie(COOKIE_KEY.HAS_PURCHASE_HISTORY);
    } catch {
      // 初回購入特典表示の判定に使用されるだけなので、エラーの場合は何もしない
    }
  });
});
