import { paths as Paths } from '~/types/api/openapi/ec';

// 存在するメソッドとパスのオブジェクト
type ECMethodPath = {
  [Path in keyof Paths]: {
    [Method in keyof Paths[Path]]: {
      method: Method;
      path: Path;
    };
  }[keyof Paths[Path]];
}[keyof Paths];

// 認証が必要なパスとメソッドの対応
export const ecAuthPaths: ECMethodPath[] = [
  { method: 'delete', path: '/api/v1/cart_items/{id}' },
  { method: 'delete', path: '/api/v1/cart_set_items/{id}' },
  { method: 'get', path: '/api/v1/carts' },
  { method: 'put', path: '/api/v1/carts' },
  { method: 'put', path: '/api/v1/carts/{id}/upload' },
  { method: 'post', path: '/api/v1/carts/login' },
  { method: 'post', path: '/api/v1/carts/value_card' },
  { method: 'put', path: '/api/v1/distribution_course_orders/{id}' },
  { method: 'post', path: '/api/v1/distribution_course_subscription_simulations' },
  { method: 'get', path: '/api/v1/distribution_course_subscriptions' },
  { method: 'post', path: '/api/v1/distribution_course_subscriptions' },
  { method: 'put', path: '/api/v1/distribution_course_subscriptions/{id}' },
  { method: 'get', path: '/api/v1/distribution_course_subscriptions/recent_orders' },
  { method: 'get', path: '/api/v1/distribution_course_subscription_orders/{id}/delivery_dates' },
  { method: 'post', path: '/api/v1/order_simulations' },
  { method: 'get', path: '/api/v1/orders' },
  { method: 'get', path: '/api/v1/orders_latest' },
  { method: 'get', path: '/api/v1/orders_primeur' },
  { method: 'post', path: '/api/v1/orders' },
  // @ts-expect-error
  { method: 'delete', path: '/api/v1/orders/{id}' },
  { method: 'get', path: '/api/v1/orders/{id}' },
  { method: 'post', path: '/api/v1/orders/{id}/cancel' },
  { method: 'post', path: '/api/v1/orders/{id}/cancel_paidy_tentative_order' },
  { method: 'post', path: '/api/v1/orders/{id}/receipt' },
  { method: 'get', path: '/api/v1/orders/{id}/paidy_payload' },
  { method: 'get', path: '/api/v1/pos_sales/{id}' },
  { method: 'post', path: '/api/v1/paidy/authorize_success' },
  { method: 'get', path: '/api/v1/user_promotions' },
  { method: 'post', path: '/api/v1/user_amazon_pay/orders' },
  { method: 'post', path: '/api/v1/rakuten_pay/orders' },
  // @ts-expect-error
  { method: 'patch', path: '/api/v1/reviews/{id}/likes' },
  // @ts-expect-error
  { method: 'post', path: '/api/v1/reviews/distribution_courses' },
  { method: 'post', path: '/api/v1/reviews/product_variants' },
  { method: 'get', path: '/api/v1/users/reviews' },
  { method: 'get', path: '/api/v1/user_liked_reviews' },
  { method: 'put', path: '/api/v1/reviews/{id}/likes' },
  { method: 'delete', path: '/api/v1/reviews/{id}/likes' },
  { method: 'get', path: '/api/v1/primeur_delivery_date_times' },
  { method: 'post', path: '/api/v1/primeur_delivery' },
  { method: 'post', path: '/api/v1/veritrans/payments/{transaction_id}/result' },
  { method: 'get', path: '/api/v1/raffle_applications' },
  { method: 'post', path: '/api/v1/raffle_applications' },
];
