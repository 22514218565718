import { definePreset } from '../core/preset';

/**
 * クレジットカードのルールプリセット
 */
export const creditCard = definePreset({
  rules: {
    min: 12,
    max: 16,
    numeric: true,
  },
});
