import { useRoute, useRouter, computed, useFetch } from '@nuxtjs/composition-api';
import { defaultsDeep, merge, castArray } from 'lodash-es';
import { MetaInfo } from 'vue-meta';
import { RawLocation } from 'vue-router';
import { useStore } from '~/composables/useStore';
import { DEFAULT_TITLE, TEMPLATE_TITLE } from '~/constants';
import { nonNullable, url } from '~/utils';
import { APIError, PageError } from '~/utils/error';
import {
  JsonLdBlogPosting,
  JsonLdSearchAction,
  JsonLdProduct,
  JsonLdHowTo,
  JsonLdFAQPage,
} from '~/types/common/jsonld';
import { BreadcrumbsItem } from '~/types/ui';

export type JsonLdData = JsonLdBlogPosting | JsonLdSearchAction | JsonLdProduct | JsonLdHowTo | JsonLdFAQPage;

type FetchState = ReturnType<typeof useFetch>['fetchState'];

// ネストしたオブジェクトのプロパティを必須化する
type DeepRequired<T = unknown> = T extends Record<any, any>
  ? T extends any[]
    ? T
    : T extends (...args: any[]) => any
    ? T
    : {
        [K in keyof T]-?: DeepRequired<T[K]>;
      }
  : T;

export type LayoutProps = {
  // レイアウトの表示に関する設定
  layout?: {
    // GlobalHeader を表示するか
    header?: 'always' | 'always-only-menu' | 'always-simple' | 'never';
    /** GlobalHelloBar を表示するか（デフォルトは表示ありなので、needed設定不要） */
    helloBar?: 'needed' | 'never';
    // GlobalTopBanner を表示するか
    topBanner?: 'needed' | 'never';
    // GlobalHeaderMenu を表示するか
    headerMenu?: 'always' | 'pc' | 'sp' | 'never';
    // GlobalFooterMenu を表示するか
    footerMenu?: 'always' | 'never';
    // GlobalFooter を表示するか
    footer?: 'always' | 'never';
    // footer下のmargin を表示するか
    footerMargin?: 'always' | 'never';
  };
  // head タグに関する設定
  head?: {
    title?: string | null;
    titleTemplate?: MetaInfo['titleTemplate'] | null;
    description?: string;
    keywords?: string | string[];
    canonical?: RawLocation | null;
    noindex?: boolean;
  };
  // OGP に関する設定
  og?: {
    title?: string | null;
    description?: string | null;
    type?: string;
    url?: string | null;
    image?: string | null;
  };
  // Facebook メタタグに関する設定
  fb?: {
    app_id?: string;
  };
  // Facebook メタタグに関する設定
  twitter?: {
    card?: string;
    site?: string;
    creator?: string;
  };
  // パンくずナビに関する設定
  // 一つでも指定するとホームのリンクが自動で追加される
  breadcrumbs?: BreadcrumbsItem[];
  // 構造化データ
  jsonld?: JsonLdData[];
  // fetchState
  fetchState?: FetchState | FetchState[];
};

export type OptimizedLayoutProps = DeepRequired<LayoutProps>;

export const FALLBACK_ROUTE_NAME = 'unknown_route';

export const FALLBACK_LAYOUT_PROPS: OptimizedLayoutProps = {
  layout: {
    header: 'always',
    helloBar: 'needed',
    topBanner: 'never',
    headerMenu: 'always',
    footerMenu: 'always',
    footer: 'always',
    footerMargin: 'always',
  },
  head: {
    title: null,
    titleTemplate: (titleChunk: string) => (titleChunk ? `${titleChunk}${TEMPLATE_TITLE}` : DEFAULT_TITLE),
    description:
      'ENOTECA（エノテカ）の公式通販サイトです。全国に60店舗以上を展開するワイン専門店。初めてのご注文は送料無料！世界中の有名ワインを正規輸入代理店として直輸入。ボルドーワインをはじめ、2,000種を超える日本最大級の品揃えをご用意しています。',
    keywords: [],
    canonical: null,
    noindex: false,
  },
  og: {
    title: null,
    description: null,
    type: 'website',
    url: null,
    image: null,
  },
  fb: {
    app_id: '994388970608433',
  },
  twitter: {
    card: 'summary',
    site: '@enoteca_online',
    creator: '@enoteca_online',
  },
  breadcrumbs: [],
  jsonld: [],
  fetchState: [],
};

export const NOT_FOUND_LAYOUT_PROPS: LayoutProps = {
  layout: {
    footerMenu: 'never',
  },
  head: {
    title: null,
  },
  breadcrumbs: [{ title: 'ワイン通販エノテカ', to: url('TOP') }],
};

export const optimizeLayoutProps = (layoutProps: LayoutProps = {}): OptimizedLayoutProps => {
  return defaultsDeep(layoutProps, FALLBACK_LAYOUT_PROPS);
};

export const useLayoutPropsProvider = () => {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const optimizedLayoutProps = computed(() => {
    const routeComponentInstances = route.value.matched.map(({ instances }) => instances.default);
    const layoutProps = routeComponentInstances.reduce<LayoutProps>((layoutProps, instance) => {
      if (!instance) {
        return layoutProps;
      }

      // 型定義されていない _uid からインスタンスの uid を取得
      // @ts-expect-error
      const uid: number = instance._uid;
      const targetLayoutProps = Object.entries(store.state.internals.layoutProps).find(([key]) => {
        return key === `${uid}`;
      })?.[1];

      if (!targetLayoutProps?.value) {
        return layoutProps;
      }

      return merge(layoutProps, targetLayoutProps.value, {
        fetchState: castArray(layoutProps.fetchState)
          .concat(...castArray(targetLayoutProps.value.fetchState))
          .filter(nonNullable),
      });
    }, {});

    const error = castArray(layoutProps.fetchState).find((fetchState) => fetchState?.error)?.error;
    const isNotFound = (APIError.isAPIError(error) || PageError.isPageError(error)) && error.statusCode === 404;

    if (isNotFound) {
      return optimizeLayoutProps({ ...NOT_FOUND_LAYOUT_PROPS, fetchState: layoutProps.fetchState });
    }

    const breadcrumbs =
      layoutProps.breadcrumbs && layoutProps.breadcrumbs.length > 0
        ? [{ title: 'ワイン通販エノテカ', to: url('TOP') }, ...layoutProps.breadcrumbs]
        : [];

    return optimizeLayoutProps({ ...layoutProps, breadcrumbs });
  });

  router.afterEach((to) => {
    // ルートにマッチしたページコンポーネントの uid リスト
    const uidList = to.matched
      .filter(({ instances }) => !!instances.default)
      // @ts-expect-error
      .map(({ instances }) => `${instances.default._uid}`);

    // マッチしたルートではない uid をStateから削除する
    Object.keys(store.state.internals.layoutProps).forEach((uid) => {
      if (!uidList.includes(uid)) {
        store.commit('internals/layoutProps/clearLayoutProps', uid);
      }
    });
  });

  return optimizedLayoutProps;
};
