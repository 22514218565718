import dayjs from 'dayjs';
import { ArticlesSearchParams } from '~/types/modules/contents';
import { ArticleType, BooleanLike } from '~/enums';

/**
 * 絞り込み
 * @param param 絞り込み条件
 * @returns {string}
 */
export const contentsSearchParams = (params: ArticlesSearchParams) => {
  const timestamp = dayjs().toISOString();
  const { articleType, title, wineType, area, categoryId, authorId, tagId, articleIds } = escapeParam(params);
  const shouldIncludeExpiredArticle =
    params.shouldIncludeExpiredArticle && params.shouldIncludeExpiredArticle === BooleanLike.TRUE;

  let q = `mst:article AND article_type_s: ${articleType}`;

  // タイトル
  if (title) {
    const titleArr = title.split(/\s+/);

    q += ` AND article_title_hn: (${titleArr.map((title) => `"${title}"`).join(' AND ')})`;
  }
  // ワインタイプ
  if (wineType) q += ` AND cms_wine_type_s: ${wineType}`;
  // 生産地
  if (area) q += ` AND cms_producing_s: ${area}`;
  // カテゴリ
  if (categoryId) q += ` AND article_category_s: ${categoryId}`;
  // 作成者
  if (authorId) q += ` AND article_magazine_writer_id_i: ${authorId}`;
  // タグID
  if (tagId) q += ` AND tag_id_tm: ${tagId}`;
  //  article_idリスト
  if (articleIds) q += ` AND iid: (${articleIds})`;
  // 特集かどうか
  if (articleType === ArticleType.Archives && !shouldIncludeExpiredArticle)
    q += ` NOT archive_publish_at_d: [${timestamp} TO *] NOT archive_expire_at_d: [* TO ${timestamp}]`;
  if (!shouldIncludeExpiredArticle) q += ` NOT is_display_archive_i: 0`;

  q += ` NOT article_publish_start_date_d: [${timestamp} TO *] NOT article_publish_end_date_d: [* TO ${timestamp}]`;

  return {
    q,
  };
};

/**
 * パラメータをエスケープする
 * @param param 絞り込み条件
 * @returns {ArticlesSearchParams}
 */
const escapeParam = (params: ArticlesSearchParams) => {
  const parsedParams: ArticlesSearchParams = {};

  Object.entries(params).forEach((param) => {
    const key = param[0] as keyof ArticlesSearchParams;
    const value = param[1];

    if (!value) return;

    if (typeof value === 'number') {
      parsedParams[key] = value;

      return;
    }

    // eslint-disable-next-line no-useless-escape
    parsedParams[key] = value.replace(/[+\-&|!(){}[\]^"~*?:/\\]/g, '\\$&');
  });

  return parsedParams;
};
