import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { DISPLAY_ORDER_NUM } from '~/constants/history';
import { ECActionPayload } from '~/types/api';
import {
  OrderRow,
  OrderShowResponse,
  OrderAddressRes,
  OrderPaymentRes,
  OrderShipmentRes,
  OrderDiscountRes,
  OrderPosResponse,
  OrderPosSingleProduct,
  OrderPosBundleProduct,
  OrderItemRes,
  OrderSetItemRes,
} from '~/types/modules/mypage';

/** State */
export type State = {
  orderList: Array<OrderRow>;
  primeurOrderList: Array<OrderRow>;
  latestOrder: OrderRow;
  orderDetail: OrderShowResponse;
  posOrderDetail: OrderPosResponse;
  total: number;
};

export const state = (): State => ({
  orderList: [],
  primeurOrderList: [],
  latestOrder: {
    completed_at: '',
    id: 0,
    is_pick_up_available: false,
    is_pos: false,
    is_primeur: false,
    is_multiple_shipping: false,
    is_cellar_in: false,
    number: '',
    order_type: 'general',
    sales_location: '',
    status: 'arranged',
    total: 0,
    is_cancelable: false,
  },
  orderDetail: {
    commission: 0,
    completed_at: '',
    cool_cost: 0,
    discounts: <Array<OrderDiscountRes>>[],
    flexible_set_items: <Array<OrderSetItemRes>>[],
    information_remarks: '',
    is_cancelable: false,
    is_cellar_pack: false,
    is_pick_up_available: false,
    is_primeur: false,
    item_total_amount: 0,
    items: <Array<OrderItemRes>>[],
    order_number: '',
    order_status: 'changeable',
    order_type: 'general',
    payment: <OrderPaymentRes>{},
    price_per_tax_rate_set: {},
    request_remarks: '',
    receipt_status: 'unavailable',
    sales_location: '',
    sender_address: <OrderAddressRes>{},
    shipments: <Array<OrderShipmentRes>>[],
    total: 0,
    total_shipping_cost: 0,
    used_point: 0,
    item_discounts: [],
  },
  posOrderDetail: {
    bundle_products: <Array<OrderPosBundleProduct>>[],
    commission_amount: 0,
    id: 0,
    order_date: '',
    place_long_name: '',
    sales_method: 0,
    single_products: <Array<OrderPosSingleProduct>>[],
    total_sales: 0,
    is_advances_received: false,
    is_handed_over: false,
    sales_type: 0,
    shipping_amount: 0,
    product_total_amount_excluding_tax: 0,
    price_data_per_tax_rate: [],
  },
  total: 0,
});

/** Getters */
export const getters = {
  getLimitedOrederList: (state: State) => (limit: number) => {
    return state.orderList.slice(0, DISPLAY_ORDER_NUM * limit);
  },
};

/** Mutations */
export const mutations = {
  setOrderList(state: State, orderList: Array<OrderRow>) {
    state.orderList = orderList;
  },
  setLatestOrder(state: State, latestOrder: OrderRow) {
    state.latestOrder = latestOrder;
  },
  setPrimeurOrderList(state: State, primeurOrderList: Array<OrderRow>) {
    state.primeurOrderList = primeurOrderList;
  },
  setOrderDetail(state: State, orderDetail: OrderShowResponse) {
    state.orderDetail = orderDetail;
  },
  setOrderTotal(state: State, total: number) {
    state.total = total;
  },
  setPosOrderDetail(state: State, posOrderDetail: OrderPosResponse) {
    state.posOrderDetail = posOrderDetail;
  },
  resetOrderList(state: State) {
    state.orderList = [];
  },
};

export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;

/** Actions */
export const actions = {
  /** 注文履歴情報を取得 */
  async fetchOrderHistories(this: Vue, { commit }: Ctx, payload: ECActionPayload<'/api/v1/orders', 'get'>) {
    const response = await this.$ecAxios.$get('/api/v1/orders', payload);
    // 選択年度に注文履歴があればそのまま更新
    if (response.data && response.total) {
      commit('setOrderList', response.data);
      commit('setOrderTotal', response.total);
      // なければカウントは0にする
    } else {
      commit('setOrderTotal', 0);
    }
  },

  /** 最新注文情報を取得 */
  async fetchLatestOrderHistory(this: Vue, { commit }: Ctx, payload: ECActionPayload<'/api/v1/orders_latest', 'get'>) {
    const response = await this.$ecAxios.$get('/api/v1/orders_latest', payload);
    if (response.data.length > 0) {
      commit('setLatestOrder', response.data[0]);
    }
  },

  /** プリムール注文履歴情報を取得 */
  async fetchPrimeurOrderHistories(
    this: Vue,
    { commit }: Ctx,
    payload: ECActionPayload<'/api/v1/orders_primeur', 'get'>
  ) {
    const response = await this.$ecAxios.$get('/api/v1/orders_primeur', payload);
    if (response.data) {
      commit('setPrimeurOrderList', response.data);
    }
  },

  /** 注文詳細情報を取得 */
  async fetchOrderDetail(this: Vue, { commit }: Ctx, payload: ECActionPayload<'/api/v1/orders/{id}', 'get'>) {
    const response = await this.$ecAxios.$get('/api/v1/orders/{id}', payload);
    commit('setOrderDetail', response);
  },

  /** POS注文詳細情報を取得 */
  async fetchPosOrderDetail(this: Vue, { commit }: Ctx, payload: ECActionPayload<'/api/v1/pos_sales/{id}', 'get'>) {
    const response = await this.$ecAxios.$get('/api/v1/pos_sales/{id}', payload);
    commit('setPosOrderDetail', response);
  },

  /** 注文キャンセル */
  async postOrderCancel(this: Vue, context: Ctx, payload: ECActionPayload<'/api/v1/orders/{id}/cancel', 'post'>) {
    await this.$ecAxios.$post('/api/v1/orders/{id}/cancel', payload);
  },
};

export type Store = DefineStoreModule<
  'modules/mypage/history',
  State,
  typeof getters,
  typeof mutations,
  typeof actions
>;
