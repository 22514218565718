import { DELIVERY_DATE_UNDEFINED_OPTION, DELIVERY_TIME_UNDEFINED, PAYMENT_METHOD_ID } from '~/constants/order';
import { createGiftsReq } from '~/utils/order/createGiftsReq';
import { ECSchema } from '~/types/api';
import {
  OrderSimulationShipment,
  OrderShipment,
  UserOptionType,
  UserOptionShipment,
  UserOptionPayment,
  GiftOption,
} from '~/types/modules/order';
import { ShippingType } from '~/enums/order';

type CommonType = {
  [K in keyof OrderShipment & keyof OrderSimulationShipment]: OrderShipment[K];
};

const commonReq = (shipment: UserOptionShipment, payment: UserOptionPayment): CommonType => ({
  /* 配送方法 */
  shipping_method_id: shipment.toRentalCellar ? undefined : shipment.shippingMethodId,
  /* お引き渡し方法ID(出荷方法ID) 1: セラーIN 2: 出荷 */
  shipping_type_id: shipment.toRentalCellar ? ShippingType.CELLAR : ShippingType.SHIP,
  /* 配送希望日付 */
  delivery_date:
    shipment.toRentalCellar || shipment.deliveryDate === DELIVERY_DATE_UNDEFINED_OPTION.VALUE
      ? undefined
      : shipment.deliveryDate,
  /* 配送希望日時 */
  delivery_time_id: shipment.toRentalCellar
    ? undefined
    : shipment.deliveryTimeId !== DELIVERY_TIME_UNDEFINED.VALUE
    ? shipment.deliveryTimeId
    : undefined,
  delivery_address_name: payment.paymentMethodId !== PAYMENT_METHOD_ID.AMAZON_PAY ? shipment.deliveryName : undefined,
  specification_type: shipment.specificationType,
  gifts: [],
  items: shipment.items,
  set_items: shipment.setItems,
  address:
    payment.paymentMethodId === PAYMENT_METHOD_ID.AMAZON_PAY && shipment.address?.prefecture_id
      ? { ...shipment.address, prefecture_id: shipment.address.prefecture_id }
      : undefined,
  leaflets: shipment.leaflets ?? [],
});

export const createShipmentsSimulationReq = (
  option: UserOptionType,
  giftOptions: GiftOption[]
): OrderSimulationShipment[] => {
  return option.shipments.map((shipment) => ({
    ...commonReq(shipment, option.payment),
    gifts: createGiftsReq(shipment, giftOptions, 'simulation'),
  }));
};

export const createShipmentsOrderReq = (
  option: UserOptionType,
  giftOptions: GiftOption[],
  simulationShipments?: Array<ECSchema<'order.SimulationShipmentRes'>>
): OrderShipment[] => {
  return option.shipments.map((shipment, index) => ({
    ...commonReq(shipment, option.payment),
    has_delivery_box: shipment.toRentalCellar ? undefined : shipment.hasDeliveryBox,
    wc_info: shipment.wineCellarInfo != null ? shipment.wineCellarInfo : undefined,
    gifts: createGiftsReq(
      shipment,
      giftOptions,
      'order',
      simulationShipments ? simulationShipments[index].items_included_gift_box : undefined
    ),
  }));
};
